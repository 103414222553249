import styled from "styled-components";

const HorizontalLayoutLayoutWrap = styled.section`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12px, 1fr));
  gap: 5px; */
  display: flex;
  justify-content: ${(props) => (props.aligment ? `${props.aligment}` : "flex-start")};
  width: 100%;

  /* & > * {
    padding: 10px;
  } */
`;

export { HorizontalLayoutLayoutWrap };
