import { useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { useMapboxDraw } from "../..";
import { inputsActions, canopyActions } from "../..";

const HandleFeatureClick = ({ features }) => {
  const dispatch = useDispatch();
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const draw = useMapboxDraw();

  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);

  useEffect(() => {
    if (!map || !draw) return;

    map.on("click", handleClick);

    return () => {
      map.off("click", handleClick);
    };
  }, [map, features]);

  const selectFeatureOnMap = (id) => {
    if (!id) return;
    draw.changeMode("direct_select", { featureId: id });
  };

  const handleClick = (e) => {
    const featureIds = draw.getFeatureIdsAt(e.point);
    const clickedFeature = featureIds.length > 0 ? draw.get(featureIds[0]) : null;
    if (clickedFeature) {
      if (clickedFeature.properties.shape === "siteFeature") {
        dispatch(inputsActions.update_site_input("selectedFeatureId", clickedFeature.properties.index));
        selectFeatureOnMap(clickedFeature.properties.index);
      } else if (["tree", "building", "treeline"].includes(clickedFeature.properties.shape)) {
        dispatch(canopyActions.updateCanopyInputs("selected_shade_object_id", clickedFeature.id));
        selectFeatureOnMap(clickedFeature.id);
      }
    } else if (clickedFeature === null) {
      //have to check for null to prevent this from running on polygon creation
      // If no feature is clicked, reset the selectedFeatureId so nothing is selected
      if (selectedFeatureId) dispatch(inputsActions.update_site_input("selectedFeatureId", undefined));
      if (selected_shade_object_id) dispatch(canopyActions.updateShadeObject("delete_shade_object", undefined));
    }
  };

  return null;
};

export { HandleFeatureClick };
