import React, { useMemo, useState, useEffect } from "react";
import { useMap, Source, Layer } from "react-map-gl";
import { useSelector } from "react-redux";
import { MapboxTopoScaleLayer } from "./MapboxTopoScaleLayer";

const TopoModeMapper = {
  ele: "vis_ele_ungraded_avail",
  NS: "vis_ns_ungraded_avail",
  EW: "vis_ew_ungraded_avail",
  U: "vis_max_ungraded_avail",
  "ele/G": "graded_avail",
  "NS/G": "vis_ns_graded_avail",
  "EW/G": "vis_ew_graded_avail",
  "U/G": "vis_max_graded_avail",
  CF: "graded_avail",
  Off: "Off",
};

const MapboxTopoLayer = () => {
  const topo_mode = useSelector((state) => state.inputs.topo_mode);
  const topo_id = useSelector((state) => state.inputs.topo_id);
  const topo_url = useSelector((state) => state.inputs.topo_url);
  const topo_scale_url = useSelector((state) => state.inputs.topo_scale_url);
  const inputs = useSelector((state) => state.inputs);
  const show_live_report = useSelector((state) => state.sift.ioManager.uiState.show_live_report);
  const using_canopy = window.location.pathname === "/canopy" || window.location.pathname === "/canopy/";
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const [before_layer, set_before_layer] = useState();

  useEffect(() => {
    if (!map) return;
    map.on("idle", () => {
      set_before_layer(map.getStyle().layers[4].id);
    });
  }, [map]);

  // Define a fixed source ID
  const sourceId = "topoSource";

  const layerData = useMemo(() => {
    if (topo_id && topo_mode !== "Off" && inputs[TopoModeMapper[topo_mode]]) {
      return {
        type: "raster",
        tiles: [topo_url],
        tileSize: 256,
        scheme: "tms",
      };
    }
    return null;
  }, [topo_id, topo_mode, topo_url, inputs]);

  if (!layerData || !before_layer) {
    return null;
  }

  return (
    <>
      <Source id={sourceId} type={layerData.type} tiles={layerData.tiles} tileSize={layerData.tileSize} scheme={layerData.scheme}>
        <Layer id={sourceId + "-layer"} type="raster" source={sourceId} paint={{ "raster-opacity": 0.8 }} beforeId={before_layer} />
      </Source>
      {topo_scale_url && topo_mode !== "Off" && !show_live_report && inputs[TopoModeMapper[topo_mode]] && !using_canopy && <MapboxTopoScaleLayer topo_scale_url={topo_scale_url} />}
    </>
  );
};

export { MapboxTopoLayer };
