import styled from "styled-components";

const SelectContainer = styled.section`
  position: relative;
  display: flex;
  align-items: center;
`;

const Prefix = styled.span`
  margin-right: 5px;
  color: #bbb;
`;

const Suffix = styled.span`
  margin-left: 5px;

  svg {
    fill: #ccc;
    width: 14px;
  }
`;

const StyledSelectContainer = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s var(--easing);
  max-height: 24px;

  &:hover {
    border-color: var(--primary-brand-color);
  }
`;

const StyledSelect = styled.section`
  flex-grow: 1;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DropdownMenu = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: calc(100% - 3px);
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background: #fff;
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: var(--subtle-box-shadow);
`;

const DropdownItem = styled.li`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: #f0f0f0;
  }
`;

export { SelectContainer, StyledSelectContainer, Prefix, Suffix, StyledSelect, DropdownMenu, DropdownItem };
