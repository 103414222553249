import React from "react";
import { MBLayoutLayer, RoadsLayer, EditedMBLayout } from "./";
import { useSelector } from "react-redux";

const MapboxLayoutLayers = ({ show_roads = true, show_racks = true, show_inverters = true, liveReportMap = false }) => {
  const layout_visible = useSelector((state) => state.inputs.uiState.layout_visible);
  const edited_layouts = useSelector((state) => state.inputs?.edited_layouts);
  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id || undefined);
  if (!layout_visible) return <></>;

  return (
    <>
      {show_roads && <RoadsLayer />}
      {/* <MBLayoutLayer /> */}
      {selectedResultId && edited_layouts && edited_layouts[selectedResultId] ? (
        <EditedMBLayout show_inverters={show_inverters} show_racks={show_racks} />
      ) : (
        <MBLayoutLayer show_inverters={show_inverters} show_racks={show_racks} />
      )}
    </>
  );
};

export { MapboxLayoutLayers };
