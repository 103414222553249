import { useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { useSelector } from "react-redux";

import { usePrevious, NaturalLocations, TerrasmartLocations, getCenterPoint, getBounds } from "../../../Redux";

const ChangeMapView = ({ map_center, zoom }) => {
  const map = useMap();
  const url = window.location.pathname;
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";

  const site_features = useSelector((state) => state.inputs.site_features);
  const user_map_center = useSelector((state) => state.user.map_center);
  const use_random_map_location = useSelector((state) => state.user.use_random_map_location);
  const project_loading = useSelector((state) => state.projects.loading);
  const new_project_loading = useSelector((state) => state.sift.projectManager.new_project_loading);
  const prev_new_project_loading = usePrevious(new_project_loading);
  const getting_user_data = useSelector((state) => state.user.getting_user_data);

  const pan_to_feature_id = useSelector((state) => state.inputs.pan_to_feature_id);
  const pan_to_feature_id_prev = usePrevious(pan_to_feature_id);

  useEffect(() => {
    if (pan_to_feature_id && pan_to_feature_id !== pan_to_feature_id_prev) {
      // get selected feature
      //let feature = site_features[pan_to_feature_id];
      // Get the center point of the selected feature
      //let center_point = turf.centroid(feature.geometry).geometry.coordinates;

      // Set the map center to the center point of the feature
      //map.panTo({ lat: center_point[1], lng: center_point[0] });
      //map.setView( [center_point[1],center_point[0]],map.getZoom(), {animate : true })
      //map.flyTo([center_point[1],center_point[0] ], map._zoom + 1);
      // Set the zoom level of the map
      //map.setZoom(map._zoom + 1); // Adjust the zoom level as needed
      //map.fire("zoomextents");

      let layer = getLayer(pan_to_feature_id);
      if (!layer) return;

      let bounds = layer.getBounds();
      // Set the map view to fit the bounds of the polygon with animation
      map.fitBounds(bounds, { animate: true });
    }
  }, [pan_to_feature_id, pan_to_feature_id_prev]);

  const getLayer = (id) => {
    let temp_layer;
    map.eachLayer((layer) => {
      if (layer.feature && layer.feature.properties.index == id) {
        temp_layer = layer;
        return;
      }
    });
    return temp_layer;
  };
  // useMapEvents({
  //   zoomend: (e) => {
  //     console.log("zoomend", e.sourceTarget.getZoom());
  //   },
  // });
  // not sure why but the prop "doubleClickZoom" passed to the main MapContainer parent component doesn't work at all. This however does, and I figured this was the best place to put it.
  useEffect(() => {
    if (!map) return;
    map.doubleClickZoom.disable();
  }, [map]);

  useEffect(() => {
    if (prev_new_project_loading == false && new_project_loading == true) {
      if (!use_random_map_location) {
        map.fitBounds(
          [
            [user_map_center[0], user_map_center[1]],
            [user_map_center[0], user_map_center[1]],
          ],
          { maxZoom: 18 }
        );
      }
    }
  }, [new_project_loading]);

  useEffect(() => {
    if (use_random_map_location || use_random_map_location == undefined) {
      getEarthView();
    } else {
      map?.fitBounds([
        [user_map_center[0], user_map_center[1]],
        [user_map_center[0], user_map_center[1]],
      ]);
    }
  }, [getting_user_data]);

  const getEarthView = () => {
    let views = internal_sift ? TerrasmartLocations : NaturalLocations;
    let viewIndex = Math.floor(Math.random() * Object.values(views).length);
    const location = views[viewIndex];
    map?.panTo([location.latlng.lat, location.latlng.lng]);
    map?.setZoom(location.zoomLevel);
  };

  useEffect(() => {
    map.setView(map_center, zoom || 16);
  }, [map_center]);

  useEffect(() => {
    if (internal_sift) {
      if (url === "/ground" || url === "/ground/") {
        // remove layers when moving back to INTERNAL_SIFT
        map.eachLayer(
          (layer) =>
            (layer.options.shape == "Rectangle" ||
              layer.options.obj_type == "shade_obj" ||
              layer.options.module_layer_id ||
              layer.options.module_cell_id ||
              layer.options.azi_layer_id ||
              (layer.options.imageId && layer.options.platform_pathname == "/canopy") ||
              layer.options.platform_pathname == "/canopy/") &&
            layer.remove()
        );
      } else if (url === "/canopy" || url === "/canopy/") {
        map.eachLayer((layer) => layer.options.imageId && layer.options.platform_pathname == "/ground" && layer.remove());
        // remove layers when moving back to INTERNAL_CANOPY
        // map.eachLayer(
        //   (layer) =>
        //     (layer.options.id == -599 || layer.options.id == -998 || layer.options.id == 597 || layer.options.id == -596 || layer.options.id == -595 || layer.options.id == 0) &&
        //     !layer.options.module_layer_id &&
        //     !layer.options.azi_layer_id &&
        //     layer.remove()
        // );
      }
    }
  }, [url]);

  return null;
};

export { ChangeMapView };
