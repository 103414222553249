import styled from "styled-components";

const TabWrapper = styled.section`
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  /* margin-bottom: 5px; */
`;

const TabItem = styled.section`
  padding: 10px 20px;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  color: ${({ active }) => (active ? "var(--primary-brand-color)" : "#333")};
  border-bottom: ${({ active }) => (active ? "2px solid var(--primary-brand-color)" : "none")};
  &:hover {
    color: var(--primary-brand-color);
  }
`;

export { TabWrapper, TabItem };
