import { useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../DrawToolProvider";
import { handleFeatureCreated } from "../DrawToolProvider/_draw.helpers";
import { inputsActions } from "../..";
import { useDispatch, useSelector } from "react-redux";

const HandleFeatureCreation = () => {
  const dispatch = useDispatch();
  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);
  const is_drawing_ref = useRef(is_drawing);
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const draw = useMapboxDraw();

  useEffect(() => {
    // console.log("is", is_drawing);
    is_drawing_ref.current = is_drawing;
  }, [is_drawing]);

  useEffect(() => {
    if (!map || !draw) return;

    map.on("draw.create", handleCreated);

    return () => {
      map.off("draw.create", handleCreated);
    };
  }, [map, draw]);

  const handleCreated = (e) => {
    if (!draw) return;
    if (!is_drawing_ref.current) return;
    // console.log("Feature created:", e.features[0]);
    if (e.features[0].geometry.type === "LineString" || e.features[0].geometry.type === "Point") return;
    const geoJson = handleFeatureCreated(e.features[0]);
    dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
    draw.delete(e.features[0].id);
  };

  return null;
};

export { HandleFeatureCreation };
