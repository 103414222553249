import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxDrawGeodesic from "mapbox-gl-draw-geodesic";
import drawRectangle from "mapbox-gl-draw-rectangle-mode";
import * as MapboxDrawWaypoint from "mapbox-gl-draw-waypoint";
import { MapboxDrawStyles } from "./MapboxDrawStyles";
const MapboxDrawContext = createContext(null);

const MapboxDrawProvider = ({ map, children }) => {
  const drawRef = useRef(null);
  const [isDrawInitialized, setDrawInitialized] = useState(false);

  const handleDoubleClickVertex = (e) => {
    const featureIds = drawRef.current.getFeatureIdsAt(e.point).filter(Boolean);

    if (featureIds.length > 0) {
      const featureId = featureIds[0];
      const feature = drawRef.current.get(featureId);

      if (feature && feature.geometry.type === "Polygon") {
        const vertexCoords = e.lngLat.toArray();
        const coordinates = feature.geometry.coordinates[0];

        const latTolerance = 12 / 111320; // to increase the tolerance on either of these--just change the first number (12), which is equal to a 12 meter tolerance. This will remove a vertex if you double-click within 12 meters of it.
        const lngTolerance = 12 / (111320 * Math.cos(e.lngLat.lat * (Math.PI / 180)));

        const updatedCoords = coordinates.filter(([lng, lat]) => {
          const lngDiff = Math.abs(lng - vertexCoords[0]);
          const latDiff = Math.abs(lat - vertexCoords[1]);
          return lngDiff > lngTolerance || latDiff > latTolerance;
        });

        if (updatedCoords.length < 3) {
          console.warn("Polygon must have at least 3 vertices");
          return;
        }

        if (updatedCoords.length > 0 && (updatedCoords[0][0] !== updatedCoords[updatedCoords.length - 1][0] || updatedCoords[0][1] !== updatedCoords[updatedCoords.length - 1][1])) {
          updatedCoords.push(updatedCoords[0]);
        }

        // Fire update event with the modified feature so that we can update it in the store
        map.fire("draw.update", {
          action: "remove_vertex",
          updatedFeature: {
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: [updatedCoords],
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (!map || drawRef.current) return;

    let modes = MapboxDraw.modes;
    modes = MapboxDrawGeodesic.enable(modes);
    modes = MapboxDrawWaypoint.enable(modes);

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      controls: {
        polygon: false,
        line_string: false,
        point: false,
        trash: false,
        combine_features: false,
        uncombine_features: false,
      },
      modes: {
        ...modes,
        draw_rectangle: drawRectangle,
      },
      styles: MapboxDrawStyles,
    });

    map.addControl(draw, "top-left");
    drawRef.current = draw;
    setDrawInitialized(true);

    map.on("dblclick", handleDoubleClickVertex);

    return () => {
      if (map && drawRef.current) {
        map.off("dblclick", handleDoubleClickVertex);
        try {
          map.removeControl(drawRef.current);
        } catch (error) {
          console.warn("Error removing MapboxDraw control:", error);
        }
      }
    };
  }, [map]);

  return <MapboxDrawContext.Provider value={isDrawInitialized ? drawRef.current : null}>{children}</MapboxDrawContext.Provider>;
};

const useMapboxDraw = () => {
  return useContext(MapboxDrawContext);
};

export { MapboxDrawProvider, useMapboxDraw };
