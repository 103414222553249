import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";

// antd
import { Tooltip, Modal } from "antd";
import { Button } from "../BaseComponents";
import { CheckCircleTwoTone, DownOutlined, RightOutlined, UpOutlined, CaretRightOutlined, CaretLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { validateWithSchema, cleanSiftInputs } from "../../_Schemas";
import { useAuth } from "../Auth";

// components
import { InputHeader } from "./InputHeader";
import { ResultTab, ErrorFromSIFT } from "./ResultTab";
import { Tabs, Tab } from "../BaseComponents";

//tutorial stuff
import { TutorialTip } from "../TutorialTip";

import {
  ModuleInputs,
  InverterInputs,
  RackingInputs,
  WeatherInputs,
  LayoutInputs,
  PerformanceInputs,
  FinanceInputs,
  TopoInputs_NEW,
  ConfigInputs,
  MapManager,
  ShadingInputs,
  MapLayoutManager,
} from "./index";

import { ShadeInputs } from "../EnvironmentalShade";

import { siftActions, inputsActions, tutorialActions, authActions, isAuthenticated, getSIFTInputs, getCenterPoint } from "../Redux";
import { run_job, execute_job, validateInputs } from "../Redux";

// css
// import './inputs.css'
import "./datagrid.css";
import {
  inverter_icon,
  layout_icon,
  module_icon,
  perf_icon,
  racking_icon,
  topo_icon,
  weather_icon,
  finance_icon,
  results_icon,
  config_icon,
  mapManagerIcon,
  shade_input_icon,
} from "../../assets/images";

import { InputPanelWrapper } from "./StyledComponents";

function isNumber(value) {
  return typeof value === "number" && !isNaN(value);
}
function getGCRs(min, max, inc) {
  if (!isNumber(min) || !isNumber(max) || !isNumber(inc)) {
    return [0.5];
  }
  let gcrs = [];
  for (let gcr = min; gcr < max; gcr += inc) {
    gcrs.push(gcr);
    if (gcrs.length >= 51) break;
  }
  gcrs.push(parseFloat(max));
  return gcrs;
}
function getTilts(min, max, inc) {
  if (!isNumber(min) || !isNumber(max) || !isNumber(inc)) {
    return [25];
  }
  let tilts = [];
  for (let tilt = min; tilt < max; tilt += inc) {
    tilts.push(tilt);
    if (tilts.length >= 20) break;
  }
  tilts.push(parseFloat(max));
  return tilts;
}

const InputPanel = (props) => {
  const { is_authenticated } = useAuth();
  // const isAuthed = useSelector((state) => isAuthenticated(state));
  const projects_loading = useSelector((state) => state.projects.loading);

  const show_agreement_modal = useSelector((state) => state.user.show_agreement_modal);
  const show_terms = useSelector((state) => state.user.show_terms);

  const inputs = useSelector((state) => state.inputs);
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);

  const sift_inputs = useSelector((state) => state.sift.ioManager.inputs);

  const current_plan = useSelector((state) => state.user.product_plan_paid);
  const customer_notes = useSelector((state) => state.user.customer_notes);
  const [selectedTab, setSelectedTab] = useState("products");

  const siftRunning = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const siftCanCancel = useSelector((state) => state.sift.ioManager.outputs.runState.can_cancel);
  const siftCanceling = useSelector((state) => state.sift.ioManager.outputs.runState.canceling);
  const runId = useSelector((state) => state.sift.ioManager.outputs.runState.runId);
  const job_id = useSelector((state) => state.sift.ioManager.outputs.job_id);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);
  const error_visible = useSelector((state) => state.sift.ioManager.uiState.error_visible);
  const outputs = useSelector((state) => state.sift.ioManager.outputs);
  const error_messages = useSelector((state) => (state.sift.ioManager.uiState.error_messages.length > 0 ? state.sift.ioManager.uiState.error_messages : outputs.errors));

  const layout_visible = useSelector((state) => state.inputs.uiState.layout_visible);
  const changed_input_warning = useSelector((state) => state.inputs.changed_input_warning);
  const shade_objects = useSelector((state) => state.canopy.shade_objects);
  const en_shade_impact = useSelector((state) => state.canopy.en_shade_impact);
  const shade_results = useSelector((state) => state.canopy.shade_results);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const boundary_exists = Object.values(sift_inputs.map.features).findIndex((feature) => feature.properties.identity == 1) >= 0;

  let output_detail_visible = useSelector((state) => state.sift.ioManager.uiState.output_detail_visible);

  const toggleMobileInputs = useSelector((state) => state.sift.ioManager.uiState.toggleMobileInputs);
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  const edited_layouts = useSelector((state) => state.inputs?.edited_layouts);
  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id || undefined);
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);
  const pm_modal_visible = useSelector((state) => state.inputs.uiState.pm_modal_visible);

  //Tutorial Controls
  const tutorial = useSelector((state) => state.tutorial);

  const dispatch = useDispatch();

  const [isComplete, setIsComplete] = useState(false);

  // used for the tutorial. Just checks to see if the example project is complete
  useEffect(() => {
    if (Object.values(outputs.results).length > 0) {
      setIsComplete(true);
    }
  }, [outputs.results]);

  const hasRun = useRef(false);

  useEffect(() => {
    //console.log('useEffect triggered');
    if (!hasRun.current && error_messages.length > 0 && !output_detail_visible) {
      dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", true));
      hasRun.current = true;
    }
  }, [error_messages, output_detail_visible, dispatch]);
  useEffect(() => {
    if (tab && tab == "config") {
      onUpdateTab(undefined);
    }
  }, [selectedTab]);

  const genExtra = () => (
    <CheckCircleTwoTone
      twoToneColor="var(--primary-brand-color)"
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  useEffect(() => {
    if (false && Object.values(outputs.errors).length > 0) {
      Modal.error({
        title: "",
        content: <ErrorFromSIFT error_messages={outputs.errors} />,
        footer: null,
        width: "50%",
        className: "sift-modal-error",
        style: { maxWidth: "1300px", borderRadius: "4px !important" },
        icon: null,
        maskClosable: true,
        closable: true,
        // destroyOnClose: true,
        // okText: "Close",
        // okType: "ghost",
        // onCancel: () => dispatch(siftActions.clearErrors()),
        onCancel: () => dispatch(siftActions.clearErrors()),
      });
    } else {
      Modal.destroyAll();
    }
  }, [outputs.errors]);

  function onLogin() {
    dispatch(authActions.toggleLoginVis(true, "login"));
  }

  function onCancelSIFT() {
    if (tutorial.tutorialActive) {
      dispatch(tutorialActions.resetTutorial());
    }
    dispatch(siftActions.cancelRun(job_id));
  }

  function onUpdateTab(_tab) {
    let new_tab = _tab == tab ? undefined : _tab;
    dispatch(siftActions.updateTab(new_tab));
  }

  function run_sift() {
    let soiling = inputs.soiling;
    let albedo = inputs.albedo;
    if (inputs.do_monthly_losses == 0) {
      // single values in input, spead to monthly
      soiling = Array(12).fill(inputs.soiling_single);
      albedo = Array(12).fill(inputs.albedo_single);
    }

    if (!isFinite(inputs.latitude) || !isFinite(inputs.longitude) || isNaN(inputs.latitude) || isNaN(inputs.longitude)) {
      let center_point = getCenterPoint(inputs.site_features);
      if (center_point) {
        inputs.latitude = center_point[0];
        inputs.longitude = center_point[1];
      }
    }

    // inputs["shade_objects"] = shade_objects;
    // inputs["en_shade_impact"] = en_shade_impact;
    // inputs["shade_results"] = shade_results;

    let job_inputs = {
      ...inputs,
      site_features: Object.values(inputs.site_features).filter((feature) => feature?.geometry?.type == "Polygon"),
      gcrs: getGCRs(inputs.gcr_min, inputs.gcr_max, inputs.gcr_inc),
      tilts: inputs.track_mode == 0 ? getTilts(inputs.tilt_min, inputs.tilt_max, inputs.tilt_inc) : [0],
      en_shade_impact,
      shade_objects,
      shade_results,
      shade_height_unit,
      soiling,
      albedo,
      current_plan,
      ...customer_notes,
    };

    // console.log(job_inputs);
    // init run in the backend
    // dispatch(portalActions.initResults(inputs));
    if (changed_input_warning) dispatch(inputsActions.update_bulk_inputs({ changed_input_warning: false, res_generated: false }));

    let inputValidation = validateInputs(job_inputs);
    // let clean_inputs = cleanSiftInputs(job_inputs);
    // let inputValidation = validateWithSchema(clean_inputs);

    console.log("input validation:", inputValidation);
    if (inputValidation.valid) {
      dispatch(execute_job(run_job(inputValidation.validatedInputs)));
      if (layout_visible == 0) dispatch(inputsActions.update_ui_state("layout_visible", 1));
    } else {
      // error
      let theErrors = [];
      inputValidation.errors.map((err) => theErrors.push(`${err}`));
      dispatch(siftActions.errorSIFT(theErrors));
    }
  }

  return (
    <InputPanelWrapper internal_sift={props.internal_sift} pm_modal_visible={pm_modal_visible}>
      {/* <Tabs defaultActiveKey={selectedTab}>
        <Tab eventKey="products" label="Products">
          <div>Products Content</div>
        </Tab>
        <Tab eventKey="performance" label="Performance">
          <div>Perf Content</div>
        </Tab>
        <Tab eventKey="finance" label="Finance">
          <div>Finance content</div>
        </Tab>
        <Tab eventKey="layout" label="Layout">
          <div>Layout content</div>
        </Tab>
      </Tabs> */}

      <div className="tab-selector">
        <div className={`tab ${selectedTab == "products" && "selected"}`} onClick={() => setSelectedTab("products")}>
          Products
        </div>
        <div className={`tab ${selectedTab == "performance" && "selected"}`} onClick={() => setSelectedTab("performance")}>
          Performance
        </div>
        <div className={`tab ${selectedTab == "finance" && "selected"}`} onClick={() => setSelectedTab("finance")}>
          Finance
        </div>
        <div className={`tab ${selectedTab == "site" && "selected"}`} onClick={() => setSelectedTab("site")}>
          Site
        </div>
      </div>

      {selectedResultId && edited_layouts && edited_layouts[selectedResultId] && show_layout_editor ? (
        <section className="input-manager">
          <section className="input-collapse">
            <section className="collapse-item">
              {/* <section className={`collapse-header ${tab == "editorTools" && "selected"}`} id="editorTools" onClick={(e) => onUpdateTab(e.target.id)}>
                <section className="collapse-header-text">
                  <span>Layout Manager</span>
                </section>
                <span className="collapse-arrow">{tab == "editorTools" ? <DownOutlined /> : <RightOutlined />}</span>
              </section> */}

              <section className="collapse-content-container">
                <section className="input-panel">
                  <MapLayoutManager />
                </section>
              </section>
            </section>
          </section>
        </section>
      ) : (
        <div className="input-manager">
          <div className="input-collapse">
            {selectedTab == "products" && (
              <div className="collapse-item">
                <div className={`collapse-header ${tab == "module" && "selected"}`} id="module" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text" onClick={(e) => onUpdateTab("module")}>
                    <img src={module_icon} alt="" />
                    <span>Module</span>
                  </div>
                  <span className="collapse-arrow">{tab == "module" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
                {tab == "module" && (
                  <div className="collapse-content-container">
                    <div className="input-panel module">
                      <InputHeader header_type="mod" />
                      <ModuleInputs />
                    </div>
                  </div>
                )}
              </div>
            )}
            {selectedTab == "products" && (
              <div className="collapse-item">
                <div className={`collapse-header ${tab == "inverter" && "selected"}`} id="inverter" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text">
                    <img src={inverter_icon} />
                    <span>Inverter</span>
                  </div>
                  <span className="collapse-arrow">{tab == "inverter" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
                {tab == "inverter" && (
                  <div className="collapse-content-container">
                    <div className="input-panel inverter">
                      <InputHeader header_type="inv" />
                      <InverterInputs />
                    </div>
                  </div>
                )}
              </div>
            )}
            {selectedTab == "products" && (
              <div className="collapse-item">
                <div className={`collapse-header ${tab == "racking" && "selected"}`} id="racking" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text">
                    <img src={racking_icon} />
                    <span>Racking</span>
                  </div>
                  <span className="collapse-arrow">{tab == "racking" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
                {tab == "racking" && (
                  <div className="collapse-content-container">
                    <div className="input-panel racking">
                      <InputHeader header_type="racking" />
                      <RackingInputs />
                    </div>
                  </div>
                )}
              </div>
            )}
            {selectedTab == "performance" && (
              <div className="collapse-item">
                <TutorialTip
                  elementKey="weather"
                  visible={tutorial.visibleTips.weather_location}
                  placement={screenWidth < 768 ? "top" : "right"}
                  customTipWidth={screenWidth < 768 ? 290 : undefined}
                  customTipHeight={screenWidth < 768 ? 80 : undefined}
                  content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.weather_location}
                >
                  <div className={`collapse-header ${tab == "weather" && "selected"}`} id="weather" onClick={(e) => onUpdateTab(e.target.id)}>
                    <div className="collapse-header-text" onClick={(e) => onUpdateTab("weather")}>
                      <img src={weather_icon} alt="" />
                      <span>Weather</span>
                    </div>
                    <span className="collapse-arrow">{tab == "weather" ? <DownOutlined /> : <RightOutlined />}</span>
                  </div>
                </TutorialTip>
                {tab == "weather" && (
                  <div className="collapse-content-container">
                    <div className="input-panel weather">
                      <InputHeader header_type="weather" />
                      <WeatherInputs />
                    </div>
                  </div>
                )}
              </div>
            )}
            {selectedTab == "performance" && (
              <div className="collapse-item">
                <div className={`collapse-header ${tab == "performance" && "selected"}`} id="performance" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text">
                    <img src={perf_icon} />
                    <span>Performance</span>
                  </div>
                  <span className="collapse-arrow">{tab == "performance" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
                {tab == "performance" && (
                  <TutorialTip
                    elementKey="performance"
                    visible={tutorial.visibleTips.perf_params}
                    placement={screenWidth < 768 ? "top" : "right"}
                    customTipWidth={screenWidth < 768 ? 290 : undefined}
                    customTipHeight={screenWidth < 768 ? 65 : undefined}
                    content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.perf_params}
                  >
                    <div className="collapse-content-container">
                      <div className="input-panel performance">
                        <InputHeader header_type="perf" />
                        <PerformanceInputs />
                      </div>
                    </div>
                  </TutorialTip>
                )}
              </div>
            )}

            {selectedTab == "performance" && (
              <div className="collapse-item">
                <div className={`collapse-header ${tab == "hor_shading" && "selected"}`} id="hor_shading" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text">
                    <img src={shade_input_icon} style={{ transform: "rotate(-90deg)" }} />
                    <span>Horizon Shading</span>
                  </div>
                  <span className="collapse-arrow">{tab == "hor_shading" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
                {tab == "hor_shading" && (
                  <div className="collapse-content-container">
                    <div className="input-panel performance">
                      <ShadingInputs />
                    </div>
                  </div>
                )}
              </div>
            )}

            {selectedTab == "performance" && (
              <div className="collapse-item">
                <div className={`collapse-header ${tab == "env_shade" && "selected"}`} id="env_shade" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text">
                    <img src={shade_input_icon} />
                    <span>Environmental Shade</span>
                  </div>
                  <span className="collapse-arrow">{tab == "env_shade" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
                {tab == "env_shade" && (
                  <div className="collapse-content-container">
                    <div className="input-panel performance">
                      <ShadeInputs />
                    </div>
                  </div>
                )}
              </div>
            )}

            {selectedTab == "finance" && (
              <div className="collapse-item">
                <TutorialTip
                  elementKey="finance"
                  visible={tutorial.visibleTips.finance_calcs}
                  showPricingLink={true}
                  placement={screenWidth < 768 ? "top" : "right"}
                  customTipWidth={screenWidth < 768 ? 120 : undefined}
                  customTipHeight={screenWidth < 768 ? 80 : undefined}
                  content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.finance_calcs}
                >
                  <div className={`collapse-header ${tab == "finance" && "selected"}`} id="finance" onClick={(e) => onUpdateTab(e.target.id)}>
                    <div className="collapse-header-text">
                      <img src={finance_icon} />
                      <span>Finance</span>
                    </div>
                    <span className="collapse-arrow">{tab == "finance" ? <DownOutlined /> : <RightOutlined />}</span>
                  </div>
                </TutorialTip>
                {tab == "finance" && (
                  <div className="collapse-content-container">
                    <div className="input-panel finance" id="finance-tip">
                      <InputHeader header_type="finance" />
                      <FinanceInputs />
                    </div>
                  </div>
                )}
              </div>
            )}
            {selectedTab == "site" && (
              <div className="collapse-item">
                <TutorialTip
                  elementKey="layout"
                  visible={tutorial.visibleTips.layout_inputs}
                  showHelpLink={true}
                  placement={screenWidth < 768 ? "top" : "right"}
                  customTipWidth={screenWidth < 768 ? 120 : undefined}
                  customTipHeight={screenWidth < 768 ? 80 : undefined}
                  content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.layout_inputs}
                >
                  <div className={`collapse-header ${tab == "layout" && "selected"}`} id="layout" onClick={(e) => onUpdateTab(e.target.id)}>
                    <div className="collapse-header-text">
                      <img src={layout_icon} />
                      <span>Layout</span>
                    </div>
                    <span className="collapse-arrow">{tab == "layout" ? <DownOutlined /> : <RightOutlined />}</span>
                  </div>
                </TutorialTip>
                {tab == "layout" && (
                  <div className="collapse-content-container">
                    <div className="input-panel layout">
                      <div id="layout-tip">
                        <InputHeader header_type="layout" />
                        <LayoutInputs />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {selectedTab == "site" && (
              <div className="collapse-item">
                <div className={`collapse-header ${tab == "topo" && "selected"}`} id="topo" onClick={(e) => onUpdateTab(e.target.id)}>
                  <div className="collapse-header-text">
                    <img src={topo_icon} />
                    <span>Topography</span>
                  </div>
                  <span className="collapse-arrow">{tab == "topo" ? <DownOutlined /> : <RightOutlined />}</span>
                </div>
                {tab == "topo" && (
                  <TutorialTip
                    elementKey="topo"
                    visible={tutorial.visibleTips.topo_inputs}
                    showPricingLink={true}
                    placement={screenWidth < 768 ? "top" : "right"}
                    customTipWidth={screenWidth < 768 ? 120 : undefined}
                    customTipHeight={screenWidth < 768 ? 100 : undefined}
                    content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.topo_inputs}
                  >
                    <div className="collapse-content-container">
                      <div className="input-panel topo">
                        <div id="topo-tip">
                          <InputHeader header_type="topo" />
                          <TopoInputs_NEW />
                        </div>
                      </div>
                    </div>
                  </TutorialTip>
                )}
              </div>
            )}

            {selectedTab == "site" && (
              <>
                <section className="collapse-item">
                  <section className={`collapse-header ${tab == "mapManager" && "selected"}`} id="mapManager" onClick={(e) => onUpdateTab(e.target.id)}>
                    <section className="collapse-header-text">
                      <img src={mapManagerIcon} />
                      <span>Map Manager</span>
                    </section>
                    <span className="collapse-arrow">{tab == "mapManager" ? <DownOutlined /> : <RightOutlined />}</span>
                  </section>
                  {tab == "mapManager" && (
                    <section className="collapse-content-container">
                      <section className="input-panel mapFeatureManager">
                        <MapManager />
                      </section>
                    </section>
                  )}
                </section>
              </>
            )}
          </div>
        </div>
      )}

      <div className="generate-buttons-container">
        <div className="input-panel config">
          {/* {siftRunning && <ResultTab />} */}
          {(siftRunning || generated || error_messages.length > 0) && (
            <div className="input-collapse config">
              <div className="collapse-item">
                <div className="collapse-content-container results">
                  <ResultTab />
                </div>
                <div
                  className={`collapse-header ${output_detail_visible && "selected"}`}
                  id="results"
                  onClick={(e) => {
                    // onUpdateTab(e.target.id);
                    dispatch(siftActions.toggleHelpToolbarVisibility("output_detail_visible", !output_detail_visible));
                  }}
                >
                  <div className="collapse-header-text">
                    <img src={results_icon} />
                    <span>{error_messages.length > 0 ? "/errors" : "/results"}</span>
                  </div>
                  <span className="collapse-arrow">{output_detail_visible ? <RightOutlined /> : <UpOutlined />}</span>
                </div>
              </div>
            </div>
          )}

          <div className="input-collapse config">
            <div className="collapse-item">
              <TutorialTip
                elementKey="config-tip"
                customTipHeight={100}
                customTipWidth={screenWidth < 768 ? -340 : undefined}
                visible={tutorial.visibleTips.config_tip}
                highlight={true}
                placement={screenWidth < 768 ? "top" : "right"}
                content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.config_tip}
              >
                <ConfigInputs />
              </TutorialTip>
              {/* {tab == "config" && (
                <div className="collapse-content-container">
                  <div className="input-panel config">
                  </div>
                </div>
              )} */}
              <div></div>
              <div className={`collapse-header ${tab == "config" && "selected"}`} id="config" onClick={(e) => onUpdateTab(e.target.id)}>
                <div className="collapse-header-text">
                  <img src={config_icon} />
                  <span>/config</span>
                </div>
                <span className="collapse-arrow">{tab == "config" ? <UpOutlined /> : <RightOutlined />}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="run-button">
          {changed_input_warning && (
            <section className="input-changed-warning">
              <InfoCircleOutlined style={{ color: "var(--primary-brand-color)", fontSize: "14px", marginTop: 3 }} />
              <p>Inputs have changed since last results were generated.</p>
            </section>
          )}
          <TutorialTip
            elementKey="run-tip"
            visible={!siftRunning && !isComplete && tutorial.visibleTips.run_sift}
            highlight={true}
            customTipWidth={screenWidth < 768 ? -360 : 290}
            customTipHeight={50}
            placement="top"
            content={tutorial.content && Object.values(tutorial.content)[tutorial.currentIndex].tip_text.run_sift}
          >
            <Button
              id="run-tip"
              size="large"
              type={siftRunning ? "ghost" : "primary"}
              // style={{ fontWeight: "700", fontSize: "16px" }}
              onClick={() => (!is_authenticated ? onLogin() : siftRunning ? onCancelSIFT() : run_sift())}
              disabled={siftCanceling || !is_authenticated || show_agreement_modal || show_terms || projects_loading}
              loading={siftCanceling || (!siftCanCancel && siftRunning)}
            >
              {!siftCanCancel && siftRunning ? (
                "PREPARING RUN"
              ) : siftCanCancel && siftRunning ? (
                "CANCEL RUN"
              ) : siftCanceling ? (
                "CANCELING RUN"
              ) : (
                <div className="run-button-content">
                  <span>RUN SIFT</span>
                  <CaretRightOutlined style={{ fontSize: "18px" }} />
                </div>
              )}
            </Button>
          </TutorialTip>
        </div>
      </div>

      <button className="toggle-inputs-button" onClick={() => dispatch(siftActions.toggleMobileInputs(!toggleMobileInputs))}>
        {toggleMobileInputs ? <CaretLeftOutlined style={{ fontSize: "30px" }} /> : <CaretRightOutlined style={{ fontSize: "30px" }} />}
      </button>
    </InputPanelWrapper>
  );
};

export { InputPanel };
