import styled, { css } from "styled-components";

const cssStyles = css`
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714285714;
  color: ${(props) => (props.danger ? "#ff4d4f" : "rgba(0, 0, 0, 0.88)")};
  border-color: ${(props) => (props.danger ? "#ff4d4f" : "#d9d9d9")};
  font-size: 14px;
  padding: 4px 15px;
  border-radius: 6px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  box-sizing: border-box;

  &.small {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }

  &.large {
    font-size: 16px;
    height: 40px;
    padding: 6px 15px;
    border-radius: 4px;
  }

  &.default {
    background-color: #ffffff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    &:not(:disabled):hover {
      color: ${(props) => (props.danger ? "#ff7875" : "#4096ff")};
      border-color: ${(props) => (props.danger ? "#ffa39e" : "#4096ff")};
    }
    &:disabled {
      cursor: not-allowed;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }

  &.primary {
    color: #fff;
    background-color: ${(props) => (props.danger ? "#ff4d4f" : "var(--primary-brand-color)")};
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    &:not(:disabled):hover {
      background-color: ${(props) => (props.danger ? "#ff7875" : "#4096ff")};
    }
    &:disabled {
      cursor: not-allowed;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }
  &.dashed {
    background-color: #ffffff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    border-style: dashed;
    &:not(:disabled):hover {
      color: ${(props) => (props.danger ? "#ff7875" : "#4096ff")};
      border-color: ${(props) => (props.danger ? "#ffa39e" : "#4096ff")};
    }
    &:disabled {
      cursor: not-allowed;
      border-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
    }
  }
  &.btn-text {
    border-color: rgba(0, 0, 0, 0);
    &:not(:disabled):hover {
      color: ${(props) => (props.danger ? "#ff7875" : "rgba(0, 0, 0, 0.88)")};
      background-color: ${(props) => (props.danger ? "#fff2f0" : "rgba(0, 0, 0, 0.06)")};
    }
    &:disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  &.link {
    color: ##002bcb;
    border-color: rgba(0, 0, 0, 0);
    &:not(:disabled):hover {
      color: ${(props) => (props.danger ? "#ff7875" : "#69b1ff")};
    }

    &:disabled {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  &.ghost {
    background-color: #ffffff;
    border-color: #d9d9d9;
    color: #333;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);

    &:hover {
      color: var(--primary-brand-color);
      border-color: var(--primary-brand-color);
    }
  }
  &.btn-loading {
    opacity: 0.65;
    cursor: default;
  }
  &.btn-circle {
    min-width: 40px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    border-radius: 50%;
  }
  &.btn-round {
    border-radius: 40px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
  &.btn-block {
    width: 100%;
  }
`;

// const ButtonWrapper = styled.button`
//   ${cssStyles}
// `;

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  /* width: min-content; */
  white-space: nowrap;
  text-align: center;
  border: ${(props) => (props.type === "primary" ? "1px solid transparent" : props.type === "danger" ? "1px solid var(--error)" : "1px solid var(--primary-brand-color)")};
  cursor: pointer;
  transition: all 0.2s var(--easing-spring);
  user-select: none;
  touch-action: manipulation;
  color: ${(props) => (props.type === "primary" ? "#fff" : props.type === "danger" ? "var(--error)" : "var(--primary-brand-color)")};
  background-color: ${(props) => (props.type === "primary" ? "var(--primary-brand-color)" : "#fff")};
  padding: 5px 3px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0px 2px;
  min-width: 30px;

  span {
    svg,
    img {
      margin: 0px 3px;
      width: min-width;
    }
  }

  .icon-label {
    svg {
      filter: invert(100%) brightness(2);
    }
  }

  svg {
    margin: 0px 3px;
    fill: ${(props) => (props.type === "primary" ? "#fff" : "var(--primary-brand-color)")};
  }

  &:hover {
    color: ${(props) => (props.type === "primary" ? "#fff" : "var(--background-hover-color)")};
    background-color: ${(props) => (props.type === "primary" ? "var(--background-hover-color)" : "transparent")};
    border-color: ${(props) => (props.type === "primary" ? "transparent" : "var(--background-hover-color)")};
  }

  &:hover > svg {
    transition: all 0.2s var(--easing-spring);
    /* opacity: 0.6; */
  }

  &.small {
    font-size: 13px;
    height: 24px;
  }

  &.medium {
    font-size: 14px;
    height: 32px;
  }

  &.large {
    font-size: 16px;
    height: 40px;
    font-weight: 700;
  }

  &.link {
    background: transparent;
    border: none;
    color: var(--primary-brand-color);

    &:focus {
      border: 1px solid var(--primary-brand-color);
    }
  }

  &.ghost {
    background-color: rgb(255, 255, 255);
    border-color: rgb(217, 217, 217);
    color: rgb(51, 51, 51);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 0px;

    &:hover {
      color: var(--primary-brand-color);
      border-color: var(--primary-brand-color);
      /* opacity: 0.7; */

      svg {
        fill: var(--primary-brand-color);
      }
    }

    svg {
      fill: #444;
    }
  }

  .secondary {
    background-color: rgb(255, 255, 255);
    /* border-color: var(--primary-brand-color); */
    border-color: purple;

    color: var(--primary-brand-color);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 0px;

    &:hover {
      /* color: var(--primary-brand-color);
      border-color: var(--primary-brand-color); */
      opacity: 0.7;
    }
  }

  .danger {
    background-color: rgb(255, 255, 255);
    border-color: var(--error);
    color: var(--error);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 0px;

    &:hover {
      /* color: var(--primary-brand-color);
      border-color: var(--primary-brand-color); */
      opacity: 0.7;
    }
  }

  &.btn-circle {
    min-width: 24px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    border-radius: 50%;
    color: var(--primary-brand-color);
    background-color: transparent;
    border-color: var(--primary-brand-color);
    box-shadow: none;

    & > span {
      svg {
        margin-right: 0px;
        margin-left: 2px;
      }
    }
  }
  &.btn-round {
    border-radius: 40px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const LinkWrapper = styled.a`
  ${cssStyles}
`;

export { ButtonWrapper, LinkWrapper };
