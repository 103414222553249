import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMap } from "react-map-gl";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";
import { Tooltip, Popconfirm } from "antd";

import { siftActions, inputsActions } from "../../../Redux";

import { boundary_poly, exclusion_poly, inactive_poly, offeset_poly, protractor, measure_dims, delete_single_polygon } from "../../../../assets/images";

const PolygonTools = ({ id }) => {
  const dispatch = useDispatch();

  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const selected_feature = useSelector((state) => state.inputs.site_features[id]);
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);

  const show_offset_tool = useSelector((state) => state.sift.ioManager.uiState.show_offset_tool);

  const show_azi = useSelector((state) => state.inputs.uiState.show_azi);
  const show_dims = useSelector((state) => state.inputs.uiState.show_dims);

  //hot key stuff
  const boundary_hotkey = useKey("b");
  const exclusion_hotkey = useKey("e");
  const inactive_hotkey = useKey("i");

  const mapHasFocus = () => {
    return document.getElementById("MapboxMap") == document.activeElement;
  };

  useEffect(() => {
    if (!mapHasFocus()) return;
    if (!selected_feature) return;
    if (selected_feature?.geometry?.type == "LineString") return;
    if (boundary_hotkey) {
      updateIdentity(1);
    }
    if (exclusion_hotkey) {
      updateIdentity(2);
    }
    if (inactive_hotkey) {
      updateIdentity(0);
    }
  }, [boundary_hotkey, exclusion_hotkey, inactive_hotkey]);

  const updateIdentity = (identity) => {
    if (selected_feature) {
      selected_feature.properties.identity = identity;

      if (identity == 0) selected_feature.properties.active = !selected_feature.properties.active;
      else selected_feature.properties.active = true;
      //   let layer = this.getLayer(selected_feature.properties.index);
      //   layer?.setStyle({ color: colors[identity] });
      dispatch(siftActions.updateFeature(selected_feature));
      dispatch(inputsActions.update_site_input("edit_site_feature", selected_feature));
    }
  };

  const handleToggleDims = () => {
    let checked = !show_dims;
    dispatch(inputsActions.update_ui_state("show_dims", checked));
    if (show_azi) dispatch(inputsActions.update_ui_state("show_azi", false));
  };

  const handleToggleAzi = () => {
    let checked = !show_azi;
    dispatch(inputsActions.update_ui_state("show_azi", checked));
    if (show_dims) dispatch(inputsActions.update_ui_state("show_dims", false));
  };

  const delete_single_feature = () => {
    dispatch(inputsActions.update_site_input("remove_site_feature", id));
    // dispatch(siftActions.selectFeatureById(undefined));
  };

  const toggle_offset_polygon = () => {
    dispatch(siftActions.updateUIState("show_offset_tool", !show_offset_tool));
  };

  return (
    <section className="map-action-buttons" style={{ marginLeft: 5 }}>
      {selected_feature && selected_feature.geometry.type == "Polygon" && (
        <>
          <Tooltip placement="bottom" title="Offset Polygon" mouseEnterDelay={0.25}>
            <button onClick={() => toggle_offset_polygon()}>
              <img draggable={false} src={offeset_poly} />
            </button>
          </Tooltip>
          <Tooltip placement="bottom" title="Show Polygon Azimuth" mouseEnterDelay={0.25}>
            <button className={show_azi ? "button-active" : ""} disabled={false} onClick={() => handleToggleAzi()}>
              <img draggable={false} src={protractor} />
            </button>
          </Tooltip>
          <Tooltip placement="bottom" title="Show Polygon Dimensions" mouseEnterDelay={0.25}>
            <button className={show_dims ? "button-active" : ""} disabled={false} onClick={() => handleToggleDims()}>
              <img draggable={false} src={measure_dims} />
            </button>
          </Tooltip>
          {/* <button onClick={() => console.log("delete me!")}>
        <img src={delete_polygon} />
      </button> */}
          {/* <section className="vert-line"></section> */}
          <Tooltip placement="bottom" title="Set As Boundary" mouseEnterDelay={0.25}>
            <button className="identity-button" onClick={() => updateIdentity(1)}>
              <img draggable={false} src={boundary_poly} />
            </button>
          </Tooltip>
          <Tooltip placement="bottom" title="Set As Exclusion" mouseEnterDelay={0.25}>
            <button className="identity-button" onClick={() => updateIdentity(2)}>
              <img draggable={false} src={exclusion_poly} />
            </button>
          </Tooltip>
          <Tooltip placement="bottom" title="Set as Inactive" mouseEnterDelay={0.25}>
            <button className="identity-button" onClick={() => updateIdentity(0)}>
              <img draggable={false} src={inactive_poly} />
            </button>
          </Tooltip>
          <Tooltip placement="bottom" title="Delete Selected Polygon" mouseEnterDelay={0.25}>
            <Popconfirm placement="bottom" title={`Are you sure?`} onCancel={() => console.log("canceled")} okText="Yes" cancelText="No" onConfirm={() => delete_single_feature()}>
              <button>
                <img draggable={false} src={delete_single_polygon} />
              </button>
            </Popconfirm>
          </Tooltip>
        </>
      )}

      {/* {selectedFeatureId &&selected_feature && selected_feature.geometry.type == "Polygon" ? (
       ---> THE STUFF ABOVE USED TO BE HERE BUT IM NOT SURE IF IT'S NEEDED ANYMORE SO I'M LEAVING IT -- JOSH
      ) : (
        <>
          <Tooltip placement="bottom" title="Delete Selected Polygon" mouseEnterDelay={0.25}>
            <Popconfirm placement="bottom" title={`Are you sure?`} onCancel={() => console.log("canceled")} okText="Yes" cancelText="No" onConfirm={() => delete_single_feature()}>
              <button>
                <img draggable={false} src={delete_single_polygon} />
              </button>
            </Popconfirm>
          </Tooltip>
        </>
      )} */}
    </section>
  );
};

export { PolygonTools };
