// Helper function to calculate the signed area of a polygon ring
function calculateSignedArea(ring) {
  let sum = 0;
  for (let i = 0, len = ring.length; i < len; i++) {
    const p1 = ring[i];
    const p2 = ring[(i + 1) % len];
    sum += (p2[0] - p1[0]) * (p2[1] + p1[1]);
  }
  return sum;
}

export default function customClassifyRings(rings, maxRings = 4000) {
  // Check to see if the input is a MultiPolygon
  if (Array.isArray(rings[0]) && Array.isArray(rings[0][0])) {
    // It's a MultiPolygon
    const polygons = [];
    for (const multiPolygon of rings) {
      const classified = classifySinglePolygon(multiPolygon);
      polygons.push(...classified);
    }
    return polygons;
  } else {
    // it s single Polygon, so we reclassify it's rings
    return classifySinglePolygon(rings);
  }
}

function classifySinglePolygon(rings) {
  const len = rings.length;
  if (len <= 1) return [rings];

  const polygons = [];
  let polygon, ccw;

  for (let i = 0; i < len; i++) {
    const area = calculateSignedArea(rings[i]);
    if (area === 0) continue;

    rings[i].area = Math.abs(area);

    if (ccw === undefined) ccw = area < 0;

    if (ccw === area < 0) {
      if (polygon) polygons.push(polygon);
      polygon = [rings[i]];
    } else {
      polygon.push(rings[i]);
    }
  }
  if (polygon) polygons.push(polygon);

  return polygons;
}

// // classifies an array of rings into polygons with outer rings and holes
// export default function customClassifyRings(rings, maxRings = 4000) {
//   const len = rings.length;

//   if (len <= 1) return [rings];

//   const polygons = [];
//   let polygon, ccw;

//   for (let i = 0; i < len; i++) {
//     const area = calculateSignedArea(rings[i]);
//     if (area === 0) continue;

//     rings[i].area = Math.abs(area);

//     if (ccw === undefined) ccw = area < 0;

//     if (ccw === area < 0) {
//       if (polygon) polygons.push(polygon);
//       polygon = [rings[i]];
//     } else {
//       polygon.push(rings[i]);
//     }
//   }
//   if (polygon) polygons.push(polygon);

//   // Earcut performance degrades with the # of rings in a polygon. For this
//   // reason, we limit strip out all but the `maxRings` largest rings.

//   // Josh here--This maxRings check basically causes large layouts and smaller features that are on the map to vanish as you zoom in--and for that reason, its out.
//   // NOTE that when you are rendering a large'ish multipolygon onto the map, you need to pass the coordinates through the customClassifyRings function above so
//   // that they get reclassified in order for them to be rendered on the map correctly.
//   // See the Mapbox LayoutLater.js for an example or just search "customClassifyRings"

//   //   if (maxRings > 1) {
//   for (let j = 0; j < polygons.length; j++) {
//     // if (polygons[j].length <= maxRings) continue;
//     quickselect(polygons[j], maxRings, 1, polygons[j].length - 1, compareAreas);
//     polygons[j] = polygons[j].slice(0, maxRings);
//   }
//   //   }

//   return polygons;
// }

// Comparison function for sorting areas
function compareAreas(a, b) {
  return b.area - a.area;
}
