//prettier-ignore
import React, { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { ModalMaskWrap, ModalWrap } from "./styles";
import { Button } from "../Button";

import { close } from "../../../assets/images";
import "./style.css";

const Modal = ({
  open,
  height = undefined,
  width = undefined,
  maskCloseable = true,
  onCancel = undefined,
  onOk = undefined,
  title = undefined,
  canClose = true,
  showHeader = true,
  showFooter = true,
  showOkText = true,
  okText = "Ok",
  cancelText = "Close",
  footer = undefined,
  containerId = "root",
  centered = false,
  children,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);

  const handleCloseModal = () => {
    if (onCancel) {
      onCancel();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  useEffect(() => {
    const modalElement = modalRef.current;

    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  return (
    <dialog
      ref={modalRef}
      onKeyDown={handleKeyDown}
      // className={"modal-open"}
      height={height}
      width={width}
      centered={centered.toString()}
      // onClick={(event) => event.stopPropagation()}
      style={{
        width: width ? `${width}` : "min-content",
        height: height ? `${height}` : "min-content",
        border: "none",
        boxShadow: "var(--subtle-box-shadow)",
      }}
    >
      {showHeader && (
        <section className="modal-header">
          {title && <h4 className="header-text">{title}</h4>}
          {canClose && (
            <section className="modal-close">
              <a onClick={handleCloseModal}>
                <img src={close} />
              </a>
            </section>
          )}
        </section>
      )}

      <section className="modal-content">{children}</section>

      {showFooter == false ? null : footer ? (
        <section className="custom-footer">{footer}</section>
      ) : (
        <section className="modal-footer">
          <Button size="small" type="ghost" label={cancelText} onClick={handleCloseModal} />
          {showOkText && <Button size="small" label={okText} onClick={() => onOk()} />}
        </section>
      )}
    </dialog>
  );
};

export { Modal };
