import React from "react";
import { ButtonWrapper, LinkWrapper } from "./styles";
import { Loader } from "../Loader";
import { Tooltip } from "../Tooltip";
import { iconConstantMap } from "../constant/icon";

const Button = ({
  label,
  onClick,
  styles,
  size = "medium",
  type = "ghost",
  disabled,
  children,
  icon = undefined,
  htmlType,
  href,
  loading = false,
  shape,
  block,
  tooltip,
  tooltipPosition,
  className,
}) => {
  // const IconToRender = icon || null;
  const IconToRender = (icon && iconConstantMap[icon]) || null;
  type = type === "text" ? "btn-text" : type;
  let css_classes = `${className} ${size} ${type} ${loading ? "btn-loading" : ""}`;
  css_classes = css_classes + `${shape === "circle" ? "btn-circle" : shape === "round" ? "btn-round" : ""}`;
  css_classes = css_classes + `${block ? "btn-block" : ""}`;
  css_classes = css_classes + `${disabled ? "disabled" : ""}`;

  const button = (
    <>
      {!href ? (
        <ButtonWrapper onClick={onClick} style={styles} className={css_classes} disabled={disabled} type={type}>
          {loading ? <Loader type={type} size={size} /> : ""}
          {IconToRender ? <IconToRender /> : ""}
          <span>{label || children}</span>
        </ButtonWrapper>
      ) : (
        <LinkWrapper onClick={onClick} style={styles} className={css_classes} disabled={disabled} type={htmlType || "button"}>
          {loading ? <Loader /> : ""}
          {IconToRender ? <IconToRender /> : ""}
          <span>{label || children}</span>
        </LinkWrapper>
      )}
    </>
  );

  if (tooltip) {
    return (
      <Tooltip title={tooltip ? tooltip : null} position={tooltipPosition}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

export { Button };
