import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Column, Table, ExpandedState, useReactTable, getCoreRowModel, getFilteredRowModel, getExpandedRowModel, getSortedRowModel, ColumnDef, flexRender } from "@tanstack/react-table";
import config from "config";
import { Button, ButtonGroup, Divider, HorizontalLayout, Dropdown, SubMenu } from "../../BaseComponents";
import { Input, Modal } from "antd";
import { DownOutlined, RightOutlined, UpOutlined, ProjectOutlined } from "@ant-design/icons";
import { DataMangagerTableWrap } from "../styles";
import { LockedByYou, LockedBySomeone, NotLocked, collab_columns, notes_columns } from "../../ProjectManager/CollabAssets";
import proj_data from "./fake_dm_data.json";
import three_dots from "../three_dots.svg";
import { canopyActions, inputsActions, projectActions, siftActions } from "../../Redux";
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils'

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

// Define a custom fuzzy sort function that will sort by rank if the row has ranking information
const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank,
      rowB.columnFiltersMeta[columnId]?.itemRank
    )
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}
const format_date_ts = (timestamp, with_time = false) => {
  let date = new Date(timestamp * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  // let strTime = hours + ":" + minutes + " " + ampm;
  let strTime = `${hours}:${minutes}${ampm}`;
  let format_d = `${strTime} - ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  let format_s = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  if (with_time) {
    return format_d.toString();
  }
  return format_s.toString();
};

const ButtonStateMachine = (project) => {
  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";

  // console.log('---',project)
  const dispatch = useDispatch();
  const shade_objects = useSelector((state) => state.canopy.shade_objects);
  const [modal_visible, set_modal_visible] = useState({ visible: false, type: "load", temp_project_name: "", temp_variant_name: "" });
  const [loading, set_loading] = useState(false);


  const new_project = () => {
    // new project confirmed
    if (using_canopy) {
      dispatch(canopyActions.new_project());
    } else {
      // dispatch(inputsActions.reset_inputs_to_default());
      dispatch(siftActions.newProject());
      if (Object.values(shade_objects).length) dispatch(canopyActions.updateShadeObject("delete_all_shade_objects"));
    }
  };

  const load_variant = () => {
    new_project();
    set_loading(true);
    dispatch(projectActions.load_project(project.original));

    // if project is not locked, meaning it's not out by someone else, then check it out.
    if (project.original.collab_on == 1 && !project.original.locked) {
      // check_in_collab(false, project_id_to_load, false);
    }
    set_loading(false);
  };

  const save_as_ = () => {
    if (modal_visible.type == "saveasvariant" && modal_visible.temp_variant_name.length == 0) {
      return;
    }
    if (modal_visible.type == "saveasproject" && modal_visible.temp_variant_name.length == 0 && modal_visible.temp_project_name.length == 0) {
      return;
    }

    set_loading(true);
    console.log("save_as_", modal_visible.type, modal_visible.temp_project_name);
    // set_modal_visible({ visible: true, type: "saveas" });

    let _inputs = { ...project.original };

    // name has been entered
    if (modal_visible.type == "saveasvariant") {
      _inputs.variant_id = undefined;
    }

    if (modal_visible.type == "saveasproject") {
      _inputs.project_id = undefined;
      _inputs.variant_id = undefined;
      _inputs.project_name = modal_visible.temp_project_name;
      // _inputs.new_project = true;
      dispatch(inputsActions.update_input("project_name", modal_visible.temp_project_name));
    }

    _inputs.variant_name = modal_visible.temp_variant_name;
    dispatch(inputsActions.update_input("variant_name", modal_visible.temp_variant_name));
    dispatch(projectActions.update_project(_inputs, modal_visible.type));
    // open_modal(false, "saveas");
    // set_selected_project_id(undefined);
    set_loading(false);
    onCloseModal();
  };


  const collab_project = () => {
    dispatch(inputsActions.update_ui_state("collab_modal_visible", { visible: true, project_id: project.original.project_id }));
  };
  const delete_project = () => {
    let _inputs = { ...project.original };
    _inputs.active = 0;
    _inputs.variant_id = undefined;
    dispatch(projectActions.update_project(_inputs, "delete_variant"));
  };
  const delete_variant = () => {
    let _inputs = { ...project.original };
    _inputs.active = 0;
    dispatch(projectActions.update_project(_inputs, "delete_variant"));
  };
  const onCloseModal = () => {
    set_modal_visible({ visible: false, type: "saveas", temp_project_name: "", temp_variant_name: "" });
  };
  const onOpenModal = (_type) => {
    set_modal_visible({ visible: true, type: _type, temp_project_name: "", temp_variant_name: "" });
  };
  const onUpdateProjectName = (e) => {
    set_modal_visible({ ...modal_visible, temp_project_name: e.target.value });
  };
  const onUpdateVariantName = (e) => {
    set_modal_visible({ ...modal_visible, temp_variant_name: e.target.value });
  };

  return (
    <div key="buttonState">
      {project.depth == 0 ? (
        <HorizontalLayout>
          {/* <Button type="link" size="small" label="Collaborate" /> */}
          <Dropdown
            placement="bottomRight"
            overlay={
              <ul>
                <li key="collab_project" id="collab_proj" onClick={collab_project}>
                  Collaborate
                </li>
                <li key="del_all_proj" id="delete_proj" style={{ color: "var(--error)" }} onClick={delete_project}>
                  Delete Project
                </li>
              </ul>
            }
          >
            <Button type="ghost" size="small">
              <img src={three_dots} alt="Actions" style={{ height: "10px", marginTop: "10px" }} />
            </Button>
          </Dropdown>
        </HorizontalLayout>
      ) : (
        <Dropdown
          placement="bottom"
          overlay={
            <ul>
              <li key="load_proj" id="load_proj" onClick={load_variant}>
                Load
              </li>
              {/* <li key="variant_sub" id="save_as_variant" onClick={() => onOpenModal("saveasvariant")}>
                  Save As Variant
                </li>
                <li key="project_sub" id="save_as_project" onClick={() => onOpenModal("saveasproject")}>
                  Save As Project
                </li> */}
              <li key="del_variant" id="delete_variant" style={{ color: "var(--error)" }} onClick={delete_variant}>
                Delete Variant
              </li>
            </ul>
          }
        >
          <HorizontalLayout>
            <Button
              size="small"
              type="secondary"
              // label={`Actions`}
              // onClick={(e) => {
              //   console.log("datamanager row clicked", row);
              //   // load_proj_click(e, row.original.project_id);
              // }}
            >
              {/* <DownOutlined /> */}
              <img src={three_dots} alt="Actions" style={{ height: "10px", marginTop: "10px" }} />
            </Button>
            {/* <Button type="secondary" size="small" label={<DownOutlined />} /> */}
          </HorizontalLayout>
        </Dropdown>
      )}
      <Modal
        key="name-modal"
        className="sift-modal"
        closable={true}
        mask={false}
        open={modal_visible.visible}
        cancelText="Close"
        okText="Save"
        title="Save As"
        maskClosable={false}
        width={"25%"}
        onCancel={onCloseModal}
        onOk={save_as_}
        // footer={[
        //     <Button label="Save" size="small" onClick={save_as_} loading={loading} type="primary" key="save"/>
        // ]}
      >
        <div style={{ overflowX: "auto" }}>
          <div style={{ padding: "2%" }}>
            {modal_visible.type == "saveasproject" && (
              <Input
                id="temp_project_name"
                disabled={loading}
                className="login-input"
                // ref={(input) => input && input.focus()}
                prefix={<ProjectOutlined type="text" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Enter project name."
                value={modal_visible.temp_project_name}
                // value={project_name}
                onChange={(e) => onUpdateProjectName(e)}
                // onPressEnter={save_as_}
              />
            )}
            <div style={{ height: "10px" }}></div>
            <Input
              id="temp_variant_name"
              disabled={loading}
              className="login-input"
              // ref={(input) => input && input.focus()}
              prefix={<ProjectOutlined type="text" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Enter variant name."
              value={modal_visible.temp_variant_name}
              // value={variant_name}
              onChange={(e) => onUpdateVariantName(e)}
              // onPressEnter={save_as_}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};


const DataManagerTable = ({ projects, open_modal }) => {
  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";
  const [expanded, setExpanded] = useState({});
  const [searchFilters, setSearchFilters] = React.useState(
    []
  )  
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  })

  const data = useMemo(() => {
    if (_.isUndefined(projects)) {
      return [];
    }
    // console.log(projects);
    let local_projs = {};
    // loop through projects and add 3 properties to each project and then add to local_projs
    // 1. parent: true/false
    // 2. subRows: []
    // 3. project_type: 1/2/3
    Object.keys(projects).map(function (key) {
      let project = projects[key];
      let skip_proj = (!using_canopy && project.project_type == 3) || (using_canopy && project.project_type != 3);
      if (skip_proj) {
        return;
      }
      project.variants = Object.values(project.variants);
      project.parent = true;
      // project.subRows = project.subRows ? project.subRows : project.variants || [];
      // project.subRows = project.variants;
      local_projs[key] = project;
    });

    local_projs = Object.values(local_projs);
    // console.log(local_projs);
    return local_projs;
  }, [projects]);

  function fetchData(options) {
    return {
      rows: data.slice(
        options.pageIndex * options.pageSize,
        (options.pageIndex + 1) * options.pageSize
      ),
      pageCount: Math.ceil(data.length / options.pageSize),
      rowCount: data.length,
    }
  }
  const dataQuery = useQuery({
    queryKey: ['data', pagination],
    queryFn: () => fetchData(pagination),
    placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
  })  

  const toggleAllRowsExpanded = () => {
    const isAllExpanded = Object.values(expanded).every((value) => value);
    const newExpandedState = {};
    Object.keys(expanded).forEach((key) => {
      newExpandedState[key] = !isAllExpanded;
    });
    setExpanded(newExpandedState);
  };

  const toggleRowExpanded = (rowId) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [rowId]: !prevExpanded[rowId],
    }));
  };

  function updateProjectFilter(value) {
    if (value == '') {
      setPagination({
        pageIndex: 0,
        pageSize: 20,
      })
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: data.length,
      })      
    }
    setGlobalFilter(value);
  }

  const columns = useMemo(
    () => [
      {
        id: "expander",
        accessorKey: "project_name",
        size: 300,
        header: "Project Name",
        filterFn: 'fuzzy', //using our custom fuzzy filter function
        sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
        cell: ({ row }) => (
          <>
            {/* {console.log(row.original)} */}
            {row.getCanExpand() ? (
              <div onClick={row.getToggleExpandedHandler()}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button type="link" label={row.getIsExpanded() ? <DownOutlined /> : <RightOutlined />} />
                  <> {row.original.project_name}</>
                </div>
              </div>
            ) : (
              <div
                style={{
                  paddingLeft: row.original.variant_name ? `${row.depth * 3}rem` : "2.2rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <>{row.original.variant_name || `${row.original.project_name}`}</>
                </div>
              </div>
            )}
          </>
        ),
      },
      {
        id: "status",
        size: 70,
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => (
          <div>
            {row.original.collab_on == 1 ? (
              <>{row.original.locked_by_me ? LockedByYou : row.original.locked ? LockedBySomeone(row.original.locked_by_email) : NotLocked}</>
            ) : (
              <Divider width={"20px"} />
            )}
            {/* {row.getCanExpand() ? (
              <Divider width={"20px"} />
            ) : (
              <>
                {row.original.collab_on==1 && 
                  <>
                    {row.original.locked_by_me ? LockedByYou : row.original.locked ? LockedBySomeone(row.original.locked_by_email) : NotLocked}
                  </>
                }
                
              </>
            )}  */}
          </div>
        ),
      },
      // {
      //   id: "variants",
      //   size: 60,
      //   header: "Variants",
      //   accessorKey: "subRows",
      //   cell: (cell) => (cell.row.depth == 0 ? <>{cell.row.originalSubRows.length}</> : <Divider width={"35px"} />),
      // },
      {
        id: "modified_dated",
        size: 130,
        header: "Modified Date",
        accessorKey: "edit_dt",
        canFilter: false,
        // cell: (cell) => (!cell.row.original.parent && cell.getValue() ? format_date_ts(cell.getValue()) : null),
        cell: (cell) => (!cell.row.original.parent && cell.getValue() ? format_date_ts(cell.getValue(), true) : format_date_ts(cell.getValue(), false)),
        // cell: (row) => console.log("Modified Date Row", row),
      },
      {
        id: "actions",
        size: 165,
        header: "Actions",
        cell: ({ row }) => ButtonStateMachine(row),
        // cell: ({ row }) => console.log("row", row),
      },
    ],
    [expanded]
  );

  const table = useReactTable({
    data: dataQuery.data?.rows ?? data,
    columns,


    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    globalFilterFn: 'fuzzy', //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    onColumnFiltersChange: setSearchFilters,
    onGlobalFilterChange: setGlobalFilter,

    rowCount: dataQuery.data?.rowCount,
    state: {
      expanded,
      pagination,
      searchFilters,
      globalFilter,      
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.variants,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),

    onPaginationChange: setPagination,
    manualPagination: true, //we're doing manual "server-side" pagination    
    debugTable: true,
  });

  return (
      <DataMangagerTableWrap>
        <div className="pagination flex items-center gap-2">
          <span className="flex items-center gap-1">
            <button
              className="border rounded p-1"
              onClick={() => table.firstPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.lastPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </span>
          <span className="flex items-center gap-1">
            Page
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount().toLocaleString()}
            </strong>
          </span>
          {/* <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              min="1"
              max={table.getPageCount()}
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className="border p-1 rounded w-16"
            />
          </span> */}
          <select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
            disabled={pagination.pageSize==data.length}
          >
            {[20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <span>
            <Input 
                id="project_search"
                // className="login-input"
                // ref={(input) => input && input.focus()}
                prefix={<ProjectOutlined type="text" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Search project name."
                onChange={(e) => updateProjectFilter(e.target.value)}
                value={globalFilter ?? ''}
                
            />
          </span>

          {/* {dataQuery.isFetching ? 'Loading...' : null} */}
        </div>
        {/* <div>
          Showing {table.getRowModel().rows.length.toLocaleString()} of{' '}
          {dataQuery.data?.rowCount.toLocaleString()} Rows
        </div>     */}
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{ width: `${header.getSize()}px` }}>
                      {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  style={{
                    backgroundColor: row.depth != 0 && "#012bcb29",
                  }}
                >
                  {row.getVisibleCells().map((cell) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </DataMangagerTableWrap>
  );
};

const queryClient = new QueryClient();

const Projects = (props) => {
  const projects = useSelector((state) => state.projects);
  // console.log("rendering projects", projects);
  // console.log("projectdata", proj_data);
  return (
    <QueryClientProvider client={queryClient}>
      <DataManagerTable projects={{ ...projects.local }} />
    </QueryClientProvider>
  );
};
export { Projects };
