import React, { useState, useEffect, useRef } from "react";
import Map, { NavigationControl, Source, Layer, Marker } from "react-map-gl";
import { MapboxLayoutLayers } from "../MapboxLayoutLayers";
import { MapNav } from "../MapNav";
import { LiveReportMapboxMapWrap } from "./styles";
import { getBounds } from "../../Redux";
import { useSelector, useDispatch } from "react-redux";

let token = "pk.eyJ1IjoibnNhbmRlcnNvbngiLCJhIjoiY2x1c2I0aW43MDEyNDJqbnVoazNzZnhnMSJ9.gkvHjLK-5PZAJp8x5t52CQ";

const TileSets = {
  Mapbox: { type: "LD", data: `satellite-v9` },
  Satellite: { type: "LD", data: "satellite-streets-v12" },
  StreetMap: { type: "LD", data: "streets-v12" },
  Outdoors: { type: "LD", data: "outdoors-v12" },
};

const LiveReportMapboxMap = ({ mapName, showToggles, addMapRefs }) => {
  const map_center = useSelector((state) => state.inputs.map_center);
  const tile_set = useSelector((state) => state.inputs.uiState.tile_set || "Mapbox");
  const site_features = useSelector((state) => state.inputs.site_features);

  const [position, setPosition] = useState([map_center[1], map_center[0]]);
  const [showControls, setShowControls] = useState(false);
  const [showBoundary, setShowBoundary] = useState(true);
  const [showExclusion, setShowExclusion] = useState(true);
  const [showInactive, setShowInactive] = useState(true);
  const [show_racks, set_show_racks] = useState(true);
  const [show_roads, set_show_roads] = useState(true);
  const [show_inverters, set_show_inverters] = useState(true);

  const [map, setMap] = useState(null);

  const onMapLoad = (event) => {
    const mapInstance = event.target;
    setMap(mapInstance);
  };

  useEffect(() => {
    if (!map) return;

    // try {
    //   const canvas = map.getCanvas();
    //   const dataUrl = canvas.toDataURL("image/png", 0.6); // Adjust quality as needed
    //   console.log("Data", dataUrl);
    // } catch (error) {
    //   console.log("Image capture error", error);
    // }

    const mapContainer = map.getContainer();
    const width = mapContainer.clientWidth;
    const height = mapContainer.clientHeight;

    // console.log("Map width:", width, "Map height:", height);

    addMapRefs(`${mapName}`, { height, width, className: mapName, theMapRef: map });

    if (mapName !== "vicinityMap") {
      setTimeout(() => {
        handleLiveMapZoomExtents();
      }, 500);
    }
  }, [map]);

  const handleLiveMapZoomExtents = (zoomLevel = undefined) => {
    if (!map) return;

    if (Object.values(site_features).length === 0) {
      console.warn("No site features available to zoom extents");
      return;
    }

    let bounds = getBounds(Object.values(site_features));

    if (!bounds || bounds.length !== 4) {
      console.error("Invalid bounds:", bounds);
      return;
    }

    let bounds_to_fit = [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ];

    map?.fitBounds(bounds_to_fit, { duration: 2000, padding: { top: 50, bottom: 50, left: 50, right: 50 } });
  };

  const handleMarkerDrag = (mark) => {
    let lngLat = [mark.lngLat.lng, mark.lngLat.lat];
    setPosition(lngLat);
    dispatch(siftActions.updateReportData("location", lngLat));
  };

  return (
    <LiveReportMapboxMapWrap onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)} small={true}>
      {showToggles && (
        <section className="map-toggles">
          <h3>Layout Map</h3>
          <section className="map-toggle-buttons">
            <div className="toggle">
              <p>Show Boundaries</p>
              <label className="switch">
                <input type="checkbox" checked={showBoundary} onChange={(e) => setShowBoundary(!showBoundary)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Exclusions</p>
              <label className="switch">
                <input type="checkbox" checked={showExclusion} onChange={(e) => setShowExclusion(!showExclusion)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Inactive</p>
              <label className="switch">
                <input type="checkbox" checked={showInactive} onChange={(e) => setShowInactive(!showInactive)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Racks</p>
              <label className="switch">
                <input type="checkbox" checked={show_racks} onChange={(e) => set_show_racks(!show_racks)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Roads</p>
              <label className="switch">
                <input type="checkbox" checked={show_roads} onChange={(e) => set_show_roads(!show_roads)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Inverters</p>
              <label className="switch">
                <input type="checkbox" checked={show_inverters} onChange={(e) => set_show_inverters(!show_inverters)} />
                <span className="slider round"></span>
              </label>
            </div>
          </section>
        </section>
      )}
      <section className="map-area">
        <Map
          mapboxAccessToken={token}
          id={mapName}
          key={mapName}
          className={mapName}
          initialViewState={{
            longitude: map_center[1],
            latitude: map_center[0],
            zoom: 14,
            bearing: 0,
            pitch: 0,
          }}
          doubleClickZoom={false}
          onLoad={onMapLoad}
          style={{ width: "100%", height: "100%" }}
          mapStyle={`mapbox://styles/mapbox/${TileSets[mapName === "vicinityMap" ? "StreetMap" : tile_set].data}`}
          attributionControl={false}
        >
          {showControls && map && (
            <>
              <NavigationControl />
              <MapNav />
            </>
          )}

          {map && mapName == "vicinityMap" && position ? (
            <Marker key="vininityMarker" draggable={true} longitude={position[0]} latitude={position[1]} anchor="bottom" onDragEnd={handleMarkerDrag} />
          ) : (
            <>
              {Object.values(site_features).map((feature) => {
                if (feature.properties.identity === 0 && !showInactive) return;
                if (feature.properties.identity === 1 && !showBoundary) return;
                if (feature.properties.identity === 2 && !showExclusion) return;

                let color;
                switch (feature.properties.identity) {
                  case 0:
                    color = "#FFFFFF";
                    break;
                  case 1:
                    color = "#FF0000";
                    break;
                  case 2:
                    color = "#FFFF00";
                    break;
                  default:
                    color = "#FFFFFF";
                }

                return (
                  <Source key={feature.properties.index} type="geojson" data={feature}>
                    <Layer
                      type="line"
                      paint={{
                        "line-color": color,
                        "line-width": 1,
                      }}
                    />
                  </Source>
                );
              })}
              {map && <MapboxLayoutLayers show_racks={show_racks} show_roads={show_roads} show_inverters={show_inverters} liveReportMap={true} />}
            </>
          )}
        </Map>
      </section>
    </LiveReportMapboxMapWrap>
  );
};

export { LiveReportMapboxMap };
