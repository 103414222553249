import React from "react";
import { RouterProvider, Navigate, createBrowserRouter } from "react-router-dom";
import { useAuth } from "./components/Auth";
import { ProtectedRoute } from "./routes/ProtectedRoute";

import { PasswordReset_New, SIFT } from "./routes/";

const Terrasmart_Proxy = () => {
  return <iframe src="https://www.terrasmart.com/products/software/sift/" style={{ border: "none", width: "100%", height: "100vh" }}></iframe>;
};

const routesForPublic = [
  // {
  //   path: "/siftlauncher/:command",
  //   element: <SIFT/>,
  // },
  // {
  //   path: "/sift",
  //   element: <SIFT />,
  // },
  {
    // {
    //   path:"/login",
    //   element:<InternalSIFTLogin />
    // },
    // {
    //   path: "/home",
    //   element: <Terrasmart_Proxy />,
    // },
    path: "/canopy",
    element: <SIFT />,
  },
  {
    path: "/pwreset/:code",
    element: <PasswordReset_New />,
  },
  {
    path: "/ground",
    element: <SIFT />,
  },
  {
    path: "/",
    element: <SIFT />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },  
];

const routesForInternal = [
  // {
  //   path:"siftinternal",
  //   element:<SIFTInternal />
  // },
  // {
  //   path:"canopy",
  //   element:<SIFTInternal />
  // },
];

const Routes = () => {
  const router = createBrowserRouter(
    [
      ...routesForPublic,
      // ...(!is_authenticated ? routesForNotAuthenticatedOnly : []),
      // ...routesForInternal,
    ],
    {basename: "/"}
  );
  return <RouterProvider router={router} fallbackElement={<SIFT/>} />;
};

export default Routes;
