import React, { useEffect, useState } from "react";
import { Source, Layer, useMap } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const EsriLayer = ({ id, url, color = "dodgerblue", setLoadingState }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const [geojsonData, setGeojsonData] = useState(null);
  const [first_layer, set_first_layer] = useState();
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    set_first_layer(map.getStyle().layers[5].id);
  }, []);

  const fetchFeatureServerData = async (url, bounds) => {
    const bbox = `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`;
    const response = await fetch(`${url}/query?where=1%3D1&outFields=*&geometry=${bbox}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&outSR=4326&f=geojson`);
    const data = await response.json();

    return {
      type: "FeatureCollection",
      features: data.features.map((feature) => ({
        type: "Feature",
        geometry: feature.geometry,
        properties: feature.attributes,
      })),
    };
  };

  const updateLayer = async () => {
    if (!map) return;
    const zoom = map.getZoom();
    if (zoom < 10) return;

    setIsLoading(true);
    setLoadingState(id, true);

    const bounds = map.getBounds();
    const geojsonData = await fetchFeatureServerData(url, bounds);

    setGeojsonData(geojsonData); // Set state with the new GeoJSON data
    setIsLoading(false);
    setLoadingState(id, false);
  };

  useEffect(() => {
    if (!map) return;

    const handleMoveEnd = () => updateLayer();

    map.on("moveend", handleMoveEnd);
    updateLayer(); // Fetch data on initial render

    return () => {
      map.off("moveend", handleMoveEnd);
    };
  }, [map, url, id]);

  if (isLoading) {
    return <div>Loading...</div>; // Display loading spinner while loading
  }

  if (!geojsonData) return null; // Return null if no data is available yet

  return (
    <>
      {first_layer && (
        <Source id={id} type="geojson" data={geojsonData}>
          <Layer
            id={id}
            type="line"
            source={id}
            paint={{
              "line-color": color,
              "line-width": 2,
            }}
          />
        </Source>
      )}
    </>
  );
};

export { EsriLayer };
