import { ModulesDD, InvertersDD, RackingDD, PerformanceDD, WeatherDD, FinanceDD, LayoutDD, ConfigDefault, TopographyDD } from "../../InputPanel";
const tabDefaults = {
  module: JSON.parse(JSON.stringify(ModulesDD["364aa9e4cee04535aed7806302f3f652"])),
  inverter: JSON.parse(JSON.stringify(InvertersDD["0f14d6b175444d6699dfe4d69f32c243"])),
  racking: JSON.parse(JSON.stringify(RackingDD["49aa35992cf4480e9a2f1152c43edcda"])),
  weather: JSON.parse(JSON.stringify(WeatherDD["0"])),
  performance: JSON.parse(JSON.stringify(PerformanceDD["0"])),
  finance: JSON.parse(JSON.stringify(FinanceDD["0"])),
  config: JSON.parse(JSON.stringify(ConfigDefault)),
  layout: JSON.parse(JSON.stringify(LayoutDD["0"])),
  topography: JSON.parse(JSON.stringify(TopographyDD["0"])),
};

function get_value(input, type, def, test = false) {
  let return_input = def;

  if (!_.isEmpty(input) || !_.isUndefined(input)) {
    if (type === "string") {
      return input.toString();
    }

    if ((_.isString(input) && _.isEmpty(input)) || _.isUndefined(input) || _.isNaN(input)) {
      return def;
    }

    if (type === "float") {
      return parseFloat(input);
    }
    if (type === "int") {
      return parseInt(input);
    }
  }

  return return_input;
}

export const get_preset_inputs = (state, tab, key) => {
  let preset_inputs = {};

  switch (tab) {
    case "module":
      preset_inputs = {
        mod_name: key === "saveas" ? `${state.mod_name} - copy` : state.mod_name,
        mod_id: key === "saveas" ? undefined : key === "save" && state.mod_id == 0 ? undefined : state.mod_id,
        mod_rating: state.mod_rating,
        mod_width: state.mod_width,
        mod_height: state.mod_height,
        mod_pnom: state.mod_pnom,
        mlm_N_series: state.mlm_N_series,
        mlm_N_parallel: state.mlm_N_parallel,
        mlm_N_diodes: state.mlm_N_diodes,
        mlm_V_mp_ref: state.mlm_V_mp_ref,
        mlm_I_mp_ref: state.mlm_I_mp_ref,
        mlm_V_oc_ref: state.mlm_V_oc_ref,
        mlm_I_sc_ref: state.mlm_I_sc_ref,
        mlm_S_ref: state.mlm_S_ref,
        mlm_T_ref: state.mlm_T_ref,
        mlm_R_shref: state.mlm_R_shref,
        mlm_R_sh0: state.mlm_R_sh0,
        mlm_R_shexp: state.mlm_R_shexp,
        mlm_R_s: state.mlm_R_s,
        mlm_alpha_isc: state.mlm_alpha_isc,
        mlm_beta_voc_spec: state.mlm_beta_voc_spec,
        mlm_n_0: state.mlm_n_0,
        mlm_mu_n: state.mlm_mu_n,
        mlm_T_c_fa_alpha: state.mlm_T_c_fa_alpha,
        mlm_E_g: state.mlm_E_g,
        module_iam_ang: state.module_iam_ang,
        module_iam_eff: state.module_iam_eff,
        muPmpReq: state.muPmpReq,
        mlm_mu_n_perc: state.mlm_mu_n_perc,
        technology: state.technology,
        mlm_D2MuTau: state.mlm_D2MuTau,
        en_bifacial: state.en_bifacial,
        bi_bifaciality: state.bi_bifaciality,
      };
      break;

    case "inverter":
      preset_inputs = {
        inv_name: key === "saveas" ? `${state.inv_name} - copy` : state.inv_name,
        inv_id: key === "saveas" ? undefined : key === "save" && state.mod_id == 0 ? undefined : state.inv_id,
        inv_rating: state.inv_rating,
        inv_pnom: state.inv_pnom,
        derate_temps: state.derate_temps,
        derate_maxoutput: state.derate_maxoutput,
        derate_toggle: state.derate_toggle,
        inv_pd_pacokw: state.inv_pd_pacokw,
        maxEff: state.maxEff,
        mppt_low_inverter: state.mppt_low_inverter,
        mppt_hi_inverter: state.mppt_hi_inverter,
        inv_pd_vdcmax: state.inv_pd_vdcmax,
        inv_pd_pnt: state.inv_pd_pnt,
        pthresh: state.pthresh,
        imax_ac: state.imax_ac,
        imax_dc: state.imax_dc,
        inv_pd_pdco: state.inv_pd_pdco,
        v1_pl: state.v1_pl,
        v1_eff: state.v1_eff,
        v2_pl: state.v2_pl,
        v2_eff: state.v2_eff,
        v3_pl: state.v3_pl,
        v3_eff: state.v3_eff,
        vs_effeu: state.vs_effeu,
        vs_effmax: state.vs_effmax,
        vs_vnom: state.vs_vnom,
        inv_pd_eff_pout: state.inv_pd_eff_pout,
        inv_pd_efficiency: state.inv_pd_efficiency,
      };
      break;

    case "racking":
      preset_inputs = {
        racking_id: key === "saveas" ? undefined : key === "save" && isTerrasmartProduct(state.racking_id) ? undefined : state.racking_id,
        racking_name: key === "saveas" ? `${state.racking_name} - copy` : state.racking_name,
        rack_dims: state.rack_dims,
        track_mode: state.track_mode,
        modules_high: state.modules_high,
        orientation: state.orientation,
        mod_per_string: state.mod_per_string,
        module_gap: state.module_gap,
        drive_gap: state.drive_gap,
        racking_errors: undefined,
        racks: state.racks,
        rack_grade_limit: state.rack_grade_limit,
        gap: state.gap,
        bi_groundClearanceHeight: state.bi_groundClearanceHeight,
        bi_transmissionFactor: state.bi_transmissionFactor,
        bi_structureShadeFactor: state.bi_structureShadeFactor,
        en_backtrack: state.en_backtrack,
        en_irradtrack: state.en_irradtrack,
        rlim: state.rlim,
        tilt_min: state.tilt_min,
        tilt_inc: state.tilt_inc,
        tilt_max: state.tilt_max,
        tilts: state.tilts,
        shade_mode: state.shade_mode,
        Fshd_StringSteps: state.Fshd_StringSteps,
        Fshd_Celltrav: state.Fshd_Celltrav,
      };
      break;

    case "weather":
      preset_inputs = {
        weather_id: key === "saveas" ? undefined : key === "save" && state.mod_id == 0 ? undefined : state.weather_id,
        weather_name: key === "saveas" ? `${state.weather_name} - copy` : state.weather_name,
        weather_source: state.weather_source,
        latitude: state.latitude,
        longitude: state.longitude,
        elevation: state.elevation,
        timezone: state.timezone,
        weather_lat: state.weather_lat,
        weather_lng: state.weather_lng,
        weather_ele: state.weather_ele,
        weather_tz: state.weather_tz,
        locationId: state.locationId,
        weather_summary: state.weather_summary,
      };
      break;

    case "performance":
      preset_inputs = {
        perf_id: key === "saveas" ? undefined : key === "save" && state.mod_id == 0 ? undefined : state.perf_id,
        perf_name: key === "saveas" ? `${state.perf_name} - copy` : state.perf_name,
        dc_degrade: state.dc_degrade,
        dc_thermal_Uc: state.dc_thermal_Uc,
        dc_thermal_Uv: state.dc_thermal_Uv,
        dc_module_quality_loss: state.dc_module_quality_loss,
        dc_module_lid_loss: state.dc_module_lid_loss,
        dc_module_mismatch: state.dc_module_mismatch,
        dc_strings_mismatch: state.dc_strings_mismatch,
        dc_wiring_loss_at_stc: state.dc_wiring_loss_at_stc,
        bi_back_mismatch: state.bi_back_mismatch,
        en_powerfactor: state.en_powerfactor,
        power_factor: state.power_factor,
        pnom_apparent: state.pnom_apparent,
        ac_aux_kw: state.ac_aux_kw,
        ac_wiring_loss_at_stc: state.ac_wiring_loss_at_stc,
        ac_transformer_loss_constant: state.ac_transformer_loss_constant,
        ac_transformer_loss_at_stc: state.ac_transformer_loss_at_stc,
        ac_MV_line_loss_stc: state.ac_MV_line_loss_stc,
        ac_hv_transformer_loss_at_stc: state.ac_hv_transformer_loss_at_stc,
        ac_transmission_loss: state.ac_transmission_loss,
        ac_other_loss: state.ac_other_loss,
        do_monthly_losses: state.do_monthly_losses,
        albedo_single: state.albedo_single,
        soiling_single: state.soiling_single,
        soiling: state.soiling,
        albedo: state.albedo,
      };
      break;

    case "finance":
      preset_inputs = {
        finance_id: key === "saveas" ? undefined : key === "save" && state.mod_id == 0 ? undefined : state.finance_id,
        finance_name: key === "saveas" ? `${state.finance_name} - copy` : state.finance_name,
        do_finance: state.do_finance,
        metric: state.metric,
        analysis_period: state.analysis_period,
        discount_rate: state.discount_rate,
        dc_op_cost: state.dc_op_cost,
        ac_op_cost: state.ac_op_cost,
        fixed_op_cost: state.fixed_op_cost,
        lease_cost: state.lease_cost,
        escalation: state.escalation,
        do_custom_schedule: state.do_custom_schedule,
        customSchedule: state.customSchedule,
        summarized_dc: state.summarized_dc,
        summarized_ac: state.summarized_ac,
        foot_print: state.foot_print,
        fixed_cost: state.fixed_cost,
        toggle_finance_type: state.toggle_finance_type,
        module_dc_cost: state.module_dc_cost,
        rack_a_finance: state.rack_a_finance,
        rack_b_finance: state.rack_b_finance,
        rack_c_finance: state.rack_c_finance,
        bos_other: state.bos_other,
        inverter_cost: state.inverter_cost,
        ac_aux: state.ac_aux,
        mv_wire: state.mv_wire,
        other_ac: state.other_ac,
        interconnection: state.interconnection,
        permits_fees: state.permits_fees,
        engineering: state.engineering,
        margin: state.margin,
        other_fixed: state.other_fixed,
        rack_footprint: state.rack_footprint,
        boundary_area_per: state.boundary_area_per,
        contingency: state.contingency,
        spacing_gcr: state.spacing_gcr,
        spacing_per_wp: state.spacing_per_wp,
        footprint_op_cost: state.footprint_op_cost,
        area_install_cost: state.area_install_cost,
        area_op_cost: state.area_op_cost,
        ri_power: state.ri_power,
        ri_escalation: state.ri_escalation,
        do_include_itc: state.do_include_itc,
        itc_percent: state.itc_percent,
        do_include_ptc: state.do_include_ptc,
        ptc_value: state.ptc_value,
        do_ri_customSchedule: state.do_ri_customSchedule,
        ri_customSchedule: state.ri_customSchedule,
        debt_structure: state.debt_structure,
        debt_percent: state.debt_percent,
        debt_dscr: state.debt_dscr,
        debt_interest: state.debt_interest,
        debt_tenor: state.debt_tenor,
        dep_5yrSL: state.dep_5yrSL,
        dep_15yrSL: state.dep_15yrSL,
        dep_20yrSL: state.dep_20yrSL,
        dep_30yrSL: state.dep_30yrSL,
        dep_35yrSL: state.dep_35yrSL,
        dep_39yrSL: state.dep_39yrSL,
        dep_5yrMACRS: state.dep_5yrMACRS,
        dep_15yrMACRS: state.dep_15yrMACRS,
        state_taxes: state.state_taxes,
        federal_taxes: state.federal_taxes,
        revenue_type: state.revenue_type,
        tou_grid: state.tou_grid,
      };
      break;

    case "layout":
      preset_inputs = {
        layout_id: key === "saveas" ? undefined : key === "save" && state.mod_id == 0 ? undefined : state.layout_id,
        // id: key === "saveas" ? undefined : key === "save" && state.mod_id == 0 ? undefined : state.layout_id,
        layout_name: key === "saveas" ? `${state.layout_name} - copy` : state.layout_name,
        do_grid_poi_lim: state.do_grid_poi_lim,
        ac_grid_poi_lim: state.ac_grid_poi_lim,
        do_inverter_limit_lock: state.do_inverter_limit_lock,
        inverter_limit: state.inverter_limit,
        do_rack_align: state.do_rack_align,
        sazm: state.sazm,
        epsg: state.epsg,
        road_spacing_option: state.road_spacing_option,
        do_roads: state.do_roads,
        road_spacing: state.road_spacing,
        road_width: state.road_width,
        road_to_rack: state.road_to_rack,
        inverter_grouping: state.inverter_grouping,
        do_inv_spacing: state.do_inv_spacing,
        inverter_per_cut: state.inverter_per_cut,
        inv_pad_x: state.inv_pad_x,
        inv_pad_y: state.inv_pad_y,
        road_to_rack_inv: state.road_to_rack_inv,
        do_wiring: state.do_wiring,
        combiner_location: state.combiner_location,
        strings_per_combiner: state.strings_per_combiner,
        wire_stringing: state.wire_stringing,
        do_designer_margin: state.do_designer_margin,
        designer_margin: state.designer_margin,
      };
      break;

    default:
      break;
  }

  return preset_inputs;
};

export const map_presets_1_8 = (key, preset) => {
  let fixed_preset = {};
  const { data } = preset;

  let is_new_structure = _.isUndefined(data.name);

  switch (key) {
    case "module":
      fixed_preset = {
        mod_id: preset.id,

        mod_name: is_new_structure ? data.mod_name : data.name,
        mod_rating: is_new_structure ? data.mod_rating : data.rating,
        mod_width: is_new_structure ? data.mod_width : data.mlm_Width,
        mod_height: is_new_structure ? data.mod_height : data.mlm_Length,
        mod_pnom: is_new_structure ? data.mod_pnom : data.rating,
        en_bifacial: is_new_structure ? data.en_bifacial : data.module_isbifacial,
        bi_bifaciality: is_new_structure ? data.bi_bifaciality : data.module_bifaciality,
        simple_module: data.simple_module == 1 ? 1 : 0,

        mlm_N_series: data.mlm_N_series,
        mlm_N_parallel: data.mlm_N_parallel,
        mlm_N_diodes: data.mlm_N_diodes,
        mlm_V_mp_ref: data.mlm_V_mp_ref,
        mlm_I_mp_ref: data.mlm_I_mp_ref,
        mlm_V_oc_ref: data.mlm_V_oc_ref,
        mlm_I_sc_ref: data.mlm_I_sc_ref,
        mlm_S_ref: data.mlm_S_ref,
        mlm_T_ref: data.mlm_T_ref,
        mlm_R_shref: data.mlm_R_shref,
        mlm_R_sh0: data.mlm_R_sh0,
        mlm_R_shexp: data.mlm_R_shexp,
        mlm_R_s: data.mlm_R_s,
        mlm_alpha_isc: data.mlm_alpha_isc,
        mlm_beta_voc_spec: data.mlm_beta_voc_spec,
        mlm_n_0: data.mlm_n_0,
        mlm_mu_n: data.mlm_mu_n,
        mlm_T_c_fa_alpha: data.mlm_T_c_fa_alpha,
        mlm_E_g: data.mlm_E_g,
        module_iam_ang: data.module_iam_ang,
        module_iam_eff: data.module_iam_eff,
        muPmpReq: data.muPmpReq,
        mlm_mu_n_perc: data.mlm_mu_n_perc,
        technology: data.technology,
        mlm_D2MuTau: data.mlm_D2MuTau,

        default: data.default || 0,
      };

      if (typeof fixed_preset.en_bifacial == "boolean") {
        fixed_preset.en_bifacial = fixed_preset.en_bifacial ? 1 : 0;
      }
      break;

    case "inverter":
      fixed_preset = {
        inv_id: preset.id,

        inv_name: is_new_structure ? data.inv_name : data.name,
        inv_rating: is_new_structure ? data.inv_rating : data.inverterRating,
        inv_pnom: is_new_structure ? data.inv_pnom : data.pnom,
        simple_inverter: data.simple_inverter == 1 ? 1 : 0,

        derate_temps: data.derate_temps,
        derate_maxoutput: data.derate_maxoutput,
        derate_toggle: data.derate_toggle,
        inv_pd_pacokw: data.inv_pd_pacokw,
        maxEff: data.maxEff,
        mppt_low_inverter: data.mppt_low_inverter,
        mppt_hi_inverter: data.mppt_hi_inverter,
        inv_pd_vdcmax: data.inv_pd_vdcmax,
        inv_pd_pnt: data.inv_pd_pnt,
        pthresh: data.pthresh,
        imax_ac: data.imax_ac,
        imax_dc: data.imax_dc,
        inv_pd_pdco: data.inv_pd_pdco,
        v1_pl: data.v1_pl,
        v1_eff: data.v1_eff,
        v2_pl: data.v2_pl,
        v2_eff: data.v2_eff,
        v3_pl: data.v3_pl,
        v3_eff: data.v3_eff,
        vs_effeu: data.vs_effeu,
        vs_effmax: data.vs_effmax,
        vs_vnom: data.vs_vnom,
        inv_pd_eff_pout: data.inv_pd_eff_pout,
        inv_pd_efficiency: data.inv_pd_efficiency,

        default: data.default || 0,
      };
      if (typeof fixed_preset.derate_toggle === "boolean") {
        fixed_preset.derate_toggle = fixed_preset.derate_toggle ? 1 : 0;
      }
      break;

    case "racking":
      fixed_preset = {
        racking_id: preset.id,

        racking_name: is_new_structure ? data.racking_name : data.name,

        rack_dims: get_value(data.rack_dims, "int", 0),
        mod_per_string: is_new_structure && data.mod_per_string ? data.mod_per_string : get_value(data.modules_per_string, "int", 26),
        modules_high: get_value(data.modules_high, "int", 1),
        orientation: get_value(data.orientation, "int", 0),

        track_mode: is_new_structure ? data.track_mode : data.type,
        rlim: is_new_structure ? data.rlim : data.track_angle,
        en_irradtrack: is_new_structure ? data.is_new_structure : 0,

        rack_grade_limit: is_new_structure ? data.rack_grade_limit : get_value(data.grade_limit, "float", 10),

        module_gap: get_value(data.module_gap, "float", 0.02),
        drive_gap: get_value(data.drive_gap, "float", 2),
        tilt_min: get_value(data.tilt_min, "float", 10),
        tilt_max: get_value(data.tilt_max, "float", 30),
        tilt_inc: get_value(data.tilt_inc, "float", 5),
        gap: get_value(data.gap, "float", 2, true),

        bi_groundClearanceHeight: is_new_structure ? data.bi_groundClearanceHeight : get_value(data.module_clearance_height, "float", 0.5),
        bi_transmissionFactor: is_new_structure ? data.bi_transmissionFactor : get_value(data.module_transmission_perc, "float", 0),
        bi_structureShadeFactor: is_new_structure ? data.bi_structureShadeFactor : get_value(data.module_structure_shade_perc, "float", 0),
        en_backtrack: is_new_structure ? data.en_backtrack : get_value(data.backtrack, "int", 1),

        shade_mode: get_value(data.shade_mode, "int", 1),
        Fshd_StringSteps: is_new_structure ? data.Fshd_StringSteps : get_value(data.string_steps, "int", 1),
        Fshd_Celltrav: is_new_structure ? data.Fshd_Celltrav : data.cell_trav,

        racking_errors: is_new_structure ? data.racking_errors : undefined,

        racks: data.racks,

        default: data.default || 0,
      };

      Object.values(fixed_preset.racks).map((rack, index) => {
        fixed_preset.racks[index].key = rack.key;
        fixed_preset.racks[index].active = rack.active;
        fixed_preset.racks[index].xdim = parseFloat(rack.xdim);
        fixed_preset.racks[index].ydim = parseFloat(rack.ydim);
        fixed_preset.racks[index].module = parseInt(rack.module);

        if (
          fixed_preset.racking_id === "Terrasmart GLIDE Wave" ||
          fixed_preset.racking_id === "Terrasmart GLIDE Agile" ||
          fixed_preset.racking_id === "Terrasmart GLIDE Fuse" ||
          fixed_preset.racking_id === "Terrasmart TerraTrak 2P" ||
          fixed_preset.racking_id === "Terrasmart TerraTrak 1P"
        ) {
          // console.log('terrasmart product presets')
        } else {
          if (fixed_preset.rack_dims == 0) {
            fixed_preset.racks[index].string_count = parseInt(rack.module / fixed_preset.mod_per_string);
          } else {
            fixed_preset.racks[index].string_count = _.isUndefined(rack.string_count) ? parseInt(rack.module) / fixed_preset.mod_per_string : rack.string_count;
          }
        }
      });
      fixed_preset.Fshd_CollWidth = fixed_preset.track_mode == 0 ? fixed_preset.racks[0].ydim : fixed_preset.racks[0].xdim;

      break;

    case "weather":
      fixed_preset = {
        // weather_id: preset.id,
        weather_id: data.weather_id,

        weather_name: is_new_structure ? data.weather_name : data.name,
        weather_source: is_new_structure ? data.weather_source : data.source,
        // latitude: is_new_structure ? data.latitude : data.lat,
        // longitude: is_new_structure ? data.longitude : data.lng,
        // elevation: is_new_structure ? data.elevation : data.ele,
        // timezone: is_new_structure ? data.timezone : data.tz,
        weather_lat: is_new_structure ? data.weather_lat : data.weather_summary.Latitude,
        weather_lng: is_new_structure ? data.weather_lng : data.weather_summary.Longitude,
        weather_ele: is_new_structure ? data.weather_ele : data.weather_summary.Elevation,
        weather_tz: is_new_structure ? data.weather_tz : data.weather_summary.Timezone,
        locationId: is_new_structure ? data.locationId : data.locationId || "",
        weather_summary: is_new_structure ? data.weather_summary : data.weather_summary.Summary,
      };
      break;

    case "performance":
      fixed_preset = {
        perf_id: preset.id,
        perf_name: is_new_structure ? data.perf_name : data.name,
        dc_degrade: is_new_structure ? data.dc_degrade : get_value(data.dc_degradation, "float", 0.5),
        dc_thermal_Uc: is_new_structure ? data.dc_thermal_Uc : data.thermal_uc,
        dc_thermal_Uv: is_new_structure ? data.dc_thermal_Uv : data.thermal_uv,
        dc_module_quality_loss: is_new_structure ? data.dc_module_quality_loss : data.quality_loss,
        dc_module_lid_loss: is_new_structure ? data.dc_module_lid_loss : data.lid_loss,
        dc_module_mismatch: is_new_structure ? data.dc_module_mismatch : data.mismatch_loss,
        dc_strings_mismatch: is_new_structure ? data.dc_strings_mismatch : 0,
        dc_wiring_loss_at_stc: is_new_structure ? data.dc_wiring_loss_at_stc : data.dc_loss_stc,
        bi_back_mismatch: is_new_structure ? data.bi_back_mismatch : 10,
        en_powerfactor: is_new_structure ? data.en_powerfactor : 0,
        power_factor: is_new_structure ? data.power_factor : 0.1,
        ac_aux_kw: is_new_structure ? data.ac_aux_kw : 0,
        ac_wiring_loss_at_stc: is_new_structure ? data.ac_wiring_loss_at_stc : data.ac_losses,
        ac_transformer_loss_constant: is_new_structure ? data.ac_transformer_loss_constant : 0,
        ac_transformer_loss_at_stc: is_new_structure ? data.ac_transformer_loss_at_stc : 0,
        ac_MV_line_loss_stc: is_new_structure ? data.ac_MV_line_loss_stc : 0,
        ac_hv_transformer_loss_at_stc: is_new_structure ? data.ac_hv_transformer_loss_at_stc : 0,
        ac_transmission_loss: is_new_structure ? data.ac_transmission_loss : 0,
        ac_other_loss: is_new_structure ? data.ac_other_loss : 0,
        albedo_single: is_new_structure ? data.albedo_single : data.albedo,
        soiling_single: is_new_structure ? data.soiling_single : data.soiling,

        do_monthly_losses: is_new_structure ? data.do_monthly_losses : data.doMonthlySoiling,

        albedo: is_new_structure ? data.albedo : data.albedo_monthly,
        soiling: is_new_structure ? data.soiling : data.soiling_monthly,
      };
      break;

    case "finance":
      if (is_new_structure) {
        fixed_preset = {
          ...data,
        };
      } else {
      }
      let summed_dc = _.isUndefined(data.summarized_dc) ? tabDefaults.finance.data.summarized_dc : parseFloat(data.summarized_dc);
      let summed_ac = _.isUndefined(data.summarized_ac) ? tabDefaults.finance.data.summarized_ac : parseFloat(data.summarized_ac);
      let summed_fixed = _.isFinite(parseFloat(data.fixed_cost)) ? parseFloat(data.fixed_cost) : 0;
      let rack_footprint = _.isUndefined(data.rack_footprint) ? tabDefaults.finance.data.rack_footprint : data.rack_footprint;
      if (_.isUndefined(data.summarized_dc) && _.isFinite(parseFloat(data.module_cost))) {
        // probably old project structure
        summed_dc = parseFloat(data.module_cost);
        if (data.other_dc_cost) {
          summed_dc += parseFloat(data.other_dc_cost);
        }
      }
      if (_.isUndefined(data.summarized_ac) && _.isFinite(parseFloat(data.ac_cost))) {
        summed_ac = parseFloat(data.ac_cost);
      }
      if (_.isFinite(parseFloat(data.footprint_install_cost))) {
        summed_fixed += parseFloat(data.footprint_install_cost);
        rack_footprint.value = parseFloat(data.footprint_install_cost);
      }

      fixed_preset = {
        ...tabDefaults.finance.data,
        ...data,

        finance_id: preset.id,
        // finance_name: data.name,
        finance_name: is_new_structure ? data.finance_name : data.name,

        toggle_finance_type: data.toggle_finance_type ? data.toggle_finance_type : tabDefaults.finance.data.toggle_finance_type,

        summarized_dc: summed_dc,
        summarized_ac: summed_ac,
        fixed_cost: summed_fixed,
        footprint_op_cost: data.footprint_op_cost ? data.footprint_op_cost : tabDefaults.finance.data.footprint_op_cost,

        // D/C Units
        module_dc_cost: data.module_dc_cost ? data.module_dc_cost : tabDefaults.finance.data.module_dc_cost,
        rack_a_finance: data.rack_a_finance ? data.rack_a_finance : tabDefaults.finance.data.rack_a_finance,
        rack_b_finance: data.rack_b_finance ? data.rack_b_finance : tabDefaults.finance.data.rack_b_finance,
        rack_c_finance: data.rack_c_finance ? data.rack_c_finance : tabDefaults.finance.data.rack_c_finance,
        bos_other: data.bos_other ? data.bos_other : tabDefaults.finance.data.bos_other,

        // fixed units
        interconnection: data.interconnection ? data.interconnection : tabDefaults.finance.data.interconnection,
        permits_fees: data.permits_fees ? data.permits_fees : tabDefaults.finance.data.permits_fees,
        engineering: data.engineering ? data.engineering : tabDefaults.finance.data.engineering,
        margin: data.margin ? data.margin : tabDefaults.finance.data.margin,
        itc_ineligible: data.itc_ineligible ? data.itc_ineligible : tabDefaults.finance.data.itc_ineligible,
        other_fixed: data.other_fixed ? data.other_fixed : tabDefaults.finance.data.other_fixed,

        // A/C Units
        inverter: data.inverter ? data.inverter : tabDefaults.finance.data.inverter,
        ac_aux: data.ac_aux ? data.ac_aux : tabDefaults.finance.data.ac_aux,
        mv_wire: data.mv_wire ? data.mv_wire : tabDefaults.finance.data.mv_wire,
        other_ac: data.other_ac ? data.other_ac : tabDefaults.finance.data.other_ac,

        // Misc Units
        rack_footprint: data.rack_footprint ? data.rack_footprint : tabDefaults.finance.data.rack_footprint,
        boundary_area_per: data.boundary_area_per ? data.boundary_area_per : tabDefaults.finance.data.boundary_area_per,
        contingency: data.contingency ? data.contingency : tabDefaults.finance.data.contingency,
        spacing_gcr: data.spacing_gcr ? data.spacing_gcr : tabDefaults.finance.data.spacing_gcr,
        spacing_per_wp: data.spacing_per_wp ? data.spacing_per_wp : tabDefaults.finance.data.spacing_per_wp,
      };

      if (!_.isUndefined(fixed_preset.id)) {
        delete fixed_preset.id;
      }
      if (!_.isUndefined(fixed_preset.name)) {
        delete fixed_preset.name;
      }

      break;

    case "layout":
      fixed_preset = {
        layout_id: preset.id,
        // id: preset.id,
        layout_name: is_new_structure ? data.layout_name : data.name,

        sazm: is_new_structure ? data.sazm : get_value(data.azimuth, "int", 180),
        do_inverter_limit_lock: is_new_structure ? data.do_inverter_limit_lock : get_value(data.do_inverter_qty_lock, "int", 0),
        inverter_limit: is_new_structure ? data.inverter_limit : get_value(data.inverter_qty, "int", 0),
        do_rack_align: is_new_structure ? data.do_rack_align : get_value(data.do_rack_align, "int", 1),
        do_roads: is_new_structure ? data.do_roads : get_value(data.do_roads, "int", 1),
        road_spacing_option: is_new_structure ? data.road_spacing_option : get_value(data.road_spacing_option, "int", 2),
        road_spacing: is_new_structure ? data.road_spacing : get_value(data.road_spacing, "int", 300),
        do_inv_spacing: is_new_structure ? data.do_inv_spacing : get_value(data.do_inv_spacing, "int", 0),
        inverter_per_cut: is_new_structure ? data.inverter_per_cut : get_value(data.inverter_per_cut, "int", 1),
        do_designer_margin: is_new_structure ? data.do_designer_margin : get_value(data.do_designer_margin, "int", 0),
        designer_margin: is_new_structure ? data.designer_margin : get_value(data.designer_margin, "float", 0),
        epsg: is_new_structure ? data.epsg : get_value(data.epsg, "int", 0),
        do_grid_poi_lim: is_new_structure ? data.do_grid_poi_lim : get_value(data.doGridPower, "int", 0),
        ac_grid_poi_lim: is_new_structure ? data.ac_grid_poi_lim : get_value(data.grid_power_limit, "int", 0),

        road_width: is_new_structure ? data.road_width : 4,
        road_to_rack: is_new_structure ? data.road_to_rack : 2,
        inverter_grouping: is_new_structure ? data.inverter_grouping : 0,
        inv_pad_x: is_new_structure ? data.inv_pad_x : 6,
        inv_pad_y: is_new_structure ? data.inv_pad_y : 3,
        road_to_rack_inv: is_new_structure ? data.road_to_rack_inv : 3,
        do_wiring: is_new_structure ? data.do_wiring : 0,
        combiner_location: is_new_structure ? data.combiner_location : 1,
        strings_per_combiner: is_new_structure ? data.strings_per_combiner : 16,
        wire_stringing: is_new_structure ? data.wire_stringing : 0,
      };
      break;

    default:
      break;
  }

  return fixed_preset;
};

function isTerrasmartProduct(product_name) {
  if (
    product_name == "Terrasmart GLIDE Wave" ||
    product_name == "Terrasmart GLIDE Agile" ||
    product_name == "Terrasmart GLIDE Fuse" ||
    product_name == "Terrasmart TerraTrak 2P" ||
    product_name == "Terrasmart TerraTrak 1P"
  ) {
    return true;
  } else {
    return false;
  }
}
