import React, { useState, useEffect, useMemo, useCallback } from "react";
import { EsriLayer, ESRIMapServerLayer } from ".";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";
import { MapboxMessageBox } from "..";

const ESRI_Layers = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const [loadingStates, setLoadingStates] = useState({
    wetlands_layer: false,
    fema_layer: false,
    rip_lay: false,
    transmission_lines_layer: false,
    gas_lines_layer: false,
    railway_lines_layer: false,
  });

  const setLoadingState = useCallback((layerId, isLoading) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [layerId]: isLoading,
    }));
  }, []);

  const additional_layers = useSelector((state) => state.inputs.uiState.additional_layers);
  const wetlands_on = useSelector((state) => state.inputs.uiState.additional_layers.wetlands_on);
  const riparian_on = useSelector((state) => state.inputs.uiState.additional_layers.riparian_on);
  const fema_on = useSelector((state) => state.inputs.uiState.additional_layers.fema_on);
  const transmission_lines_on = useSelector((state) => state.inputs.uiState.additional_layers.transmission_lines_on);
  const gas_lines_on = useSelector((state) => state.inputs.uiState.additional_layers.gas_lines_on);
  const railway_lines_on = useSelector((state) => state.inputs.uiState.additional_layers.railway_lines_on);

  const local_layers = useMemo(() => {
    return {
      wetlands_on,
      riparian_on,
      fema_on,
      transmission_lines_on,
      gas_lines_on,
      railway_lines_on,
    };
  }, [wetlands_on, riparian_on, fema_on, transmission_lines_on, gas_lines_on, railway_lines_on]);

  return (
    <>
      {local_layers.wetlands_on && (
        <ESRIMapServerLayer id={"wetlands_layer"} setLoadingState={setLoadingState} url={"https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Wetlands/MapServer"} />
      )}
      {local_layers.fema_on && (
        <EsriLayer id={"fema_layer"} setLoadingState={setLoadingState} url={"https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0"} />
      )}
      {local_layers.riparian_on && <ESRIMapServerLayer id={"rip_lay"} setLoadingState={setLoadingState} url={"https://fwsprimary.wim.usgs.gov/server/rest/services/Riparian/MapServer"} />}
      {local_layers.transmission_lines_on && (
        <EsriLayer
          id={"transmission_lines_layer"}
          setLoadingState={setLoadingState}
          url={"https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/US_Electric_Power_Transmission_Lines/FeatureServer/0"}
        />
      )}
      {local_layers.gas_lines_on && (
        <EsriLayer
          id={"gas_lines_layer"}
          setLoadingState={setLoadingState}
          url={"https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/Natural_Gas_Interstate_and_Intrastate_Pipelines_1/FeatureServer/0"}
        />
      )}
      {local_layers.railway_lines_on && (
        <EsriLayer id={"railway_lines_layer"} setLoadingState={setLoadingState} url={"https://services3.arcgis.com/6rJKAjBRDRSfjCzV/arcgis/rest/services/Norfolk_Southern_Railway/FeatureServer/0"} />
      )}
      <MapboxMessageBox position="bottomleft" layerLoading={loadingStates} />
    </>
  );
};

export { ESRI_Layers };
