import { useEffect } from "react";
import { useMapboxDraw } from "../DrawToolProvider";
import { useKey } from "../../Map/components/MapEventHandler/useKey";
import { inputsActions } from "../..";
import { useSelector, useDispatch } from "react-redux";

const HandleFeatureIdentity = ({ feature }) => {
  const dispatch = useDispatch();
  const draw = useMapboxDraw();
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const selected_feature = useSelector((state) => state.inputs.site_features[selectedFeatureId]);

  //hot key stuff
  const boundary_hotkey = useKey("b");
  const exclusion_hotkey = useKey("e");
  const inactive_hotkey = useKey("i");

  useEffect(() => {
    if (!selected_feature) return;
    // if (selected_feature?.geometry?.type == "LineString") return;
    if (boundary_hotkey) updateIdentity(1);
    if (exclusion_hotkey) updateIdentity(2);
    if (inactive_hotkey) updateIdentity(0);
  }, [boundary_hotkey, exclusion_hotkey, inactive_hotkey]);

  const updateIdentity = (identity) => {
    if (selected_feature) {
      const updatedFeature = {
        ...selected_feature,
        properties: {
          ...selected_feature.properties,
          identity: identity,
          active: identity !== 0 || !selected_feature.properties.active,
        },
      };
      // Dispatch the updated feature to Redux
      dispatch(inputsActions.update_site_input("edit_site_feature", updatedFeature));

      // Remove and re-add only the updated feature
      draw.delete(updatedFeature.properties.index);

      // draw.add(updatedFeature);
      // selectFeatureOnMap(updatedFeature.properties.index);
    }
  };

  // const selectFeatureOnMap = (id) => {
  //   draw.changeMode("simple_select", { featureIds: id });
  // };

  return null;
};

export { HandleFeatureIdentity };
