import React, { useState, useEffect } from "react";
import { useMap } from "react-map-gl"; // Import Mapbox components
import { useMapboxDraw } from "../../DrawToolProvider";
import { draw_polygon } from "../../../../assets/images";
import { useSelector, useDispatch } from "react-redux";
import { inputsActions } from "../../..";

const DrawPolygon = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const draw = useMapboxDraw();
  const dispatch = useDispatch();

  const is_drawing = useSelector((state) => state.inputs.is_drawing);

  useEffect(() => {
    if (!map || !draw) return;

    map.on("draw.polygon", () => {
      draw.changeMode("draw_polygon");
      dispatch(inputsActions.update_ui_state("is_drawing", true));
    });
  }, [map, draw]);

  return (
    <button className={is_drawing ? "button-active" : ""} onClick={() => map.fire("draw.polygon")}>
      <img draggable={false} src={draw_polygon} />
    </button>
  );
};

export { DrawPolygon };
