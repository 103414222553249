import React from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import { TreeEventLayer, TreeLineEventLayer, BuildingEventLayer } from "./";
import { HandleFeatureClick } from "../../MapboxFeatureHandlers";

const ShadeLayer = ({ interactive = true }) => {
  const { current: mapRef } = useMap();

  const shade_objects = useSelector((state) => state.canopy.shade_objects);

  return (
    <>
      <HandleFeatureClick features={shade_objects} />
      {shade_objects &&
        Object.values(shade_objects)?.length > 0 &&
        Object.values(shade_objects)?.map((obj) => {
          if (obj.shape === "tree") {
            return <TreeEventLayer key={obj.id} tree={obj} interactive={interactive} />;
          } else if (obj.shape === "treeLine") {
            return <TreeLineEventLayer key={obj.id} treeline={obj} interactive={interactive} />;
          } else {
            return <BuildingEventLayer key={obj.id} building={obj} interactive={interactive} />;
          }
        })}
    </>
  );
};

export { ShadeLayer };
