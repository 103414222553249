import React, { useEffect, useState, useMemo } from "react";
import { useMap, Source, Layer } from "react-map-gl";
import { useSelector } from "react-redux";
import * as turf from "@turf/turf";
import customClassifyRings from "../MapboxOverrides/MapboxOverrides"; // Import the classifyRings function
import { ensureMultiPolygonFormat } from "./LayoutHelpers";

const layout_colors = {
  racks: "#33A2FF",
  inverter: "#ee00ff",
  combiner_boxes: "#7FFF00",
  wires: "#33A2FF",
  strings: "#33A2FF",
  roads: "#e0710f",
};

const MBLayoutLayer = ({ show_inverters, show_racks }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const layout = useSelector((state) => state.sift.ioManager.outputs.layout);
  let memoized_layout = useMemo(() => layout, [layout]);

  const [group_layers, set_group_layers] = useState(undefined);

  // useEffect(() => {
  //   if (!layout) return;
  //   console.log("layout", layout);
  // }, [layout]);

  useEffect(() => {
    if (Object.values(memoized_layout).length === 0) return;

    let _layouts = {};
    let combined_layouts = {};

    let collectedPolygons = {
      combiner_boxes: [],
      inverter: [],
      racks: [],
      strings: [],
      wires: [],
    };

    Object.values(memoized_layout.inverter_groups).forEach((group) => {
      Object.keys(group).forEach((key) => {
        let feature = group[key];

        feature = ensureMultiPolygonFormat(feature);

        const flippedFeature = turf.flip(feature);

        if (collectedPolygons[key]) {
          collectedPolygons[key].push(flippedFeature.geometry.coordinates[0]);
        }
      });
    });

    Object.keys(collectedPolygons).forEach((key) => {
      if (collectedPolygons[key].length > 0) {
        let multiPolygon = turf.multiPolygon(collectedPolygons[key], {
          color: layout_colors[key],
        });

        multiPolygon = ensureMultiPolygonFormat(multiPolygon);

        // Classify the rings of the MultiPolygon
        const classifiedRings = customClassifyRings(multiPolygon.geometry.coordinates);

        combined_layouts[key] = {
          ...multiPolygon,
          geometry: {
            ...multiPolygon.geometry,
            coordinates: classifiedRings,
          },
          properties: {
            color: layout_colors[key],
          },
        };
      }
    });

    // console.log("combined_layouts", combined_layouts);

    // Set the combined layouts into the state
    set_group_layers(combined_layouts);

    // Object.values(memoized_layout.inverter_groups).forEach((group, group_index) => {
    //   let temp_layouts = {};

    //   Object.keys(group).forEach((key) => {
    //     // console.log("Layout Layer Group", group);
    //     // console.log("Layout Layer Group key", key);

    //     let feature = group[key];

    //     feature = ensureMultiPolygonFormat(feature);

    //     const flippedFeature = turf.flip(feature);
    //     const classifiedRings = customClassifyRings(flippedFeature.geometry.coordinates[0]);

    //     temp_layouts[key] = {
    //       ...flippedFeature,
    //       geometry: {
    //         ...flippedFeature.geometry,
    //         coordinates: classifiedRings,
    //       },
    //       properties: {
    //         ...feature.properties,
    //         color: feature.properties.color ? feature.properties.color : layout_colors[key],
    //       },
    //     };
    //   });

    //   _layouts[group_index] = temp_layouts;
    // });

    // set_group_layers(_layouts);
  }, [memoized_layout]);

  if (!group_layers || Object.values(layout).length == 0) return null;

  return (
    <>
      {Object.values(group_layers).length > 0 &&
        Object.keys(group_layers).map((key, index) => {
          if (key === "inverters" && !show_inverters) return null;
          if (key === "racks" && !show_racks) return null;

          return (
            <Source key={`${key}_${index}`} id={`${key}_${index}`} type="geojson" data={group_layers[key]}>
              <Layer
                type="line"
                paint={{
                  "line-color": group_layers[key]?.properties?.color || "#33A2FF",
                  "line-width": 1,
                }}
              />
            </Source>
          );
        })}
    </>
  );

  return (
    <>
      {Object.values(group_layers).length > 0 &&
        Object.values(group_layers).map((groups, index) => {
          return Object.keys(groups).map((key) => {
            if (key === "inverters" && !show_inverters) return null;
            if (key === "racks" && !show_racks) return null;

            return (
              <Source key={`${key}_${index}`} id={`${key}_${index}`} type="geojson" data={groups[key]}>
                <Layer
                  type="line"
                  paint={{
                    "line-color": groups[key]?.properties?.color || "#33A2FF",
                    "line-width": 1,
                  }}
                />
              </Source>
            );
          });
        })}
    </>
  );
};

export { MBLayoutLayer };
