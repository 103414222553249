import styled, { css } from "styled-components";

// Define sizes
const sizes = {
  small: {
    fontSize: "14px",
    padding: "8px",
  },
  medium: {
    fontSize: "15px",
    padding: "10px",
  },
  large: {
    fontSize: "16px",
    padding: "12px",
  },
};

// Styled components
const InputWrapper = styled.section`
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
  font-size: ${(props) => sizes[props.size].fontSize};
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
  margin: 5px 10px;
  background-color: #ffffff;
  transition: all 0.2s;

  &:hover {
    border-color: var(--primary-brand-color);
  }

  &:focus-within {
    border-color: var(--primary-brand-color);
  }
`;

const Prefix = styled.section`
  padding: 0 3px;
  color: #999;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const Suffix = styled.section`
  /* padding: 0 3px; */
  color: #999;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const ClearButton = styled.section`
  padding: 0 3px;
  cursor: pointer;
  height: 32px;
  display: flex;
  background: transparent;
  align-items: center;
  transition: scale 0.3s var(--easing);
  box-sizing: border-box;

  svg {
    height: 16px;
    fill: var(--primary-brand-color);
  }

  &:hover {
    background: var(--gutter-background-color);
  }
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  padding: ${(props) => sizes[props.size].padding};
  padding-left: ${(props) => (props.hasPrefix ? "0" : sizes[props.size].padding)};
  padding-right: ${(props) => (props.hasSuffix ? "0" : sizes[props.size].padding)};
  font-size: inherit;
  box-sizing: border-box;
  color: var(--primary-text-color);
  background-color: #ffffff;

  &::placeholder {
    color: #ccc;
    text-overflow: ellipsis;
  }
`;

export { StyledInput, Suffix, Prefix, ClearButton, InputWrapper };

// const GroupWrapper = styled.span`
//   &.input-group-wrapper {
//     display: inline-block;
//     width: 100%;
//     text-align: start;
//     vertical-align: top;
//   }
//   .input-group {
//     box-sizing: border-box;
//     margin: 0;
//     padding: 0;
//     color: rgba(0, 0, 0, 0.88);
//     font-size: 14px;
//     line-height: 1.5714285714285714;
//     list-style: none;
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//     position: relative;
//     display: table;
//     width: 100%;
//     border-collapse: separate;
//     border-spacing: 0;
//   }
//   .input-group-addon {
//     position: relative;
//     padding: 0 11px;
//     color: rgba(0, 0, 0, 0.88);
//     font-weight: normal;
//     font-size: 14px;
//     text-align: center;
//     background-color: rgba(0, 0, 0, 0.02);
//     border: 1px solid #d9d9d9;
//     border-radius: 4px;
//     transition: all 0.3s;
//     line-height: 1;
//   }
//   .input-group .input-group-addon {
//     display: table-cell;
//     width: 1px;
//     white-space: nowrap;
//     vertical-align: middle;
//   }
//   .input-group .input-group-addon:first-child {
//     border-start-end-radius: 0;
//     border-end-end-radius: 0;
//     border-inline-end: 0;
//   }
//   .input-group .input-group-addon:last-child {
//     border-start-start-radius: 0;
//     border-end-start-radius: 0;
//     border-inline-start: 0;
//   }
//   &.input-group-wrapper-disabled .input-group-addon {
//     color: rgba(0, 0, 0, 0.25);
//     background-color: rgba(0, 0, 0, 0.04);
//     border-color: #d9d9d9;
//     box-shadow: none;
//     cursor: not-allowed;
//     opacity: 1;
//   }
//   .input-group .input-affix-wrapper {
//     border-radius: 0px;
//   }
//   .input-group-wrapper-disabled .input-group-addon {
//     color: rgba(0, 0, 0, 0.25);
//     background-color: rgba(0, 0, 0, 0.04);
//     border-color: #d9d9d9;
//     box-shadow: none;
//     cursor: not-allowed;
//     opacity: 1;
//   }
// `;

// const SpanWrapper = styled.span`
//   &.input-affix-wrapper {
//     position: relative;
//     display: inline-flex;
//     width: 100%;
//     min-width: 0;
//     padding: 4px 11px;
//     color: rgba(0, 0, 0, 0.88);
//     font-size: 14px;
//     line-height: 1.5714285714285714;
//     background-color: #ffffff;
//     background-image: none;
//     border-width: 1px;
//     border-style: solid;
//     border-color: #d9d9d9;
//     border-radius: 4px;
//     transition: all 0.2s;
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//     font-size: 14px;
//     box-sizing: border-box;
//     ::before {
//       width: 0;
//       visibility: hidden;
//       content: "\a0";
//     }
//     &:focus {
//       border-color: #4096ff;
//       box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
//       border-inline-end-width: 1px;
//       outline: 0;
//     }

//     &:hover {
//       border-color: #4096ff;
//       box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
//       border-inline-end-width: 1px;
//       outline: 0;
//     }
//   }

//   .input-prefix {
//     margin-inline-end: 4px;
//     display: flex;
//     flex: none;
//     align-items: center;
//   }
//   &.input-affix-wrapper-status-warning:not(.input-affix-wrapper-disabled).input-affix-wrapper {
//     border-color: #faad14;
//   }
//   &.input-affix-wrapper-status-warning:not(.input-affix-wrapper-disabled).input-affix-wrapper .input-prefix {
//     color: #faad14;
//   }
//   &.input-affix-wrapper-status-error:not(.input-affix-wrapper-disabled).input-affix-wrapper {
//     border-color: #ff4d4f;
//   }
//   &.input-affix-wrapper-status-error:not(.input-affix-wrapper-disabled).input-affix-wrapper .input-prefix {
//     color: #ff4d4f;
//   }
//   .input-affix {
//     padding: 0 !important;
//     font-size: inherit !important;
//     border: none;
//     border-radius: 0;
//     outline: none;
//   }
//   &.input-affix-wrapper-small {
//     padding: 0px 7px;
//     border-radius: 4px;
//     max-width: 120px;
//   }
//   &.input-affix-wrapper-medium {
//     width: 100%;
//   }

//   &.input-affix-wrapper-large {
//     padding: 7px 11px;
//     font-size: 16px;
//     line-height: 1.5;
//     border-radius: 4px;
//     width: 100%;
//   }

//   &.input-affix-wrapper-disabled {
//     color: rgba(0, 0, 0, 0.25);
//     background-color: rgba(0, 0, 0, 0.04);
//     border-color: #d9d9d9;
//     box-shadow: none;
//     cursor: not-allowed;
//     opacity: 1;
//     &:focus {
//       border-color: #d9d9d9;
//     }
//     &:hover {
//       border-color: #d9d9d9;
//     }
//   }
//   .input-suffix {
//     margin-inline-start: 4px;
//     color: rgba(0, 0, 0, 0.45);
//   }
//   .input-suffix > div > .spin-spinning {
//     position: static;
//     display: inline-block;
//     color: rgba(0, 0, 0, 0.45);
//   }
// `;

// const InputWrapper = styled.input`
//   box-sizing: border-box;
//   margin: 0;
//   padding: 4px 11px;
//   color: #333;
//   font-size: 14px;
//   line-height: 1.5714285714285714;
//   list-style: none;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//   position: relative;
//   display: inline-block;
//   width: 100%;
//   max-height: 30px;
//   min-width: 0;
//   background-color: #ffffff;
//   background-image: none;
//   border-width: 1px;
//   border-style: solid;
//   border-color: #d9d9d9;
//   border-radius: 4px;
//   transition: all 0.2s;
//   &:placeholder-shown {
//     text-overflow: ellipsis;
//   }
//   &::-webkit-input-placeholder {
//     color: #333;
//   }
//   &:hover {
//     border-color: #4096ff;
//     border-inline-end-width: 1px;
//   }
//   &:focus {
//     border-color: #4096ff;
//     border-inline-end-width: 1px;
//   }
//   :not(.input-affix-wrapper) > input:focus {
//     border-color: #4096ff;
//     box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
//     border-inline-end-width: 1px;
//     outline: 0;
//   }

//   :not(.input-affix-wrapper) > input:hover {
//     border-color: #4096ff;
//     box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
//     border-inline-end-width: 1px;
//     outline: 0;
//   }
//   &.input-large {
//     padding: 7px 11px;
//     font-size: 16px;
//     line-height: 1.5;
//     border-radius: 4px;
//   }
//   &.input-small {
//     padding: 0px 7px;
//     border-radius: 4px;
//     max-width: 120px;
//   }
//   &.input-medium {
//     padding: 0px 7px;
//     border-radius: 4px;
//     width: 100%;
//     max-width: 400px;
//   }
//   &.input-large {
//     padding: 0px 7px;
//     border-radius: 4px;
//     width: 100%;
//     max-width: 400px;
//   }
//   &.input-affix-disabled {
//     color: rgba(0, 0, 0, 0.25);
//     background-color: rgba(0, 0, 0, 0.04);
//     border-color: #d9d9d9;
//     box-shadow: none;
//     cursor: not-allowed;
//     opacity: 1;
//     background: transparent;
//   }
//   &.input-status-error:not(.ant-input-disabled).input-affix {
//     border-color: #ff4d4f;
//   }
// `;

// export { GroupWrapper, SpanWrapper, InputWrapper };
