import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { DropdownWrapper, SubMenuWrap } from "./styles";

const Dropdown = ({ children, overlay = null, placement = "bottom", trigger = "click", disabled = false }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownCoords, setDropdownCoords] = useState(undefined);

  const dropdownRef = useRef();
  const showDropRef = useRef(showDropdown);

  const handleDropdownClick = (e) => {
    const pos = e.currentTarget.getBoundingClientRect();

    if (placement === "top") {
      setDropdownCoords({
        top: pos.top + window.pageYOffset,
        left: pos.left + pos.width / 2 + window.pageXOffset,
      });
    } else if (placement === "bottom") {
      setDropdownCoords({
        top: pos.bottom + window.pageYOffset,
        left: pos.left + pos.width / 2 + window.pageXOffset,
      });
    } else if (placement === "bottomRight") {
      setDropdownCoords({
        top: pos.bottom + window.pageYOffset,
        left: pos.right + window.pageXOffset,
      });
    } else if (placement === "left") {
      setDropdownCoords({
        top: pos.top + pos.height / 2 + window.pageYOffset,
        left: pos.left + window.pageXOffset,
      });
    } else if (placement === "right") {
      setDropdownCoords({
        top: pos.top + pos.height / 2 + window.pageYOffset,
        left: pos.left + pos.width + window.pageXOffset,
      });
    }

    setShowDropdown(!showDropdown);
  };

  const dropDownStyles = {
    position: "fixed",
    borderRadius: "4px",
    zIndex: "9999",
    opacity: "0",
    maxWidth: "300px",
    transitionProperty: "opacity, transform",
    transformOrigin: "center center",
    textAlign: "center",
  };

  const dropDownPositionStyles = {
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  useEffect(() => {
    if (showDropdown && dropdownCoords && dropdownRef.current) {
      let el = dropdownRef.current;
      if (el) {
        el.style.transitionDuration = 0.4 + "ms";
        setTimeout(() => {
          if (placement === "top") {
            el.style.top = `${dropdownCoords.top - el.clientHeight}px`;
            el.style.left = `${dropdownCoords.left}px`;
            el.style.transform = `translate(-50%, -2px) scale(1)`;
          } else if (placement === "bottom") {
            el.style.top = `${dropdownCoords.top}px`;
            el.style.left = `${dropdownCoords.left}px`;
            el.style.transform = `translate(-50%, 2px) scale(1)`;
          } else if (placement === "bottomRight") {
            el.style.top = `${dropdownCoords.top}px`;
            el.style.left = `${dropdownCoords.left}px`;
            el.style.transform = `translate(-20%, 2px) scale(1)`;
          } else if (placement === "left") {
            el.style.top = `${dropdownCoords.top}px`;
            el.style.left = `${dropdownCoords.left - el.clientWidth}px`;
            el.style.transform = `translate(-15px, -50%) scale(1)`;
          } else if (placement === "right") {
            el.style.top = `${dropdownCoords.top}px`;
            el.style.left = `${dropdownCoords.left}px`;
            el.style.transform = `translate(15px, -50%) scale(1)`;
          }

          el.style.opacity = "1";
        }, 20);
      }
    }
  }, [showDropdown]);

  const clearDropdown = () => {
    setTimeout(() => {
      setShowDropdown(false);
      setDropdownCoords(undefined);
    }, 300);
  };

  const menuOverlay = (
    <section ref={dropdownRef} className={`dropdown dropdown--${placement}`} style={{ ...dropDownStyles, ...dropDownPositionStyles }}>
      {overlay}
    </section>
  );

  return (
    <DropdownWrapper onClick={(e) => !disabled && handleDropdownClick(e)} disabled={disabled} onBlur={() => clearDropdown()}>
      {children}
      {showDropdown && createPortal(menuOverlay, document.getElementById("root"))}
    </DropdownWrapper>
  );
};

const SubMenu = ({ title, children }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [submenuStyles, setSubmenuStyles] = useState({});
  const submenuRef = useRef();
  const wrapperRef = useRef();

  const handleMouseEnter = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      setSubmenuStyles({
        top: rect.top + window.scrollY,
        left: rect.right + window.scrollX + 4,
      });
    }
    setShowSubmenu(true);
  };

  const handleMouseLeave = () => {
    setShowSubmenu(false);
  };

  const submenuContainerStyles = {
    position: "absolute",
    backgroundColor: "white",
    border: "1px solid #ccc",
    boxShadow: "var(--subtle-box-shadow)",
    zIndex: "1000",
    margin: "0px",
    padding: "5px",
    listStyle: "none",
    borderRadius: "4px",
  };

  const submenuItemStyles = {
    padding: "5px 10px",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  const submenuItemHoverStyles = {
    backgroundColor: "#f0f0f0",
  };

  return (
    <li className="submenu-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ position: "relative" }} ref={wrapperRef}>
      <div className="submenu-title" style={{ cursor: "pointer" }}>
        {title} &gt;
      </div>
      {showSubmenu &&
        createPortal(
          <ul className="submenu" ref={submenuRef} style={{ ...submenuContainerStyles, ...submenuStyles }}>
            {React.Children.map(children, (child) => (
              <li
                style={submenuItemStyles}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = submenuItemHoverStyles.backgroundColor)}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              >
                {child}
              </li>
            ))}
          </ul>,
          document.getElementById("root")
        )}
    </li>
  );
};

export { Dropdown, SubMenu };
