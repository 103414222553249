import React, { useState, useEffect } from "react";

import { LabelWrap } from "./styles";

const Checkbox = ({ label, value, onChange, disabled = false, labelposition = "right" }) => {
  return (
    <LabelWrap>
      <input id="custom-checkbox" type="checkbox" disabled={disabled} checked={value} labelposition={labelposition} onChange={onChange} />
      <p>{label}</p>
    </LabelWrap>
  );
};

export { Checkbox };
