import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MapContainer } from "react-leaflet";

//components
import { MapTileLayer, MapEventHandler, MapZoomHandler, MapFeatureHandler, Toolbar, ChangeMapView, ContextMenu, MapLayoutLayer, MapMarker, CoordSystemBox, ImageLayer } from "../";
import { ShadeLayer, ShadeDrawingLayers, ShadeObjectMarker } from "../../../EnvironmentalShade";
import { VisualShadeArea } from "../../../EnvironmentalShade";
import { Canopy } from "../../../_Internal/Canopy";

//styles
import "../leaflet.css";

const Map = () => {
  const map_center = useSelector((state) => state.inputs.map_center);
  const url = window.location.pathname;

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";

  const [pathname, set_pathname] = useState(window.location.pathname);

  useEffect(() => {
    set_pathname(url);
  }, [url]);

  return (
    <MapContainer
      id="map"
      key="main_map"
      center={map_center}
      zoom={16}
      zoomSnap={0.25}
      // zoomSnap={zoomGranularity}
      // zoomDelta={zoomGranularity}
      animate={true}
      doubleClickZoom={false}
      zoomControl={false}
      worldCopyJump={true}
      attributionControl={false}
      minZoom={2}
      maxZoom={20}
      // preferCanvas={using_canopy ? false : true}
    >
      <ChangeMapView map_center={map_center} zoom={16} />
      <MapZoomHandler position="topright" />

      {/* {internal_sift && (pathname == "/canopy" || pathname == "/canopy/") ? (
        <>
          <Canopy />
        </>
      ) : (
        <>
          <Toolbar position="topleft" />
          <MapFeatureHandler />
          <MapLayoutLayer />
          <ContextMenu />
          <CoordSystemBox />
          <ShadeDrawingLayers />
          <ShadeLayer />
          <ShadeObjectMarker />
          <VisualShadeArea />
        </>
      )} */}

      <Canopy />
      <ImageLayer pathname={pathname} />
      <MapTileLayer />
      <MapEventHandler />
      <MapMarker />
    </MapContainer>
  );
};

export { Map };
