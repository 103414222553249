import React, { useEffect, useState } from "react";
import { Source, Layer, useMap } from "react-map-gl";

const ESRIMapServerLayer = ({ url, id, setLoadingState }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const [imageUrl, setImageUrl] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [first_layer, set_first_layer] = useState();

  useEffect(() => {
    set_first_layer(map.getStyle().layers[5].id);
  }, [map]);

  useEffect(() => {
    if (!map) return;

    const fetchMapServerData = async (url, bounds) => {
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      const bbox = `${sw.lng},${sw.lat},${ne.lng},${ne.lat}`;

      setLoadingState(id, true); // Set loading state to true before fetching

      const response = await fetch(`${url}/export?bbox=${bbox}&size=1127,968&dpi=96&format=png32&transparent=true&bboxSR=4326&imageSR=3857&f=json`);

      if (!response.ok) {
        setLoadingState(id, false); // Handle case where request fails
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setLoadingState(id, false); // Set loading state to false once image is fetched
      return data.href;
    };

    const updateLayer = async () => {
      const zoom = map.getZoom();
      if (zoom < 8) return;

      const newBounds = map.getBounds();
      setBounds(newBounds);

      try {
        const newImageUrl = await fetchMapServerData(url, newBounds);
        setImageUrl(newImageUrl);
      } catch (error) {
        console.error("Error fetching the map server data", error);
        setLoadingState(id, false); // Ensure loading is stopped in case of errors
      }
    };

    updateLayer();

    map.on("moveend", updateLayer);

    return () => {
      map.off("moveend", updateLayer);
    };
  }, [map, url, id, setLoadingState]);

  if (!imageUrl || !bounds) return null;

  return (
    <>
      {first_layer && (
        <Source
          id={id}
          type="image"
          url={imageUrl}
          coordinates={[
            [bounds.getWest(), bounds.getNorth()],
            [bounds.getEast(), bounds.getNorth()],
            [bounds.getEast(), bounds.getSouth()],
            [bounds.getWest(), bounds.getSouth()],
          ]}
        >
          <Layer id={id} type="raster" source={id} paint={{ "raster-opacity": 0.5 }} beforeId={first_layer} />
        </Source>
      )}
    </>
  );
};

export { ESRIMapServerLayer };
