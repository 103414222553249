import styled from "styled-components";

const MapNavWrap = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 145px;
  right: 10px;
  z-index: 1;
  background: #fff;
  border-radius: 4px;

  .map-action-buttons {
    display: flex;
    /* flex-direction: ${(props) => (props.vertical ? "column" : "row")}; */
    /* background-color: #fff; */
    flex-direction: column;
    gap: 0px;
    align-items: center;
    height: auto;
    transition: all 0.3s var(--easing-spring);
    z-index: 10;

    button {
      width: 30px;
      height: 30px;
      border: 1px solid transparent;
      background: transparent;
      /* margin-right: ${(props) => (props.vertical ? "0px" : "2px")}; */
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s var(--easing-spring);
      border-radius: 4px;

      img {
        width: ${(props) => (props.vertical ? "16px" : "23px")};
        height: ${(props) => (props.vertical ? "16px" : "23px")};
      }

      &:hover {
        cursor: pointer;
        background-color: var(--background-hover-color);
      }

      &:hover > img {
        filter: invert(100%);
      }

      &:first-child {
        height: 29px;
        width: 29px;
      }

      /* &:nth-child(2) {
        border-radius: 4px 4px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 4px 4px;
      } */
    }
  }
`;

export { MapNavWrap };
