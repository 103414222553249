import React, { useState, useEffect, useMemo } from "react";
import { useMap, Marker } from "react-map-gl";
// redux
import { useSelector, useDispatch } from "react-redux";
import { siftActions, usePrevious, isAuthenticated } from "../../Redux";
import { locationPin } from "../../../assets/images";

const MapboxMarker = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const dispatch = useDispatch();
  const isAuthed = useSelector((state) => isAuthenticated(state));

  const map_center = useSelector((state) => state.inputs.map_center);
  const lat = useSelector((state) => state.inputs.latitude);
  const lng = useSelector((state) => state.inputs.longitude);
  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  const pull_location = useSelector((state) => state.sift.ioManager.inputs.map.pull_location);
  const boundary_bbox = useSelector((state) => state.inputs.boundary_bbox);
  const site_features = useSelector((state) => state.inputs.site_features);
  const boundaries_present = Object.values(site_features).some((feature) => feature.properties.identity === 1);

  const [marker_position, set_marker_position] = useState({ lat: map_center[0], lng: map_center[1] });
  const [marker_visible, set_marker_visible] = useState(false);

  const prev_pull_location = usePrevious(pull_location);

  useEffect(() => {
    if (boundaries_present && boundary_bbox.length > 0) return;
    set_marker_position({ lat: map_center[0], lng: map_center[1] });
  }, [map_center]);

  useEffect(() => {
    set_marker_position({ lat, lng });
  }, [lat, lng]);

  useEffect(() => {
    if (prev_pull_location != pull_location) {
      if (pull_location && isAuthed) {
        handleUpdateMarker();
      }
    }
  }, [pull_location]);

  useEffect(() => {
    if (tab == "weather") {
      set_marker_visible(true);
    } else if (marker_visible) {
      set_marker_visible(false);
    }
  }, [tab]);

  const handleMarkerUpdate = (mark) => {
    dispatch(siftActions.updateMarker(mark));
  };

  const handleUpdateMarker = (latlng = undefined) => {
    let mark = latlng;
    if (mark == undefined) {
      mark = map.getBounds().getCenter();
    }
    set_marker_position(mark);
    handleMarkerUpdate(mark);
  };

  const handleDragEnd = (mark) => {
    let lngLat = { lng: mark.lngLat.lng, lat: mark.lngLat.lat };
    set_marker_position(lngLat);
    handleMarkerUpdate(lngLat);
  };

  return (
    <>
      {marker_visible && marker_position.lat && marker_position.lng && (
        <Marker draggable={true} longitude={marker_position.lng} latitude={marker_position.lat} anchor="bottom" onDragEnd={handleDragEnd}>
          <img src={locationPin} style={{ height: 30 }} />
          {/* <Popup>
            <p>{`Site Location ${marker_position.lat?.toFixed(4)}, ${marker_position.lng?.toFixed(4)}`}</p>
          </Popup> */}
        </Marker>
      )}
    </>
  );
};

export { MapboxMarker };
