import * as turf from "@turf/turf";
import ZipLoader from "zip-loader";
import XMLParser from "react-xml-parser";
import textEncoding from "text-encoding";
import _ from "lodash";

const json2csv = require("json2csv").parse;

function createFeature(index, identity, coordinates) {
  let feature = {};
  let properties = {};
  let geometry = {};

  properties.index = index;
  properties.identity = identity;
  properties.active = true;
  properties.area = getArea([coordinates]);
  properties.oversized = false;

  geometry.type = "Polygon";
  geometry.coordinates = [coordinates];

  feature.type = "Feature";
  feature.properties = properties;
  feature.geometry = geometry;

  return feature;
}

function validateFeature(feature, features) {
  let poly_count = feature.geometry.coordinates[0].length;

  let str_coord = feature.geometry.coordinates[0].toString();
  let valid = features.length === 0;
  let match = false;
  for (let feat in features) {
    if (features[feat].geometry.coordinates[0].length === poly_count) {
      let str_coord_compare = features[feat].geometry.coordinates[0].toString();
      // compare the strings
      var is_equal = str_coord === str_coord_compare;
      // if the strings are equal, then this should NOT be added
      if (is_equal) {
        match = true;
        break;
      }
    }
  }
  // if there was a match within the above loop, we need to set valid to false so this isn't added
  valid = !match;
  if (feature.properties.area == -1) {
    valid = false;
  }
  return valid;
}

export function getCenterPoint(features) {
  var featCollection = {
    type: "FeatureCollection",
    features: [...Object.values(features)],
  };
  // console.log(turf.featureCollection([...Object.values(features)]));
  let centeroid = turf.centroid(featCollection);
  // console.ålog(featCollection, features);
  return [centeroid.geometry.coordinates[1], centeroid.geometry.coordinates[0]];
}

export function getCenterOfLineString(geoJson) {
  if (!geoJson) return [];
  let points = turf.points(geoJson.geometry.coordinates);
  let center = turf.center(points);
  return center.geometry.coordinates;
}

export function getCenterPointofFeature(coords) {
  if (coords.length === 1 && coords[0].length >= 4) {
    let turfPoly = turf.polygon(coords);
    let centroid = turf.centroid(turfPoly);
    return centroid.geometry.coordinates;
  } else if (coords.length === 1) {
    return coords[0][0];
  }

  return [];
}

// Function to check if a number is a valid latitude
export function isLatitude(num) {
  return isFinite(num) && Math.abs(num) <= 90;
}

// Function to check if a number is a valid longitude
export function isLongitude(num) {
  return isFinite(num) && Math.abs(num) <= 180;
}

export function flipFeature(feature) {
  return turf.flip(feature);
}

export function getNewCenterPoint(features) {
  let centers = [];
  Object.values(features).map((feat) => {
    if (feat["properties"]["center"]) {
      centers.push(turf.point(feat["properties"]["center"]));
    }
  });
  var center_features = turf.featureCollection(centers);
  return turf.getCoord(turf.flip(turf.center(center_features)));
}

export async function getFeaturesFromFile(file) {
  let file_type = file.name.substr(file.name.length - 3).toLowerCase();
  var output = {
    features: [],
    error: undefined,
  };

  if (file_type === "kml") {
    async function getStringFileOutput(file) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = (function (file) {
          var fileName = file.name;
          return function (evt) {
            if (evt.target.readyState == FileReader.DONE) {
              // DONE == 2
              var xml = new XMLParser().parseFromString(evt.target.result);
              try {
                let features = loadSurfaceFromXML(xml);
                console.log(features);
                output.features = features;
              } catch (error) {
                // console.log(error)
                output.error = error;
              }

              resolve(output);
            }
          };
        })(file);
        reader.readAsBinaryString(file);
      });
    }
    return await getStringFileOutput(file);
  } else if (file_type === "kmz") {
    // If file is *.kmz we need to unzip the .kml to get the polygons
    let instance = await ZipLoader.unzip(file);
    // unzipping the doc.kml returns an object that holds the reference to a Utf8Array buffer which holds the kml info
    let TextDecoder = textEncoding.TextDecoder;
    try {
      // Decode the buffer into a string
      let all_features = [];
      let files_names = Object.keys(instance.files);
      for (let file in files_names) {
        if (files_names[file].substr(-3, 3).toLowerCase() === "kml") {
          let string = new TextDecoder("utf-8").decode(instance.files[files_names[file]]["buffer"]);
          // parse the xml document from the string
          let xml = new XMLParser().parseFromString(string);
          // let features = tj.kml(xml)
          let features = loadSurfaceFromXML(xml);
          all_features.push(...features);
        }
      }
      output.features = all_features;
    } catch (error) {
      console.log(error);
      output.error = error;
    }
    return output;
  }
}

function processElements(elements, is_multi, is_lines, inden = 0) {
  let features = [];
  let index = create_UUID();
  let identity = inden;

  for (var e in elements) {
    // console.log(`processing element ${index}`)
    let coordinates = [];
    let coords = elements[e].getElementsByTagName("coordinates");
    // console.log(coords)
    for (let c in coords) {
      if (!is_multi) {
        coordinates = [];
      }

      let poly = coords[c].value.toString();
      let points = poly.split(" ");
      let last_lonlat = null;
      for (let p in points) {
        let lonlat = points[p].split(",");
        if (!isNaN(parseFloat(lonlat[1]))) {
          coordinates.push([parseFloat(lonlat[0]), parseFloat(lonlat[1])]);
          last_lonlat = lonlat;
        }
      }

      if (is_lines) {
        let first_lonlat = points[0].split(",");
        if (!isNaN(parseFloat(first_lonlat[1]))) {
          let first_point = turf.point([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])]);
          let last_point = turf.point([parseFloat(last_lonlat[0]), parseFloat(last_lonlat[1])]);
          let options = { units: "kilometers" };
          let distance = turf.distance(first_point, last_point, options);
          if (distance > 0.085) {
            // console.log(distance)
            // distance too far
            continue;
          }
          coordinates.push([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])]);
        }
      }

      if (coordinates.length < 4) {
        continue;
      }

      if (!is_multi) {
        coordinates = checkFirstandLast(coordinates);

        let feature = createFeature(index, identity, coordinates);
        let valid = validateFeature(feature, features);
        // only add this if it's valid
        if (valid) {
          features.push(feature);
        }
        // increment
        index = create_UUID();
      }
    }

    if (is_multi) {
      coordinates.push(coordinates[0]);
      let feature = createFeature(index, identity, coordinates);
      let valid = validateFeature(feature, features);
      // only add this if it's valid
      if (valid) {
        features.push(feature);
      }
      index = create_UUID();
    }
  }

  return features;
}

export function loadSurfaceFromXML(xml) {
  // console.log(xml);
  // pull all the polygons from the xml
  let features = [];

  // Time to dig into the XML to pull out the coordinates
  // Doing so this way ensures we only pull out valid coords
  // var nodes=xml.getElementsByTagName('Placemark')
  if (xml.children.length > 0) {
    let extData = xml.getElementsByTagName("ExtendedData");
    if (extData.length > 0) {
      // this might have been exported from SIFT
      let nodes = xml.getElementsByTagName("Placemark");
      for (let child in nodes) {
        let c = nodes[child].getElementsByTagName("Data");
        let polys = nodes[child].getElementsByTagName("Polygon");
        let title = c.filter((el) => el.attributes.name === "title")[0].getElementsByTagName("value")[0];
        if (title && (title.value === "Boundary" || title.value === "Exclusion" || title.value === "Boundary-Unused")) {
          let iObj = c.filter((el) => el.attributes.name === "identity")[0].getElementsByTagName("value")[0];
          let valid_obj = title && iObj && (title.value === "Boundary" || title.value === "Exclusion" || title.value === "Boundary-Unused");
          if (valid_obj) {
            let processed_polygons = processElements(polys, false, false, parseInt(iObj.value));
            if (processed_polygons) {
              features.push(...processed_polygons);
            }
          }
        }
      }
    }
    // finish early if this is a reimport
    if (features.length > 0) {
      return features;
    }

    // NOT EXPORT FROM SIFT KMZ
    let polys = [];
    let processed_polygons = undefined;

    // process polygons
    polys = xml.getElementsByTagName("Polygon");
    // console.log(polys)
    processed_polygons = processElements(polys, false, false);
    if (processed_polygons) {
      features.push(...processed_polygons);
    }

    // process multi
    polys = xml.getElementsByTagName("MultiGeometry");
    // console.log(polys)
    processed_polygons = processElements(polys, true, false);
    if (processed_polygons) {
      features.push(...processed_polygons);
    }

    // process lines
    polys = xml.getElementsByTagName("LineString");
    // console.log(polys)
    processed_polygons = processElements(polys, false, true);
    if (processed_polygons) {
      features.push(...processed_polygons);
    }
  }

  return features;
}

function oldXMLParse() {
  // var index = 0
  // for (var n in nodes) {
  //   console.log(nodes[n])
  //   continue
  //   var save = true;
  //   var is_lines = false;
  //   var is_multi = false;
  //   var identity = 0;
  //   var name=nodes[n].getElementsByTagName('name')
  //   if (name === "Site Exclusions") {
  //     continue
  //   }
  //   if (name === "Site Boundaries")
  //   var polys=nodes[n].getElementsByTagName('Polygon')
  //   // console.log(polys)
  //   // console.log(polys.length, nodes[n]['name'])
  //   if (polys.length == 0) {
  //     polys=nodes[n].getElementsByTagName('MultiGeometry')
  //     if (polys.length >0)
  //       is_multi = true;
  //   }
  //   if (polys.length == 0) {
  //     polys=nodes[n].getElementsByTagName('LineString')
  //     is_lines = true;
  //   }
  //   if (name.length > 0) {
  //     name = name[0]['value']
  //   } else { name = 'no name provided'; }
  //   // SunfigData comes from exporting from SIFT
  //   var extData = nodes[n].getElementsByTagName('ExtendedData');
  //   if (extData.length > 0) {
  //     var data = extData[0].getElementsByTagName('Data');
  //     for (var d in data) {
  //       if (data[d].attributes.name == 'title') {
  //         if (data[d].children[0].value == 'Autolayout' || data[d].children[0].value == 'Road') {
  //           save = false;
  //         }
  //         else if (data[d].children[0].value == 'Boundary') {
  //           identity = 1;
  //         }
  //         else if (data[d].children[0].value == 'Exclusion') {
  //           identity = 2;
  //         }
  //       }
  //     }
  //     if (!save) continue;
  //   }
  //   for (var p in polys) {
  //     // continue
  //     // console.log(polys[p])
  //     var coordinates = []
  //     // if (polys[p]['name'] == 'LineString'){
  //     //   is_lines = true;
  //     // }
  //     // var coords=polys[p].getElementsByTagName('coordinates')
  //     var coords=nodes[n].getElementsByTagName('coordinates')
  //     // console.log(coords)
  //     for (var c in coords) {
  //       if (!is_multi) {
  //         coordinates = []
  //       }
  //       let poly = coords[c].value.toString()
  //       let points = poly.split(" ")
  //       let last_lonlat = null
  //       for (var p in points) {
  //         var lonlat = points[p].split(",")
  //         // console.log(lonlat)
  //         if (!isNaN(parseFloat(lonlat[1]))) {
  //           // coordinates.push([parseFloat(lonlat[1]), parseFloat(lonlat[0])])
  //           coordinates.push([parseFloat(lonlat[0]), parseFloat(lonlat[1])])
  //           last_lonlat = lonlat
  //         }
  //       }
  //       // console.log(coordinates)
  //       if (is_lines) {
  //         var first_lonlat = points[0].split(",")
  //         // var last_lonlat = points[points.length-1].split(",")
  //         if (!isNaN(parseFloat(first_lonlat[1]))) {
  //           let first_point = turf.point([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])])
  //           let last_point = turf.point([parseFloat(last_lonlat[0]), parseFloat(last_lonlat[1])])
  //           var options = {units: 'kilometers'};
  //           var distance = turf.distance(first_point, last_point, options);
  //           if (distance > 0.085) {
  //             // console.log(distance)
  //             // distance too far
  //             continue
  //           }
  //           coordinates.push([parseFloat(first_lonlat[0]), parseFloat(first_lonlat[1])])
  //         }
  //       }
  //       if (coordinates.length < 4) {
  //         continue
  //       }
  //       if (!is_multi) {
  //         coordinates = checkFirstandLast(coordinates)
  //         let feature = createFeature(index, identity, coordinates)
  //         let valid = validateFeature(feature, features)
  //         // only add this if it's valid
  //         if (valid) {
  //           features.push(feature)
  //         }
  //         // increment
  //         index++;
  //       }
  //     }
  //     if (is_multi) {
  //       coordinates.push(coordinates[0])
  //       let feature = createFeature(index, identity, coordinates)
  //       let valid = validateFeature(feature, features)
  //       // only add this if it's valid
  //       if (valid) {
  //         features.push(feature)
  //       }
  //       index++;
  //     }
  //   }
  // }
  // // console.log(features)
  // return features
  // if (surfaces.length > 0) {
  //   // Update state with new surfaces
  //   this.setState({
  //     Surfaces : surfaces
  //   }, () => {
  //     // Call our update method that will add surfaces to our map
  //     this.forceUpdate();
  //     this.onSurfacesUpdates();
  //     // this.handleZoomExtents();
  //   });
  // } else {
  //   if (points.length == 0) {
  //     Swal({title:'Error', text:'No polygons or markers found in KMZ.', type:'warning', confirmButtonColor:'#d9534f'});
  //   } else {
  //     Swal({title:'Error', text:'No polygons found in KMZ - centering on first found marker.', type:'warning', confirmButtonColor:'#d9534f'});
  //     this.setState({center: points[0]})
  //   }
  // }
}

function getArea(coords) {
  // console.log(coords[0])
  // make sure first and last coord
  // if (coords[0][0] != coords[0][coords.length-1]){
  //   console.log(coords[0][0], coords[0][coords.length-1])
  //   coords[0].push(coords[0][0])
  // }
  let area = 0;
  try {
    let turfPoly = turf.polygon(coords);
    area = (turf.area(turfPoly) / 1000000) * 100;
  } catch (error) {
    console.log(error);
    // console.log(coords[0][0] == coords[0][coords[0].length-1])
    // console.log(coords[0][0], coords[0][coords[0].length-1])
    area = -1;
  }
  return area;
}
function checkFirstandLast(coords) {
  if (coords[0] != coords[coords.length - 1]) {
    // var options = {units: 'kilometers'};
    // var distance = turf.distance(coords[0], coords[coords.length-1], options);
    // console.log(distance)
    coords.push(coords[0]);
  }
  return coords;
}

export function create_UUID() {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
export function uuid() {
  let dt = new Date().getTime();
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function getTodaysDate() {
  const d = new Date();
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
}

export function getMMDDTYYYY() {
  const d = new Date();
  let MM = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  let DD = d.getDate() + 1 < 10 ? `0${d.getDate() + 1}` : d.getDate() + 1;
  let YYYY = d.getFullYear().toString();
  return MM + DD + YYYY;
}

export function fixResultFiles(data, meta, code) {
  let fields;
  let values = [];

  Object.values(data).map((info) => {
    // console.log("info", info);
    values.push({
      Key: info.id,
      Racking: info.racking_name,
      Module: info.mod_name,
      Inverter: info.simple_inverter == 1 ? "Simple Inverter Input" : info.inv_name,
      GCR: info.gcr,
      "Pitch (m)": info.pitch,
      "Intra-row Spacing (m)": info.r2r,
      "Azimuth (°)": info.sazm,
      "Tilt (°)": info.tilt,
      "Yield (kWh/kWp)": info._yield,
      "IRR (%)": code == 152 ? info.irr : undefined,
      NPV: code == 152 ? info.npv : undefined,
      "LCOE ($/kWh)": code >= 151 ? info.lcoe : undefined,
      "DC:AC": info.dcac,
      "DC Capacity (MWp)": info.MWp,
      "AC Capacity (MW)": info.MWac,
      "Generation Yr1 (MWh)": info["fh. Energy injected into Grid (MWh)"].toFixed(2),
      "Module Qty": info.en_mod_target == 1 ? info.mod_target : info.module_count,
      "Inverter Qty": info.simple_inverter == 1 ? "Simple Inverter Input" : info.inverter_qty,
      "String Qty": info.string_qty,
      "String Qty in Plot": info.string_qty,
      "Table Count (A) in Plot": info.rack_breakdown[0],
      "Table Count (B) in Plot": info.rack_breakdown[1],
      "Table Count (C) in Plot": info.rack_breakdown[2],
      "Racking Footprint (ha)": info.footprint,
      "Total Install Cost": code >= 151 ? info.total_install_cost : undefined,
      "Operating Cost Array": code >= 151 ? info.op_cost_array.toString().replace("[", "").replace("]", "") : undefined,
      "Cashflow After Tax": code === 152 ? info.cashflow_after_tax.toString().replace("[", "").replace("]", "") : undefined,
      "Yearly Generation (kWh)": info.generation.toString().replace("[ ", "").replace(" ]", ""),
      "DC Degradation (%)": info.dc_degrade * 100,
      "Horizontal global irradiation (kWh/m^2)": info["aa. Horizontal global irradiation (kWh/m^2)"],
      "Global incident in coll. plane (%)": info["ab. Global incident in coll. plane %"] * 100,
      "Global incident below threshold (%)": info["ac. Global incident below threshold"] * 100,
      "Near Shadings: irradiance loss (%)": info["ad. Near Shadings: irradiance_loss"] * 100,
      "Environmental, near shade (%)": info["adb. Environmental, near shade (%)"],
      "IAM factor on global (%)": info["ae. IAM factor on global"] * 100,
      "Soiling loss factor (%)": info["af. Soiling loss factor"] * 100,
      "Global irradiance on rear side (%)": info["bk. Global irradiance on rear side"] * 100,
      "Effective irradiation on rear (kWh/m²)": info["bl. Effective irradiation on collectors rear (kWh/m^2)"],
      "Effective irradiation on collectors (kWh/m²)": info["bl. Effective irradiation on collectors rear (kWh/m^2)"],
      "Array nominal energy at STC (MWh)": info["cd. Array nominal energy at STC (MWh)"],
      "Loss due to irradiance level (%)": info["da. Loss due to irradiance level"] * 100,
      "PV loss due to temperature (%)": info["db. PV loss due to temperature"] * 100,
      "Shadings: Electrical loss (%)": info["dc. Shadings: Electrical loss"] * 100,
      "Module quality loss (%)": info["de. Module quality loss"] * 100,
      "LID (%)": info["df. LID - Light induced degradation"] * 100,
      "Mismatch loss: , modules and strings (%)": info["dg. Mismatch loss, modules and strings"] * 100,
      "Mismatch for back irradiance (%)": info["dh. Mismatch for back irradiance"] || 0 * 100,
      "Ohmic wiring loss (%)": info["di. Ohmic wiring loss"] * 100,
      "Array virtual energy at MPP (MWh)": info["dj. Array virual energy at MPP"],
      "Inverter Loss during operating: , efficiency (%)": info["ea. Inverter Loss during operation (efficiency)"] * 100,
      "Inverter Loss over nominal inv. Power (%)": info["eb. Inverter Loss over nominal inv. power"] * 100,
      "Inverter Loss due to max input current (%)": info["ec. Inverter Loss due to max input current"] * 100,
      "Inverter Loss over nominal inv. Voltage (%)": info["ed. Inverter Loss over nominal inv. voltage"] * 100,
      "Inverter loss due to power threshold (%)": info["ee. Inverter loss due to power threshold"] * 100,
      "Inverter loss due to voltage threshold (%)": info["ef. Inverter loss due to voltage threshold"] * 100,
      "Night consumption (%)": info["eg. Night consumption"] * 100,
      "Available Energy at Inverter Output (MWh)": info["eh. Available Energy at Inverter Output (MWh)"],
      "Auxiliaries (fans: , other) (%)": info["fa. Auxiliaries (fans, other)"] * 100,
      "AC ohmic loss (%)": info["fb. AC ohmic loss"] * 100,
      "MV transformer loss (%)": info["fc. MV transformer loss"] * 100,
      "MV line ohmic loss (%)": info["fd. MV line ohmic losss"] * 100,
      "HV transformer loss (%)": info["fda. HV transformer loss"] * 100,
      "Fixed Transmission Loss (%)": info["fe. Fixed Transmission Loss"] * 100,
      "AC Other (%)": info["ff. AC Other"] * 100,
      "Unused energy (grid limitation) (%)": info["fg. Unused energy (grid limitation)"] * 100,
      "Energy injected into Grid (MWh)": info["fh. Energy injected into Grid (MWh)"],
    });
  });

  if (code == 150) {
    // performance
    fields = [
      "Key",
      "Racking",
      "Module",
      "Inverter",
      "GCR",
      "Pitch (m)",
      "Intra-row Spacing (m)",
      "Azimuth (°)",
      "Tilt (°)",
      "Yield (kWh/kWp)",
      "DC:AC",
      "DC Capacity (MWp)",
      "AC Capacity (MW)",
      "Generation Yr1 (MWh)",
      "Module Qty",
      "Inverter Qty",
      "String Qty",
      "String Qty in Plot",
      "Table Count (A) in Plot",
      "Table Count (B) in Plot",
      "Table Count (C) in Plot",
      "Racking Footprint (ha)",
      "Yearly Generation (kWh)",
      "DC Degradation (%)",
      "Horizontal global irradiation (kWh/m^2)",
      "Global incident in coll. plane (%)",
      "Global incident below threshold (%)",
      "Near Shadings: irradiance loss (%)",
      "Environmental, near shade (%)",
      "IAM factor on global (%)",
      "Soiling loss factor (%)",
      "Global irradiance on rear side (%)",
      "Effective irradiation on rear (kWh/m²)",
      "Effective irradiation on collectors (kWh/m²)",
      "Array nominal energy at STC (MWh)",
      "Loss due to irradiance level (%)",
      "PV loss due to temperature (%)",
      "Shadings: Electrical loss (%)",
      "Module quality loss (%)",
      "LID (%)",
      "Mismatch loss: , modules and strings (%)",
      "Mismatch for back irradiance (%)",
      "Ohmic wiring loss (%)",
      "Array virtual energy at MPP (MWh)",
      "Inverter Loss during operating: , efficiency (%)",
      "Inverter Loss over nominal inv. Power (%)",
      "Inverter Loss due to max input current (%)",
      "Inverter Loss over nominal inv. Voltage (%)",
      "Inverter loss due to power threshold (%)",
      "Inverter loss due to voltage threshold (%)",
      "Night consumption (%)",
      "Available Energy at Inverter Output (MWh)",
      "Auxiliaries (fans: , other) (%)",
      "AC ohmic loss (%)",
      "MV transformer loss (%)",
      "MV line ohmic loss (%)",
      "HV transformer loss (%)",
      "Fixed Transmission Loss (%)",
      "AC Other (%)",
      "Unused energy (grid limitation) (%)",
      "Energy injected into Grid (MWh)",
    ];
  } else if (code === 151) {
    // performance+finance

    fields = [
      "Key",
      "Racking",
      "Module",
      "Inverter",
      "GCR",
      "Pitch (m)",
      "Intra-row Spacing (m)",
      "Azimuth (°)",
      "Tilt (°)",
      "Yield (kWh/kWp)",
      "LCOE ($/kWh)",
      "DC:AC",
      "DC Capacity (MWp)",
      "AC Capacity (MW)",
      "Generation Yr1 (MWh)",
      "Module Qty",
      "Inverter Qty",
      "String Qty",
      "String Qty in Plot",
      "Table Count (A) in Plot",
      "Table Count (B) in Plot",
      "Table Count (C) in Plot",
      "Racking Footprint (ha)",
      "Total Install Cost",
      "Operating Cost Array",
      "Yearly Generation (kWh)",
      "DC Degradation (%)",
      "Horizontal global irradiation (kWh/m^2)",
      "Global incident in coll. plane (%)",
      "Global incident below threshold (%)",
      "Near Shadings: irradiance loss (%)",
      "Environmental, near shade (%)",
      "IAM factor on global (%)",
      "Soiling loss factor (%)",
      "Global irradiance on rear side (%)",
      "Effective irradiation on rear (kWh/m²)",
      "Effective irradiation on collectors (kWh/m²)",
      "Array nominal energy at STC (MWh)",
      "Loss due to irradiance level (%)",
      "PV loss due to temperature (%)",
      "Shadings: Electrical loss (%)",
      "Module quality loss (%)",
      "LID (%)",
      "Mismatch loss: , modules and strings (%)",
      "Mismatch for back irradiance (%)",
      "Ohmic wiring loss (%)",
      "Array virtual energy at MPP (MWh)",
      "Inverter Loss during operating: , efficiency (%)",
      "Inverter Loss over nominal inv. Power (%)",
      "Inverter Loss due to max input current (%)",
      "Inverter Loss over nominal inv. Voltage (%)",
      "Inverter loss due to power threshold (%)",
      "Inverter loss due to voltage threshold (%)",
      "Night consumption (%)",
      "Available Energy at Inverter Output (MWh)",
      "Auxiliaries (fans: , other) (%)",
      "AC ohmic loss (%)",
      "MV transformer loss (%)",
      "MV line ohmic loss (%)",
      "HV transformer loss (%)",
      "Fixed Transmission Loss (%)",
      "AC Other (%)",
      "Unused energy (grid limitation) (%)",
      "Energy injected into Grid (MWh)",
    ];
  } else if (code === 152) {
    fields = [
      "Key",
      "Racking",
      "Module",
      "Inverter",
      "GCR",
      "Pitch (m)",
      "Intra-row Spacing (m)",
      "Azimuth (°)",
      "Tilt (°)",
      "Yield (kWh/kWp)",
      "IRR (%)",
      "NPV",
      "LCOE ($/kWh)",
      "DC:AC",
      "DC Capacity (MWp)",
      "AC Capacity (MW)",
      "Generation Yr1 (MWh)",
      "Module Qty",
      "Inverter Qty",
      "String Qty",
      "String Qty in Plot",
      "Table Count (A) in Plot",
      "Table Count (B) in Plot",
      "Table Count (C) in Plot",
      "Racking Footprint (ha)",
      "Total Install Cost",
      "Operating Cost Array",
      "Cashflow After Tax",
      "Yearly Generation (kWh)",
      "DC Degradation (%)",
      "Horizontal global irradiation (kWh/m^2)",
      "Global incident in coll. plane (%)",
      "Global incident below threshold (%)",
      "Near Shadings: irradiance loss (%)",
      "Environmental, near shade (%)",
      "IAM factor on global (%)",
      "Soiling loss factor (%)",
      "Global irradiance on rear side (%)",
      "Effective irradiation on rear (kWh/m²)",
      "Effective irradiation on collectors (kWh/m²)",
      "Array nominal energy at STC (MWh)",
      "Loss due to irradiance level (%)",
      "PV loss due to temperature (%)",
      "Shadings: Electrical loss (%)",
      "Module quality loss (%)",
      "LID (%)",
      "Mismatch loss: , modules and strings (%)",
      "Mismatch for back irradiance (%)",
      "Ohmic wiring loss (%)",
      "Array virtual energy at MPP (MWh)",
      "Inverter Loss during operating: , efficiency (%)",
      "Inverter Loss over nominal inv. Power (%)",
      "Inverter Loss due to max input current (%)",
      "Inverter Loss over nominal inv. Voltage (%)",
      "Inverter loss due to power threshold (%)",
      "Inverter loss due to voltage threshold (%)",
      "Night consumption (%)",
      "Available Energy at Inverter Output (MWh)",
      "Auxiliaries (fans: , other) (%)",
      "AC ohmic loss (%)",
      "MV transformer loss (%)",
      "MV line ohmic loss (%)",
      "HV transformer loss (%)",
      "Fixed Transmission Loss (%)",
      "AC Other (%)",
      "Unused energy (grid limitation) (%)",
      "Energy injected into Grid (MWh)",
    ];
  }

  let csv_data_struct = [];
  csv_data_struct.push(fields);
  Object.values(values).map((vals) => {
    let data_row = [];
    fields.forEach((field) => {
      if (isFloatWithDecimalLength(vals[field], 10)) {
        data_row.push(parseFloat(vals[field].toFixed(8)));
      } else {
        data_row.push(vals[field]);
      }
    });
    csv_data_struct.push(data_row);
  });

  let copy_opts = { fields, delimiter: "\t", eol: "", header: true, withBOM: false };
  let csv_opts = { fields, delimiter: ",", header: true, withBOM: false, quote: '"' };

  return { tsvResult: json2csv(values, copy_opts), csvResult: csv_data_struct };
  // return { tsvResult: json2csv(values, copy_opts), csvResult: json2csv(values, csv_opts) }
}
function isFloatWithDecimalLength(value, decimalLength) {
  // Parse the input value into a float
  const num = parseFloat(value);

  // Check if the parsed value is a valid number
  if (isNaN(num) || !Number.isFinite(num)) {
    return false;
  }

  // Convert the number to a string to check the decimal length
  const numStr = num.toString();

  // Find the decimal point in the string representation of the number
  const decimalIndex = numStr.indexOf(".");

  // If there is no decimal point or the decimal length is less than the specified length, return false
  if (decimalIndex === -1 || numStr.length - decimalIndex - 1 <= decimalLength) {
    return false;
  }

  // Return true if the number is a float and has the specified decimal length
  return true;
}
// takes in an array of GeoJSON features and returns the overall bbox
export function getBounds(features) {
  let _features = [];
  for (var feat in features) {
    _features.push(features[feat]);
  }
  var featCollection = {
    type: "FeatureCollection",
    features: _features,
  };
  return turf.bbox(featCollection);
}

export function getSiteCenter(canopies) {
  let canopy_arr = [];
  for (var canopy in canopies) {
    canopy_arr.push(canopies[canopy].geoJson);
  }

  let canopyCollection = {
    type: "FeatureCollection",
    features: canopy_arr,
  };

  let bbox = turf.bbox(canopyCollection);

  let latitude = bbox[1] + Math.abs(bbox[3] - bbox[1]) / 2;
  let longitude = bbox[0] + Math.abs(bbox[2] - bbox[0]) / 2;
  return [latitude, longitude];
}

export function calculateTotalArea(features) {
  let total_area = 0;
  Object.values(features).forEach((feature) => {
    if (feature.properties.identity === 1) {
      let turfPoly = turf.polygon(feature.geometry.coordinates);
      total_area += (turf.area(turfPoly) / 1000000) * 100;
    }
  });
  return total_area;
}

export function calculateAcreage(features) {
  let total_area = 0;
  Object.values(features).forEach((feature) => {
    if (feature.properties.identity == 1) {
      let turfPoly = turf.polygon(feature.geometry.coordinates);
      total_area += turf.area(turfPoly) / 4046.856422;
    }
  });
  return total_area;
}

export function roundOff(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

// DETAILED RESULTS
const summary_shape = {
  location: ["latitude", "longitude", "elevation", "timezone", "meteo"],
  module: ["name", "rating", "bifacial", "area"],
  inverter: ["name", "rating"],
  racking: ["name", "type", "tilt", "backtracking", "tracking_angle"],
  configuration: [
    "gcr",
    "pitch",
    "intra_row_spacing",
    "inverter_qty",
    "string_qty",
    "module_qty",
    "dcac",
    "azimuth",
    "dc_capacity_mw",
    "ac_capacity_mw",
    "racking_footprint",
    "racking_footprint_acres",
    "buildable_area",
    "buildable_area_acres",
  ],
  performance: ["yield", "generation_yr1"],
  losses: [
    "dc_degrade",
    "aa. Horizontal global irradiation (kWh/m^2)",
    "ab. Global incident in coll. plane %",
    "ac. Global incident below threshold",
    "ad. Near Shadings: irradiance_loss",
    "adb. Environmental, near shade (%)",
    "ae. IAM factor on global",
    "af. Soiling loss factor",
    "bk. Global irradiance on rear side",
    "bl. Effective irradiation on collectors rear (kWh/m^2)",
    "ca. Effective irradiation on collectors (kWh/m^2)",
    "cb. Area of collectors (m^2)",
    "cc. Efficiency at STC (%)",
    "cd. Array nominal energy at STC (MWh)",
    "da. Loss due to irradiance level",
    "db. PV loss due to temperature",
    "dc. Shadings: Electrical loss",
    "dc. Spectral correction",
    "de. Module quality loss",
    "df. LID - Light induced degradation",
    "dg. Mismatch loss, modules and strings",
    "dh. Mismatch for back irradiance",
    "dj. Array virual energy at MPP",
    "di. Ohmic wiring loss",
    "ea. Inverter Loss during operation (efficiency)",
    "eb. Inverter Loss over nominal inv. power",
    "ec. Inverter Loss due to max input current",
    "ed. Inverter Loss over nominal inv. voltage",
    "ee. Inverter loss due to power threshold",
    "ef. Inverter loss due to voltage threshold",
    "eg. Night consumption",
    "eh. Available Energy at Inverter Output (MWh)",
    "fa. Auxiliaries (fans, other)",
    "fb. AC ohmic loss",
    "fc. MV transformer loss",
    "fd. MV line ohmic losss",
    "fda. HV transformer loss",
    "fe. Fixed Transmission Loss",
    // "fh. Energy injected into Grid (MWh)",
  ],
  finance: ["name", "analysis_period", "discount_rate", "lcoe", "irr", "npv"],
  install_cost: ["install_type", "summarized_dc", "summarized_ac", "fixed_cost", "total_install_cost"],
  dc_units: ["module_dc_cost", "rack_a_finance", "rack_b_finance", "rack_c_finance", "bos_other_value"],
  ac_units: ["inverter", "ac_aux", "mv_wire_value", "other_ac_value", "total_ac_cost"],
  fixed_units: ["interconnection_value", "permits_fees_value", "engineering_value", "margin_value", "other_fixed_value", "total_fixed_cost"],
  misc_units: ["rack_footprint", "boundary_area", "contingency_value", "space_adders", "total_install_cost"],
  operating_cost: ["dc_op_cost", "ac_op_cost", "fixed_op_cost", "footprint_op_cost", "lease_cost", "escalation"],
  revenue_incentives: ["revenue_type", "power", "rev_ac", "rev_fixed", "ri_escalation", "itc_percent", "ptc_value"],
  debt: ["debt_structure", "debt_percent", "debt_interest", "debt_tenor"],
  tax_depriciation: ["dep_5yrSL", "dep_15yrSL", "dep_20yrSL", "dep_30yrSL", "dep_35yrSL", "dep_39yrSL", "dep_5yrMACRS", "dep_15yrMACRS", "state_taxes", "federal_taxes"],
  topo_impact: ["impact", "irradiance"],
};

export function shape_and_sort_summary_data(obj, key) {
  let new_obj;

  summary_shape[key].map((item) => {
    if (!_.isUndefined(obj.value[item])) {
      new_obj = {
        ...new_obj,
        [item]: obj.value[item],
      };
    }
  });
  return new_obj;
}

export function sort_summary(summary) {
  let sorted_summary;
  Object.keys(summary_shape).map((key) => {
    if (summary[key]) {
      sorted_summary = {
        ...sorted_summary,
        [key]: JSON.parse(JSON.stringify(summary[key])),
      };
    }
  });

  return sorted_summary;
}

export function cleanRackingName(name) {
  switch (name) {
    case "Generic SAT 26mod/str":
      return "Generic SAT";
    case "Generic GFT 2HP 26mod/str":
      return "Generic GFT";
    case "Generic EWF 1HP 26mod/str":
      return "Generic EWF";
    default:
      return name;
  }
}

export function removePercent(label) {
  return label.replace(/%\s*|\(\%\)\s*/g, "");
}
