import React, { useState, useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as turf from "@turf/turf";

import { inputsActions, create_UUID, getBounds } from "../../../Redux";

import { getArea, getLineArea, getLineAcreage, getAcreage, isLinearRing, isValidPolygon, getAcreageOfmultipolygon, getAreaOfMultipolygon } from "../../DrawToolProvider/_draw.helpers";
import { Radio, Tooltip, Space, Button, Checkbox } from "antd";
import { DownloadOutlined, CloseOutlined } from "@ant-design/icons";
// import { Checkbox } from "../../../BaseComponents";

import { LayerMenuWrap } from "./styles";
import { Toggle } from "../../../Toggle";
import _ from "lodash";
import fetchUtil from "../../../Redux/_services/fetchUtil";

const default_local = {
  fema_count: 0,
  riparian_count: 0,
  wetland_count: 0,
  transmission_count: 0,
  gasline_count: 0,
  railway_count: 0,
  loaded: false,
};

const MapboxLayerMenu = ({ additional_layers }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const tile_set = useSelector((state) => state.inputs.uiState.tile_set);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);

  // const boundary_bbox = useSelector((state) => state.inputs.boundary_bbox);
  const site_features = useSelector((state) => state.inputs.site_features);

  const [layerState, setLayerState] = useState(additional_layers);

  useEffect(() => {
    // console.log("additional_layers", additional_layers);
    setLayerState(additional_layers);
  }, []);

  const local_data = useMemo(() => {
    let boundaries = Object.values(site_features).filter((feature) => feature.properties.identity == 1);

    if (site_features.length == 0 || boundaries.length < 1) return default_local;

    let only_boundaries_bbox = getBounds(boundaries);

    let real_ob_bbox = only_boundaries_bbox;
    let scaled_ob_bbox = turf.transformScale(turf.bboxPolygon(only_boundaries_bbox), 1.15);

    let local_d = {
      fema_count: _.filter(site_features, (s) => {
        return s.properties?.source == "fema";
      }).length,
      riparian_count: _.filter(site_features, (s) => {
        return s.properties?.source == "riparian";
      }).length,
      wetland_count: _.filter(site_features, (s) => {
        return s.properties?.source == "wetlands";
      }).length,
      transmission_count: _.filter(site_features, (s) => {
        return s.properties?.source == "transmission";
      }).length,
      gasline_count: _.filter(site_features, (s) => {
        return s.properties?.source == "gasline";
      }).length,
      railway_count: _.filter(site_features, (s) => {
        return s.properties?.source == "railway";
      }).length,
      real_boundary_bbox: real_ob_bbox,
      scaled_ob_bbox: scaled_ob_bbox,
      loaded: true,
    };
    return local_d;
  }, [site_features]);

  const add_layer = (key) => {
    const updatedLayers = {
      ...layerState,
      [key]: !layerState[key],
    };

    setLayerState(updatedLayers); // Update local state
    dispatch(inputsActions.update_ui_state("additional_layers", updatedLayers)); // Update Redux state
  };

  const change_tile_set = (key) => {
    dispatch(inputsActions.update_ui_state("tile_set", key));
  };

  const [wetlands_imported, set_wetlands_imported] = useState({ loading: false, error: undefined });

  const fetch_wetlands_polys = () => {
    if (local_data.wetland_count > 0 && local_data.loaded) {
      let new_features = _.filter(site_features, (s) => {
        return s.properties?.source != "wetlands";
      });
      dispatch(inputsActions.update_site_input("overwrite_bulk", new_features));
      return;
    }

    if (local_data.wetland_count == 0 && local_data.loaded && _.every(local_data.real_boundary_bbox, (bbox) => _.isFinite(bbox))) {
      set_wetlands_imported({ ...wetlands_imported, loading: true, error: undefined });

      const baseUrl = "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Wetlands/FeatureServer/0/query";
      const params = {
        where: "",
        objectIds: "",
        time: "",
        geometry: local_data.real_boundary_bbox.toString(),
        geometryType: "esriGeometryEnvelope",
        inSR: "",
        spatialRel: "esriSpatialRelIntersects",
        resultType: "none",
        distance: 100,
        units: "esriSRUnit_Meter",
        returnGeodetic: false,
        outFields: "",
        returnGeometry: true,
        returnCentroid: false,
        featureEncoding: "esriDefault",
        multipatchOption: "xyFootprint",
        returnIdsOnly: false,
        returnUniqueIdsOnly: false,
        returnCountOnly: false,
        returnExtentOnly: false,
        returnQueryGeometry: false,
        returnDistinctValues: false,
        cacheHint: false,
        returnZ: false,
        returnM: false,
        returnExceededLimitFeatures: true,
        sqlFormat: "none",
        f: "pgeojson",
        token: "",
      };

      const queryString = new URLSearchParams(params).toString();
      // console.log("`${baseUrl}?${queryString}`", `${baseUrl}?${queryString}`);
      // fetch(`${baseUrl}?${queryString}`)
      fetchUtil(`${baseUrl}?${queryString}`, {}, false, undefined, true)
        // .then((response) => response.json())
        .then((data) => {
          let count = 0;
          let newFeatures = {};

          if (data["features"] && _.isArray(data["features"])) {
            _.forEach(data["features"], (feature) => {
              if (!isValidPolygon(feature)) return;
              // console.log("feature", feature);
              if (feature.geometry.coordinates[0].length <= 3) return;
              if (count > 60) {
                return;
              }

              let new_id = create_UUID();

              let real_poly = turf.polygon(feature.geometry.coordinates);

              let intersectedPoly = turf.intersect(local_data.scaled_ob_bbox, real_poly);
              // console.log("intersectedPoly", intersectedPoly);
              if (intersectedPoly) {
                let geoJson = intersectedPoly;
                if (_.isNull(geoJson.properties)) {
                  geoJson.properties = {};
                }
                geoJson.properties["index"] = new_id;
                geoJson.properties["identity"] = 0;
                geoJson.properties["active"] = true;
                geoJson.properties["source"] = "wetlands";
                geoJson.properties["name"] = undefined;
                geoJson.properties["sortIndex"] = 1;
                geoJson.properties["area"] = intersectedPoly.geometry.type == "MultiPolygon" ? getAreaOfMultipolygon(intersectedPoly) : getArea(geoJson.geometry.coordinates);
                geoJson.properties["acreage"] = intersectedPoly.geometry.type == "MultiPolygon" ? getAcreageOfmultipolygon(intersectedPoly) : getAcreage(geoJson.geometry.coordinates);
                geoJson.properties["center"] = turf.getCoords(turf.centroid(intersectedPoly));
                // dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
                newFeatures = { ...newFeatures, [new_id]: geoJson };
                // console.log("center", geoJson.properties["center"]);
                count += 1;
              }
            });
          }

          if (Object.values(newFeatures).length > 0) {
            dispatch(inputsActions.update_site_input("add_bulk_features", newFeatures));
          }

          set_wetlands_imported({ ...wetlands_imported, loading: false, error: undefined });

          // setData(data);
          // setLoading(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          set_wetlands_imported({ ...wetlands_imported, loading: false, error: error });
        });
    }
    // const queryUrl = 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0/query';

    // const riparian0 =
    //   "https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer/0/query?where=&geometry=-1.35386323515E7,3676122.5529000014,-1.05058024824E7,6274868.355599999&geometryType=esriGeometryEnvelope&inSR=3857&spatialRel=esriSpatialRelIntersects&f=geojson";
  };

  const [fema_imported, set_fema_imported] = useState({ loading: false, error: undefined });
  function fetch_fema_polys() {
    if (local_data.fema_count > 0 && local_data.loaded) {
      let new_features = _.filter(site_features, (s) => {
        return s.properties?.source != "fema";
      });
      dispatch(inputsActions.update_site_input("overwrite_bulk", new_features));
      return;
    }

    if (local_data.fema_count == 0 && local_data.loaded && _.every(local_data.real_boundary_bbox, (bbox) => _.isFinite(bbox))) {
      set_fema_imported({ loading: true, error: undefined });

      // Base URL for the ArcGIS service
      const baseUrl = "https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0/query";

      // Parameters for the query
      const params = {
        where: "",
        objectIds: "",
        time: "",
        geometry: local_data.real_boundary_bbox.toString(),
        geometryType: "esriGeometryEnvelope",
        inSR: "",
        spatialRel: "esriSpatialRelIntersects",
        resultType: "none",
        distance: 0.0,
        units: "esriSRUnit_Meter",
        returnGeodetic: false,
        outFields: "",
        returnGeometry: true,
        returnCentroid: false,
        featureEncoding: "esriDefault",
        multipatchOption: "xyFootprint",
        returnIdsOnly: false,
        returnUniqueIdsOnly: false,
        returnCountOnly: false,
        returnExtentOnly: false,
        returnQueryGeometry: false,
        returnDistinctValues: true,
        cacheHint: false,
        returnZ: false,
        returnM: false,
        returnExceededLimitFeatures: true,
        sqlFormat: "none",
        f: "pgeojson",
        token: "",
      };

      // Create a query string from the parameters
      const queryString = new URLSearchParams(params).toString();

      // fetchUtil(`${baseUrl}?${queryString}`, {}, false, undefined, true)
      fetch(`${baseUrl}?${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          let count = 0;
          let newFeatures = {};
          if (data["features"] && _.isArray(data["features"])) {
            if (data["features"][0]?.geometry?.type == "MultiPolygon") {
              data["features"][0]?.geometry?.coordinates.map((feature) => {
                // console.log("feature", feature);
                // if (feature[0].length <= 3) return;
                if (count > 50) {
                  return;
                }

                let new_id = create_UUID();

                let real_poly = turf.polygon(feature);
                real_poly = turf.intersect(local_data.scaled_ob_bbox, real_poly);

                if (real_poly) {
                  let geoJson = real_poly;
                  if (_.isNull(geoJson.properties)) {
                    geoJson.properties = {};
                  }

                  geoJson.properties["index"] = new_id;
                  geoJson.properties["identity"] = 0;
                  geoJson.properties["active"] = true;
                  geoJson.properties["source"] = "fema";
                  geoJson.properties["name"] = undefined;
                  geoJson.properties["sortIndex"] = 1;
                  geoJson.properties["area"] = isLinearRing(geoJson) ? getArea(geoJson.geometry.coordinates) : 0;
                  geoJson.properties["acreage"] = isLinearRing(geoJson) ? getAcreage(geoJson.geometry.coordinates) : 0;
                  geoJson.properties["center"] = turf.getCoords(turf.centroid(real_poly));
                  // dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
                  newFeatures = { ...newFeatures, [new_id]: geoJson };

                  count += 1;
                }
              });
            } else {
              _.forEach(data["features"], (feature) => {
                // console.log("feature", feature);

                if (count > 50) {
                  return;
                }

                let real_poly = turf.polygon(feature.geometry.coordinates);

                real_poly = turf.intersect(local_data.scaled_ob_bbox, real_poly);

                if (real_poly) {
                  // Flatten the  polygon in case it's a MultiPolygon. This is happening with the new fema enpoint
                  const flattenedPolygons = turf.flatten(real_poly);

                  flattenedPolygons.features.forEach((poly) => {
                    let intersectedPoly = turf.intersect(local_data.scaled_ob_bbox, poly);

                    if (intersectedPoly) {
                      let new_id = create_UUID();

                      let geoJson = intersectedPoly;

                      if (_.isNull(geoJson.properties)) {
                        geoJson.properties = {};
                      }

                      geoJson.properties["index"] = new_id;
                      geoJson.properties["identity"] = 0;
                      geoJson.properties["active"] = true;
                      geoJson.properties["source"] = "fema";
                      geoJson.properties["name"] = undefined;
                      geoJson.properties["sortIndex"] = 1;
                      geoJson.properties["area"] = isLinearRing(geoJson) ? getArea(geoJson.geometry.coordinates) : 0;
                      geoJson.properties["acreage"] = isLinearRing(geoJson) ? getAcreage(geoJson.geometry.coordinates) : 0;
                      geoJson.properties["center"] = turf.getCoords(turf.centroid(intersectedPoly));

                      newFeatures = { ...newFeatures, [new_id]: geoJson };

                      count += 1;
                    }
                  });
                }
              });
            }
          }

          if (Object.values(newFeatures).length > 0) {
            dispatch(inputsActions.update_site_input("add_bulk_features", newFeatures));
          }

          set_fema_imported({ loading: false, error: undefined });

          // setData(data);
          // setLoading(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          set_fema_imported({ loading: false, error: error });
        });
    }
  }

  const [riparian_imported, set_riparian_imported] = useState({ loading: false, error: undefined });

  function fetch_riparian_polys() {
    if (local_data.riparian_count > 0 && local_data.loaded) {
      let new_features = _.filter(site_features, (s) => {
        return s.properties?.source != "riparian";
      });
      dispatch(inputsActions.update_site_input("overwrite_bulk", new_features));
      return;
    }

    if (local_data.riparian_count == 0 && local_data.loaded && _.every(local_data.real_boundary_bbox, (bbox) => _.isFinite(bbox))) {
      set_riparian_imported({ ...riparian_imported, loading: true, error: undefined });

      // Base URL for the ArcGIS service
      // const baseUrl = 'https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer/0/query';
      // console.log(turf.bbox(turf.toMercator(turf.bboxPolygon(local_data.real_boundary_bbox))))
      // https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer/0/query?where=&geometry=-13134047.598404437,4092090.0612181793,-13132941.08266595,4093622.859691045&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&f=geojson
      // https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer/0/query?where=&geometry=-13167947.16633826,4122728.5205492815&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&f=geojson
      let mercator_bbox = turf.bbox(turf.toMercator(turf.bboxPolygon(local_data.real_boundary_bbox)));
      // https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer/0/query?where=&text=&objectIds=&time=&geometry=-13132455.729686093%2C+4084309.910964243%2C+-13132231.309592655%2C+4084463.332103528&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=&returnGeometry=true&returnTrueCurves=false&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&returnIdsOnly=false&returnCountOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&gdbVersion=&historicMoment=&returnDistinctValues=false&resultOffset=&resultRecordCount=&returnExtentOnly=false&datumTransformation=&parameterValues=&rangeValues=&quantizationParameters=&featureEncoding=esriDefault&f=html
      const baseUrl = "https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer/0/query";

      const params = {
        where: "",
        text: "",
        objectIds: "",
        time: "",
        geometry: mercator_bbox.toString(),
        geometryType: "esriGeometryEnvelope",
        inSR: "",
        spatialRel: "esriSpatialRelIntersects",
        distance: "",
        units: "esriSRUnit_Foot",
        relationParam: "",
        outFields: "",
        returnGeometry: "true",
        returnTrueCurves: "false",
        maxAllowableOffset: "",
        geometryPrecision: "",
        outSR: "",
        havingClause: "",
        returnIdsOnly: "false",
        returnCountOnly: "false",
        orderByFields: "",
        groupByFieldsForStatistics: "",
        outStatistics: "",
        returnZ: "false",
        returnM: "false",
        gdbVersion: "",
        historicMoment: "",
        returnDistinctValues: "false",
        resultOffset: "",
        resultRecordCount: "",
        returnExtentOnly: "false",
        datumTransformation: "",
        parameterValues: "",
        rangeValues: "",
        quantizationParameters: "",
        featureEncoding: "esriDefault",
        f: "geojson",
      };
      // Create a query string from the parameters
      const queryString = new URLSearchParams(params).toString();
      // fetchUtil(`${baseUrl}?${queryString}`, {}, false, undefined, true);
      fetch(`${baseUrl}?${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          let count = 0;
          let newFeatures = {};
          if (data["features"] && _.isArray(data["features"])) {
            _.forEach(data["features"], (feature) => {
              if (feature.geometry.coordinates[0].length <= 3) return;
              if (count > 30) {
                return;
              }

              let new_id = create_UUID();

              let real_poly = turf.polygon(feature.geometry.coordinates);
              real_poly = turf.intersect(local_data.scaled_ob_bbox, real_poly);

              if (real_poly) {
                let geoJson = feature;
                if (_.isNull(geoJson.properties)) {
                  geoJson.properties = {};
                }
                geoJson.properties["index"] = new_id;
                geoJson.properties["identity"] = 0;
                geoJson.properties["active"] = true;
                geoJson.properties["source"] = "riparian";
                geoJson.properties["name"] = undefined;
                geoJson.properties["sortIndex"] = 1;
                geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
                geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
                geoJson.properties["center"] = turf.getCoords(turf.centroid(real_poly));
                newFeatures = { ...newFeatures, [new_id]: geoJson };

                // dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
                count += 1;
              }
            });
          }

          if (Object.values(newFeatures).length > 0) {
            dispatch(inputsActions.update_site_input("add_bulk_features", newFeatures));
          }

          set_riparian_imported({ ...riparian_imported, loading: false, error: undefined });

          // setData(data);
          // setLoading(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          set_riparian_imported({ ...riparian_imported, loading: false, error: error });
        });
    }
  }

  const [transmission_imported, set_transmission_imported] = useState({ loading: false, error: undefined });

  function fetch_transmission_polys() {
    if (local_data.transmission_count > 0 && local_data.loaded) {
      let new_features = _.filter(site_features, (s) => {
        return s.properties?.source != "transmission";
      });
      dispatch(inputsActions.update_site_input("overwrite_bulk", new_features));
      return;
    }

    if (local_data.transmission_count == 0 && local_data.loaded && _.every(local_data.real_boundary_bbox, (bbox) => _.isFinite(bbox))) {
      set_transmission_imported({ loading: true, error: undefined });

      // Base URL for the ArcGIS service
      const baseUrl = "https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Transmission_Lines/FeatureServer/0/query";

      // Parameters for the query
      const params = {
        // where: '',
        // objectIds: '',
        // time: '',
        geometry: local_data.real_boundary_bbox.toString(),
        returnGeometry: true,
        // where: 1,
        outSR: 4326,
        outFields: "*",
        inSR: 4326,
        // geometry: { "xmin": 46.38427734375, "ymin": 50.30337575356313, "xmax": 46.40625, "ymax": 50.317408112618715 },
        geometryType: "esriGeometryEnvelope",
        spatialRel: "esriSpatialRelIntersects",
        geometryPrecision: 6,
        resultType: "tile",
        f: "geojson",
      };

      // Create a query string from the parameters
      const queryString = new URLSearchParams(params).toString();

      fetchUtil(`${baseUrl}?${queryString}`, {}, false, undefined, true)
        // .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          let count = 0;
          let newFeatures = {};
          if (data["features"] && _.isArray(data["features"])) {
            _.forEach(data["features"], (feature) => {
              // if (feature.geometry.coordinates[0].length <= 3) return;
              // if (count > 30) {
              // 	return;
              // }

              let new_id = create_UUID();

              let lineString = turf.lineString(feature.geometry.coordinates);

              // // real_poly = turf.intersect(local_data.scaled_ob_bbox, real_poly)
              let bufferedPolygon = turf.buffer(lineString, 1, { units: "meters" });

              let intersectedPoly = turf.intersect(local_data.scaled_ob_bbox, bufferedPolygon);

              if (intersectedPoly) {
                let geoJson = intersectedPoly;
                if (_.isNull(geoJson.properties)) {
                  geoJson.properties = {};
                }
                geoJson.properties["index"] = new_id;
                geoJson.properties["identity"] = 0;
                geoJson.properties["active"] = true;
                geoJson.properties["source"] = "transmission";
                // geoJson.properties["name"] = undefined;
                geoJson.properties["sortIndex"] = 1;
                geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
                geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
                geoJson.properties["center"] = turf.getCoords(turf.centroid(intersectedPoly));
                newFeatures = { ...newFeatures, [new_id]: geoJson };

                // dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
                count += 1;
              }
            });
          }

          if (Object.values(newFeatures).length > 0) {
            dispatch(inputsActions.update_site_input("add_bulk_features", newFeatures));
          }

          set_transmission_imported({ loading: false, error: undefined });
          // add_layer("transmission_lines_on");
          // setData(data);
          // setLoading(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          set_transmission_imported({ loading: false, error: error });
        });
    }
  }

  const [gasline_imported, set_gasline_imported] = useState({ loading: false, error: undefined });
  function fetch_gasline_polys() {
    if (local_data.gasline_count > 0 && local_data.loaded) {
      let new_features = _.filter(site_features, (s) => {
        return s.properties?.source != "gasline";
      });
      dispatch(inputsActions.update_site_input("overwrite_bulk", new_features));
      return;
    }

    if (local_data.gasline_count == 0 && local_data.loaded && _.every(local_data.real_boundary_bbox, (bbox) => _.isFinite(bbox))) {
      set_gasline_imported({ loading: true, error: undefined });

      // const baseUrl = "https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Natural_Gas_Liquid_Pipelines/FeatureServer/0/query";
      const baseUrl = "https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/Natural_Gas_Interstate_and_Intrastate_Pipelines_1/FeatureServer/0/query";
      // Parameters for the query
      const params = {
        // time: '',
        geometry: local_data.real_boundary_bbox.toString(),
        returnGeometry: true,
        outSR: 4326,
        outFields: "*",
        inSR: 4326,
        // geometry: { "xmin": 46.38427734375, "ymin": 50.30337575356313, "xmax": 46.40625, "ymax": 50.317408112618715 },
        geometryType: "esriGeometryEnvelope",
        spatialRel: "esriSpatialRelIntersects",
        geometryPrecision: 6,
        resultType: "tile",
        f: "geojson",
      };

      // Create a query string from the parameters
      const queryString = new URLSearchParams(params).toString();

      fetchUtil(`${baseUrl}?${queryString}`, {}, false, undefined, true)
        //.then((response) => response.json())
        .then((data) => {
          // console.log(data);
          let count = 0;
          let newFeatures = {};
          if (data["features"] && _.isArray(data["features"])) {
            _.forEach(data["features"], (feature) => {
              // if (feature.geometry.coordinates[0].length <= 3) return;
              if (count > 30) {
                return;
              }

              let new_id = create_UUID();

              // let real_poly = turf.lineString(feature.geometry.coordinates);
              // real_poly = turf.intersect(local_data.scaled_ob_bbox, real_poly)

              let lineString = turf.lineString(feature.geometry.coordinates);

              let bufferedPolygon = turf.buffer(lineString, 1, { units: "meters" });

              let intersectedPoly = turf.intersect(local_data.scaled_ob_bbox, bufferedPolygon);

              if (intersectedPoly) {
                let geoJson = intersectedPoly;
                if (_.isNull(geoJson.properties)) {
                  geoJson.properties = {};
                }
                geoJson.properties["index"] = new_id;
                geoJson.properties["identity"] = 0;
                geoJson.properties["active"] = true;
                geoJson.properties["source"] = "gasline";
                // geoJson.properties["name"] = undefined;
                geoJson.properties["sortIndex"] = 1;
                geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
                geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
                geoJson.properties["center"] = turf.getCoords(turf.centroid(intersectedPoly));
                newFeatures = { ...newFeatures, [new_id]: geoJson };
                // dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
                count += 1;
              }
            });
          }

          if (Object.values(newFeatures).length > 0) {
            dispatch(inputsActions.update_site_input("add_bulk_features", newFeatures));
          }

          set_gasline_imported({ loading: false, error: undefined });

          // setData(data);
          // setLoading(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          set_fema_imported({ loading: false, error: error });
        });
    }
  }

  const [railway_imported, set_railway_imported] = useState({ loading: false, error: undefined });
  function fetch_railway_polys() {
    if (local_data.railway_count > 0 && local_data.loaded) {
      let new_features = _.filter(site_features, (s) => {
        return s.properties?.source != "railway";
      });
      dispatch(inputsActions.update_site_input("overwrite_bulk", new_features));
      return;
    }

    if (local_data.fema_count == 0 && local_data.loaded && _.every(local_data.real_boundary_bbox, (bbox) => _.isFinite(bbox))) {
      set_fema_imported({ loading: true, error: undefined });

      // Base URL for the ArcGIS service
      const baseUrl = "https://tigerweb.geo.census.gov/arcgis/rest/services/TIGERweb/Transportation/MapServer/9/query";

      // Parameters for the query
      const params = {
        time: "",
        geometry: local_data.real_boundary_bbox.toString(),
        returnGeometry: true,
        outSR: 4326,
        outFields: "*",
        inSR: 4326,
        // geometry: { "xmin": 46.38427734375, "ymin": 50.30337575356313, "xmax": 46.40625, "ymax": 50.317408112618715 },
        geometryType: "esriGeometryEnvelope",
        spatialRel: "esriSpatialRelIntersects",
        geometryPrecision: 6,
        resultType: "tile",
        f: "geojson",
      };

      // Create a query string from the parameters
      const queryString = new URLSearchParams(params).toString();

      // fetchUtil(`${baseUrl}?${queryString}`, {}, false, undefined, true)
      fetch(`${baseUrl}?${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          let count = 0;
          let newFeatures = {};
          if (data["features"] && _.isArray(data["features"])) {
            _.forEach(data["features"], (feature) => {
              // if (feature.geometry.coordinates[0].length <= 3) return;
              if (count > 30) {
                return;
              }

              let new_id = create_UUID();

              let lineString = turf.lineString(feature.geometry.coordinates);

              let bufferedPolygon = turf.buffer(lineString, 1, { units: "meters" });

              let intersectedPoly = turf.intersect(local_data.scaled_ob_bbox, bufferedPolygon);

              if (intersectedPoly) {
                let geoJson = intersectedPoly;

                if (_.isNull(geoJson.properties)) {
                  geoJson.properties = {};
                }
                geoJson.properties["index"] = new_id;
                geoJson.properties["identity"] = 0;
                geoJson.properties["active"] = true;
                geoJson.properties["source"] = "railway";
                geoJson.properties["name"] = undefined;
                geoJson.properties["sortIndex"] = 1;
                geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
                geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
                geoJson.properties["center"] = turf.getCoords(turf.centroid(intersectedPoly));
                newFeatures = { ...newFeatures, [new_id]: geoJson };

                // dispatch(inputsActions.update_site_input("add_site_feature", geoJson));
                count += 1;
              }
            });
          }

          if (Object.values(newFeatures).length > 0) {
            dispatch(inputsActions.update_site_input("add_bulk_features", newFeatures));
          }

          set_railway_imported({ loading: false, error: undefined });

          // setData(data);
          // setLoading(false);
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          set_railway_imported({ loading: false, error: error });
        });
    }
  }

  // console.log(fema_imported)
  return (
    <LayerMenuWrap>
      <section className="layer-select">
        <p>Current Basemap: </p>
        <Radio.Group value={tile_set} onChange={(e) => change_tile_set(e.target.value)}>
          <Radio value="Mapbox" checked={"Mapbox"}>
            HD Satellite
          </Radio>
          <Radio value="Satellite" checked={tile_set == "Satellite"}>
            Satellite {user && user.product_plan_paid > 1 && "LoRes"}
          </Radio>
          <Radio value="StreetMap" checked={tile_set == "StreetMap"}>
            Streets Map
          </Radio>
          {/* {user && user.product_plan_paid > 1 && (
            <Radio value="SatelliteHD" checked={"SatelliteHD"}>
              Satellite HD
            </Radio>
          )} */}
          {/* {user && user.product_plan_paid > 1 && (
            <Radio value="Mapbox" checked={"Mapbox"}>
              Mapbox Satellite
            </Radio>
          )} */}
          {/* {user && user.product_plan_paid > 1 && (
            <Radio value="Satellite_With_Labels" checked={tile_set == "Satellite_With_Labels"}>
              Satellite w/Labels
            </Radio>
          )} */}

          {/* {user && user.product_plan_paid > 1 ? (
            <Radio value="StreetMapHD" checked={tile_set == "StreetMapHD"}>
              Streets Map 
            </Radio>
          ) : (
            <Radio value="StreetMap" checked={tile_set == "StreetMap"}>
              Streets map
            </Radio>
          )} */}

          {/* {user && user.product_plan_paid == 1 && (
            <Tooltip placement="top" title={`Upgrade to paid plan \nfor higher quality maps`} mouseEnterDelay={0.5}>
              <Radio value="upsale" checked={false} disabled>
                HD Map
              </Radio>
            </Tooltip>
          )} */}
        </Radio.Group>
      </section>
      <section className="additional-layers">
        <p>Additional Layers:</p>

        <Space>
          <Tooltip placement="top" title={local_data.riparian_count == 0 ? `Import Linework into Map` : `Clear imported Linework`} mouseEnterDelay={0.1}>
            <Button
              type="link"
              icon={local_data.riparian_count == 0 ? <DownloadOutlined /> : <CloseOutlined />}
              size="small"
              onClick={(e) => fetch_riparian_polys()}
              loading={riparian_imported.loading}
            />
          </Tooltip>
          <Checkbox onChange={() => add_layer("riparian_on")} checked={layerState.riparian_on}>
            Riparian
          </Checkbox>
        </Space>

        <Space>
          <Tooltip placement="top" title={local_data.wetland_count == 0 ? `Import Linework into Map` : `Clear imported Linework`} mouseEnterDelay={0.1}>
            <Button
              type="link"
              icon={local_data.wetland_count == 0 ? <DownloadOutlined /> : <CloseOutlined />}
              size="small"
              onClick={(e) => fetch_wetlands_polys()}
              loading={wetlands_imported.loading}
            />
          </Tooltip>
          <Checkbox onChange={() => add_layer("wetlands_on")} checked={layerState.wetlands_on}>
            Wetlands
          </Checkbox>
        </Space>

        <Space>
          <Tooltip placement="top" title={local_data.fema_count == 0 ? `Import Linework into Map` : `Clear imported Linework`} mouseEnterDelay={0.1}>
            <Button type="link" icon={local_data.fema_count == 0 ? <DownloadOutlined /> : <CloseOutlined />} size="small" onClick={(e) => fetch_fema_polys()} loading={fema_imported.loading} />
          </Tooltip>
          <Checkbox onChange={() => add_layer("fema_on")} checked={layerState.fema_on}>
            FEMA
          </Checkbox>
        </Space>

        <Space>
          <Tooltip placement="top" title={local_data.transmission_count == 0 ? `Import Linework into Map` : `Clear imported Linework`} mouseEnterDelay={0.1}>
            <Button
              type="link"
              icon={local_data.transmission_count == 0 ? <DownloadOutlined /> : <CloseOutlined />}
              size="small"
              onClick={(e) => fetch_transmission_polys()}
              loading={transmission_imported.loading}
            />
          </Tooltip>
          <Checkbox onChange={() => add_layer("transmission_lines_on")} checked={layerState.transmission_lines_on}>
            Transmission Lines
          </Checkbox>
        </Space>
        <Space>
          <Tooltip placement="top" title={local_data.gasline_count == 0 ? `Import Linework into Map` : `Clear imported Linework`} mouseEnterDelay={0.1}>
            <Button
              type="link"
              icon={local_data.gasline_count == 0 ? <DownloadOutlined /> : <CloseOutlined />}
              size="small"
              onClick={(e) => fetch_gasline_polys()}
              loading={gasline_imported.loading}
            />
          </Tooltip>
          <Checkbox onChange={() => add_layer("gas_lines_on")} checked={layerState.gas_lines_on}>
            Gas Pipelines
          </Checkbox>
        </Space>
        <Space>
          <Tooltip placement="top" title={local_data.railway_count == 0 ? `Import Linework into Map` : `Clear imported Linework`} mouseEnterDelay={0.1}>
            <Button
              type="link"
              icon={local_data.railway_count == 0 ? <DownloadOutlined /> : <CloseOutlined />}
              size="small"
              onClick={(e) => fetch_railway_polys()}
              loading={railway_imported.loading}
            />
          </Tooltip>
          <Checkbox onChange={() => add_layer("railway_lines_on")} checked={layerState.railway_lines_on}>
            Railways
          </Checkbox>
        </Space>
      </section>
      <section className="additional-layers">
        <p>Toggle Layers:</p>
        <section className="toggle-layer-button">
          <label>Show Layout</label>
          <Toggle id_key={"layout_visible"} uiControler={true} disabled={generated ? false : true} />
        </section>
      </section>
    </LayerMenuWrap>
  );
};

export { MapboxLayerMenu };
