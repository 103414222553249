import { accessToken, refreshToken, isAccessValid, isRefreshValid } from "../_reducers";
import { UserActions, undoRedoActions, inputsActions } from "../_actions";

export default function requestMiddleware(client) {
  return (next) => (action) => {
    if (typeof action === "function") {
      return action(dispatch, getState);
    }
    let state = client.getState();

    // Check if token is expired here...
    if (action.type !== "USERS_REFRESH_TOKEN_REQUEST") {
      if (accessToken(state) && refreshToken(state)) {
        // UNDO REDO DISABLED FOR NOW
        // if (
        //   action.type === "LOAD_PROJECT_COMPLETE" ||
        //   action.type === "NEW_PROJECT_REQUEST" ||
        //   (action.type === "UPDATE_SITE_INPUT" && (action.key === "add_site_feature" || action.key === "edit_site_feature" || action.key === "remove_site_feature"))
        // ) {
        //   setTimeout(() => {
        //     client.dispatch(undoRedoActions.storeFeatureState({ features: client.getState().inputs.site_features }, action.type));
        //   }, 750);
        // }
        // // if (action.type == "CREATE_FEATURE" || action.type == "UPDATE_FEATURE" || action.type == "DELETE_FEATURE" || action.type == "LOAD_PROJECT_COMPLETE" || action.type == "NEW_PROJECT_REQUEST") {
        // //   setTimeout(() => {
        // //     client.dispatch(undoRedoActions.storeFeatureState({ features: client.getState().inputs.site_features }, action.type));
        // //   }, 750);
        // // }

        // if (action.type === "UNDO") {
        //   setTimeout(() => {
        //     client.dispatch(inputsActions.undoFeatures(state.undoRedo));
        //   }, 500);
        // }
        // if (action.type === "REDO") {
        //   setTimeout(() => {
        //     client.dispatch(inputsActions.redoFeatures(state.undoRedo));
        //   }, 500);
        // }

        if (!isAccessValid(state) && isRefreshValid(state)) {
          // console.log('automatically refreshing token')
          client.dispatch(UserActions.refreshToken(JSON.parse(localStorage.getItem("refresh"))));
          // let refreshAction = {
          //   type: 'USER_REFRESH_TOKEN_REQUEST',
          // };
          // next(refreshAction);
        } else if (!isAccessValid(state) && !isRefreshValid(state)) {
          next({ type: "LOGOUT" });
        }
      }
    }

    const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
    if (!promise) {
      // console.log('running normal code')
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });

    const actionPromise = promise(client);
    actionPromise
      .then(
        (result) => next({ ...rest, result, type: SUCCESS }),
        (error) => next({ ...rest, error, type: FAILURE })
      )
      .catch((error) => {
        console.error("MIDDLEWARE ERROR:", error);
        next({ ...rest, error, type: FAILURE });
      });

    return actionPromise;
  };
}
