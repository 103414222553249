import React, { useState, useEffect } from "react";

import {
  Button,
  Divider,
  Card,
  Col,
  Row,
  Modal,
  Tabs,
  Spin,
  Alert,
  Empty,
} from "antd";
import "./funnel.css";
import "../../routes/Home/_components/buttons.css";

import { plan_specs } from "./plan_specs";
import { Info_50MW, Info_1000MW } from "./info";

// const PlanCards = () => {
//   return (
//     <>
//       {Object.keys(plan_specs).map((key, index) => (
//         <section className="plan-card">
//           <Card key={index} title={key} className="funnel-card"></Card>
//         </section>
//       ))}
//     </>
//   );
// };

const PlanCards = ({
  selected,
  summary = false,
  handleSelectSub,
  current_plan,
  current_plan_paid
}) => {

  const email = () => {
    var part1 = "info";
    var part2 = Math.pow(2, 6);
    var part3 = String.fromCharCode(part2);
    var part4 = "sunfig.com";
    var part5 = part1 + String.fromCharCode(part2) + part4;
    window.open("mai" + "lto" + ":" + part5);
  };

  return (
    <>
      {selected == 1 && (
        <Card title="Starter" bordered={false} className="funnel-card">
          <div className="two-options">
            <p>{plan_specs["starter"].plan_descrption}</p>
            <p className="bold-text">Individual Plan</p>
            <div className="amount">{`$${plan_specs["starter"].amount} USD`}</div>
          </div>
          <Divider className="funnel-divider" />
          <ul>
            <li>
              Projects up to <b>100 MW</b>
              <span>
                <Info_50MW />
              </span>
            </li>
            {plan_specs["starter"].plan_details.map((detail, index) => {
              if (!detail.account_page_specific) {
                return (
                  <li key={index}>
                    {detail.text == "plus_divider" ? (
                      <div className="plus">+</div>
                    ) : (
                      <>
                        {detail.text}
                        <span>{detail.component}</span>
                      </>
                    )}
                  </li>
                );
              }
            })}
          </ul>
          <div className="btn-wrap">
            {!summary && (
              <Button
                type="primary"
                id="1"
                className="btn"
                onClick={(e) => handleSelectSub(1)}
                disabled={current_plan == selected || current_plan_paid == selected}
              >
                {current_plan == selected
                  ? "CURRENT PLAN"
                  : current_plan_paid == selected ?
                  "PAID PLAN"                  
                  : "SELECT PLAN"}
              </Button>
            )}
          </div>
        </Card>
      )}
      {selected == 2 && (
        <Card title="Standard" bordered={false} className="funnel-card">
          <div className="two-options">
            <p className="bold-text">Individual Plan</p>
            <section className="mo-suffix">
              <p className="amount">{`$${plan_specs["standard"].amount[0]} USD`}</p>
              <p>{`/mo`}</p>
            </section>
            <p>Month to month, 7-day free trial</p>
            <p className="center-align bold">OR</p>
            <p className="bold-text">{`Group Plan (3 users included)`}</p>
            <section className="mo-suffix">
              <p className="amount">{`$${plan_specs["standard"].amount[1]} USD`}</p>
              <p className="amount small">+$40</p>
              <p>each add'l user /mo</p>
            </section>

            <a onClick={email}>Contact sales </a>
            {/* <p>Customize your projects and generate the data you need to make optimal project decisions.</p>
            <div className="amt">$85 USD</div>
            <div className="below-amt">/ month per user</div>
            <div className="below-amt">7-day free trial</div> */}
          </div>
          <Divider className="funnel-divider" />
          <ul>
            <li>
              Projects up to <b>100 MW</b>
              <span>
                <Info_50MW />
              </span>
            </li>
            {plan_specs["standard"].plan_details.map((detail, index) => {
              if (!detail.account_page_specific) {
                return (
                  <li key={index}>
                    {detail.text == "plus_divider" ? (
                      <div className="plus">+</div>
                    ) : (
                      <>
                        {detail.text}
                        <span>{detail.component}</span>
                      </>
                    )}
                  </li>
                );
              }
            })}
          </ul>
          <div className="btn-wrap">         
            {!summary && (
              <Button
                type="primary"
                id="2"
                className="btn"
                onClick={(e) => handleSelectSub(2)}
                disabled={current_plan == selected || current_plan_paid == selected}
              >
                {current_plan == selected
                  ? "CURRENT PLAN"
                  : current_plan_paid == selected ?
                  "PAID PLAN"                  
                  : "SELECT PLAN"}
              </Button>
            )}
          </div>
        </Card>
      )}
      {selected == 3 && (
        <Card title="Pro" bordered={false} className="funnel-card">
          <div className="two-options">
            <p className="bold-text">Individual Plan</p>
            <section className="mo-suffix">
              <p className="amount">{`$${plan_specs["pro"].amount[0]} USD`}</p>
              <p>{`/mo`}</p>
            </section>
            <p>Month to month, 7-day free trial</p>
            <p className="center-align bold">OR</p>
            <p className="bold-text">{`Group Plan (3 users included)`}</p>
            <section className="mo-suffix">
              <p className="amount">{`$${plan_specs["pro"].amount[1]} USD`}</p>
              <p className="amount small">+$40</p>
              <p>each add'l user /mo</p>
            </section>

            <a onClick={email}>Contact sales </a>
          </div>

          <Divider className="funnel-divider" />
          <ul>
            <li>
              Projects up to <b>1,000 MW</b>
              <span>
                <Info_1000MW />
              </span>
            </li>
            {plan_specs["pro"].plan_details.map((detail, index) => {
              if (!detail.account_page_specific) {
                return (
                  <li key={index}>
                    {detail.text == "plus_divider" ? (
                      <div className="plus">+</div>
                    ) : (
                      <>
                        {detail.text}
                        <span>{detail.component}</span>
                      </>
                    )}
                  </li>
                );
              }
            })}
          </ul>
          <div className="btn-wrap">
            {!summary && (
              <Button
                type="primary"
                id="3"
                className="btn"
                onClick={(e) => handleSelectSub(3)}
                disabled={current_plan == selected || current_plan_paid == selected}
              >
                {current_plan == selected
                  ? "CURRENT PLAN" 
                  : current_plan_paid == selected ?
                  "PAID PLAN"
                  : "SELECT PLAN"}
              </Button>
            )}
          </div>
        </Card>
      )}
    </>
  );
};

export { PlanCards };
