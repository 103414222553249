import React, { useState } from "react";

// styles
import "./footer.css";

//images
import youtubeIcon from "../_images/youtube.svg";
import linkedInIcon from "../_images/linkedin.svg";
import CEBN from "../_images/CEBN_logo_color.png";
import DOE from "../_images/DOE_logo_color.png";
import NREL from "../_images/nrel_logo_color.png";
import Powerhouse from "../_images/powerhouse_logo_color.png";
import SBIR from "../_images/sbir_logo_color.png";

import { Agreement } from "../../../components/Auth/agreement";

const Footer = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <footer className="footer">
      <div className="products">
        <h2>Products</h2>
        <a href="">SIFT</a>
        <a href="#sift">About SIFT</a>
        <a onClick={props.onShowPricing}>Pricing</a>
        {/* <a href="#services">Development Services</a>
        <a href="#api">API</a> */}
      </div>
      <div className="about">
        <h2>About</h2>
        <a href="#contact">Contact</a>
        <a href="#team">Team</a>
        <a href="https://calendly.com/siftdemo/demo" target="_blank" rel="noopener noreferrer">
          Schedule a Demo
        </a>
        <a onClick={() => setVisible(true)}>Terms and Conditions</a>
      </div>
      <div className="follow">
        <h2>Follow</h2>
        <div className="social-links">
          <a href="https://www.linkedin.com/company/sunfig/" target="_blank" rel="noopener noreferrer">
            <img src={linkedInIcon} alt="LinkedIn icon with orange circular background" />
          </a>
          <a href="https://www.youtube.com/channel/UChPl-NyfqHzjUBOETkGTvdQ/featured" target="_blank" rel="noopener noreferrer">
            <img src={youtubeIcon} alt="Youtube icon with orange circular background" />
          </a>
        </div>
      </div>
      <div className="thank-you">
        <h2>With Support From</h2>
        <div className="thank-you-logos">
          <a href="https://www.energy.gov/" target="_blank" rel="noopener noreferrer">
            {" "}
            <img src={DOE} alt="Department of Energy Logo" />
          </a>
          <a href="https://www.nrel.gov/" target="_blank" rel="noopener noreferrer">
            <img src={NREL} alt="National Renewable Energy Laboratory Logo" />
          </a>
          <a href="https://www.cebn.org/" target="_blank" rel="noopener noreferrer">
            {" "}
            <img src={CEBN} alt="Clean Energy Business Network Logo" />
          </a>
          <a href="https://www.powerhouse.fund/" target="_blank" rel="noopener noreferrer">
            <img src={Powerhouse} alt="Powerhouse Logo" />
          </a>
          <a href="https://seedfund.nsf.gov/" target="_blank" rel="noopener noreferrer">
            <img src={SBIR} alt="Americal Seed Fund Logo" />
          </a>
        </div>
      </div>
      {/* <div className="copyright">
        <p>©Sunfig 2020</p>
      </div> */}

      <Agreement visible={visible} onCancel={() => setVisible(false)} />
    </footer>
  );
};

export default Footer;
