import React, { useState, useEffect } from "react";
import { Source, Layer } from "react-map-gl";
import { calculatePolygonDimensions } from "../DrawToolProvider/_draw.helpers";

const DimensionsLayer = ({ features }) => {
  const [dims_data, set_dims_data] = useState(null);

  useEffect(() => {
    const dimsFeatures = Object.values(features).map((feature) => calculatePolygonDimensions(feature));
    set_dims_data({
      type: "FeatureCollection",
      features: dimsFeatures.flat(),
    });
  }, [features]);

  return (
    <>
      {dims_data && (
        <Source id="dims-source" type="geojson" data={dims_data}>
          <Layer
            id="dimension-text-layer"
            type="symbol"
            layout={{
              "text-field": ["get", "dimension"], //
              "text-size": 16,
              "text-font": ["Open Sans Bold"],
              //   "text-rotate": ["get", "bearing"],
              "text-offset": [0, ["case", ["==", ["get", "offsetDirection"], -1], -1, 1]],
              "symbol-spacing": 400,
              "text-allow-overlap": false,
              "text-keep-upright": true,
            }}
            paint={{
              //   "text-color": "rgba(0, 213, 231, 1)",
              "text-color": "#FFFFFF",
              "text-halo-color": "#000000",
              "text-halo-width": 1,
            }}
          />
        </Source>
      )}
    </>
  );
};

export { DimensionsLayer };
