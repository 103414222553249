import styled from "styled-components";

const LiveReportMapboxMapWrap = styled.section`
  height: 100%;
  width: 100%;
  padding: 0px 4px;
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "map-header"
    "map-area";

  .map-title {
    grid-area: map-header;

    h3 {
      margin: 0px;
    }
  }

  .map-area {
    grid-area: map-area;
  }

  .map-toggles {
    grid-area: map-header;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h3 {
      margin: 0px;
    }

    .map-toggle-buttons {
      display: flex;
      align-items: center;

      .toggle {
        display: flex;
        align-items: center;
        margin-right: 5px;
        p {
          margin: 0px 3px;
          font-size: ${(props) => (props.small ? "11px" : "unset")};
        }
      }

      .switch {
        position: relative;
        display: inline-block;
        width: ${(props) => (props.small ? "34px" : "38px")};
        height: ${(props) => (props.small ? "18px" : "22px")};
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: ${(props) => (props.small ? "12px" : "15px")};
        width: ${(props) => (props.small ? "12px" : "15px")};
        left: 4px;
        bottom: ${(props) => (props.small ? "3px" : "4px")};
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: var(--primary-brand-color);
      }

      input:focus + .slider {
        box-shadow: 0 0 1px var(--primary-brand-color);
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }
`;

export { LiveReportMapboxMapWrap };
