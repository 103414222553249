import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl"; // Import Mapbox components
import { delete_polygon, search_map, stree_view } from "../../../assets/images";
import { inputsActions, usePrevious } from "../../Redux";
import { Popconfirm, Tooltip } from "antd";
import { ToolbarWrap } from "./styles";
import { DrawPolygon, SearchLocations, PolygonTools, MapboxExportMenu } from "./";
import { MapboxLayerMenu } from "./MapboxLayerMenu";

const MapboxToolbar = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();

  // Store
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const searchVisible = useSelector((state) => state.inputs.uiState.search_visible);
  const isDrawing = useSelector((state) => state.inputs.uiState.is_drawing);
  const isDrawingPolyline = useSelector((state) => state.inputs.uiState.is_drawing_polyline);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);
  const prevSelectedId = usePrevious(selectedFeatureId);
  const additional_layers = useSelector((state) => state.inputs.uiState.additional_layers);

  // Use ref to keep track of additional_layers state
  const additional_layers_ref = useRef(additional_layers);

  useEffect(() => {
    additional_layers_ref.current = additional_layers; // Keep ref updated with latest state
  }, [additional_layers]);

  // State
  const [expand, setExpand] = useState(false);
  const [expandMapLayers, setExpandMapLayers] = useState(false);
  const [expandPolygonTools, setExpandPolygonTools] = useState(false);
  const [expandExportTools, setExpandExportTools] = useState(false);
  const [expandHeight, setExpandHeight] = useState(40); // Initialize with a default height

  // Refs
  const expandMapLayersRef = useRef(expandMapLayers);
  const expandExportToolsRef = useRef(expandExportTools);

  // Update refs when state changes
  useEffect(() => {
    expandMapLayersRef.current = expandMapLayers;
  }, [expandMapLayers]);

  useEffect(() => {
    expandExportToolsRef.current = expandExportTools;
  }, [expandExportTools]);

  // Close expanded menus when the map is clicked
  useEffect(() => {
    if (map) {
      const handleClick = () => {
        setExpandMapLayers(false);
        setExpandExportTools(false);
        if (searchVisible) {
          handleToggleSearch();
        }
      };
      map.on("click", handleClick);
      return () => map.off("click", handleClick);
    }
  }, [map, searchVisible]);

  useEffect(() => {
    if (selectedFeatureId) {
      handleTogglePolygonTools(true);
    } else {
      handleTogglePolygonTools(false);
    }
  }, [selectedFeatureId]);

  // Handle toggling functions
  const toggleExpandState = useCallback((setExpandFn, stateValue) => {
    const newHeight = stateValue ? 40 : 435; // Set height based on expanded state
    setExpandFn(!stateValue);
    setExpandHeight(newHeight); // Ensure expandHeight is updated correctly
  }, []);

  const handleToggleSearch = useCallback(() => {
    dispatch(inputsActions.update_ui_state("search_visible", !searchVisible));
    if (!searchVisible) resetExpandedStates();
  }, [searchVisible]);

  const handleToggleMapLayers = useCallback(() => {
    toggleExpandState(setExpandMapLayers, expandMapLayers);
    if (expandMapLayers) resetExpandedStates();
    if (searchVisible) dispatch(inputsActions.update_ui_state("search_visible", false));
    if (!expandPolygonTools) setExpandPolygonTools(false);
  }, [expandMapLayers, searchVisible]);

  const handleTogglePolygonTools = useCallback((toggled) => {
    setExpandPolygonTools(toggled);
    setExpandHeight(40);
    if (!toggled) resetExpandedStates();
  }, []);

  const handleToggleExportTools = useCallback(() => {
    toggleExpandState(setExpandExportTools, expandExportTools);
    resetExpandedStates();
  }, [expandExportTools]);

  const resetExpandedStates = useCallback(() => {
    setExpandMapLayers(false);
    setExpandPolygonTools(false);
    setExpandExportTools(false);
    setExpandHeight(40);
    if (isDrawing) map.fire("cancelDraw");
    if (isDrawingPolyline) dispatch(inputsActions.update_ui_state("is_drawing_polyline", false));
  }, [isDrawing, isDrawingPolyline]);

  // Update Mapbox based on selected feature
  useEffect(() => {
    if (selectedFeatureId) {
      handleTogglePolygonTools(true);
      if (searchVisible) dispatch(inputsActions.update_ui_state("search_visible", false));
    } else {
      handleTogglePolygonTools(false);
    }
    if (prevSelectedId !== selectedFeatureId) setExpandHeight(40);
  }, [selectedFeatureId, prevSelectedId]);

  // Update toolbar based on polyline drawing state
  useEffect(() => {
    if (isDrawingPolyline) {
      resetExpandedStates();
    }
  }, [isDrawingPolyline]);

  const deleteAll = () => {
    map.fire("deleteAllFeatures");
  };

  return (
    <ToolbarWrap expandHeight={expandHeight} expand={searchVisible || expandMapLayers}>
      <section className="map-action-buttons">
        <Tooltip placement="left" title="Search" mouseEnterDelay={0.5}>
          <button className={searchVisible ? "button-active" : ""} onClick={handleToggleSearch}>
            <img draggable={false} src={search_map} />
          </button>
        </Tooltip>
        <Tooltip placement="bottom" title="Change Basemap" mouseEnterDelay={0.5}>
          <button className={expandMapLayers ? "button-active" : ""} onClick={handleToggleMapLayers}>
            <img draggable={false} src={stree_view} />
          </button>
        </Tooltip>
        <DrawPolygon />
        <Tooltip placement="bottom" title="Delete All" mouseEnterDelay={0.5}>
          <Popconfirm title="Are you sure?" onConfirm={deleteAll}>
            <button disabled={generated}>
              <img draggable={false} src={delete_polygon} />
            </button>
          </Popconfirm>
        </Tooltip>
        <MapboxExportMenu />
      </section>

      <section className={searchVisible || expandMapLayers || expandPolygonTools || expandExportTools ? "expand-down" : "map-action-tray"}>
        {searchVisible && <SearchLocations />}
        {expandMapLayers && <MapboxLayerMenu additional_layers={additional_layers_ref.current} />} {/* Pass updated additional_layers */}
        {selectedFeatureId && expandPolygonTools && !searchVisible && <PolygonTools id={selectedFeatureId} />}
      </section>
    </ToolbarWrap>
  );
};

export { MapboxToolbar };
