import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";

import { canopyActions, usePrevious, getCenterPointofFeature } from "../../../Redux";
import { build_shade_object, getCornersOfCircle } from "../ShadeHelpers";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";

const DrawTree = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  const drawing_tree = useSelector((state) => state.canopy.drawing_tree);
  const prev_drawing_tree = usePrevious(drawing_tree);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const drawing_tree_ref = useRef(drawing_tree);
  const cancel_key = useKey("escape");

  useEffect(() => {
    if (cancel_key && drawing_tree) {
      cancelDraw();
    }
  }, [cancel_key, drawing_tree]);

  useEffect(() => {
    drawing_tree_ref.current = drawing_tree;
    if (!prev_drawing_tree && drawing_tree) {
      drawTree();
    } else if (prev_drawing_tree && !drawing_tree) {
      cancelDraw();
    }
  }, [drawing_tree]);

  useEffect(() => {
    if (!map || !draw) return;

    map.on("draw.create", handleCreated);

    return () => {
      map.off("draw.create", handleCreated);
    };
  }, [map, draw]);

  const handleCreated = (e) => {
    if (!draw) return;
    if (!drawing_tree_ref.current) return;
    // console.log("creating a tree", e);
    const geoJson = e.features[0];
    const center = getCenterPointofFeature(geoJson);
    const corners = getCornersOfCircle(geoJson);
    let shade_object = build_shade_object(geoJson, corners, center, shade_height_unit, "tree");
    dispatch(canopyActions.updateShadeObject("add_shade_object", shade_object));
    draw.delete(e.features[0].id);
  };

  const drawTree = () => {
    draw.changeMode("draw_circle");
  };

  const cancelDraw = () => {
    draw.changeMode("simple_select");
    dispatch(canopyActions.update_canopy_toolbar("drawing_tree", false));
  };

  return null;
};

export { DrawTree };
