import styled, { css } from "styled-components";

const MapboxContextMenuStyles = css`
  .context-menu {
    position: absolute;
    display: none;
    background: white;
    border: 1px solid #ccc;
    box-shadow: var(--subtle-box-shadow);
    z-index: 1000;
    border-radius: 2px;
  }

  .context-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 2px;
  }

  .context-menu li {
    padding: 4px 12px;
    cursor: pointer;
    position: relative; /* Ensure the submenu is positioned relative to the parent item */
  }

  .context-menu li:hover {
    background: #f0f0f0;
  }

  /* Adding the arrow for items with a submenu */
  .context-menu .has-submenu::after {
    content: "►"; /* Arrow character */
    font-size: 0.8em;
    position: absolute;
    right: 10px; /* Position the arrow on the right */
    top: 50%; /* Align it vertically */
    transform: translateY(-50%); /* Adjust for perfect vertical centering */
    color: #555; /* Arrow color */
  }

  .context-menu .has-submenu > ul {
    display: none;
    position: absolute;
    left: 100%; /* Align the submenu to the right of the parent item */
    top: 0; /* Align the submenu at the top of the parent item */
    background: #fff;
    white-space: nowrap;
    z-index: 1001;
  }

  .context-menu .has-submenu:hover > ul {
    display: block;
  }
`;

export { MapboxContextMenuStyles };
