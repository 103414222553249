import React, { useState, useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import { SearchLocationWrap } from "./styles";

let token = "pk.eyJ1IjoibnNhbmRlcnNvbngiLCJhIjoiY2x1c2I0aW43MDEyNDJqbnVoazNzZnhnMSJ9.gkvHjLK-5PZAJp8x5t52CQ";

const LocationSearch = () => {
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) return;

    const geocoder = new MapboxGeocoder({
      accessToken: token,
      mapboxgl: mapboxgl,
    });

    map.addControl(geocoder, "top-left");

    return () => {
      map.removeControl(geocoder);
    };
  }, [map]);

  //   return <SearchLocationWrap className={"locationSearch"} />;
};

export { LocationSearch };
