import { create_UUID } from "../../../Redux";

import * as turf from "@turf/turf";

export const build_shade_object = (geoJson, corners, center, shade_height_unit, shape) => {
  let shade_object = {
    id: create_UUID(),
    geoJson: geoJson,
    corners,
    center,
    height: shade_height_unit === "m" ? 8 : metersToFeet(8),
    obj_type: "shade_obj",
    shape,
    type: shape,
    checked: true, //this is for the map manager
  };

  return shade_object;
};

export const getCornersOfCircle = (feature) => {
  const center = turf.getCoords(feature)[0][0];
  const radius = feature.properties.circleRadius;

  const angles = [0, 90, 180, 270];

  const radiusMeters = radius * 1000;

  const calculateCoordinate = (center, radiusMeters, angle) => {
    const point = turf.destination(center, radiusMeters, angle, { units: "meters" });
    return turf.getCoords(point);
  };

  const north = calculateCoordinate(center, radiusMeters, 0);
  const east = calculateCoordinate(center, radiusMeters, 90);
  const south = calculateCoordinate(center, radiusMeters, 180);
  const west = calculateCoordinate(center, radiusMeters, 270);

  return [north, east, south, west];
};

export const getCornersOfPolygon = (feature) => {
  const bbox = turf.bbox(feature);

  const [minLng, minLat, maxLng, maxLat] = bbox;

  const northWest = [minLng, maxLat];
  const northEast = [maxLng, maxLat];
  const southEast = [maxLng, minLat];
  const southWest = [minLng, minLat];

  const northWestPoint = turf.point(northWest);
  const northEastPoint = turf.point(northEast);
  const southEastPoint = turf.point(southEast);
  const southWestPoint = turf.point(southWest);

  return [northWest, northEast, southEast, southWest];

  return [northWestPoint, northEastPoint, southEastPoint, southWestPoint];
};

export const getCorners = (layer) => {
  const corners = layer.getBounds();

  const northwest = corners.getNorthWest();
  const northeast = corners.getNorthEast();
  const southeast = corners.getSouthEast();
  const southwest = corners.getSouthWest();

  return [northwest, northeast, southeast, southwest];
};

export const flipGeoJson = (geoJson) => {
  return turf.flip(geoJson);
};

export const flipCorners = (geoJson) => {
  // console.log("flip corners", turf.flip(geoJson));
  return turf.flip(geoJson);
};

export const collect_shade_inputs = (canopies = undefined, shade_objects, shade_options) => {
  // console.log("collect_shade_inputs", inputs);

  let objects = {};
  let modules = {};

  Object.values(shade_objects).map((obj) => {
    objects[obj.id] = {
      corners:
        obj.type == "tree"
          ? [
              [obj.corners[0].lat, obj.corners[0].lng],
              [obj.corners[1].lat, obj.corners[1].lng],
              [obj.corners[2].lat, obj.corners[2].lng],
              [obj.corners[3].lat, obj.corners[3].lng],
            ]
          : obj.type == "treeLine"
          ? obj.geoJson.geometry.coordinates
          : obj.geoJson.geometry.coordinates[0],
      //   corners: obj.corners,
      height: shade_options.shade_height_unit == "m" ? obj.height : feetToMeters(obj.height), // convert back to meters if user was using ft
      rotation: obj.rotation || 0,
      type: obj.type,
    };
  });

  let shade_inputs;

  if (shade_options.type == "canopy" && canopies) {
    Object.values(canopies).map((obj) => {
      modules[obj.id] = {
        moduleCorners: obj.editCellsGeoJson.map((module) => flipCorners(module).geometry.coordinates[0]),
        canopyCorners: flipCorners(canopies[obj.id].geoJson).geometry.coordinates[0],
        height: 1.5, // meters
        tilt: canopies[obj.id].tilt,
        rotation: canopies[obj.id].rotation,
      };
    });

    shade_inputs = {
      objects,
      modules,
      ...shade_options,
    };
  } else {
    shade_inputs = {
      objects,
      modules: {},
      ...shade_options,
    };
  }

  console.log("shade", shade_inputs);

  return shade_inputs;
};

// export const createShadeGeoJson = (coords) => {
//   let temp_shade;
//   if (coords.length > 1) {
//     let temp_union = [];
//     coords.map((coord) => {
//       if (coord[0][0] !== coord[3][0] || coord[0][1] !== coord[3][1]) {
//         coord.push(coord[0]);
//       }
//       temp_union.push(turf.polygon([coord]));
//     });
//     temp_shade = turf.union(...temp_union);
//   } else {
//     if (coords[0][0][0] !== coords[0][3][0] || coords[0][0][1] !== coords[0][3][1]) {
//       coords[0].push(coords[0][0]);
//     }
//     temp_shade = turf.polygon(coords);
//   }
//   temp_shade.properties.index = create_UUID();
//   return turf.flip(temp_shade);
// };

export const createShadeGeoJson = (coords) => {
  let temp_shade;

  coords = coords.map((coord) => {
    if (coord[0][0] !== coord[coord.length - 1][0] || coord[0][1] !== coord[coord.length - 1][1]) {
      coord.push(coord[0]);
    }
    return coord;
  });

  if (coords.length > 1) {
    const temp_union = coords.map((coord) => turf.polygon([coord]));

    temp_shade = turf.union(...temp_union);
  } else {
    temp_shade = turf.polygon([coords[0]]);
  }

  temp_shade.properties.index = create_UUID();
  return temp_shade;
  // return turf.flip(temp_shade);
};

export const feetToMeters = (feet) => {
  return parseFloat((feet * 0.3048).toFixed(2));
};

export const metersToFeet = (meters) => {
  return parseFloat((meters / 0.3048).toFixed(2));
};
