import React, { useMemo, useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";
import { useSelector, useDispatch } from "react-redux";
import { getCornersOfPolygon, getCorners } from "../ShadeHelpers";

import { canopyActions, getCenterOfLineString } from "../../../Redux";

const TreeLineEventLayer = ({ treeline, interactive = true }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  // const treeline = useSelector((state) => state.canopy.shade_objects[id]);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const selected_shade_object_id_ref = useRef(selected_shade_object_id);

  useEffect(() => {
    selected_shade_object_id_ref.current = selected_shade_object_id;
  }, [selected_shade_object_id]);

  const memoizedTreeline = useMemo(() => treeline, [treeline]);

  useEffect(() => {
    if (!map || !draw || !memoizedTreeline) return;
    // console.log("the memorized treeline:", memoizedTreeline);

    const memoizedTreelineWithId = {
      ...memoizedTreeline.geoJson,
      id: memoizedTreeline.id, // add our custom id so that we can delete/highlight etc.
      properties: {
        ...memoizedTreeline.geoJson.properties,
        shape: "treeline",
      },
    };

    draw.add(memoizedTreelineWithId);

    map.once("idle", () => {
      selectFeatureOnMap(selected_shade_object_id_ref.current);
    });

    map.on("draw.update", handleUpdate);
    map.on("draw.delete", handleDelete);
    return () => {
      draw.delete(memoizedTreeline.id);
      map.off("draw.update", handleUpdate);
      map.off("draw.delete", handleDelete);
    };
  }, [map, memoizedTreeline]);

  const selectFeatureOnMap = (id) => {
    if (!id) return;
    draw.changeMode("direct_select", { featureId: id });
  };

  const handleDelete = (e) => {
    if (e.features[0].id === treeline.id) {
      // console.log("Deleted feature:", e.features[0].id);
      dispatch(canopyActions.updateShadeObject("delete_shade_object", treeline.id));
      draw.delete(e.features[0].id);
    }
  };

  const handleUpdate = (e) => {
    if (selected_shade_object_id_ref.current !== treeline.id) return;
    // console.log("updating treeline", e.features[0]);
    let edited_treeline = { ...treeline };
    edited_treeline.geoJson = e.features[0];
    edited_treeline.center = getCenterOfLineString(edited_treeline.geoJson);
    edited_treeline.corners = e.features[0].geometry.coordinates[0];
    edited_treeline.rotation = 0;
    dispatch(canopyActions.updateShadeObject("edit_shade_object", edited_treeline));
    draw.delete(e.features[0].id);
  };

  return null;
};

export { TreeLineEventLayer };
