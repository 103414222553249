import React from "react";
import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState, useCallback, useReducer } from "react";
import { useDispatch } from "react-redux";
import { AuthModal } from "./AuthModal_NEW";
import { alertActions } from "../Redux";
import useIsTabActive from "../_hooks/useIsTabActive";
import VersionCheck from "./VersionCheck";

import config from "config";
import jwtDecode from "jwt-decode";

// Auth Events
  // auth.login
  // auth.register
  // auth.refreshToken

const AuthContext = createContext();

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
api.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("user"); // Assuming you store the token in localStorage
    const token = user ? JSON.parse(user).access.token : null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AuthProvider = ({ children }) => {
  const isTabActive = useIsTabActive();
  const dispatch = useDispatch();
  const show_auth_accoring_to_path_name = window.location.pathname == "/" || window.location.pathname == "/sift" || window.location.pathname == "/canopy" || window.location.pathname == "/ground";
  const [user, setUser_] = useState(JSON.parse(localStorage.getItem("user")));
  const [is_authenticated, setIsAuthenticated_] = useState(false);

  // Trigger for when user changes
  useEffect(() => {
    let _user = typeof user == "string" ? JSON.parse(user) : JSON.parse(JSON.stringify(user));
    if (user?.access?.exp) {
      setIsAuthenticated();
      // localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  const setUser = (newUser) => {
    setUser_(newUser);
  };

  // useEffect(() => {
  //   console.log('isTabActive', isTabActive)
  // }, [isTabActive]);

  const setIsAuthenticated = async () => {
    if (user.access && user.access.exp) {
      // console.log(`Current time left on access ${1000* user.access.exp - (new Date()).getTime()}`)
      // return false when user expires in less than 10m
      let is_valid_token = 1000 * user.access.exp - new Date().getTime() > 600000;
      // console.log('is_valid_token', is_valid_token)
      setIsAuthenticated_(is_valid_token);
      if (is_valid_token) {
        localStorage.setItem("prev_logged_in", true);
      }
    } else {
      setIsAuthenticated_(false);
      localStorage.setItem("prev_logged_in", false);
    }
  };

  const useApi = () => {
    const axApi = api;
    // Register function
    const register = useCallback(async (userData) => {
      try {
        // backend request to register as a new user
        const response = await api.post("/rest-auth/registration/", userData);
        dispatch(alertActions.success("Registration successful"));
        await login(userData.email, userData.password1);

        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    }, []);

    // Login function
    const login = useCallback(async (email, pw) => {
      try {
        let authUrl = "/api/auth/token/obtain/";
        if (config.platform == "internal_sift") {
          authUrl = "/platform/rbi/";
        }
        // backend request -> (refresh, access, terrasmart_terms, admin, role, hash, email, user_id)
        const response = await api.post(authUrl, { email, password: pw });
        // create user object
        let user_data = {
          ...response.data.data,
          access: {
            token: response.data.data.access,
            ...jwtDecode(response.data.data.access),
          },
          refresh: {
            token: response.data.data.refresh,
            ...jwtDecode(response.data.data.refresh),
          },
        };
        localStorage.setItem("user", JSON.stringify(user_data));
        setUser(user_data);
        // return to callback
        return user_data;
      } catch (error) {
        throw error.response.data;
      }
    }, []);

    // Refresh token function
    const getTokenRefresh = useCallback(async (refreshToken) => {
      try {
        // backend request to refresh our access token with our refresh token
        const response = await api.post("/token", { refreshToken });
        // console.log(response);
        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    }, []);

    const get_library = useCallback(async () => {
      try {
        const response = await api.get("/user/library/");
        // console.log(response.data)
        const json_data = await downloadFromS3(response.data.data.url);
        // console.log(json_data)

        let user_data = JSON.parse(localStorage.getItem("userInfo"));
        if (user_data) {
          user_data.plan_id = json_data.user_data.product_plan;
          localStorage.setItem("userInfo", JSON.stringify(user_data));
        }

        dispatch({ type: "GOT_PROJECT_DATA", projects: json_data.projects });
        dispatch({ type: "GET_USER_LIB_COMP", user_data: json_data.user_data });

        return response.data;
      } catch (error) {
        throw error.response.data;
      }
    }, []);

    async function downloadFromS3(url) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "attachment" },
      };
      let response = await fetch(url, requestOptions);
      return response.text().then((text) => {
        return JSON.parse(text);
      });
    }

    return { axApi, register, login, getTokenRefresh, get_library };
  };

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      user,
      is_authenticated,
      internal_sift: config.platform == "internal_sift",
      setUser,
      setIsAuthenticated,
      useApi,
    }),
    [user, is_authenticated]
  );

  // Provide the authentication context to the children components
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
      {!is_authenticated && show_auth_accoring_to_path_name && <AuthModal />}
      {is_authenticated && <VersionCheck />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
