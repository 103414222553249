let MapboxDrawStyles = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_identity"], 0],
        "#ffffff", // white
        ["==", ["get", "user_identity"], 1],
        "#ff0000", // red
        ["==", ["get", "user_identity"], 2],
        "#ffff00", // yellow
        "#ffffff", // default to black
      ],
      // "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  // polygon fills
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": ["case", ["==", ["get", "user_highlight"], true], "#002bcb", "transparent"],
      "fill-opacity": ["case", ["==", ["get", "user_highlight"], true], 0.5, 0],
      // "fill-outline-color": "transparent",
      // "fill-color": "transparent",
      // "fill-opacity": 0,
      // "fill-color": [
      //   "case",
      //   ["==", ["get", "user_highlight"], true],
      //   "#002bcb", // If true, set to blue
      //   "#000000", // If false, set to transparent
      // ],
      // "fill-opacity": [
      //   "case",
      //   ["==", ["get", "user_highlight"], true],
      //   0.5, // If true, set opacity to 0.5
      //   0, // If false, set opacity to 0
      // ],
    },
  },
  {
    id: "mapbox-gl-draw-cold",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"]],
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_identity"], 0],
        "#ffffff", // white
        ["==", ["get", "user_identity"], 1],
        "#ff0000", // red
        ["==", ["get", "user_identity"], 2],
        "#ffff00", // yellow
        "#ffffff", // default to black
      ],
      "line-width": 2,
    },
  },
  {
    id: "mapbox-gl-draw-hot",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"]],
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_identity"], 0],
        "#ffffff", // white
        ["==", ["get", "user_identity"], 1],
        "#ff0000", // red
        ["==", ["get", "user_identity"], 2],
        "#ffff00", // yellow
        "#ffffff", // default to black
      ],
    },
  },

  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "user_identity"], 0],
        "#ffffff", // white
        ["==", ["get", "user_identity"], 1],
        "#ff0000", // red
        ["==", ["get", "user_identity"], 2],
        "#ffff00", // yellow
        "#ffffff", // default to black
      ],
      "line-width": 2,
    },
  },
  // vertex point halos or the verticies handle circle borders
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#FFF",
    },
  },
  // the vertexessss
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"]],
    paint: {
      "circle-radius": 6,
      "circle-color": "#002bcb",
    },
  },
  // polygon mid points
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 6,
      "circle-color": "#fff",
      "circle-stroke-color": "#002bcb", // Outline color for the midpoint
      "circle-stroke-width": 2,
    },
  },
];

export { MapboxDrawStyles };
