import { rectangle } from "leaflet";
import { create_UUID, getCenterPointofFeature } from "../Redux";
import * as turf from "@turf/turf";

const createEditableLayout = (layout, selectedResultId) => {
  // convert the multipolygon to an object with each feature as it's own polygon
  let temp_layout = { ...JSON.parse(JSON.stringify(layout)) };
  temp_layout.inverter_groups[0]["racks"] = {};
  let temp_racks = {};
  if (Object.values(layout).length == 0) return;
  Object.values(layout.inverter_groups).map((group, index) => {
    Object.keys(group).map((key, index) => {
      if (key == "racks") {
        Object.values(layout.inverter_groups[0][key].geometry.coordinates[0]).map((feature, index) => {
          let id = create_UUID();
          let layout_poly = turf.flip(turf.polygon([feature]));
          layout_poly.properties = {
            index: id,
            org_index: index, // used for restoring racks
            hidden: 0, // 0 == false, 1 == true
            rack_size: undefined,
            alignment: 1, // 1 = top, 2 = middle, 3 = bottom
            precision: 9,
            type: "Feature",
            weight: 2,
            color: "#33A2FF",
            fillColor: "none",
            name: `Rack ${index}`,
            center: getCenterPointofFeature(layout_poly.geometry.coordinates),
          };
          temp_racks[id] = layout_poly;
        });
      }
    });
  });

  const temp_racks_with_rows = create_row_polygons(temp_racks);
  temp_layout["row_boxes"] = temp_racks_with_rows;
  temp_layout.inverter_groups[0]["racks"] = temp_racks;
  temp_layout["id"] = selectedResultId;
  return temp_layout;
};

const formatEditableLayout = (layout, selectedResultId, properties) => {
  // convert the multipolygon to an object with each feature as it's own polygon

  let temp_layout = { ...JSON.parse(JSON.stringify(layout)) };
  if (Object.values(layout).length == 0) return;
  temp_layout.disabled_cells.map((cell) => {
    cell["enabled"] = false;
  });
  let combined_racks = [...temp_layout.disabled_cells, ...layout.inverter_groups[0].combiner_groups[0]["rack_groups"]];
  temp_layout.inverter_groups[0].combiner_groups[0]["rack_groups"] = {};
  let temp_racks = {};
  combined_racks.map((feature, index) => {
    let layout_poly = turf.flip(turf.polygon([feature.rack]));
    feature["geoJson"] = layout_poly;
    feature["orgGeoJson"] = layout_poly;
    feature["resized"] = false;
    feature["track_mode"] = properties.track_mode;
    feature["racks"] = properties.rack_sizes;
    feature["org_index"] = index; // used for restoring racks
    feature["rack_size"] = compareRectangleWithPreset(layout_poly, layout.rack_dims_latlng);
    feature["alignment"] = properties.track_mode == 0 ? 2 : 3; // 1 = top/left, 2 = middle/center, 3 = bottom/right
    feature["name"] = `Rack ${index}`;
    feature["center"] = getCenterPointofFeature(layout_poly.geometry.coordinates);
    temp_racks[feature.index] = feature;
  });

  const temp_racks_with_rows = create_row_polygons(temp_racks, properties.track_mode);
  // console.log("temp", temp_racks_with_rows);
  temp_layout.inverter_groups[0].combiner_groups[0]["rack_groups"] = temp_racks_with_rows.racks;
  temp_layout["row_boxes"] = temp_racks_with_rows.boundingBoxes;
  temp_layout["id"] = selectedResultId;
  temp_layout["edited"] = false;
  temp_layout["inverter_grouping_off"] = properties.inverter_grouping_off;
  temp_layout["layout_MWp_limit"] = properties.layout_MWp_limit;
  temp_layout["do_rack_align"] = properties.do_rack_align;

  return temp_layout;
};

const create_row_polygons = (racks, track_mode) => {
  const groupedPolygons = [];

  Object.values(racks).forEach((rack) => {
    const key = track_mode == 0 ? rack.geoJson.geometry.coordinates[0][3][0].toFixed(4) : rack.geoJson.geometry.coordinates[0][0][1].toFixed(4);

    // Group polygons by origin point lat
    if (groupedPolygons[key]) {
      groupedPolygons[key].push(rack.geoJson);
    } else {
      groupedPolygons[key] = [rack.geoJson];
    }
  });

  const boundingBoxes = {};

  Object.values(groupedPolygons).forEach((group, index) => {
    let id = create_UUID();
    // Combine all polygons in the group into a single feature collection
    const featureCollection = turf.featureCollection(group);
    // Calculate the bounding box around the feature collection
    const bbox = turf.bbox(featureCollection);
    // create some padding to the left and right of the bounding box
    const expandedBbox = [bbox[0] - expandUsingFeet(1), bbox[1] - expandUsingFeet(1), bbox[2] + expandUsingFeet(1), bbox[3] + expandUsingFeet(1)];

    const bbox_poly = turf.bboxPolygon(expandedBbox);
    bbox_poly.properties["index"] = id;
    bbox_poly.properties["group"] = index;
    boundingBoxes[id] = bbox_poly;

    Object.values(racks).map((rack) => {
      if (turf.booleanContains(bbox_poly, rack.geoJson)) {
        rack["group"] = index;
      }
    });
  });

  return { boundingBoxes, racks };
};

const expandUsingFeet = (feet) => {
  const feetToKm = 0.0003048; // Conversion factor from feet to kilometers
  const kmToDegrees = 1 / 111; // Conversion factor from kilometers to degrees of latitude

  const expansionInDegrees = feet * feetToKm * kmToDegrees;

  return expansionInDegrees;
};

const latitudeToMeters = (latitude) => {
  const equatorCircumference = 40075000; // in meters
  const metersPerDegreeAtEquator = equatorCircumference / 360;

  return latitude * metersPerDegreeAtEquator;
};

const longitudeToMeters = (longitude, latitude) => {
  const equatorCircumference = 40075000; // in meters
  const metersPerDegreeAtEquator = equatorCircumference / 360;
  const metersPerDegreeAtLatitude = metersPerDegreeAtEquator * Math.cos((latitude * Math.PI) / 180);

  return longitude * metersPerDegreeAtLatitude;
};

const changeRackSize = (rack, dimensions, selected_dim, row_box) => {
  let temp_rack = { ...JSON.parse(JSON.stringify(rack)) };

  let origin_lng = temp_rack.resized ? temp_rack.geoJson.geometry.coordinates[0][0][0] : temp_rack.geoJson.geometry.coordinates[0][0][0] - dimensions[1];
  let origin_lat = temp_rack.geoJson.geometry.coordinates[0][0][1];
  console.log("orig", temp_rack.geoJson);
  const originalBearing =
    temp_rack.track_mode == 0 ? 0 : turf.bearing(turf.point([origin_lng, origin_lat]), turf.point([temp_rack.geoJson.geometry.coordinates[0][1][0], temp_rack.geoJson.geometry.coordinates[0][1][1]]));

  const bottomLeft = [origin_lng, origin_lat];
  const topLeft = [bottomLeft[0], bottomLeft[1] + dimensions[0]];
  const topRight = [bottomLeft[0] + dimensions[1], bottomLeft[1] + dimensions[0]];
  const bottomRight = [bottomLeft[0] + dimensions[1], bottomLeft[1]];

  let polygon;

  if (temp_rack.track_mode == 0) {
    //GFT coordinate order is different for some reason. I'm sure I am not flipping coords or something somewhere during size change. This makes sure all coords stay...coorindated so that we can move them and change their sizes.
    polygon = turf.polygon([[bottomRight, topRight, topLeft, bottomLeft, bottomRight]]);
  } else {
    polygon = turf.polygon([[bottomLeft, topLeft, topRight, bottomRight, bottomLeft]]);
  }
  console.log("after", polygon);

  temp_rack.geoJson = temp_rack.track_mode == 0 ? turf.transformRotate(polygon, originalBearing) : polygon;
  temp_rack.rack_size = selected_dim;
  temp_rack.resized = true;
  // this makes sure the rack alignment is persistant after adjusting the size
  let rack_adjusted_alignment = changeRackAlignment(temp_rack, row_box, temp_rack.alignment);

  return rack_adjusted_alignment;
};

// const changeRackSize = (rack, dimensions, selected_dim, row_box) => {
//   let temp_rack = { ...JSON.parse(JSON.stringify(rack)) };

//   let origin_lng = temp_rack.resized ? temp_rack.geoJson.geometry.coordinates[0][0][0] : temp_rack.geoJson.geometry.coordinates[0][0][0] - dimensions[1];
//   let origin_lat = temp_rack.geoJson.geometry.coordinates[0][0][1];

//   const originalBearing = turf.bearing(turf.point([origin_lng, origin_lat]), turf.point([temp_rack.geoJson.geometry.coordinates[0][1][0], temp_rack.geoJson.geometry.coordinates[0][1][1]]));

//   // Calculate the new corner points based on the original bearing
//   const bottomLeft = [origin_lng, origin_lat];
//   const topLeft = turf.destination(turf.point(bottomLeft), dimensions[0], originalBearing + 90, { units: "meters" }).geometry.coordinates;
//   const topRight = turf.destination(turf.point(topLeft), dimensions[1], originalBearing, { units: "meters" }).geometry.coordinates;
//   const bottomRight = turf.destination(turf.point(bottomLeft), dimensions[1], originalBearing, { units: "meters" }).geometry.coordinates;

//   let polygon;
//   if (temp_rack.track_mode == 0) {
//     polygon = turf.polygon([[bottomRight, topRight, topLeft, bottomLeft, bottomRight]]);
//   } else {
//     polygon = turf.polygon([[bottomLeft, topLeft, topRight, bottomRight, bottomLeft]]);
//   }

//   temp_rack.geoJson = polygon;
//   temp_rack.rack_size = selected_dim;
//   temp_rack.resized = true;

//   // Keep the rack alignment after resizing
//   let rack_adjusted_alignment = changeRackAlignment(temp_rack, row_box, temp_rack.alignment);

//   return rack_adjusted_alignment;
// };

const changeRackAlignment = (rack, row_box, alignment = 2) => {
  let temp_rack = { ...JSON.parse(JSON.stringify(rack)) };

  if (!row_box) {
    return temp_rack;
  }

  let offset;
  let point_1;
  let point_2;
  let direction_in_degrees;

  let options = {
    units: "meters",
  };

  if (alignment == 1) {
    if (rack.track_mode == 0) {
      point_2 = turf.point([row_box[0].geometry.coordinates[0][0][0], temp_rack.geoJson.geometry.coordinates[0][3][1]]);
      point_1 = turf.point([temp_rack.geoJson.geometry.coordinates[0][3][0] + expandUsingFeet(1), temp_rack.geoJson.geometry.coordinates[0][3][1]]);
      direction_in_degrees = point_2.geometry.coordinates[0] < point_1.geometry.coordinates[0] ? 270 : 90;
    } else {
      point_2 = turf.point([temp_rack.geoJson.geometry.coordinates[0][2][0], row_box[0].geometry.coordinates[0][2][1]]);
      point_1 = turf.point([temp_rack.geoJson.geometry.coordinates[0][2][0], temp_rack.geoJson.geometry.coordinates[0][2][1] + expandUsingFeet(1)]);
      direction_in_degrees = point_2.geometry.coordinates[1] > point_1.geometry.coordinates[1] ? 0 : 180;
    }
    offset = turf.distance(point_2, point_1, options);
  } else if (alignment == 2) {
    const rackCentroid = turf.centroid(temp_rack.geoJson);
    const rowBoxCentroid = turf.centroid(row_box[0]);
    if (rack.track_mode == 0) {
      point_1 = turf.point([rackCentroid.geometry.coordinates[0], rackCentroid.geometry.coordinates[1]]);
      point_2 = turf.point([rowBoxCentroid.geometry.coordinates[0], rackCentroid.geometry.coordinates[1]]);

      direction_in_degrees = point_2.geometry.coordinates[0] < point_1.geometry.coordinates[0] ? 270 : 90;
    } else {
      point_1 = turf.point([rackCentroid.geometry.coordinates[0], rackCentroid.geometry.coordinates[1]]);
      point_2 = turf.point([rackCentroid.geometry.coordinates[0], rowBoxCentroid.geometry.coordinates[1]]);
      direction_in_degrees = point_2.geometry.coordinates[1] > point_1.geometry.coordinates[1] ? 0 : 180;
    }
    offset = turf.distance(point_1, point_2, options);
  } else if (alignment == 3) {
    if (rack.track_mode == 0) {
      point_1 = turf.point([temp_rack.geoJson.geometry.coordinates[0][0][0], temp_rack.geoJson.geometry.coordinates[0][0][1]]);
      point_2 = turf.point([row_box[0].geometry.coordinates[0][1][0] - expandUsingFeet(1), temp_rack.geoJson.geometry.coordinates[0][0][1]]);
      direction_in_degrees = point_2.geometry.coordinates[0] < point_1.geometry.coordinates[0] ? 270 : 90;
    } else {
      point_1 = turf.point([temp_rack.geoJson.geometry.coordinates[0][0][0], temp_rack.geoJson.geometry.coordinates[0][0][1] - expandUsingFeet(1)]);
      point_2 = turf.point([temp_rack.geoJson.geometry.coordinates[0][0][0], row_box[0].geometry.coordinates[0][0][1]]);
      direction_in_degrees = point_2.geometry.coordinates[1] > point_1.geometry.coordinates[1] ? 0 : 180;
    }
    offset = turf.distance(point_2, point_1, options);
  }

  const alignedRectangle = turf.transformTranslate(temp_rack.geoJson, offset, direction_in_degrees, options);

  temp_rack.geoJson = alignedRectangle;
  temp_rack.alignment = alignment;

  return temp_rack;
};

const swapRacks = (rack, disabled_rack) => {
  let temp_rack = { ...rack };
};

const correct_rack_spill = (rack, row_box, dimensions) => {
  let temp_rack = { ...JSON.parse(JSON.stringify(rack)) };

  let origin_lng = temp_rack.geoJson.geometry.coordinates[0][0][0];
  let origin_lat = row_box[0].geometry.coordinates[0][1][1] - expandUsingFeet(1);

  const topLeft = [origin_lng, origin_lat];
  const bottomLeft = [topLeft[0], topLeft[1] - dimensions[0]];
  const bottomRight = [topLeft[0] + topLeft[1], topLeft[1] - dimensions[0]];
  const topRight = [topLeft[0] + dimensions[1], topLeft[0]];

  const polygon = turf.polygon([[bottomLeft, topLeft, topRight, bottomRight, bottomLeft]]);

  return polygon;
};

const calculateRectangleDimensions = (rectangle) => {
  const bbox = turf.bbox(rectangle);
  const width = bbox[2] - bbox[0];
  const height = bbox[3] - bbox[1];
  return [height, width]; // [height, width] format
};

// Function to compare the dimensions of a rectangle with preset sizes
const compareRectangleWithPreset = (rectangle, presetSizes) => {
  const dimensions = calculateRectangleDimensions(rectangle);
  let closestSize = null;
  let closestDistance = Infinity;
  let size_index = null;

  presetSizes.forEach((presetSize, index) => {
    const distance = Math.abs(presetSize[0] - dimensions[0]) + Math.abs(presetSize[1] - dimensions[1]);
    if (distance < closestDistance) {
      size_index = index;
      closestSize = presetSize;
      closestDistance = distance;
    }
  });

  return size_index;
};

const createMultiPolygon = (racks) => {
  // Ensure racks array is not empty
  if (!racks || racks.length === 0) {
    return null;
  }

  let temp_multi = [];

  // Convert each GeoJSON polygon to a Turf.js feature
  Object.values(racks).map((rack) => rack.enabled && temp_multi.push(rack.geoJson.geometry.coordinates[0]));

  const multiPolygon = turf.flip(turf.multiPolygon([temp_multi]));
  return multiPolygon;
};

export { createEditableLayout, formatEditableLayout, changeRackSize, changeRackAlignment, createMultiPolygon, swapRacks };
