import React, { useState, useEffect } from "react";
import { useMap } from "react-map-gl";
import { useSelector, useDispatch } from "react-redux";
import { siftActions, create_UUID, usePrevious, execute_job, export_data, checkCoordSystemBounds } from "../../../Redux";
import { isValidPolygon } from "../../DrawToolProvider/_draw.helpers";

import { Dropdown, Tooltip } from "../../../BaseComponents";
import { export_map } from "../../../../assets/images";
import { createMultiPolygon } from "../../../LayoutEditor";
import { collect_shade_inputs } from "../../../EnvironmentalShade";
import * as turf from "@turf/turf";

const FileSaver = require("file-saver");
import tokml from "tokml";

const MapboxExportMenu = ({ expand_export_tools }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const dispatch = useDispatch();

  // this component is probably holding too much information and should send the requests to some root component that holds the data
  // const site_features = useSelector((state) => Object.values(state.inputs.site_features).filter((feature) => feature.geometry.type == "Polygon"));
  const site_features = useSelector((state) => state.inputs.site_features);

  const inputs = useSelector((state) => state.inputs);
  const shade_objects = useSelector((state) => state.canopy.shade_objects);
  const coord_system_bbox = useSelector((state) => state.inputs.coord_system_bbox);
  const layout = useSelector((state) => state.sift.ioManager.outputs.layout);
  const roads = useSelector((state) => state.sift.ioManager.outputs.layout.roads);
  const selectedResult = useSelector((state) => state.sift.ioManager.outputs.selectedResult);
  const selectedResultId = selectedResult?.id || undefined;
  const map_center = useSelector((state) => state.inputs.map_center);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);
  const edited_layouts = useSelector((state) => state.inputs?.edited_layouts);

  const current_plot = useSelector((state) => state.sift.ioManager.uiState.currentPlot);
  const topo_id = useSelector((state) => state.inputs.topo_id);
  const epsg = useSelector((state) => state.inputs.epsg);
  const file_name = useSelector((state) => state.sift.ioManager.report?.reportData?.file_name);
  const runId = useSelector((state) => state.sift.ioManager.outputs?.runState?.job_id);

  const exportKML = useSelector((state) => state.sift.ioManager.uiState.export_kml);
  const exportDXF = useSelector((state) => state.sift.ioManager.uiState.export_dxf);
  const exportPVSYST = useSelector((state) => state.sift.ioManager.uiState.export_pvsyst);

  const prev_exportKML = usePrevious(exportKML);
  const prev_exportDXF = usePrevious(exportDXF);
  const prev_exportPVSYST = usePrevious(exportPVSYST);

  const [loading_dxf, set_loading_dxf] = useState(false);

  useEffect(() => {
    if (!map) return;

    map.on("export_file", (e) => {
      if (e.type == "export_kmz") {
        export_kmz();
      } else if (e.type == "export_cad") {
        export_cad();
      }
    });

    return () => {
      map.off("export_file");
    };
  }, [map]);

  useEffect(() => {
    if (!prev_exportKML && exportKML) {
      export_kmz();
    }
  }, [exportKML]);

  useEffect(() => {
    if (!prev_exportDXF && exportDXF) {
      export_cad();
    }
  }, [exportDXF]);

  useEffect(() => {
    if (!prev_exportPVSYST && exportPVSYST) {
      export_kmz(true);
    }
  }, [exportPVSYST]);

  const export_kmz = (pvsyst = false) => {
    let features = [];
    let temp_file_name;

    if (file_name && pvsyst) {
      temp_file_name = `${file_name}.shd`;
    } else if (!file_name && pvsyst) {
      temp_file_name = "SIFT_Map.shd";
    } else if (file_name) {
      temp_file_name = `${file_name}.kml`;
    } else {
      temp_file_name = "SIFT_Map.kml";
    }

    let export_kmz_inputs;

    if (pvsyst) {
      export_kmz_inputs = {
        result_id: current_plot.id,
        meta: {
          job_type: "pvsyst-export",
          latitude: inputs.latitude,
          longitude: inputs.longitude,
          mod_width: inputs.mod_width,
          mod_length: inputs.mod_height,
          track_mode: inputs.track_mode,
          tracking_angle: inputs.track_mode == 1 ? selectedResult.rlim : selectedResult.track_mode == 0 ? selectedResult.tilt : selectedResult.tilt,
          azimuth: inputs.sazm,
          rack_grade_limit: inputs.rack_grade_limit,
          bi_groundClearanceHeight: inputs.bi_groundClearanceHeight,
          racking_name: `${inputs.racking_name};${inputs.mod_width};${inputs.mod_height}`,
          orientation: inputs.orientation,
          epsg: inputs.epsg,
          file_name: temp_file_name,
          // file_name: file_name && pvsyst ? `${file_name}.kml` : "SIFT_Map.kml",
        },
        export_type: "pvsyst-export",
        job_type: "export-job",
      };

      if (Object.values(shade_objects).length > 0) {
        let temp_shade_objects = collect_shade_inputs(undefined, shade_objects, map_center, shade_height_unit, "ground");
        export_kmz_inputs["objects"] = temp_shade_objects.objects;
      }
    } else {
      export_kmz_inputs = {
        boundaries: [],
        exclusions: [],
        inactive: [],
        roads: [],
        racks: [],
        inverters: [],
        meta: {
          job_type: "kmz-export",
          file_name: temp_file_name,
          latitude: inputs.latitude,
          longitude: inputs.longitude,
          mod_width: inputs.mod_width,
          mod_length: inputs.mod_height,
          track_mode: inputs.track_mode,
          tracking_angle: inputs.rlim,
          azimuth: inputs.sazm,
          rack_grade_limit: inputs.rack_grade_limit,
          bi_groundClearanceHeight: inputs.bi_groundClearanceHeight,
          // file_name: file_name && pvsyst ? `${file_name}.kml` : "SIFT_Map.kml",
        },
        export_type: "kmz-export",
        job_type: "export-job",
      };

      // BOUNDARY / EXCLUSION / INACTIVE
      Object.values(site_features).map((feature) => {
        if (!isValidPolygon(feature)) return;
        let poly = feature.geometry.type == "LineString" ? feature : turf.flip(turf.polygon([feature.geometry.coordinates[0]]));
        feature.properties["center"] = turf.getCoords(turf.centroid(poly));
        if (feature.properties.identity == 1) {
          export_kmz_inputs.boundaries.push(feature);
          feature.name = "Boundary";
          feature.properties["title"] = "Boundary";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "#ff0001";
        } else if (feature.properties.identity == 2) {
          export_kmz_inputs.exclusions.push(feature);
          feature.name = "Exclusion";
          feature.properties["title"] = "Exclusion";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "ffff03";
        } else if (feature.properties.identity == 0) {
          export_kmz_inputs.inactive.push(feature);
          feature.name = "Boundary-Unused";
          feature.properties["title"] = "Boundary-Unused";
          feature.properties["fill-opacity"] = 0.0;
          feature.properties["fill"] = "#000000";
          feature.properties["stroke-width"] = 3;
          feature.properties["stroke"] = "#ffffff";
        }
        // console.log("feature", feature);
        features.push(feature);
      });
      // LAYOUT

      if (Object.values(layout).length && selectedResult) {
        // console.log(selectedResult);
        export_kmz_inputs.meta.track_mode = selectedResult.track_mode;
        export_kmz_inputs.meta.height_above_ground = selectedResult.bi_groundClearanceHeight;
        export_kmz_inputs.meta.tilt = selectedResult.tilt;
        export_kmz_inputs.meta.tracking_angle = selectedResult.tilts;
        export_kmz_inputs.meta.orientation = selectedResult.orientation;
        export_kmz_inputs.meta.epsg = selectedResult.epsg;
        export_kmz_inputs.meta.topo_id = selectedResult.topo_id;

        Object.values(layout.inverter_groups).map((group, group_index) => {
          let group_color = group["wires"]["properties"]["color"];

          Object.keys(group).map((key, index) => {
            let indx = 0;
            if (key == "inverter" && layout.inverter_groups[0].inverter.geometry.coordinates[0].length > 0) {
              group[key].color = "#ee00ff";
              group[key].fillColor = "none";
              group[key].weight = 2;
              indx = 597;
              export_kmz_inputs.inverters.push(group[key]);
            }
            if (key == "combiner_boxes") {
              group[key].color = "#7FFF00";
              group[key].fillColor = "#7FFF00";
              group[key].weight = 4;
            }
            if (key == "racks") {
              // group[key].color = group_color; //33A2FF
              // group[key].fillColor = "none";
              // group[key].weight = 2;
              // indx = -599;
              // export_kmz_inputs.racks.push(group[key]);
              if (edited_layouts && selectedResultId && edited_layouts[selectedResultId]?.inverter_groups[0]?.combiner_groups[0]["rack_groups"]) {
                let racks = createMultiPolygon(edited_layouts[selectedResultId]?.inverter_groups[0]?.combiner_groups[0]["rack_groups"]);
                racks.color = group_color; //33A2FF
                racks.precision = 9;
                racks.fillColor = "none";
                racks.weight = 2;
                export_kmz_inputs.racks.push(racks);
              } else {
                group[key].color = group_color; //33A2FF
                group[key].fillColor = "none";
                group[key].weight = 2;
                indx = -599;
                export_kmz_inputs.racks.push(group[key]);
              }
            }
            if (key == "wires") {
              // return
              group[key].color = group[key].properties.color;
              group[key].fillColor = "none";
              group[key].weight = 0.5;

              // #33A2FF
              // #5377ad
              // #33A2FF
              // #33A2FF
              // #33A2FF
              // 131 - #AAFFFF
              // 141 - #AAEAFF
              // 151 - #AAD4FF
              // 161 - #AABFFF
              // 171 - #AAAAFF
              // 181 - #BFAAFF
            }
            if (key == "strings") {
              group[key].color = group[key].properties.color;
              group[key].fillColor = "none";
              group[key].weight = 0.5;
            }
          });
        });
      }
      // ROADS
      if (roads) {
        export_kmz_inputs.roads.push(roads);
      }
    }

    // These are the inputs that need to go to the sift-export tool
    console.log("export_kmz_inputs", export_kmz_inputs);

    dispatch(execute_job(export_data(export_kmz_inputs)));
    // export_data(export_kmz_inputs);

    dispatch(siftActions.updateUIState("export_kml", false));

    if (pvsyst) {
      dispatch(siftActions.updateUIState("export_pvsyst", false));
    }
    // map.eachLayer((layer) => {
    //   if (layer.options.id < 0 && layer.options.positions[0]?.length != 0 && layer.options.positions[0][0]?.length != 0) {
    //     let feature = layer.toGeoJSON();
    //     if (feature.geometry.coordinates[0][0] != undefined) {
    //       if (layer.options.id == -599) {
    //         // racks
    //         feature.properties["title"] = "Racks";
    //         feature.properties["fill-opacity"] = 0.0;
    //         feature.properties["fill"] = "#000000";
    //         feature.properties["stroke-width"] = 3;
    //         feature.properties["stroke"] = "#32a2ff";
    //         feature.properties["index"] = "racks";
    //         feature.properties["identity"] = 0;
    //         feature.properties["active"] = true;
    //         feature.properties["area"] = 0.0;
    //         feature.properties["center"] = 0.0;
    //         features.push(feature);
    //       }
    //       if (layer.options.id == -598) {
    //         // inverters
    //       }
    //     }
    //   }

    //   if (layer.options && layer.options.children && layer.options.children.key) {
    //     let geoJson = layer.toGeoJSON();
    //     let feature = geoJson["features"][0];
    //     if (feature.geometry.coordinates[0][0] != undefined) {
    //       let key = layer.options.children.key;
    //       if (key == 599) {
    //       } else if (key == 598) {
    //         // roads
    //         feature.properties["title"] = "Road";
    //         feature.properties["fill-opacity"] = 0.0;
    //         feature.properties["fill"] = "#000000";
    //         feature.properties["stroke-width"] = 3;
    //         feature.properties["stroke"] = "#e07111";
    //         features.push(feature);
    //       } else if (key == 597) {
    //       }
    //     }
    //   }
    // });
    // // RESULT DATA
    // // this.props.selectedResult

    // var featureCollection = {
    //   type: "FeatureCollection",
    //   features: features,
    // };
    // // console.log(featureCollection);

    // var kml = tokml(featureCollection, {
    //   documentName: "SIFT KML Export",
    //   documentDescription: "Export from SIFT Map",
    //   simplestyle: true,
    // });

    // var blob = new Blob([kml], { type: "xml;encoding=UTF-8" });
    // let FILE_NAME = file_name ? `${file_name}.kml` : `SIFT_Map.kml`;
    // FileSaver.saveAs(blob, `${FILE_NAME}`);
  };

  const layout_colors = {
    racks: "#33A2FF",
    inverter: "#ee00ff",
    combiner_boxes: "#7FFF00",
    wires: "#33A2FF",
    strings: "#33A2FF",
    roads: "#e0710f",
  };

  const export_cad = () => {
    if (Object.values(site_features).length > 0 && coord_system_bbox) {
      let coordSystemCheck = checkCoordSystemBounds(Object.values(site_features), coord_system_bbox);
      if (coordSystemCheck === false) {
        dispatch(
          siftActions.errorSIFT([
            "Your boundaries are located outside of the selected coordinate system area. Please select an appropriate coordinate system in the layout menu before exporting your CAD file",
          ])
        );
        return;
      }
    }
    console.log(layout);

    Object.keys(layout.inverter_groups[0]).map((key, index) => {
      if (key == "racks" && edited_layouts && selectedResultId && edited_layouts[selectedResultId]?.inverter_groups[0]?.combiner_groups[0]["rack_groups"]) {
        let racks = createMultiPolygon(edited_layouts[selectedResultId]?.inverter_groups[0]?.combiner_groups[0]["rack_groups"]);
        layout.inverter_groups[0][key] = { ...racks, color: "#33A2FF" };
      } else {
        layout.inverter_groups[0][key] = {
          ...layout.inverter_groups[0][key],
          properties: {
            ...layout.inverter_groups[0][key].properties,
            color: layout_colors[key],
          },
          color: layout_colors[key],
        };
      }

      // Object.keys(group).map((key, index) => {
      //   if (key == "racks") {
      //     // console.log(group[key])
      //     if (edited_layouts && selectedResultId && edited_layouts[selectedResultId]?.inverter_groups[0]?.combiner_groups[0]["rack_groups"]) {
      //       let racks = createMultiPolygon(edited_layouts[selectedResultId]?.inverter_groups[0]?.combiner_groups[0]["rack_groups"]);
      //       group[key] = { ...racks, color: "#33A2FF" };
      //     }
      //   }
      // });
    });

    let dxf_export_inputs = { ...layout };
    // console.log(edited_layouts)
    // console.log(rack_groups)
    // console.log(site_features)
    dxf_export_inputs["features"] = [...Object.values(site_features)];

    // console.log(layout)
    // console.log(dxf_export_inputs)
    // debugger

    let result = null;

    if (current_plot) {
      // console.log("current", current_plot);
      // these are both required to pull the proper layout data from S3 when generating the DXF
      // we cannot send the entire layout+boundaries from here, might be too big
      result = {
        layout_id: current_plot.layout_id.replace(/-/g, ""),
        removed_modules: 0,
        inverter_count: 0,
      };
      // dxf_export_inputs["layout_id"] = current_plot.layout_id;
      // dxf_export_inputs["id"] = current_plot.id;
    }
    dxf_export_inputs["key"] = `SIFT_Map_${create_UUID()}.dxf`;
    dxf_export_inputs["runId"] = runId ? runId : null;
    dxf_export_inputs["env"] = process.env.NODE_ENV == "production" ? "prod" : "dev";
    dxf_export_inputs["result"] = result;
    // dxf_export_inputs["epsg"] = epsg > 0 ? epsg : 3857;

    // Add topo ID here so we can also export the topo point cloud
    dxf_export_inputs["topo_id"] = topo_id ? topo_id : null;
    dxf_export_inputs["job_type"] = "export-job";
    dxf_export_inputs["export_type"] = "dxf-export";

    dxf_export_inputs = {
      ...dxf_export_inputs,
      meta: {
        file_name: file_name ? `${file_name}.dxf` : "SIFT_Map.dxf",
      },
    };

    set_loading_dxf(true);
    // console.log("dxf dxf_export_inputs", dxf_export_inputs);
    dispatch(execute_job(export_data(dxf_export_inputs)));
    // dispatch(siftActions.createDXF(dxf_export_inputs));
  };

  const export_3d_pvsyst = () => {
    console.log("export to 3d pvsyst");
  };

  return (
    <Dropdown overlay={<Menu export_cad={export_cad} export_kmz={export_kmz} export_3d_pvsyst={export_3d_pvsyst} />} disabled={Object.values(site_features).length < 1}>
      <Tooltip placement="bottom" title="Export Drawing">
        <button>
          <img draggable={false} src={export_map} />
        </button>
      </Tooltip>
    </Dropdown>
  );
};

const Menu = ({ export_cad, export_kmz, export_3d_pvsyst }) => {
  const plan_restrictions = useSelector((state) => state.user.plan_restrictions);

  const onSelectInput = (e) => {
    // console.log("e", e);
    let id = e.target.id;
    if (id == "cad") {
      export_cad();
    } else if (id == "kmz") {
      export_kmz();
    } else if (id == "pvsyst") {
      export_kmz(true);
    } else {
      return;
    }
  };
  return (
    <ul onClick={(e) => onSelectInput(e)}>
      <li key="kmz" id="kmz">
        Export KMZ
      </li>
      {plan_restrictions.can_dxf && (
        <li key="cad" id="cad">
          Export CAD
        </li>
      )}
      {/* <li key="pvsyst" id="pvsyst">
        Export SHD
      </li> */}
    </ul>
  );
};

export { MapboxExportMenu };
