import React, { useState, useEffect, Fragment } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { inputsActions, execute_job, topo_job, run_topo_job, getBounds, usePrevious, flipFeature } from "../../Redux";

// antd
import { Button, Menu, Dropdown, Input, Switch, Collapse, Empty, Radio, Popconfirm, Tooltip, Checkbox, message, section } from "antd";
import { DownOutlined, UpOutlined, RightOutlined } from "@ant-design/icons";
// import { jobsConstants } from '../../Redux/constants';

import { TopoPanelWrap } from "./styles";

import {
  UnorderedListOutlined,
  CheckOutlined,
  EditOutlined,
  BuildOutlined,
  FontColorsOutlined,
  FileImageOutlined,
  ExportOutlined,
  GatewayOutlined,
  BorderOuterOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  GlobalOutlined,
  MenuUnfoldOutlined,
  BorderInnerOutlined,
  BorderHorizontalOutlined,
  BorderVerticleOutlined,
  StopOutlined,
  ColumnHeightOutlined,
} from "@ant-design/icons";

const TopoKeys = {
  ele: "Elevation Ungraded",
  NS: "North-South Ungraded",
  EW: "East-West Ungraded",
  U: "Max Ungraded",
  "ele/G": "Elevation Graded",
  "NS/G": "North-South Graded",
  "EW/G": "East-West Graded",
  "U/G": "Max Graded",
  CF: "Cut/Fill",
  off: "off",
};

const TopoValueMapper = {
  // ele: "vis_ele_ungraded_percent",
  NS: "vis_ns_ungraded_percent",
  EW: "vis_ew_ungraded_percent",
  U: "vis_max_ungraded_percent",
  // "ele/G": "vis_ele_graded_percent",
  "NS/G": "vis_ns_ungraded_percent",
  "EW/G": "vis_ew_ungraded_percent",
  "U/G": "vis_max_ungraded_percent",
  CF: "grade_target",
  off: "off",
};

const TopoModeMapper = {
  ele: "vis_ele_ungraded_avail",
  NS: "vis_ns_ungraded_avail",
  EW: "vis_ew_ungraded_avail",
  U: "vis_max_ungraded_avail",
  // "ele/G": "vis_ele_graded_avail",
  "ele/G": "graded_avail",
  "NS/G": "vis_ns_graded_avail",
  "EW/G": "vis_ew_graded_avail",
  "U/G": "vis_max_graded_avail",
  // CF: "vis_g_ungraded_avail",
  CF: "graded_avail",
  off: "off",
};

const CheckBoxKeys = {
  "NS/G": "ns_graded_checked",
  "EW/G": "ew_graded_checked",
  "U/G": "max_graded_checked",
  "ele/G": "ele_graded_checked",
  NS: "ns_ungraded_checked",
  EW: "ew_ungraded_checked",
  U: "max_ungraded_checked",
  ele: "ele_ungraded_checked",
};

const flip_topo_mode = {
  ele: "ele/G",
  NS: "NS/G",
  EW: "EW/G",
  U: "U/G",
  "ele/G": "ele",
  "NS/G": "NS",
  "EW/G": "EW",
  "U/G": "U",
};

const getTopoModeLabel = (key) => {
  if (key == "CF") {
    return "Grade Target";
  } else {
    return "Visual Scale";
  }
};

const TopoInputs_NEW = () => {
  // const portal = useSelector((state) => state.portal);
  const inputs = useSelector((state) => state.inputs);
  const dispatch = useDispatch();

  const topo_mode = useSelector((state) => state.inputs.topo_mode);
  const isSurface = useSelector((state) => Object.values(inputs.site_features).filter((feature) => feature.properties.identity == 1).length > 0);

  const [local_inputs, set_local_inputs] = useState(inputs);

  const prev_vis_ew_ungraded_avail = usePrevious(local_inputs.vis_ew_ungraded_avail);
  const prev_vis_ew_graded_avail = usePrevious(local_inputs.vis_ew_graded_avail);
  const prev_vis_ns_ungraded_avail = usePrevious(local_inputs.vis_ns_ungraded_avail);
  const prev_vis_ns_graded_avail = usePrevious(local_inputs.vis_ns_graded_avail);
  const prev_vis_max_ungraded_avail = usePrevious(local_inputs.vis_max_ungraded_avail);
  const prev_vis_max_graded_avail = usePrevious(local_inputs.vis_max_graded_avail);

  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";
  const product_plan = useSelector((state) => state.user.product_plan);
  const starter_limits = !internal_sift && product_plan == 1;

  useEffect(() => {
    if (
      (prev_vis_ew_ungraded_avail && !local_inputs.vis_ew_ungraded_avail) ||
      (prev_vis_ew_graded_avail && !local_inputs.vis_ew_graded_avail) ||
      (prev_vis_ns_ungraded_avail && !local_inputs.vis_ns_ungraded_avail) ||
      (prev_vis_ns_graded_avail && !local_inputs.vis_ns_graded_avail) ||
      (prev_vis_max_ungraded_avail && !local_inputs.vis_max_ungraded_avail) ||
      (prev_vis_max_graded_avail && !local_inputs.vis_max_graded_avail)
    ) {
      changeTopoMode("off");
    }
  }, [
    local_inputs.vis_ew_ungraded_avail,
    local_inputs.vis_ew_graded_avail,
    local_inputs.vis_ns_ungraded_avail,
    local_inputs.vis_ns_graded_avail,
    local_inputs.vis_max_ungraded_avail,
    local_inputs.vis_max_graded_avail,
  ]);

  useEffect(() => {
    console.log("topo local inputs", local_inputs);
  }, [local_inputs]);

  useEffect(() => {
    set_local_inputs(inputs);
  }, [inputs]);

  useEffect(() => {
    if (inputs.track_mode == 2) {
      dispatch(inputsActions.update_input("en_topo_perf", 0));
    }
  }, [inputs.track_mode]);

  // all or layers
  const clearTopoData = (which_data = "all") => {
    dispatch(inputsActions.clear_topo_data(which_data));
  };

  const updateVisData = (key, value) => {
    // for updating the visual percent inputs boxes

    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });

    if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
      return;
    }

    let fixed_value = parseInt(value);

    dispatch(inputsActions.update_input(key, fixed_value));
  };

  const updateInput = (key, value) => {
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });

    // do some input validation here
    let fixed_value = value;
    let decPart = (value + "").split(".")[1];
    if (decPart && decPart.slice(-1) == "0") return;
    // console.log(key, fixed_value)

    if (key == "grade_target") {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      fixed_value = parseFloat(value);
    }

    // console.log(key,fixed_value)
    // dispatch(portalActions.updatePerformance(key,fixed_value));
    dispatch(inputsActions.update_input(key, fixed_value));
  };

  const handleTopoJob = (action) => {
    if (action == "pull_ele") {
      if (local_inputs.boundary_bbox.length == 0 || !local_inputs.boundary_bbox.every((x) => isFinite(x))) {
        message.error("Draw a boundary in the map before importing Topography");
        return;
      }
    }
    if (action == "calc_grade") {
      if (local_inputs.grade_target <= 0 || local_inputs.grade_target > 100) {
        message.error("Grade Target needs to be a number between 1-100");
        return;
      }
      local_inputs.uiState[CheckBoxKeys["ele/G"]] = true;
    }

    if (action == "gen_layers") {
      if (topo_mode == "ele/G") {
        local_inputs.uiState[CheckBoxKeys[topo_mode]] = true;
      } else {
        local_inputs.uiState[CheckBoxKeys[topo_mode]] = true;
        local_inputs.uiState[CheckBoxKeys[flip_topo_mode[topo_mode]]] = false;
      }

      // if (
      //   !local_inputs.uiState.ns_graded_checked &&
      //   !local_inputs.uiState.ew_graded_checked &&
      //   !local_inputs.uiState.max_graded_checked &&
      //   !local_inputs.uiState.ns_ungraded_checked &&
      //   !local_inputs.uiState.ew_ungraded_checked &&
      //   !local_inputs.uiState.max_ungraded_checked &&
      //   !local_inputs.uiState.ele_graded_checked
      // ) {
      //   message.error("At least 1 Visual Layer must be checked before Creating Layers.");
      //   return;
      // }
    }
    // this flips the features for the topo job as all of these features are [lng, lat] since the mapbox conversion
    // let flipped_features = {};
    // let temp_site_feature_flip = Object.values(local_inputs.site_features).map((feature) => flipFeature(feature));
    // temp_site_feature_flip.map((feature) => {
    //   flipped_features[feature.properties.index] = feature;
    // });
    // local_inputs.site_features = flipped_features;
    let boundary_site_features = Object.values(local_inputs.site_features).filter((feature) => feature.properties.identity == 1);
    // let flip_boundary_site_features = boundary_site_features.map((feature) => flipFeature(feature));
    local_inputs.boundary_bbox = getBounds(boundary_site_features);
    console.log("local_inputs", local_inputs);

    dispatch(execute_job(topo_job(local_inputs, action)));
    // dispatch(run_topo_job(topo_job(local_inputs, action)));
  };

  const handleUpdateTopoMode = (mode) => {
    // console.log(mode, portal.topoData.topo_live, portal.topoData.topo_id);
    if (local_inputs.topo_id) {
      let mode_ext = mode;

      if (mode == "NS" || mode == "EW" || mode == "U" || mode == "CF") {
        if (!local_inputs[TopoModeMapper[mode]]) return;
        mode_ext = `${mode}/${local_inputs[TopoValueMapper[mode]]}`;
      }
      if (mode == "NS/G" || mode == "EW/G" || mode == "U/G") {
        if (!local_inputs[TopoModeMapper[mode]]) return;
        mode_ext = `${mode}/${local_inputs[TopoValueMapper[mode]]}`;
      }
      // console.log(mode_ext);
      let data = {
        topo_mode: mode,
        // topo_url: `https://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${this.props.topoData.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
        // topo_scale_url: `https://topo.xyz.s3-website.us-east-2.amazonaws.com/test/${this.props.topoData.topo_id}/${mode_ext}/scale.png`
        // topo_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${portal.topoData.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
        // topo_scale_url: `https://s3.us-east-2.amazonaws.com/topo.xyz/test/${portal.topoData.topo_id}/${mode_ext}/scale.png`,
        topo_url: `https://topo-tiles.sunfig.com/test/${local_inputs.topo_id}/${mode_ext}/{z}/{x}/{y}.png`,
        topo_scale_url: `https://topo-tiles.sunfig.com/test/${local_inputs.topo_id}/${mode_ext}/scale.png`,
      };

      dispatch(inputsActions.update_input("topo_mode", mode));
      dispatch(inputsActions.update_input("topo_url", `https://topo-tiles.sunfig.com/test/${inputs.topo_id}/${mode_ext}/{z}/{x}/{y}.png`));
      dispatch(inputsActions.update_input("topo_scale_url", `https://topo-tiles.sunfig.com/test/${inputs.topo_id}/${mode_ext}/scale.png`));
    }
  };

  const changeTopoMode = (mode) => {
    dispatch(inputsActions.update_input("topo_mode", mode));
  };

  const hasLayers =
    local_inputs.vis_ele_ungraded_avail ||
    local_inputs.vis_ele_graded_avail ||
    local_inputs.vig_g_graded_avail ||
    local_inputs.vig_g_ungraded_percent ||
    local_inputs.vis_max_ungraded_avail ||
    local_inputs.vis_max_graded_avail ||
    local_inputs.vis_ns_ungraded_avail ||
    local_inputs.vis_ns_graded_avail ||
    local_inputs.vis_ew_ungraded_avail ||
    local_inputs.vis_ew_graded_avail;

  return (
    <TopoPanelWrap starter_limits={starter_limits}>
      {local_inputs.uiState.topo_error && (
        <section className="input-one-col">
          <label style={{ color: "red" }}>{local_inputs.uiState.topo_error}</label>
        </section>
      )}

      <h3>Elevation</h3>
      <section className="col-two">
        {/* <label>Source</label> */}

        <Radio.Group
          value={local_inputs.topo_source}
          disabled={local_inputs.uiState.topo_loading}
          onChange={(e) => {
            dispatch(inputsActions.update_input("topo_source", e.target.value));
          }}
        >
          <Radio value={"USGS"}>USGS</Radio>
          <Radio value={"Google"}>Google</Radio>
        </Radio.Group>

        {local_inputs.topo_id ? (
          <section className="topo-button">
            <Popconfirm title="Are you sure you want to remove all topography data, including visual layers, from this project?" onConfirm={() => clearTopoData()} okText="Yes" cancelText="No">
              <Button type="link" loading={local_inputs.uiState.topo_loading}>
                <span>Clear All Topo Data</span>
              </Button>
            </Popconfirm>
          </section>
        ) : (
          <section className="topo-button">
            <Tooltip
              placement="right"
              mouseEnterDelay={0.5}
              title={
                <span style={{ width: "300px" }}>
                  It can take up to 2 minutes to import the requested elevation data from USGS. Elevation request will only cover areas on the map marked as boundaries (red). Please ensure your
                  boundaries are complete before proceeding.
                </span>
              }
            >
              <Button onClick={() => handleTopoJob("pull_ele")} type="primary" disabled={!isSurface || local_inputs.boundary_bbox.length == 0} loading={local_inputs.uiState.topo_loading} ghost>
                <span>Import Elevation</span>
              </Button>
            </Tooltip>
          </section>
        )}
      </section>

      {local_inputs.topo_id ? (
        // {true ? (
        <Fragment>
          <Tooltip title="Rack Grade Limit can be edited in the Racking Menu" placement="top">
            <p className="bold-font">{`Rack Grade Limit: ${local_inputs.rack_grade_limit} %`}</p>
          </Tooltip>

          <section className="label-lrg">
            <h3>Layout Options:</h3>
          </section>

          <section className="topo-row">
            <p>Racking on Topography Actions:</p>
          </section>

          <section className="vert-buttons">
            <Radio.Group
              value={local_inputs.topo_layout_action}
              onChange={(e) => {
                dispatch(inputsActions.update_input("topo_layout_action", e.target.value));
              }}
            >
              <Radio value={"nothing"}>No Action, Ignore Grade</Radio>
              <Radio value={"delete"}>{`Remove if Grade > Racking Grade Limit`}</Radio>
            </Radio.Group>
          </section>

          <section className="topo-row">
            <p>Topographic Performance Impact:</p>
          </section>
          <section className="racking-input.radio-buttons">
            <Tooltip
              placement="top"
              title={
                starter_limits ? "Topo Performance Impact only available on Standard and Pro Plans" : local_inputs.track_mode == 2 ? "Topo Performance Impact not available for East-West Facing" : ""
              }
              mouseEnterDelay={0.5}
            >
              <Radio.Group
                disabled={starter_limits || local_inputs.track_mode == 2}
                value={local_inputs.en_topo_perf}
                onChange={(e) => {
                  dispatch(inputsActions.update_input("en_topo_perf", e.target.value));
                }}
              >
                <Radio value={1}>Enable</Radio>
                <Radio value={0}>Disable</Radio>
              </Radio.Group>
            </Tooltip>
          </section>

          <section className="surface-btns ">
            <label>Act on Surface:</label>
            <Radio.Group
              value={local_inputs.topo_layout_surface}
              onChange={(e) => {
                dispatch(inputsActions.update_input("topo_layout_surface", e.target.value));
              }}
            >
              <Radio value={"ungraded"}>Ungraded</Radio>
              <Radio value={"graded"} disabled={!local_inputs.graded_avail}>
                Graded
              </Radio>
            </Radio.Group>
          </section>

          <section className="topo-row-lrg">
            <h3>Visual Analysis:</h3>
            {/* <Switch
              size="large"
              checkedChildren="On"
              unCheckedChildren="off"
              checked={local_inputs.topo_mode != "off"}
              onChange={(checked) => {
                changeTopoMode(checked ? "ele" : "off");
              }}
            /> */}
          </section>

          <section className="topo-row">
            <p>
              {topo_mode == "off"
                ? "Displayed: off"
                : local_inputs[TopoModeMapper[topo_mode]]
                ? `Displayed: ${TopoKeys[topo_mode]} ${topo_mode != "ele" && topo_mode != "ele/G" ? "-" : ""} ${
                    topo_mode != "ele" && topo_mode != "ele/G" ? local_inputs[TopoValueMapper[topo_mode]] : ""
                  } ${topo_mode != "ele" && topo_mode != "ele/G" ? "%" : ""}`
                : `Create ${TopoKeys[topo_mode]} Layer Below`}
            </p>
          </section>

          <section className="topo-two-row move-rt">
            <label>Ungraded</label>
            <section className="topo-button-row">
              <Tooltip placement="top" title="Elevation" mouseEnterDelay={0.25}>
                <button
                  disabled={local_inputs.uiState.topo_loading}
                  style={{ cursor: local_inputs.uiState.topo_loading && "not-allowed" }}
                  className={topo_mode == "ele" ? "active-topo-button" : local_inputs.vis_ele_ungraded_avail ? "avail-topo-button" : undefined}
                  onClick={() => {
                    if (topo_mode == "ele") {
                      changeTopoMode("off");
                    } else {
                      handleUpdateTopoMode("ele");
                    }
                  }}
                >
                  <MenuUnfoldOutlined style={{ fontSize: 14 }} />
                </button>
              </Tooltip>
              <Tooltip placement="top" title={starter_limits ? "Max Slope only available on Standard and Pro Plans" : "Max Slope"} mouseEnterDelay={0.25}>
                <button
                  disabled={starter_limits || local_inputs.uiState.topo_loading}
                  style={{ cursor: local_inputs.uiState.topo_loading && "not-allowed" }}
                  className={
                    local_inputs.vis_max_ungraded_avail && topo_mode == "U"
                      ? "active-topo-button"
                      : local_inputs.vis_max_ungraded_avail
                      ? "avail-topo-button"
                      : !local_inputs.vis_max_ungraded_avail && topo_mode == "U"
                      ? "avail-topo-button"
                      : undefined
                  }
                  onClick={() => {
                    if (local_inputs.vis_max_ungraded_avail && topo_mode == "U") {
                      changeTopoMode("off");
                    } else if (local_inputs.vis_max_ungraded_avail) {
                      handleUpdateTopoMode("U");
                    } else if (!local_inputs.vis_max_ungraded_avail) {
                      changeTopoMode("U");
                    }
                  }}
                >
                  <BorderInnerOutlined style={{ fontSize: 14 }} />
                </button>
              </Tooltip>
              <Tooltip placement="top" title={starter_limits ? "North-South Slope is only available on Standard and Pro Plans" : "North-South Slope"} mouseEnterDelay={0.25}>
                <button
                  disabled={starter_limits || local_inputs.uiState.topo_loading}
                  style={{ cursor: (local_inputs.uiState.topo_loading || starter_limits) && "not-allowed" }}
                  className={
                    local_inputs.vis_ns_ungraded_avail && topo_mode == "NS"
                      ? "active-topo-button"
                      : local_inputs.vis_ns_ungraded_avail
                      ? "avail-topo-button"
                      : !local_inputs.vis_ns_ungraded_avail && topo_mode == "NS"
                      ? "avail-topo-button"
                      : undefined
                  }
                  onClick={() => {
                    if (local_inputs.vis_ns_ungraded_avail && topo_mode == "NS") {
                      changeTopoMode("off");
                    } else if (local_inputs.vis_ns_ungraded_avail) {
                      handleUpdateTopoMode("NS");
                    } else if (!local_inputs.vis_ns_ungraded_avail) {
                      changeTopoMode("NS");
                    }
                  }}
                >
                  <BorderHorizontalOutlined style={{ fontSize: 14 }} />
                </button>
              </Tooltip>
              <Tooltip placement="top" title={starter_limits ? "East-West Slope is only available on Standard and Pro Plans" : "East-West Slope"} mouseEnterDelay={0.25}>
                <button
                  disabled={starter_limits || local_inputs.uiState.topo_loading}
                  style={{ cursor: (local_inputs.uiState.topo_loading || starter_limits) && "not-allowed" }}
                  className={
                    local_inputs.vis_ew_ungraded_avail && topo_mode == "EW"
                      ? "active-topo-button"
                      : local_inputs.vis_ew_ungraded_avail
                      ? "avail-topo-button"
                      : !local_inputs.vis_ew_ungraded_avail && topo_mode == "EW"
                      ? "avail-topo-button"
                      : undefined
                  }
                  onClick={() => {
                    if (local_inputs.vis_ew_ungraded_avail && topo_mode == "EW") {
                      changeTopoMode("off");
                    } else if (local_inputs.vis_ew_ungraded_avail) {
                      handleUpdateTopoMode("EW");
                    } else if (!local_inputs.vis_ew_ungraded_avail) {
                      changeTopoMode("EW");
                    }
                  }}
                >
                  <BorderVerticleOutlined style={{ fontSize: 14 }} />
                </button>
              </Tooltip>
            </section>
          </section>

          <section className="topo-one-row">
            <section className="topo-two-row lg-mg-r">
              <label>Cut/Fill</label>
              <section className="topo-button-row">
                <Tooltip placement="top" title={starter_limits ? "Cut and Fill only is available with Standard and Pro Plans" : ""} mouseEnterDelay={0.25}>
                  <button
                    disabled={starter_limits || local_inputs.uiState.topo_loading}
                    style={{ cursor: (local_inputs.uiState.topo_loading || starter_limits) && "not-allowed" }}
                    className={
                      local_inputs.graded_avail && topo_mode == "CF" && !local_inputs.uiState.topo_loading
                        ? "active-topo-button"
                        : local_inputs.graded_avail
                        ? "avail-topo-button"
                        : !local_inputs.graded_avail && topo_mode == "CF"
                        ? "avail-topo-button"
                        : undefined
                    }
                    onClick={() => {
                      if (local_inputs.graded_avail && topo_mode == "CF") {
                        changeTopoMode("off");
                      } else if (local_inputs.graded_avail) {
                        handleUpdateTopoMode("CF");
                      } else if (!local_inputs.graded_avail) {
                        changeTopoMode("CF");
                      }
                    }}
                  >
                    <ColumnHeightOutlined style={{ fontSize: 14 }} />
                  </button>
                </Tooltip>
              </section>
            </section>

            <section className="topo-two-row">
              <label>Graded</label>
              <section className="topo-button-row">
                <Tooltip placement="top" title={starter_limits ? "Graded Slope only available with Standard and Pro Plans" : "Graded Slope"} mouseEnterDelay={0.25}>
                  <button
                    className={
                      local_inputs.graded_avail && topo_mode == "ele/G"
                        ? "active-topo-button"
                        : local_inputs.graded_avail
                        ? "avail-topo-button"
                        : !local_inputs.graded_avail && topo_mode == "ele/G"
                        ? "avail-topo-button"
                        : undefined
                    }
                    disabled={starter_limits || !local_inputs.graded_avail || local_inputs.uiState.topo_loading}
                    style={{ cursor: (local_inputs.uiState.topo_loading || starter_limits) && "not-allowed" }}
                    onClick={() => {
                      if ((local_inputs.vis_ele_graded_avail || local_inputs.graded_avail) && topo_mode == "ele/G") {
                        changeTopoMode("off");
                      } else if (local_inputs.vis_ele_graded_avail || local_inputs.graded_avail) {
                        handleUpdateTopoMode("ele/G");
                      } else if (!local_inputs.vis_ele_graded_avail || !local_inputs.graded_avail) {
                        changeTopoMode("ele/G");
                      }
                    }}
                  >
                    <MenuUnfoldOutlined style={{ fontSize: 14 }} />
                  </button>
                </Tooltip>
                <Tooltip placement="top" title={starter_limits ? "Max Slope only available with Standard and Pro Plans" : "Max Slope"} mouseEnterDelay={0.25}>
                  <button
                    className={
                      local_inputs.vis_max_graded_avail && topo_mode == "U/G"
                        ? "active-topo-button"
                        : local_inputs.vis_max_graded_avail
                        ? "avail-topo-button"
                        : !local_inputs.vis_max_graded_avail && topo_mode == "U/G"
                        ? "avail-topo-button"
                        : undefined
                    }
                    disabled={!local_inputs.graded_avail || local_inputs.uiState.topo_loading}
                    style={{ cursor: (local_inputs.uiState.topo_loading || starter_limits) && "not-allowed" }}
                    onClick={() => {
                      if (local_inputs.vis_max_graded_avail && topo_mode == "U/G") {
                        changeTopoMode("off");
                      } else if (local_inputs.vis_max_graded_avail) {
                        handleUpdateTopoMode("U/G");
                      } else if (!local_inputs.vis_max_graded_avail) {
                        changeTopoMode("U/G");
                      }
                    }}
                  >
                    <BorderInnerOutlined style={{ fontSize: 14 }} />
                  </button>
                </Tooltip>
                <Tooltip placement="top" title={starter_limits ? "North-South Slope only available with Standard and Pro Plans" : "North-South Slope"} mouseEnterDelay={0.25}>
                  <button
                    className={
                      local_inputs.vis_ns_graded_avail && topo_mode == "NS/G"
                        ? "active-topo-button"
                        : local_inputs.vis_ns_graded_avail
                        ? "avail-topo-button"
                        : !local_inputs.vis_ns_graded_avail && topo_mode == "NS/G"
                        ? "avail-topo-button"
                        : undefined
                    }
                    disabled={starter_limits || !local_inputs.graded_avail || local_inputs.uiState.topo_loading}
                    style={{ cursor: (local_inputs.uiState.topo_loading || starter_limits) && "not-allowed" }}
                    onClick={() => {
                      if (local_inputs.vis_ns_graded_avail && topo_mode == "NS/G") {
                        changeTopoMode("off");
                      } else if (local_inputs.vis_ns_graded_avail) {
                        handleUpdateTopoMode("NS/G");
                      } else if (!local_inputs.vis_ns_graded_avail) {
                        changeTopoMode("NS/G");
                      }
                    }}
                  >
                    <BorderHorizontalOutlined style={{ fontSize: 14 }} />
                  </button>
                </Tooltip>
                <Tooltip placement="top" title={starter_limits ? "East-West Slope only available with Standard and Pro Plans" : "East-West Slope"} mouseEnterDelay={0.25}>
                  <button
                    className={
                      local_inputs.vis_ew_graded_avail && topo_mode == "EW/G"
                        ? "active-topo-button"
                        : local_inputs.vis_ew_graded_avail
                        ? "avail-topo-button"
                        : !local_inputs.vis_ew_graded_avail && topo_mode == "EW/G"
                        ? "avail-topo-button"
                        : undefined
                    }
                    disabled={starter_limits || !local_inputs.graded_avail || local_inputs.uiState.topo_loading}
                    style={{ cursor: (local_inputs.uiState.topo_loading || starter_limits) && "not-allowed" }}
                    onClick={() => {
                      if (local_inputs.vis_ew_graded_avail && topo_mode == "EW/G") {
                        changeTopoMode("off");
                      } else if (local_inputs.vis_ew_graded_avail) {
                        handleUpdateTopoMode("EW/G");
                      } else if (!local_inputs.vis_ew_graded_avail) {
                        changeTopoMode("EW/G");
                      }
                    }}
                  >
                    <BorderVerticleOutlined style={{ fontSize: 14 }} />
                  </button>
                </Tooltip>
              </section>
            </section>
          </section>

          {topo_mode != "off" && topo_mode != "ele" && topo_mode != "ele/G" && (
            <section className="topo-input">
              <label>{getTopoModeLabel(topo_mode)}</label>
              <Input
                disabled={topo_mode == "CF" ? false : local_inputs[TopoModeMapper[topo_mode]] || local_inputs[TopoModeMapper[flip_topo_mode[topo_mode]]] || local_inputs.uiState.topo_loading}
                type="number"
                suffix="%"
                value={local_inputs[TopoValueMapper[topo_mode]]}
                onChange={(e) => {
                  if (topo_mode == "CF") {
                    updateInput(TopoValueMapper[topo_mode], e.target.value);
                  } else {
                    updateVisData(TopoValueMapper[topo_mode], e.target.value);
                  }
                }}
              />
            </section>
          )}

          {topo_mode != "off" && topo_mode != "ele" && topo_mode != "ele/G" && (
            <section className="flex-row">
              {topo_mode == "CF" ? (
                <Button type="link" loading={local_inputs.uiState.topo_loading} onClick={() => handleTopoJob("calc_grade")}>
                  Estimate Cut/Fill
                </Button>
              ) : local_inputs[TopoModeMapper[topo_mode]] ? (
                <Button
                  type="link"
                  loading={local_inputs.uiState.topo_loading}
                  onClick={() => {
                    clearTopoData(TopoModeMapper[topo_mode]);
                  }}
                >
                  Delete Layer
                </Button>
              ) : (
                <Button
                  disabled={local_inputs[TopoModeMapper[topo_mode]]}
                  type="link"
                  loading={local_inputs.uiState.topo_loading}
                  onClick={() => {
                    handleTopoJob("gen_layers");
                  }}
                >
                  Create Layer
                </Button>
              )}
            </section>
          )}

          {local_inputs.topo_id && topo_mode == "CF" && (
            <section className="cut-fill">
              <p>{`Cut: ${local_inputs.grade_cut_amt} m³, ${+(local_inputs.grade_cut_amt * 1.308).toFixed(2)} yd³`}</p>
              <p>{`Fill: ${local_inputs.grade_fill_amt} m³, ${+(local_inputs.grade_fill_amt * 1.308).toFixed(2)} yd³`}</p>
            </section>
          )}

          {/* <section className="weather-section" orientation="left">
            <span> Grading</span>
          </section>
          <Fragment>
            <section className="input-two-col small">
              <label>Grade Target</label>
              <Input
                className="input-box"
                size="small"
                type="number"
                value={local_inputs.grade_target}
                onChange={(e) => updateInput("grade_target", e.target.value)}
                min="0"
                max="100"
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </section>
            <section className="input-one-col">
              <section>
                <label>
                  <i>
                    Cut: {local_inputs.grade_cut_amt.toFixed(0)} yd³
                    {"   "}
                    Fill: {local_inputs.grade_fill_amt.toFixed(0)} yd³
                  </i>
                </label>
              </section>
            </section>
            <section className="input-two-col">
              <section></section>
              <Button onClick={() => handleTopoJob("calc_grade")} type="link" loading={local_inputs.uiState.topo_loading}>
                <span>Calculate Cut/Fill</span>
              </Button>
            </section>
          </Fragment>

          <section className="weather-section" orientation="left">
            <span> Visual Layers</span>
          </section>

          <section className="input-two-col topo-vis">
            <Tooltip
              placement="top"
              title="Visualize the different map layers here. You cannot generate a 
                                Ungraded and Graded layer at the same time but you can generate them separately"
            >
              <label>
                <u>Layer</u>
              </label>
            </Tooltip>
            <section>
              <Tooltip placement="top" title="Use Ungraded Elevation data">
                <label style={{ padding: "0 4px" }}>
                  <u>U</u>
                </label>
              </Tooltip>
              <Tooltip placement="top" title="Use Graded Elevation data">
                <label style={{ padding: "0 5px" }}>
                  <u>G</u>
                </label>
              </Tooltip>
              <Tooltip placement="top" title="Values set scale of visual layers">
                <label style={{ padding: "0 14px" }}>
                  <u>Visual Scale</u>
                </label>
              </Tooltip>
            </section>
          </section>

          <section className="input-two-col topo-vis">
            <label>Elevation</label>
            <section>
              <Tooltip placement="left" title="Ungraded Elevation is generated when Topography is imported">
                <Checkbox checked={true} disabled />
              </Tooltip>

              <Tooltip placement="right" title="Generate Elevation layer using Graded data">
                <Checkbox
                  style={{ marginLeft: "3px" }}
                  checked={local_inputs.vis_ele_graded_avail || local_inputs.uiState.ele_graded_checked}
                  disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ele_graded_avail || !local_inputs.graded_avail}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ele_graded_checked", e.target.checked))}
                />
              </Tooltip>

              <section style={{ width: "125px" }}></section>
            </section>
          </section>

          <section className="input-two-col topo-vis">
            <label>Max Grade</label>
            <section className="input-topo-vis">
              <Tooltip placement="left" title="Generate Max Grade layers with Ungraded data">
                <Checkbox
                  checked={local_inputs.vis_max_ungraded_avail || local_inputs.uiState.max_ungraded_checked}
                  disabled={local_inputs.uiState.topo_loading || local_inputs.vis_max_ungraded_avail || (!local_inputs.vis_max_ungraded_avail && local_inputs.uiState.max_graded_checked)}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("max_ungraded_checked", e.target.checked))}
                />
              </Tooltip>
              <Tooltip placement="right" title="Generate Resultant layer with Graded data">
                <Checkbox
                  checked={local_inputs.vis_max_graded_avail || local_inputs.uiState.max_graded_checked}
                  disabled={
                    !local_inputs.graded_avail ||
                    local_inputs.uiState.topo_loading ||
                    local_inputs.vis_max_graded_avail ||
                    (!local_inputs.vis_max_graded_avail && local_inputs.uiState.max_ungraded_checked)
                  }
                  onChange={(e) => dispatch(inputsActions.update_ui_state("max_graded_checked", e.target.checked))}
                />
              </Tooltip>
              <Input
                className="input-box"
                size="small"
                min="0"
                max="100"
                value={local_inputs.vis_max_ungraded_percent}
                disabled={local_inputs.uiState.topo_loading || local_inputs.vis_max_ungraded_avail || local_inputs.vis_max_graded_avail}
                onChange={(e) => {
                  updateVisData("vis_max_ungraded_percent", e.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </section>
          </section>

          <section className="input-two-col topo-vis">
            <label>NS Grade</label>
            <section className="input-topo-vis">
              <Tooltip placement="left" title="Generate North-South layers with Ungraded data">
                <Checkbox
                  checked={local_inputs.vis_ns_ungraded_avail || local_inputs.uiState.ns_ungraded_checked}
                  disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ns_ungraded_avail || (!local_inputs.vis_ns_ungraded_avail && local_inputs.uiState.ns_graded_checked)}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ns_ungraded_checked", e.target.checked))}
                />
              </Tooltip>
              <Tooltip placement="right" title="Generate North-South layer with Graded data">
                <Checkbox
                  checked={local_inputs.vis_ns_graded_avail || local_inputs.uiState.ns_graded_checked}
                  disabled={
                    !local_inputs.graded_avail ||
                    local_inputs.uiState.topo_loading ||
                    local_inputs.vis_ns_graded_avail ||
                    (!local_inputs.vis_ns_graded_avail && local_inputs.uiState.ns_ungraded_checked)
                  }
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ns_graded_checked", e.target.checked))}
                />
              </Tooltip>

              <Input
                className="input-box"
                size="small"
                min="0"
                max="100"
                value={local_inputs.vis_ns_ungraded_percent}
                disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ns_ungraded_avail || local_inputs.vis_ns_graded_avail}
                onChange={(e) => {
                  updateVisData("vis_ns_ungraded_percent", e.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </section>
          </section>

          <section className="input-two-col topo-vis">
            <label>EW Grade</label>
            <section className="input-topo-vis">
              <Tooltip placement="left" title="Generate East-West layers with Ungraded data">
                <Checkbox
                  checked={local_inputs.vis_ew_ungraded_avail || local_inputs.uiState.ew_ungraded_checked}
                  disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ew_ungraded_avail || (!local_inputs.vis_ew_ungraded_avail && local_inputs.uiState.ew_graded_checked)}
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ew_ungraded_checked", e.target.checked))}
                />
              </Tooltip>
              <Tooltip placement="right" title="Generate East-West layer with Graded data">
                <Checkbox
                  checked={local_inputs.vis_ew_graded_avail || local_inputs.uiState.ew_graded_checked}
                  disabled={
                    !local_inputs.graded_avail ||
                    local_inputs.uiState.topo_loading ||
                    local_inputs.vis_ew_graded_avail ||
                    (!local_inputs.vis_ew_graded_avail && local_inputs.uiState.ew_ungraded_checked)
                  }
                  onChange={(e) => dispatch(inputsActions.update_ui_state("ew_graded_checked", e.target.checked))}
                />
              </Tooltip>
              <Input
                className="input-box"
                size="small"
                min="0"
                max="100"
                value={local_inputs.vis_ew_ungraded_percent}
                disabled={local_inputs.uiState.topo_loading || local_inputs.vis_ew_ungraded_avail || local_inputs.vis_ew_graded_avail}
                onChange={(e) => {
                  updateVisData("vis_ew_ungraded_percent", e.target.value);
                }}
                suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%</span>}
              />
            </section>
          </section>

          <section className="input-two-col">
            <section>
              {hasLayers && (
                <Popconfirm
                  title="Are you sure you want to remove all slope analysis visual layers (except elevation and cut/fill), from this project?"
                  onConfirm={() => clearTopoData("layers")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" loading={local_inputs.uiState.topo_loading}>
                    <span>Delete Layers</span>
                  </Button>
                </Popconfirm>
              )}

              <Tooltip placement="right" mouseEnterDelay={0.5} title={<span style={{ width: "300px" }}>This creates visual layers for each of the selected slope analysis options.</span>}>
                <Button
                  onClick={() => handleTopoJob("gen_layers")}
                  type="link"
                  loading={local_inputs.uiState.topo_loading}
                  // put disabled logic here if there are no layers that can be created
                >
                  <span>Create Layers</span>
                </Button>
              </Tooltip>
            </section>
          </section>
          <section className="weather-section" orientation="left">
            <span style={{ color: "var(--primary-brand-color)" }}>
              <RightOutlined />
            </span>
            <span> Layout Options</span>
          </section>

          <section className="input-one-col-label">
            <label>Act on Surface:</label>
          </section>
          <section className="input-one-col-label radio noinline indent">
            <Radio.Group
              value={local_inputs.topo_layout_surface}
              onChange={(e) => {
                dispatch(inputsActions.update_input("topo_layout_surface", e.target.value));
              }}
            >
              <Radio value={"ungraded"}>Ungraded</Radio>
              <Radio value={"graded"} disabled={!local_inputs.graded_avail}>
                Graded
              </Radio>
            </Radio.Group>
          </section>

          <section className="input-one-col-label">
            <label>Racking on Topography Actions:</label>
          </section>
          <section className="input-one-col-label radio indent">
            <Radio.Group
              value={local_inputs.topo_layout_action}
              onChange={(e) => {
                dispatch(inputsActions.update_input("topo_layout_action", e.target.value));
              }}
            >
              <Radio value={"nothing"}>No Action, Ignore Grade</Radio>
              <Radio value={"delete"}>{`Remove if Grade > Racking Grade Limit`}</Radio>
            </Radio.Group>
          </section> */}
        </Fragment>
      ) : (
        <section className="empty-box" style={{ marginTop: "20px", height: "40%" }}>
          <Empty description="Import Elevation data to view actions" />
        </section>
      )}
    </TopoPanelWrap>
  );
};

export { TopoInputs_NEW };
