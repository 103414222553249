export const alertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  LOADING: "ALERT_LOADING",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
  WARNING: "ALERT_WARNING",
  INFO: "ALERT_INFO",
  CANCEL: "ALERT_CANCEL",
  INFO: "INFO",
};
