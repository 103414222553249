import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isAuthenticated, siftActions, inputsActions, canopyActions } from "../../Redux";

import { Select, Switch, Dropdown, Input } from "antd";

import { ButtonGroup, Button } from "../../BaseComponents";

import { UserOutlined, SaveOutlined, LockOutlined, ImportOutlined, EllipsisOutlined, CloudSyncOutlined, DeleteOutlined } from "@ant-design/icons";
import { ts_product_icon } from "../../../assets/images";
import { InputHeaderWrap } from "./styles";

import { get_preset_inputs } from "./presets";
import _ from "lodash";

const TerrasmartIcon = () => {
  return <img style={{ width: 15, height: 15 }} src={ts_product_icon} />;
};

const InputHeader = ({ header_type }) => {
  const dispatch = useDispatch();
  let name = `${header_type}_name`;
  let _id = `${header_type}_id`;

  const tab = useSelector((state) => state.sift.ioManager.uiState.tab);
  // const inputs = useSelector((state) => state.sift.ioManager.inputs[tab]);
  const inputs = useSelector((state) => state.inputs);

  const account_loading = useSelector((state) => state.user.getting_user_data);
  //const en_target_inputs = useSelector((state) => state.user.en_target_inputs);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading;

  // const dropdown = useSelector((state) => state.sift.ioManager.dropdowns[tab]);
  const dropdown = useSelector((state) => state.user.presets[tab]);
  const [local_dropdown, set_local_dropdown] = useState(dropdown);

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform === "internal_sift";
  const using_canopy = window.location.pathname === "/canopy" || window.location.pathname === "/canopy/";
  const canopy_length = useSelector((state) => Object.values(state.canopy.canopies).length);

  useEffect(() => {
    let temp_dropdown = dropdown;
    // //this just filters out the simple inverter in the dropdown if you have target inputs enabled in the user settings so as not to allow the user to run with a non-simple inverter.
    // if (tab == "inverter" && en_target_inputs) {
    //   let simple_inverter_index = dropdown.findIndex((inverter) => inverter.inv_id == "simple_inv");
    //   temp_dropdown = [temp_dropdown[simple_inverter_index]];
    // }
    // console.log("temp", temp_dropdown);
    set_local_dropdown(temp_dropdown);
  }, [dropdown]);

  //const selected = inputs && inputs.data;
  //const isAuthed = useSelector((state) => isAuthenticated(state));

  const running = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const do_finance = useSelector((state) => state.inputs.do_finance == 1);
  const topo_live = useSelector((state) => state.inputs.topo_id !== undefined && state.inputs.vis_ele_ungraded_avail);
  const current_plan = useSelector((state) => state.user.product_plan_paid);
  //const can_import = current_plan > 1;
  const screenWidth = useSelector((state) => state.sift.ioManager.uiState.screenWidth);
  const simple_module = useSelector((state) => state.inputs.simple_module);
  const simple_inverter = useSelector((state) => state.inputs.simple_inverter);

  const [is_default_selected, set_is_default_selected] = useState(0);

  //let is_generic = false;
  let can_delete = true;
  //let can_save = true;
  let can_save_as = true;

  function updateInput(key, value) {
    dispatch(inputsActions.update_input(key, value));
  }
  // function SaveIcon() {
  //   let style = { width: 15, height: 15, paddingBottom: 2 };
  //   if (tab == "finance" && !do_finance) {
  //     style.opacity = 0.5;
  //   }
  //   return <img style={style} src={save_icon_down} />;
  // }

  function onDropDownSelect(value) {
    let preset_selected = local_dropdown.findIndex((preset) => preset[_id] == value);
    if (preset_selected >= 0) {
      let is_valid = true;
      // console.log(tab, value, local_dropdown[preset_selected]);

      if (tab === "inverter" && local_dropdown[preset_selected].simple_inverter !== 1) {
        let inv_pd_eff_pout = local_dropdown[preset_selected].inv_pd_eff_pout;
        let inv_rating = local_dropdown[preset_selected].inv_rating;
        let inv_pnom = local_dropdown[preset_selected].inv_pnom;
        if (_.isUndefined(inv_pnom)) {
          inv_pnom = inv_rating;
        }

        let partload = [];
        for (let i = 0; i < inv_pd_eff_pout.length; i++) partload.push(_.round(inv_pd_eff_pout[i] / inv_pnom, 2));
        if (partload[partload.length - 1] > 3) {
          partload = [];
          for (let i = 0; i < inv_pd_eff_pout.length; i++) {
            inv_pd_eff_pout[i] = inv_pd_eff_pout[i] / 100;
            partload.push(_.round(inv_pd_eff_pout[i] / inv_pnom, 2));
          }
        }
        let fixed_inverter = {};
        if (partload[partload.length - 1] <= 3) {
          fixed_inverter = {
            ...local_dropdown[preset_selected],
            inv_pnom: inv_pnom,
            inv_pd_eff_pout: inv_pd_eff_pout,
            pthresh: _.isUndefined(local_dropdown[preset_selected].pthresh) ? 0 : local_dropdown[preset_selected].pthresh,
          };
        } else {
          is_valid = false;
          // delete local_dropdown[preset_selected];
        }
      }

      if (is_valid) {
        set_is_default_selected(local_dropdown[preset_selected].default);
        let bulk_update_preset;
        if (internal_sift && using_canopy) {
          bulk_update_preset = {
            ...JSON.parse(JSON.stringify(local_dropdown[preset_selected])),
            // [_id]: local_dropdown[preset_selected].default == 1 ? undefined : local_dropdown[preset_selected][_id],
            [_id]: local_dropdown[preset_selected][_id],
          };
        } else {
          bulk_update_preset = {
            ...JSON.parse(JSON.stringify(local_dropdown[preset_selected])),
            [_id]: local_dropdown[preset_selected].default == 1 ? undefined : local_dropdown[preset_selected][_id],
            // [_id]: local_dropdown[preset_selected][_id],
          };
        }

        dispatch(inputsActions.update_bulk_inputs(bulk_update_preset));

        if (internal_sift && using_canopy && canopy_length > 0 && bulk_update_preset.mod_id) {
          // this timeout seems to improve perf a little bit. After we update bulk inputs,
          // this runs which hits the redux store, and loops through the canopies and updates module inputs and redraws accordingly.
          setTimeout(() => {
            // change the module of all canopies that are locked if the user selects a new module.
            // params are (key, preset inputs, changed_from_input_header)
            dispatch(canopyActions.applyGlobalOverride("module", bulk_update_preset, true));
          }, 500);
        }
      }
    }

    // dispatch(siftActions.dropDownSelect(value));
  }
  function onSaveInputPressedNew(event) {
    // this function is used by save, saveas, and delete buttons

    let key = event.key;
    let input = JSON.parse(JSON.stringify(inputs));
    let preset = get_preset_inputs(input, tab, key);

    // console.log(tab, key, inputs, preset);
    preset.default = 0;

    if (key === "delete") {
      preset.delete = "delete";
    }

    dispatch(siftActions.saveInput(preset, tab, key, _id));
  }
  function onSaveInputPressed(key) {
    // this function is used by save, saveas, and delete buttons

    let input = JSON.parse(JSON.stringify(inputs));
    let preset = get_preset_inputs(input, tab, key);

    // console.log(tab, key, inputs, preset);
    preset.default = 0;

    if (key === "delete") {
      preset.delete = "delete";
    }

    // if (key == "saveas") {
    //   // if (input.id == 0) {
    //   //   let tabName = sift.ioManager.uiState.tab.charAt(0).toUpperCase() + sift.ioManager.uiState.tab.slice(1);
    //   //   input.data.name = `Custom ${tabName} [${Date.now()}]`
    //   // } else {
    //   //   input.data.name = `${input.data.name} - copy`
    //   // }

    //   input.data.name = `${input.data.name} - copy`;

    //   input.id = "";
    //   input.data.id = "";
    //   input.data.default = 0;
    // } else if (key == "delete") {
    //   input.delete = "delete";
    // } else if (key == "save") {
    //   if (input.id == 0) {
    //     input.id = "";
    //     input.data.id = "";
    //     input.data.default = 0;
    //   }
    // }
    dispatch(siftActions.saveInput(preset, tab, key, _id));
  }

  const items = [
    {
      label: "Save As",
      key: "saveas",
      icon: <SaveOutlined />,
      disabled: tab === "weather",
    },
    {
      label: "delete",
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];
  const menuProps = {
    items,
    onClick: onSaveInputPressedNew,
  };
  function capitalizeFirstLetter(type) {
    return type.replace(/^./, type[0].toUpperCase());
  }

  function isTerrasmartProduct(product_name) {
    return (
      product_name === "Terrasmart GLIDE Wave" ||
      product_name === "Terrasmart GLIDE Agile" ||
      product_name === "Terrasmart GLIDE Fuse" ||
      product_name === "Terrasmart TerraTrak 2P" ||
      product_name === "Terrasmart TerraTrak 1P"
    );
  }

  const headerClassName = `input-selector ${tab}`;
  return (
    <div className={headerClassName}>
      {tab === "config" && (
        <div className="select-content">
          <label>When your site and inputs are prepared, click Initialize SIFT and each DC:AC and GCR combination will be evaluated.</label>
        </div>
      )}
      {tab === "topo" && (
        <div className="select-content">
          {/* header - not sure what would go here */}
          {!topo_live ? (
            <label>
              Topography import is defined by map boundaries, verify boundaries before clicking import. SIFT will create slope analysis layers from the imported topography. Limits define the color
              gradients and scale of the generated layers. Slopes above limit will display in dark gray.
            </label>
          ) : (
            <label>
              Topography import is complete! Use the buttons at the bottom right of the map to toggle between slope analysis layers. Limits define the color gradients and scale of the generated layers
              and cannot be modified after import. Slopes above set limits are displayed in dark gray.
            </label>
          )}
        </div>
      )}

      {(tab === "module" || tab === "inverter" || tab === "racking" || tab === "layout" || tab === "weather" || tab === "finance" || tab === "performance") && (
        <InputHeaderWrap changeFillOpacity={(tab === "finance" && !do_finance) || (tab === "module" && simple_module == 1) || (tab === "inverter" && simple_inverter == 1)}>
          <div className="select-content" id="import-menu">
            <Select
              disabled={(tab === "finance" && !do_finance) || saving}
              showSearch
              size="small"
              style={{ width: 130, marginBottom: 5, marginRight: 5, marginLeft: 5 }}
              placeholder={`Select a ${tab} Preset`}
              optionFilterProp="children"
              onChange={(value) => {
                onDropDownSelect(value);
              }}
              popupMatchSelectWidth={290}
              dropdownStyle={{ width: 290 }}
              value={saving ? "Loading..." : `Search...`}
              filterOption={(input, option) => {
                // let index = tab === "weather" ? 1 : 2;
                let index = 1;
                // console.log("options", option);
                return option.children[index]?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {/* Drop in any custom modules the user has saved to their account */}
              {Object.values(local_dropdown).map((item, index) => {
                if (tab === "weather") {
                  if (item.default && item.default == 1) {
                    return null;
                  } else {
                    return (
                      <Select.Option value={item[_id]} key={item[_id]}>
                        <span>{tab === "weather" && item[_id] == 0 ? <CloudSyncOutlined /> : item.default && item.default == 1 ? <LockOutlined /> : <UserOutlined />}</span>
                        <span>{item[name]}</span>
                      </Select.Option>
                    );
                  }
                } else {
                  return (
                    <Select.Option value={item[_id]} key={item[_id]}>
                      <span>
                        {tab === "weather" && item[_id] == 0 ? (
                          <CloudSyncOutlined />
                        ) : tab === "racking" && isTerrasmartProduct(item.racking_id) ? (
                          <TerrasmartIcon />
                        ) : item.default && item.default == 1 ? (
                          <LockOutlined />
                        ) : (
                          <UserOutlined />
                        )}
                      </span>
                      <span>{tab === "layout" ? item.layout_name || "My Custom Layout" : item[name]}</span>
                    </Select.Option>
                  );
                }
              })}
            </Select>

            {/* INPUT MANAGER */}
            <div className="button-group">
              <ButtonGroup style={{ display: "flex" }}>
                {(tab === "module" || tab === "inverter" || tab === "weather") && (
                  <Button
                    label="Import"
                    size="small"
                    type="ghost"
                    icon="ImportOutlined"
                    // loading={saving}
                    disabled={saving}
                    onClick={() => dispatch(siftActions.toggleImportVis(true))}
                  />
                )}

                {/* <Tooltip
										placement="top"
										title={
											(tab == "module" || tab == "inverter" || tab == "weather") 
											&& !can_save 
											&& !is_generic 
											? `${capitalizeFirstLetter(tab)} import and save limited to paid plans.` : null}
									> */}
                <Button
                  label="Store"
                  size="small"
                  type="ghost"
                  id="save-as-btn"
                  icon="StoreImportIcon"
                  // loading={saving}
                  disabled={
                    (tab === "finance" && !do_finance) ||
                    (tab === "module" && simple_module == 1) ||
                    (tab === "inverter" && simple_inverter == 1) ||
                    (tab === "racking" && isTerrasmartProduct(inputs.racking_id))
                  }
                  onClick={() => onSaveInputPressed("save")}
                />

                {/* </Tooltip> */}
                <Dropdown
                  trigger={["click"]}
                  menu={menuProps}
                  //overlay={<MenuOverlay type={tab} can_delete={can_delete} onMenuClick={onSaveInputPressed} is_default_selected={is_default_selected} />}
                  placement="bottomRight"
                  disabled={(tab === "finance" && !do_finance) || (tab === "module" && simple_module == 1) || (tab === "inverter" && simple_inverter == 1) || !can_save_as || saving}
                >
                  <Button label="..." size="small" type="ghost" style={{ paddingLeft: "8px", paddingRight: "8px" }} disabled={tab === "finance" && !do_finance} />
                </Dropdown>
              </ButtonGroup>
            </div>
          </div>

          {tab === "finance" && (
            <div className="input-name">
              <div className="input-row name">
                <label>Enable Finance</label>
                <Switch
                  size="small"
                  checked={do_finance}
                  onChange={() => {
                    updateInput("do_finance", do_finance ? 0 : 1);
                  }}
                  disabled={running}
                />
              </div>
            </div>
          )}

          <div className="input-name">
            <div className="input-row name">
              <label>Name</label>
              <Input
                id={name}
                onFocus={() => dispatch(inputsActions.update_ui_state("disable_hotkeys", true))}
                onBlur={() => dispatch(inputsActions.update_ui_state("disable_hotkeys", false))}
                onChange={(event) => {
                  updateInput(event.target.id, event.target.value);
                }}
                value={inputs[name]}
                size="small"
                placeholder="Enter Name for Preset"
                disabled={saving}
              />
            </div>
          </div>
        </InputHeaderWrap>
      )}
    </div>
  );
};

export { InputHeader };
