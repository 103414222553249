import { useEffect } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";
import { usePrevious, NaturalLocations } from "../../Redux";

const MapboxViewHandler = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const map_center = useSelector((state) => state.inputs.map_center);
  const site_features = useSelector((state) => state.inputs.site_features);
  const user_map_center = useSelector((state) => state.user.map_center);
  const use_random_map_location = useSelector((state) => state.user.use_random_map_location);
  const project_loading = useSelector((state) => state.projects.loading);
  const new_project_loading = useSelector((state) => state.sift.projectManager.new_project_loading);
  const prev_new_project_loading = usePrevious(new_project_loading);
  const getting_user_data = useSelector((state) => state.user.getting_user_data);

  const pan_to_feature_id = useSelector((state) => state.inputs.pan_to_feature_id);
  const pan_to_feature_id_prev = usePrevious(pan_to_feature_id);

  useEffect(() => {
    if (!map) return;

    if (prev_new_project_loading == false && new_project_loading == true) {
      if (!use_random_map_location) {
        map.fitBounds(
          [
            [user_map_center[1], user_map_center[0]],
            [user_map_center[1], user_map_center[0]],
          ],
          { maxZoom: 18, duration: 2000 }
        );
      }
    }
  }, [new_project_loading]);

  useEffect(() => {
    if (!map) return;
    if (use_random_map_location || use_random_map_location == undefined) {
      getEarthView();
    } else {
      map?.fitBounds(
        [
          [user_map_center[1], user_map_center[0]],
          [user_map_center[1], user_map_center[0]],
        ],
        { maxZoom: 14, duration: 2000 }
      );
    }
  }, [getting_user_data, use_random_map_location]);

  const getEarthView = () => {
    let views = NaturalLocations;
    let viewIndex = Math.floor(Math.random() * Object.values(views).length);
    const location = views[viewIndex];
    map.panTo([location.latlng.lng, location.latlng.lat], {
      duration: 1000,
      zoom: location.zoomLevel,
    });
    // map.flyTo({ center: [location.latlng.lng, location.latlng.lat], zoom: location.zoomLevel });
  };

  useEffect(() => {
    if (!map) return;
    map.flyTo({ center: [map_center[1], map_center[0]], zoom: 14, duration: 2000 });
  }, [map_center]);

  return null;
};

export { MapboxViewHandler };
