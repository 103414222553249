import React, { useState } from "react";
import { TabWrapper, TabItem } from "./styles";

const Tabs = ({ defaultActiveKey, children }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  const handleTabClick = (key) => {
    setActiveKey(key);
  };

  return (
    <>
      <TabWrapper>
        {React.Children.map(children, (child) => {
          if (child.type === Tab) {
            const { eventKey, label } = child.props;
            return (
              <TabItem active={activeKey == eventKey} onClick={() => handleTabClick(eventKey)}>
                {label}
              </TabItem>
            );
          }
          return null;
        })}
      </TabWrapper>
      {React.Children.map(children, (child) => {
        if (child.type === Tab && child.props.eventKey == activeKey) {
          return child.props.children;
        }
        return null;
      })}
    </>
  );
};

const Tab = ({ eventKey, label, children }) => (
  <div eventKey={eventKey}>
    <div>{label}</div>
    {children}
  </div>
);

export { Tabs, Tab };
