import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useMap } from "react-map-gl";
import mapboxgl from "mapbox-gl";

const MapboxImageLayer = () => {
  const images = useSelector((state) => state.canopy.images);
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  // Using refs instead of state for dragging and draggedImage
  const draggingRef = useRef(false);
  const draggedImageRef = useRef(null);
  const currentMarkerRef = useRef(null);
  const markersRef = useRef([]); // Store marker instances
  const startCoordRef = useRef(null);

  useEffect(() => {
    if (!map || !images || Object.values(images).length === 0) return;

    Object.values(images).forEach((image, index) => {
      const imageId = `imported-image-${index}`;

      // Check if the image is already added
      if (map.getSource(imageId)) {
        return;
      }

      // Create a new image from the base64 data
      const imageBlob = new Image();
      imageBlob.src = image.imgSrc;

      imageBlob.onload = () => {
        // Get map center and set initial image position
        const center = map.getCenter();
        const { lng, lat } = center;

        // Calculate aspect ratio
        const aspectRatio = imageBlob.width / imageBlob.height;
        const imageHeight = 0.005; // Set a default height
        const imageWidth = imageHeight * aspectRatio;

        const imageBounds = [
          [lng - imageWidth / 2, lat - imageHeight / 2], // Bottom-left corner
          [lng + imageWidth / 2, lat + imageHeight / 2], // Top-right corner
        ];

        const initialCoordinates = [
          [imageBounds[0][0], imageBounds[1][1]], // Top-left
          [imageBounds[1][0], imageBounds[1][1]], // Top-right
          [imageBounds[1][0], imageBounds[0][1]], // Bottom-right
          [imageBounds[0][0], imageBounds[0][1]], // Bottom-left
        ];

        // Add the image as a source
        map.addSource(imageId, {
          type: "image",
          url: imageBlob.src, // Use the base64 image URL
          coordinates: initialCoordinates,
        });

        // Add the raster layer for the image
        map.addLayer({
          id: imageId,
          type: "raster",
          source: imageId,
          paint: {
            "raster-opacity": 0.6,
          },
        });

        // Add resize markers
        const createMarker = (coord, markerIndex) => {
          const el = document.createElement("div");
          el.className = "image-marker";
          el.style.width = "10px";
          el.style.height = "10px";
          el.style.backgroundColor = "#002bcb";
          el.style.borderRadius = "50%";

          const marker = new mapboxgl.Marker(el).setLngLat(coord).addTo(map);

          el.addEventListener("mousedown", (e) => {
            e.stopPropagation();
            currentMarkerRef.current = markerIndex; // Use ref for current marker
            startCoordRef.current = coord;
            map.getCanvas().style.cursor = "nwse-resize";
          });

          return marker;
        };

        markersRef.current = initialCoordinates.map((coord, index) => createMarker(coord, index));

        // Set up dragging for image
        map.on("mousedown", (e) => {
          if (map.queryRenderedFeatures(e.point, { layers: [imageId] })) {
            draggingRef.current = true; // Use ref instead of state
            draggedImageRef.current = imageId; // Use ref instead of state
            startCoordRef.current = e.lngLat;
            map.getCanvas().style.cursor = "move";
            map.dragPan.disable();
          }
        });

        map.on("mousemove", (e) => {
          if (draggingRef.current && draggedImageRef.current === imageId) {
            // Get the new mouse position and update the image coordinates
            const { lng, lat } = e.lngLat;
            const dx = lng - startCoordRef.current.lng;
            const dy = lat - startCoordRef.current.lat;

            const updatedCoordinates = initialCoordinates.map(([lng, lat]) => [lng + dx, lat + dy]);

            map.getSource(imageId).setCoordinates(updatedCoordinates);

            markersRef.current.forEach((marker, index) => {
              marker.setLngLat(updatedCoordinates[index]);
            });

            startCoordRef.current = e.lngLat; // Update start coordinate
          }

          if (currentMarkerRef.current !== null) {
            const updatedCoordinates = [...initialCoordinates];
            updatedCoordinates[currentMarkerRef.current] = [e.lngLat.lng, e.lngLat.lat];
            map.getSource(imageId).setCoordinates(updatedCoordinates);
            markersRef.current[currentMarkerRef.current].setLngLat(e.lngLat);
          }
        });

        map.on("mouseup", () => {
          if (draggingRef.current) {
            draggingRef.current = false;
            draggedImageRef.current = null;
            map.getCanvas().style.cursor = "";
            map.dragPan.enable();
          }

          if (currentMarkerRef.current !== null) {
            currentMarkerRef.current = null;
            map.getCanvas().style.cursor = "";
          }
        });
      };
    });
  }, [map, images]);

  return null;
};

export { MapboxImageLayer };
