import React, { createContext, useContext, useRef, useState, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

mapboxgl.accessToken = "pk.eyJ1IjoibnNhbmRlcnNvbngiLCJhIjoiY2x1c2I0aW43MDEyNDJqbnVoazNzZnhnMSJ9.gkvHjLK-5PZAJp8x5t52CQ";

const MapboxMapContext = createContext(null);

const MapboxMapProvider = ({ children, mapboxConfig }) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [mapHasFocus, setMapHasFocus] = useState(false);

  useEffect(() => {
    if (map) return;

    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      ...mapboxConfig,
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });

    newMap.addControl(geocoder, "top-right");

    const navControl = new mapboxgl.NavigationControl({
      showCompass: true,
      showZoom: true,
    });

    newMap.addControl(navControl, "top-right");

    newMap.on("load", () => {
      newMap.addLayer({
        id: "sky",
        type: "sky",
        paint: {
          "sky-type": "atmosphere",
          "sky-atmosphere-sun": [0.0, 9.0],
          "sky-atmosphere-sun-intensity": 12, // the lower the number, the more it looks like a sunset.
          "sky-atmosphere-halo-color": "#ff0000", // Red halo around the sun
          "sky-atmosphere-sun-color": "#ffff00", // Yellow sun
        },
      });

      newMap.addSource("mapbox-dem", {
        type: "raster-dem",
        url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        tileSize: 512,
        maxzoom: 14,
      });

      newMap.setTerrain({ source: "mapbox-dem", exaggeration: 1.0 });

      newMap.setLight({
        anchor: "viewport",
        color: "white",
        intensity: 0.5,
        position: [1.5, 90, 80], // [azimuthal angle, polar angle, radial distance]
      });

      setMap(newMap);
    });

    // Add focus/blur event listeners
    newMap.on("focus", () => setMapHasFocus(true));
    newMap.on("blur", () => setMapHasFocus(false));

    return () => {
      if (newMap) {
        newMap.off("focus");
        newMap.off("blur");
        newMap.remove();
      }
    };
  }, [map, mapboxConfig]);

  return (
    <MapboxMapContext.Provider value={{ map, mapHasFocus }}>
      <section ref={mapContainer} style={{ width: "100%", height: "100%" }} />
      {children}
    </MapboxMapContext.Provider>
  );
};

const useMapboxMap = () => {
  return useContext(MapboxMapContext);
};

export { MapboxMapProvider, useMapboxMap };
