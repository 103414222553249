import React, { useState, useEffect, useRef } from "react";

import { ZoomControl } from "../ZoomControl";

import { MapContainer, LayerGroup, GeoJSON, Polygon, Marker } from "react-leaflet";
import { LiveReportImageLayer } from "../../../Map/components/ImageLayer";

import { MapLayoutLayer } from "../../../Map/components";
//styles
import { LiveReportMapWrapper } from "./styles";

// redux
import { useSelector, useDispatch } from "react-redux";

// helpers
import { siftActions, getBounds } from "../../../Redux";

//map tiles
import { BingLayer } from "../../../Map/components";

const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";

const TileSets = {
  Satellite: "Aerial",
  "Satellite With Labels": "AerialWithLabels",
  "Satellite With OnDemand Labels": "AerialWithLabelsOnDemand",
  "Street Map": "Road",
  // "Street Map Dark": "CanvasDark",
  // "CanvasLight": "CanvasLight",
  // "CanvasGray": "CanvasGray"
};
const basePolyStates = {
  Boundary: true,
  Exclusions: true,
  Inactive: true,
  Layout: true,
};
// const WiringKeys = ["racks"];
const WiringKeys = ["inverter", "racks"];

const zoomGranularity = 0.25;

// const LiveReportMap = ({ mapName, showToggles, addMapRefs }) => {
//   const mapCenter = useSelector((state) => state.inputs.map_center);
//   const [position, setPosition] = useState(mapCenter);
//   const [zoom, setZoom] = useState(mapName == "vicinityMap" ? 11 : 16);

//   return (
//     <MapContainer
//       id={mapName}
//       className={mapName}
//       animate={true}
//       noWrap={true}
//       attributionControl={false}
//       center={mapCenter}
//       zoom={zoom}
//       minZoom={0}
//       maxZoom={25}
//       zoomControl={false}
//       zoomSnap={zoomGranularity}
//       zoomDelta={zoomGranularity}
//       style={{ width: "100%", height: "100%" }}
//       onzoomend={(e) => {
//         // console.log(e.target._zoom);
//       }}
//     >
//       <LiveReportMapGuts mapName showToggles addMapRefs />
//     </MapContainer>
//   );
// };

const LiveReportMap = ({ mapName, showToggles, addMapRefs }) => {
  // const map = useMap();
  const dispatch = useDispatch();

  const features = useSelector((state) => state.inputs.site_features);
  const layout = useSelector((state) => state.sift.ioManager.outputs.layout);
  const roads = useSelector((state) => state.sift.ioManager.outputs.layout.roads);
  const layer_toggle = useSelector((state) => state.sift.ioManager.uiState.layer_toggle);
  const mapCenter = useSelector((state) => state.inputs.map_center);
  const [position, setPosition] = useState(mapCenter);

  const [activeTileSet, setActivetileSet] = useState(mapName === "vicinityMap" ? "Street Map" : "Satellite");
  const [showControls, setShowControls] = useState(false);
  //const [polygonStates, setPolygonStates] = useState(JSON.parse(JSON.stringify(basePolyStates)));

  const [zoom, setZoom] = useState(mapName === "vicinityMap" ? 11 : 16);
  const [showBoundary, setShowBoundary] = useState(true);
  const [showExclusion, setShowExclusion] = useState(true);
  const [showInactive, setShowInactive] = useState(true);
  const [show_racks, set_show_racks] = useState(true);
  const [show_roads, set_show_roads] = useState(true);
  const [show_inverters, set_show_inverters] = useState(true);

  const [map, setMap] = useState(null);

  // const liveReportMap = useRef();
  const markerRef = useRef();

  const [bingLayerVisible, setBingLayerVisible] = useState(true);

  useEffect(() => {
    setBingLayerVisible(true);
  }, [activeTileSet]);

  useEffect(() => {
    if (!map) return;
    addMapRefs(`${mapName}`, { height: map._size.y, width: map._size.x, className: mapName });
    if (mapName !== "vicinityMap") {
      setTimeout(() => {
        handleLiveMapZoomExtents();
      }, 500);
    }
  }, [map]);

  const handleMarkerDrag = () => {
    if (markerRef != null) {
      let pos = markerRef.current.getLatLng();
      setPosition(pos);
      let newPos = [pos.lat, pos.lng];
      dispatch(siftActions.updateReportData("location", newPos));
    }
  };

  const handleLiveMapZoomExtents = (zoomLevel = undefined) => {
    if (Object.values(features).length === 0) return;

    let bounds = getBounds(Object.values(features));

    if (zoomLevel) {
      map.fitBounds(
        [
          [bounds[1], bounds[0]],
          [bounds[3], bounds[2]],
        ],
        { maxZoom: zoomLevel }
      );
    } else {
      map.fitBounds([
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ]);
    }
  };

  return (
    <LiveReportMapWrapper onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)} small={true}>
      {showToggles ? (
        <section className="map-toggles">
          <h3>Layout Map</h3>
          <section className="map-toggle-buttons">
            <div className="toggle">
              <p>Show Boundaries</p>
              <label className="switch">
                <input type="checkbox" checked={showBoundary} onChange={(e) => setShowBoundary(!showBoundary)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Exclusions</p>
              <label className="switch">
                <input type="checkbox" checked={showExclusion} onChange={(e) => setShowExclusion(!showExclusion)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Inactive</p>
              <label className="switch">
                <input type="checkbox" checked={showInactive} onChange={(e) => setShowInactive(!showInactive)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Racks</p>
              <label className="switch">
                <input type="checkbox" checked={show_racks} onChange={(e) => set_show_racks(!show_racks)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Roads</p>
              <label className="switch">
                <input type="checkbox" checked={show_roads} onChange={(e) => set_show_roads(!show_roads)} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="toggle">
              <p>Show Inverters</p>
              <label className="switch">
                <input type="checkbox" checked={show_inverters} onChange={(e) => set_show_inverters(!show_inverters)} />
                <span className="slider round"></span>
              </label>
            </div>
          </section>
        </section>
      ) : (
        <section className="map-title">
          <h3>Vicinity Map</h3>
        </section>
      )}

      <section className="map-area">
        <MapContainer
          whenReady={(map) => setMap(map.target)}
          // whenCreated={(map) => console.log("map", map)}
          id={mapName}
          key={mapName}
          className={mapName}
          animate={true}
          noWrap={true}
          attributionControl={false}
          center={mapCenter}
          zoom={zoom}
          minZoom={2}
          maxZoom={20}
          zoomControl={false}
          zoomSnap={zoomGranularity}
          zoomDelta={zoomGranularity}
          style={{ width: "100%", height: "100%" }}
          onzoomend={(e) => {
            // console.log(e.target._zoom);
          }}
        >
          {bingLayerVisible && <BingLayer bingkey={bing_key} type={TileSets[activeTileSet]} maxZoom={25} maxNativeZoom={18} />}

          {showControls && map && <ZoomControl mapRef={map} mapName={mapName} position={position} />}
          {/* <ImageEventHandler is_interactive={false} />
          <ImageLayer is_interactive={false} /> */}

          {mapName === "vicinityMap" ? (
            <Marker ref={markerRef} draggable={true} ondragend={handleMarkerDrag} position={position} />
          ) : (
            <>
              {map && <LiveReportImageLayer platform={""} map={map.target} />}
              <LayerGroup key="site-features">
                {features &&
                  Object.values(features).map((poly) => {
                    if (poly.properties.identity === 0 && !showInactive) return;
                    if (poly.properties.identity === 1 && !showBoundary) return;
                    if (poly.properties.identity === 2 && !showExclusion) return;
                    return (
                      <GeoJSON
                        data={poly}
                        key={poly.properties.index}
                        style={{
                          fillColor: "#ffffff",
                          fillOpacity: 0.01,
                          weight: 1,
                          color:
                            poly.properties.identity === 0 && showInactive
                              ? "white"
                              : poly.properties.identity === 1 && showBoundary
                              ? "red"
                              : poly.properties.identity === 2 && showExclusion
                              ? "yellow"
                              : "white",
                        }}
                      ></GeoJSON>
                    );
                  })}
              </LayerGroup>

              <MapLayoutLayer show_racks={show_racks} show_roads={show_roads} show_inverters={show_inverters} liveReportMap={true} />
            </>
          )}
        </MapContainer>
      </section>
    </LiveReportMapWrapper>
  );
};

export { LiveReportMap };
