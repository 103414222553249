const ensureMultiPolygonFormat = (feature) => {
  if (!feature.geometry || !feature.geometry.coordinates) {
    throw new Error("Invalid feature format: Missing geometry or coordinates.");
  }

  const { type, coordinates } = feature.geometry;

  // Check to see if geometry is already in a MultiPolygon format
  if (type === "MultiPolygon") {
    if (Array.isArray(coordinates) && Array.isArray(coordinates[0]) && Array.isArray(coordinates[0][0]) && !Array.isArray(coordinates[0][0][0])) {
      // Wrap coordinates to fit MultiPolygon structure if we need to
      return {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: [coordinates],
        },
      };
    }
    return feature; // Already properly formatted
  }

  // If it's a Polygon, we wrap it to make it a MultiPolygon
  if (type === "Polygon" && Array.isArray(coordinates)) {
    return {
      ...feature,
      geometry: {
        type: "MultiPolygon",
        coordinates: [coordinates],
      },
    };
  }

  throw new Error("Unsupported geometry type or invalid coordinates. Only Polygon and MultiPolygon are supported.");
};

export { ensureMultiPolygonFormat };
