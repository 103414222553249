import styled, { css } from "styled-components";

const ProjectsWrap = styled.section`
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr;
`;

const DataMangagerTableWrap = styled.section`
  table-layout: fixed;
  padding: 0;
  overflow-y: auto;

  table {
    margin: 0;
    width: 100%;
    empty-cells: show;
    outline: 0;
    border-spacing: 0;
    border-bottom: 1px solid var(--light-border);

    thead {
      border-top: 1px solid var(--primary-text-color);
      border-bottom: 1px solid var(--primary-text-color);
    }

    tbody {
      tr {
        border-bottom: 1px solid var(--light-border);
        height: 32px;

        :hover {
          cursor: pointer;
          /* border: 1px solid white; */
          background-color: var(--background-hover-color);
        }
      }

      .expanded-row {
        border-bottom: 1px solid var(--light-border);
        background-color: '#012bcb29';

        :hover {
          /* background-color: var(--background-hover-color); */
        }
      }

      .selected-row {
        border-bottom: none;
        background-color: var(--background-highlight-color);
        /* border-top: 1px solid var(--light-border); */
      }
    }

    tr {
      /* cursor: pointer; */

      :last-child {
        td {
          border-bottom: 0;
          white-space: nowrap;
        }
      }
    }

    th,
    td {
      margin: 0;

      :last-child {
        border-right: 0;
      }
    }

    th {
      padding: 5px 5px;
      text-align: left;
      color: var(--primary-text-color);
    }
  }

  .expander {
    text-align: center;
    cursor: pointer;
  }

  .pagination {
    padding: 0.5rem;
    display: flex;
    gap: 1em;
    align-items: center;
  }

  .variant-row {
    /* display: grid;
    grid-template-columns: repeat(6, );
    gap: 10px;
    margin-top: 10px; */
    /* border: 0.5px solid; */
    /* background-color: var(--background-highlight-color); */

    :hover {
      background-color: var(--background-highlight-color);

      /* border: 1px solid white; */
    }
  }
`;

const PresetManagerWrap = styled.section`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(7, min-content);

  .preset-row {
    padding: 5px 10px;
    height: 32px;
    display: grid;
    grid-template-columns: 75px 1fr 1fr;
    align-items: center;
    gap: 0px 50px;
    border-bottom: 1px solid var(--light-border);

    p {
      font-weight: bold;
      margin: 0px;
    }
  }
`;

const RowContentWrap = styled.section`
  width: 100%;
  padding: 0px;
  overflow: hidden;
  transition: max-height 0.4s var(--easing);
  max-height: 0;
  box-sizing: border-box;
  border-bottom: 1px solid transparent;
  display: flex;
  justify-content: center;

  ${(props) =>
    props.open &&
    css`
      overflow: auto;
      padding: 10px;
      border-bottom: 1px solid var(--light-border);
      max-height: 2300px;
    `}
`;

const ImportBoxWrap = styled.section`
  width: 100%;
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 10px 0px;
`;

const ImportRowButtons = styled.section`
  grid-row: 1;
  justify-self: end;
`;

const ImportWrap = styled.section`
  grid-row: 2;
  width: 100%;
  height: 320px;
  padding: 10px;
  /* border-radius: 4px;
  border: 1px solid var(--primary-brand-color);
  box-shadow: var(--subtle-box-shadow); */
  justify-self: center;
`;

const EditWrap = styled.section`
  width: 100%;
  padding: 10px;
  line-break: anywhere;
  border-radius: 4px;
  border: 1px solid var(--primary-brand-color);
  box-shadow: var(--subtle-box-shadow);
`;

const EditActions = styled.section`
  width: 100%;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr min-content;
  margin: 10px 0px;

  h3 {
    margin: 0px;
  }
`;

export { ProjectsWrap, DataMangagerTableWrap, PresetManagerWrap, RowContentWrap, ImportWrap, EditWrap, EditActions, ImportBoxWrap, ImportRowButtons };

/// original styles ///

// const Styles = styled.section`;
//   table-layout: fixed;
//   padding: 5px;

//   table {
//     margin: 0;
//     width: 100%;
//     border-width: 0;
//     border-color: inherit;
//     border-collapse: initial;
//     border-spacing: 0;
//     empty-cells: show;
//     outline: 0;
//     border-spacing: 0;
//     border: 1px solid black;

//     tbody tr {
//       :hover {
//         border: 1px solid white;
//         background-color: var(--background-hover-color);
//       }
//     }
//     .expanded-row {
//       border-left: 2px solid;
//       border-bottom: 3px solid;
//       border-right: 2px solid !important;

//       :hover {
//         background-color: var(--background-hover-color);
//       }
//     }
//     tr {
//       cursor: pointer;
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//     }
//     th {
//       padding: 5px 5px;
//       text-align: center;
//       /* background-color: #66656533; */
//       color: black;
//     }
//     .selected-row {
//       background-color: #66656533;
//     }
//     .expander {
//       text-align: center;
//     }
//   }

//   .pagination {
//     padding: 0.5rem;
//     text-align: right;
//   }

//   .variant-row {
//     display: grid;
//     grid-template-columns: 3% 44% 23% auto;
//     gap: 10px;
//     border: 0.5px solid;
//     :hover {
//       border: 1px solid white;
//       background-color: #66656533;
//     }
//   }
// `;
