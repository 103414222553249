import styled from "styled-components";

const SearchLocationWrap = styled.section`
  .mapboxgl-ctrl-geocoder--input {
    height: 32px;
    border-radius: 2px;
    border: 1px solid var(--primary-brand-color);
  }
`;

export { SearchLocationWrap };
