import { alertConstants } from "../_constants";

export const alertActions = {
  success,
  error,
  loading,
  clear,
  cancel,
  info,
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function loading(message) {
  return { type: alertConstants.LOADING, message };
}

function info(message) {
  return { type: alertConstants.INFO, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear(messages) {
  return { type: alertConstants.CLEAR, messages };
}

function cancel() {
  return { type: alertConstants.CANCEL };
}
