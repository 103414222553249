import Map, { FullscreenControl, NavigationControl } from "react-map-gl";
let token = "pk.eyJ1IjoibnNhbmRlcnNvbngiLCJhIjoiY2x1c2I0aW43MDEyNDJqbnVoazNzZnhnMSJ9.gkvHjLK-5PZAJp8x5t52CQ";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MapboxFeatureHandler } from "./MapboxFeatureHandlers";
import { MapboxDrawProvider, MapboxLayoutLayers, MapboxContext, MapboxMarker, MapboxToolbar, MapboxViewHandler, MapNav, ESRI_Layers, MapboxMessageBox, MapboxTopoLayer, MapboxImageLayer } from "./";
import { ShadeDrawingLayers, ShadeLayer } from "./MBEnvironmentalShade";
import { MBVisualShadeArea } from "./MBEnvironmentalShade";
const TileSets = {
  Mapbox: { type: "LD", data: `satellite-v9` },
  Satellite: { type: "LD", data: "satellite-streets-v12" },
  StreetMap: { type: "LD", data: "streets-v12" },
  Outdoors: { type: "LD", data: "outdoors-v12" },
  // Test: { type: "LD", data: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}" },
  // SatelliteHD: { type: "HD", data: "Aerial" },
  // StreetMapHD: { type: "HD", data: "Road" },
  // Satellite_With_Labels: { type: "HD", data: "AerialWithLabels" },
};

const MapboxMap = () => {
  const [map, setMap] = useState(null);

  const map_center = useSelector((state) => state.inputs.map_center);
  const tile_set = useSelector((state) => state.inputs.uiState.tile_set || "Mapbox");

  const onMapLoad = (event) => {
    const mapInstance = event.target;
    setMap(mapInstance);
  };

  // useEffect(() => {
  //   if (!map) return;

  //   const handleZoom = () => {
  //     if (map.getZoom() > 12) {
  //       // Load terrain source and layer only at high zoom levels
  //       if (!map.getSource("mapbox-dem")) {
  //         map.addSource("mapbox-dem", {
  //           type: "raster-dem",
  //           url: "mapbox://mapbox.mapbox-terrain-dem-v1",
  //           tileSize: 512,
  //           maxzoom: 14,
  //         });

  //         map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
  //       }
  //     } else {
  //       if (map.getSource("mapbox-dem")) {
  //         map.setTerrain(null);
  //         map.removeLayer("mapbox-dem");
  //         map.removeSource("mapbox-dem");
  //       }
  //     }
  //   };

  //   map.on("zoom", handleZoom);
  //   return () => {
  //     map.off("zoom", handleZoom);
  //   };
  // }, [map]);

  return (
    <Map
      mapboxAccessToken={token}
      id="MapboxMap"
      preserveDrawingBuffer={true}
      initialViewState={{
        longitude: map_center[1],
        latitude: map_center[0],
        zoom: 14,
        bearing: 0,
        pitch: 0,
      }}
      doubleClickZoom={false}
      onLoad={onMapLoad}
      style={{ width: "100%", height: "100%" }}
      // onLoad={onMapLoad}
      mapStyle={`mapbox://styles/mapbox/${TileSets[tile_set].data}`}
      attributionControl={false}
    >
      {map && (
        <MapboxDrawProvider map={map}>
          {/* Map controls */}
          <MapboxToolbar />
          <MapNav />
          <FullscreenControl />
          <NavigationControl />
          <MapboxViewHandler />
          <MapboxContext />
          <MapboxMarker />
          {/* <MapboxMessageBox /> */}
          {/* all feature handlers */}
          <MapboxFeatureHandler />
          <ESRI_Layers />
          {/* layout layers and edited layout layers */}
          <MapboxLayoutLayers />

          {/* shade drawing stuff */}
          <ShadeDrawingLayers />
          <ShadeLayer />
          <MBVisualShadeArea />
          <MapboxImageLayer />
          <MapboxTopoLayer />
        </MapboxDrawProvider>
      )}
    </Map>
  );
};

export { MapboxMap };
