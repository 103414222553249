import React, { useState, useEffect } from "react";
import { useMap } from "react-map-gl";
import { MapNavWrap } from "./styles";
import { getBounds } from "../..";
import { useSelector } from "react-redux";

import { extents } from "../../../assets/images";
import { Tooltip } from "antd";

const MapNav = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const site_features = useSelector((state) => state.inputs.site_features);

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (!map) return;

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, [map]);

  const handleZoomIn = () => {
    map.zoomIn();
  };

  const handleZoomOut = () => {
    map.zoomOut();
  };

  const handleResetBearing = () => {
    if (!map) return;

    map.easeTo({
      bearing: 0,
      duration: 500,
    });
  };

  const handleToggleFullscreen = () => {
    if (!isFullscreen) {
      map.getContainer().requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleZoomExtents = () => {
    if (!map) return;

    if (Object.values(site_features).length === 0) {
      console.warn("No site features available");
      return;
    }

    let bounds = getBounds(Object.values(site_features));

    if (!bounds || bounds.length !== 4) {
      console.error("Invalid bounds:", bounds);
      return;
    }

    let bounds_to_fit = [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ];

    map?.fitBounds(bounds_to_fit, { duration: 2000, padding: { top: 50, bottom: 50, left: 50, right: 50 } });
  };

  return (
    <MapNavWrap>
      <section className="map-action-buttons">
        {/* <button onClick={handleToggleFullscreen}>{isFullscreen ? "EFS" : "FS"}</button>
        <button onClick={handleZoomIn}>
          <img src={zoom_in}></img>
        </button>
        <button onClick={handleZoomOut}>
          <img src={zoom_out}></img>
        </button>
        <button onClick={handleResetBearing}>RB</button> */}
        <Tooltip placement="bottom" title="Zoom Extents" mouseEnterDelay={0.5}>
          <button onClick={handleZoomExtents}>
            <img src={extents}></img>
          </button>
        </Tooltip>
      </section>
    </MapNavWrap>
  );
};

export { MapNav };
