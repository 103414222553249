import React, { useEffect, useState, useMemo } from "react";
import { useMap, Source, Layer } from "react-map-gl";
import { useSelector } from "react-redux";
import * as turf from "@turf/turf";
import customClassifyRings from "../MapboxOverrides/MapboxOverrides";

const RoadsLayer = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const roads = useSelector((state) => state.sift.ioManager.outputs.layout.roads);

  const memoized_roads = useMemo(() => roads, [roads]);

  const [road_layer, set_road_layer] = useState(undefined);

  useEffect(() => {
    if (!memoized_roads) return;

    let temp_roads = {};

    const flippedRoads = turf.flip(memoized_roads);
    const classifiedRings = customClassifyRings(flippedRoads.geometry.coordinates);

    temp_roads = {
      ...flippedRoads,
      geometry: {
        ...flippedRoads.geometry,
        coordinates: classifiedRings,
      },
      properties: {
        ...memoized_roads.properties,
        color: memoized_roads.properties.color ? memoized_roads.properties.color : "#e0710f",
      },
    };

    console.log("temp", temp_roads);

    set_road_layer(temp_roads);
  }, [memoized_roads]);

  //   useEffect(() => {
  //     if (!map || !road_layer) return;

  //     const sourceId = "polygon-source-roads";
  //     const layerId = `polygon-layer-roads`;

  //     // Check if the layer already exists and remove it
  //     if (map.getLayer(layerId)) {
  //       map.removeLayer(layerId);
  //     }

  //     // Check if the source exists and remove it
  //     if (map.getSource(sourceId)) {
  //       map.removeSource(sourceId);
  //     }

  //     map.addSource(sourceId, {
  //       type: "geojson",
  //       data: road_layer,
  //     });

  //     map.addLayer({
  //       id: layerId,
  //       type: "line",
  //       source: sourceId,
  //       paint: {
  //         "line-color": road_layer.properties.color || "#e0710f",
  //         "line-width": 1,
  //       },
  //     });
  //   }, [road_layer, map]);

  if (!road_layer || roads == undefined || Object.values(roads).length == 0) return null;

  return (
    <>
      <Source id="polygon-source-roads" key="polygon-source-roads" type="geojson" data={road_layer}>
        <Layer
          type="line"
          paint={{
            "line-color": road_layer.properties.color,
            "line-width": 1,
          }}
        />
      </Source>
    </>
  );
};

export { RoadsLayer };
