import styled from "styled-components";

const LabelWrap = styled.label`
  display: flex;
  align-items: center;
  margin: 0px 8px 0px 0px;
  color: #333;
  flex-direction: ${({ labelposition }) => (labelposition == "left" ? "row-reverse" : "row")};

  p {
    font-size: 13px;
    margin: 0px;
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  /* Hide the native checkbox */
  input[type="checkbox"] {
    display: none;
  }

  /* Custom checkbox */
  #custom-checkbox {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 2px;
    margin: 0px 5px;
    position: relative;
    cursor: pointer;

    &:hover {
      border-color: var(--primary-brand-color);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    /* Checked state */
    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 10px;
      height: 10px;
      background-color: var(--primary-brand-color);
      display: none;
    }
  }

  /* When the checkbox is checked, show the custom styling */
  input[type="checkbox"]:checked + .custom-checkbox:after {
    display: block;
  }
`;

export { LabelWrap };
