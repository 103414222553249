import styled from "styled-components";

export const ProjectWrapper = styled.section`
  display: grid;
  position: relative;
  grid-template-columns: 325px 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const InputPanelWrapper = styled.section`
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-rows: ${(props) => (props.pm_modal_visible ? "75px min-content auto" : "85px auto")};
  width: ${(props) => (props.pm_modal_visible ? "800px" : "100%")};
  height: 100vh;
  z-index: 999;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  background-color: var(--main-background-Color);
  position: relative;
  pointer-events: ${(props) => (props.isAuthed && !props.show_agreement_modal ? "all" : "none")};
  transition: width 0.5s var(--easing);

  @media (max-width: 768px) {
    position: absolute;
    grid-column: 1;
    width: 325px;
    left: ${(props) => (props.toggleMobileInputs ? "0px" : "-325px")};
    z-index: 601;
    transition: left 0.4s var(--easing);
  }

  /* .pm-toolbar {
    display: grid;
    grid-row: 1;
    grid-template-rows: 35px 35px;

    .pm-buttons {
      grid-row: 1;
      margin: 3px auto;
    }

    .pm-name {
      width: 100%;
      max-width: 325px;
      grid-row: 2;
      display: grid;
      grid-template-columns: 1fr min-content;
      padding: 0px 10px;
      box-sizing: border-box;
      position: relative;

      label {
        grid-column: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;

        & > span {
          height: 20px;
        }
      }

      span {
        grid-column: 2;
        margin-left: auto;
      }
    }
  } */

  .io-manager {
    height: 90vh;
    /* overflow-y: scroll; */
  }

  .toggle-inputs-button {
    display: none;
  }

  @media (max-width: 768px) {
    .toggle-inputs-button {
      display: block;
      position: fixed;
      background: #fff;
      bottom: 50px;
      left: ${(props) => (props.toggleMobileInputs ? "325px" : "0px")};
      height: 200px;
      width: 30px;
      margin: auto;
      z-index: 501;
      border-left: none;
      border-top: 1px solid var(--primary-brand-color);
      border-right: 1px solid var(--primary-brand-color);
      border-bottom: 1px solid var(--primary-brand-color);
      border-radius: 0px 3px 3px 0px;
      transition: left 0.4s var(--easing);
      box-shadow: inset 0 2px 10px 0 rgba(0, 0, 0, 0.1);

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const MapWrapper = styled.section`
  grid-column: 2;
  position: relative;

  @media (max-width: 768px) {
    grid-column: 1;
  }

  .map-container {
    width: 100%;
    height: 100%;
    /* height: 100vh; */
  }

  .map-loader {
    position: absolute;
    top: 20%;
    right: 25%;
    left: 25%;
    margin: 0 auto;
    z-index: 1001;
    background-color: #fff;
    border-radius: 2px;
    /* border: 1px solid #1bac51; */
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    width: 350px;
    padding: 50px;

    & > .ant-spin-nested-loading {
      position: unset;

      .ant-spin-container .content {
        min-height: unset;
      }
    }
  }

  .warning-container {
    position: absolute;
    height: 0px;
    z-index: 505;
    width: 100%;

    .warning-alert {
      margin: 12px auto;
      width: 420px;
      @media (max-width: 768px) {
        margin-top: 60px;
      }
    }
  }

  .generating-box {
    width: 100%;
    height: 100px;
    position: absolute;
    display: grid;
    place-items: center;
    bottom: 0;
    z-index: 600;

    .generating-box-contents,
    .initializing-box {
      color: #1bac51;
      height: 80px;
      width: 250px;
      background-color: #fff;
      border-radius: 2px;
      border: 1px solid #1bac51;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      p {
        font-size: 16px;
        margin: 0;
      }
    }
  }

  .results {
    width: 100%;
    max-height: 400px;
    position: absolute;
    bottom: 0;
    z-index: 505;
    display: grid;
    place-items: center;

    .results-box {
      width: 100%;
      height: 100%;
      padding: 7px;
      background-color: #fff;
      grid-template-rows: auto 1fr;
      border: 1px solid #1bac51;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);

      .results-close-button {
        z-index: 1;
        right: 0px;
        position: absolute;
        display: flex;
        justify-content: flex-end;
      }
    }

    .results-table {
      grid-row: 2;
    }
  }
`;
