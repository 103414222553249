import styled from "styled-components";

const DividerStyles = styled.span`
  width: ${(props) => (props.width ? `${props.width}` : "98%")};
  height: 1px;
  background-color: var(--light-border);
  display: block;
`;

export { DividerStyles };
