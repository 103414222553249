import styled from "styled-components";

const MapboxMessageWrap = styled.section`
  position: absolute;
  left: 10px;
  bottom: 10px;
`;

const MapMessageBoxWrap = styled.section`
  background: #fff;
  padding: 2px;
  width: fit-content;
  height: 25px;
  margin-bottom: 2px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  position: relative;
  left: -8px;
  bottom: -10px;

  .identity-link-button {
    a {
      width: auto;
      background: none;
    }

    & > span {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }
`;

export { MapMessageBoxWrap, MapboxMessageWrap };
