import React, { useEffect, useMemo, useRef } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../DrawToolProvider";
import { HandleFeatureCreation, HandleFeatureIdentity, HandleFeatureUpdate, HandleFeatureClick, HandleFeatureDelete, HandleFeatureHover, AzimuthLayer, DimensionsLayer } from ".";
import { MapboxOffset } from "../MapboxOffset";
import { useSelector } from "react-redux";

const MapboxFeatureHandler = () => {
  const site_features = useSelector((state) => state.inputs.site_features);
  const memoizedFeatures = useMemo(() => site_features, [site_features]);
  const show_azi = useSelector((state) => state.inputs.uiState.show_azi);
  const show_dims = useSelector((state) => state.inputs.uiState.show_dims);

  const is_drawing = useSelector((state) => state.inputs.uiState.is_drawing);
  const is_drawing_ref = useRef(is_drawing);

  useEffect(() => {
    is_drawing_ref.current = is_drawing;
  }, [is_drawing]);
  // const loading = useSelector((state) => state.projects.loading);
  // const loaded = useSelector((state) => state.projects.loaded);

  // const just_loaded = loading === false && prev_loading === true;

  // useEffect(() => {
  //   console.log(" cur", loaded, loading);
  //   console.log("prev", prev_loaded, prev_loading);
  //   console.log("just", just_loaded);
  // }, [loaded, loading]);

  return (
    <>
      <HandleFeatureCreation />
      {!is_drawing && <HandleFeatureClick features={site_features} />}
      {Object.values(memoizedFeatures).map((feature) => {
        let visible = feature.properties.checked == undefined ? true : feature.properties.checked ? true : false;
        if (!visible) return;
        return <GeoJsonFeature key={feature.properties.index} feature={feature} />;
      })}
      {Object.values(memoizedFeatures).length > 0 && (
        <>
          {show_azi && <AzimuthLayer features={memoizedFeatures} />}
          {show_dims && <DimensionsLayer features={memoizedFeatures} />}
        </>
      )}
    </>
  );
};

const GeoJsonFeature = ({ feature }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const draw = useMapboxDraw();

  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const memoizedFeature = useMemo(() => feature, [feature]);

  useEffect(() => {
    if (!map || !draw || !memoizedFeature) return;
    // console.log("the memorized feature:", memoizedFeature);
    const memoizedFeatureWithId = {
      ...memoizedFeature,
      id: memoizedFeature.properties.index, // add our custom id so that we can delete/highlight etc.
      properties: {
        ...memoizedFeature?.properties,
        shape: "siteFeature",
      },
    };
    draw.add(memoizedFeatureWithId);

    map.once("idle", () => {
      selectFeatureOnMap(selectedFeatureId);
    });

    return () => {
      try {
        draw.delete(memoizedFeature.properties.index);
      } catch (error) {
        console.warn("Error removing feature:", error);
      }
    };
  }, [map, memoizedFeature, memoizedFeature.properties.identity]);

  const selectFeatureOnMap = (id) => {
    if (!id) return;
    draw.changeMode("direct_select", { featureId: id });
  };

  return (
    <>
      <HandleFeatureUpdate feature={memoizedFeature} />
      <HandleFeatureIdentity feature={memoizedFeature} />
      <HandleFeatureDelete feature={memoizedFeature} />
      <HandleFeatureHover feature={memoizedFeature} />
      {selectedFeatureId && memoizedFeature.properties.index && <MapboxOffset feature_id={memoizedFeature.properties.index} />}
    </>
  );
};

export { MapboxFeatureHandler, GeoJsonFeature };
