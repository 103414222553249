import React, { useMemo, useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";
import { useSelector, useDispatch } from "react-redux";
import { getCornersOfPolygon, getCornersOfCircle } from "../ShadeHelpers";

import { canopyActions, getCenterPointofFeature } from "../../../Redux";

const TreeEventLayer = ({ tree, interactive = true }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  // const tree = useSelector((state) => state.canopy.shade_objects[id]);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);

  const selected_shade_object_id_ref = useRef(selected_shade_object_id);

  useEffect(() => {
    selected_shade_object_id_ref.current = selected_shade_object_id;
  }, [selected_shade_object_id]);

  const memoizedTree = useMemo(() => tree, [tree]);

  useEffect(() => {
    if (!map || !draw || !memoizedTree) return;
    console.log("the memorized tree:", memoizedTree);

    const memoizedTreeWithId = {
      ...memoizedTree.geoJson,
      id: tree.id, // add our custom id so that we can delete/highlight etc.
      properties: {
        ...memoizedTree.geoJson.properties,
        shape: "tree",
      },
    };

    draw.add(memoizedTreeWithId);

    map.once("idle", () => {
      selectFeatureOnMap(selected_shade_object_id_ref.current);
    });

    map.on("draw.update", handleUpdate);
    map.on("draw.delete", handleDelete);
    return () => {
      draw.delete(memoizedTree.id);
      map.off("draw.update", handleUpdate);
      map.off("draw.delete", handleDelete);
    };
  }, [map, memoizedTree]);

  const selectFeatureOnMap = (id) => {
    if (!id) return;
    draw.changeMode("direct_select", { featureId: id });
  };

  const handleDelete = (e) => {
    if (e.features[0].id === tree.id) {
      // console.log("Deleted feature:", e.features[0].id);
      dispatch(canopyActions.updateShadeObject("delete_shade_object", tree.id));
      draw.delete(e.features[0].id);
    }
  };

  const handleUpdate = (e) => {
    if (selected_shade_object_id_ref.current !== tree.id) return;
    // console.log("updating tree", e.features[0]);
    let edited_tree = { ...tree };
    edited_tree.geoJson = e.features[0];
    edited_tree.center = getCenterPointofFeature(edited_tree.geoJson);
    edited_tree.corners = getCornersOfCircle(edited_tree.geoJson);
    edited_tree.radius = edited_tree.geoJson.properties.circleRadius;
    dispatch(canopyActions.updateShadeObject("edit_shade_object", edited_tree));
    draw.delete(e.features[0].id);
  };

  // const update_tree = (layer) => {
  //   set_editing(true);
  //   let edited_tree = { ...tree };
  //   let new_geoJson = layer.toGeoJSON();
  //   edited_tree.geoJson = flipGeoJson(new_geoJson);
  //   edited_tree.center = layer.getBounds().getCenter();
  //   edited_tree.corners = getCorners(layer);
  //   edited_tree.radius = layer.getRadius();
  //   dispatch(canopyActions.updateShadeObject("edit_shade_object", edited_tree));
  // };

  return <></>;
};

export { TreeEventLayer };
