import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { TopoScaleWrap } from "./styles";
import { useMap } from "react-map-gl";

const MapboxTopoScaleLayer = ({ topo_scale_url }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  const topo_scale_ref = useRef();
  const screen_width = useSelector((state) => state.sift.ioManager.uiState.screenWidth);

  useEffect(() => {
    if (map) {
      dragStuff();
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      dragStuff();
    }
  }, [screen_width, map]);

  const dragStuff = () => {
    const topo_scale_box = document.getElementById("topo-scale-box");

    const map_edge_bounds = map.getContainer().getBoundingClientRect();

    dragElement(topo_scale_box);

    function dragElement(topo_scale_box) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;

      if (document.getElementById(topo_scale_box.id + "-header")) {
        document.getElementById(topo_scale_box.id + "-header").onmousedown = dragMouseDown;
      } else {
        topo_scale_box.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;

        // Get the current bounds of the cale box
        const topo_scale_box_bounds = topo_scale_box.getBoundingClientRect();

        // Check if the new position is within map bounds
        if (
          topo_scale_box_bounds.left - pos1 >= map_edge_bounds.left &&
          topo_scale_box_bounds.right - pos1 <= map_edge_bounds.right &&
          topo_scale_box_bounds.top - pos2 >= map_edge_bounds.top &&
          topo_scale_box_bounds.bottom - pos2 <= map_edge_bounds.bottom
        ) {
          // set the element's new position:
          topo_scale_box.style.top = topo_scale_box.offsetTop - pos2 + "px";
          topo_scale_box.style.left = topo_scale_box.offsetLeft - pos1 + "px";
        }
      }

      function closeDragElement() {
        /* stop moving when mouse button is let up*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  };

  return (
    <TopoScaleWrap id="topo-scale-box" ref={topo_scale_ref}>
      {/* <section className="topo-scale-box-header">Click here to move</section> */}
      <img src={topo_scale_url} alt="Topo Scale" />
    </TopoScaleWrap>
  );
};

export { MapboxTopoScaleLayer };
