import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Marker, Popup, useMap } from "react-map-gl";
import { useKey } from "../../Map/components/MapEventHandler/useKey";
import { siftActions, inputsActions } from "../../Redux";
import { invisibleMarker } from "../../../assets/images";
import { Spinner } from "../../Spinner";
import { Radio } from "antd";

const MapboxOffset = ({ feature_id }) => {
  const dispatch = useDispatch();

  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const show_offset_tool = useSelector((state) => state.sift.ioManager.uiState.show_offset_tool);
  const site_features = useSelector((state) => state.inputs.site_features);
  const feature = site_features[selectedFeatureId];
  const offsetType = useSelector((state) => state.inputs.offset_type);

  const [offset_loading, set_offset_loading] = useState(false);
  const [menu_latlng, set_menu_latlng] = useState({ lat: undefined, lng: undefined });
  const [offset_distance, set_offset_distance] = useState(25);

  let offset_poly_hotkey = useKey("o"); // Hook for hotkey

  const { current: mapRef } = useMap();
  const map = mapRef.getMap();

  useEffect(() => {
    if (!map) return;
    map.on("toggleOffset", (e) => toggle_offset_polygon(e));
  }, [map]);

  const updateOffSetType = (event) => {
    dispatch(inputsActions.update_offset_type("offset_type", event.target.value));
  };

  useEffect(() => {
    if (selectedFeatureId !== feature_id) return;

    if (!feature) return;

    if (offset_poly_hotkey && feature_id === selectedFeatureId) {
      toggle_offset_polygon();
    }
  }, [offset_poly_hotkey]);

  useEffect(() => {
    if (!feature) return;
    set_menu_latlng({ lat: feature.properties.center[1], lng: feature.properties.center[0] });
  }, [selectedFeatureId, feature]);

  // Convert feet to meters
  function feetToMeters(feet) {
    return feet * 0.3048;
  }

  const offsetPolygon = (offsetDirection, saveOffset = false) => {
    let distance = offsetType === "ft" ? feetToMeters(offset_distance) : offset_distance;
    console.log("distance - " + distance);
    console.log("feature", feature);
    dispatch(siftActions.offsetPolygon(feature, parseFloat(distance) * (offsetDirection === "inside" ? -1 : 1)));
    set_offset_loading(false);
    set_offset_distance(25);
  };

  const toggle_offset_polygon = (e = undefined) => {
    if (selectedFeatureId !== feature_id) return;
    if (e && e.selected_from === "context") {
      dispatch(inputsActions.update_site_input("selectedFeatureId", e.id));
      setTimeout(() => {
        dispatch(siftActions.updateUIState("show_offset_tool", !show_offset_tool));
      }, 500);
    } else {
      dispatch(siftActions.updateUIState("show_offset_tool", !show_offset_tool));
    }
  };

  if (!show_offset_tool) return null;

  return (
    <>
      {feature && feature_id === selectedFeatureId && show_offset_tool && menu_latlng.lat && menu_latlng.lng && (
        <Marker latitude={menu_latlng.lat} longitude={menu_latlng.lng} anchor="bottom">
          <img src={invisibleMarker} alt="Invisible Marker" />
          <Popup
            latitude={menu_latlng.lat}
            longitude={menu_latlng.lng}
            closeButton={true}
            onClose={() => dispatch(siftActions.updateUIState("show_offset_tool", false))}
            onOpen={() => console.log("Popup opened")}
          >
            <section className="offset-tool" style={{ width: 275 }}>
              <div className="offset-tool-close">
                {offset_loading && (
                  <div className="offset-loading">
                    <p>Loading</p>
                    <Spinner color={"var(--primary-brand-color)"} />
                  </div>
                )}
              </div>
              <div className="offset-tool-input">
                <label>Offset Distance </label>
                <Radio.Group buttonStyle="solid" size="small" value={offsetType} onChange={updateOffSetType}>
                  <Radio.Button key="feet" value="ft" className="offset-tool-button">
                    ft
                  </Radio.Button>
                  <Radio.Button key="meter" value="m" className="offset-tool-button">
                    m
                  </Radio.Button>
                </Radio.Group>
              </div>

              <div className="offset-tool-controls">
                <input
                  //   style={{ outlineColor: "var(--primary-brand-color", width: 134 }}
                  type="number"
                  step="1"
                  value={offset_distance}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => set_offset_distance(e.target.value)}
                />

                <section className="offset-tool-buttons">
                  <button onClick={() => offsetPolygon("inside", true)}>Inside</button>
                  <button onClick={() => offsetPolygon("outside", true)}>Outside</button>
                </section>
              </div>
            </section>
          </Popup>
        </Marker>
      )}
    </>
  );
};

export { MapboxOffset };
