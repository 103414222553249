import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { UserActions, authActions, siftActions, inputsActions, accountActions } from "../Redux/_actions";
import { default_inverter_inputs, simple_inverter } from "../Redux";
import { Plan, Billing, UserSettings } from "./_components/_containers";
import { plan_specs } from "../Funnel";

import { Tabs, Spin, Card, Button, Alert, Modal as AntModal } from "antd";
import { Modal } from "../BaseComponents";
const TabPane = Tabs.TabPane;
import { SolutionOutlined, BookOutlined, UserOutlined, DiffOutlined, InfoCircleFilled, BugOutlined } from "@ant-design/icons";
import { ManagePlanColumn, FreeTrialColumn } from "./_components/_containers/ContainerStyles";

import { Skeleton, Input, Divider, Popconfirm, message, Radio, Checkbox, Switch } from "antd";
import { getAgreement, Agreement } from "../../components/Auth/agreement";
const { TextArea } = Input;

import config from "config";
import fetchUtil from "../Redux/_services/fetchUtil";

const styles = {
  root: {
    display: "inline-block",
    borderRadius: 3,
    overflow: "hidden",
    color: "rgba(0, 0, 0, 0.85)",
    lineHeight: "30px",
    width: "500px",
    paddingTop: "15px",
  },
  content: {
    left: "30px",
    position: "relative",
  },
  skeleton: {
    padding: "7%",
  },
  input: {
    width: "128px",
    invoice: {
      width: "175px",
    },
  },
  label: {
    display: "inline-block",
    width: "75px",
    textAlign: "right",
    paddingRight: "5px",
  },
  save: {
    left: "373px",
    top: "51px",
    // position: 'absolute',
    zIndex: 100,
  },
};
const agreement = (
  <div style={{ height: 500, overflowY: "auto" }}>
    <div dangerouslySetInnerHTML={getAgreement()} />
  </div>
);

function show_agreement() {
  AntModal.success({
    title: "Terms and Conditions",
    content: agreement,
    footer: null,
    width: "80%",
    className: "sift-modal",
    style: { maxWidth: "1300px" },
    icon: null,
    maskClosable: true,
    closable: true,
    okText: "Close",
    okType: "ghost",
  });
}
function password_reset(email) {
  const requestOptions = {
    method: "POST",
    //headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
    }),
  };
  // fetch(`${config.apiUrl}/api/password_reset/`, requestOptions).then(handleResponse);
  fetchUtil(`${config.apiUrl}/api/password_reset/`, requestOptions, false, handleResponse);
}
function handleResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);
    if (!response.ok) {
      message.error("Error requesting reset.", 2.5);
      return Promise.reject(error);
    } else {
      message.success("Please check your email for reset link.", 2.5);
    }
    return data;
  });
}

const Account = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const getting_user_data = useSelector((state) => state.user.getting_user_data);
  const funnel_visible = useSelector((state) => state.user.funnel_visible);
  const platform = useSelector((state) => state.user.platform);
  const local_run_count = useSelector((state) => state.sift.local_run_count);

  const [plan_updating, set_plan_updating] = useState(false);
  const [saving_user, set_saving_user] = useState(false);
  const [tab, set_tab] = useState(1);

  const [local_user, set_local_user] = useState({
    invoice_name: user ? user.invoice_name : undefined,
    invoice_company: user ? user.company : undefined,
    invoice_address: user ? user.invoice_address : undefined,
    default_lat: user ? user.map_center[0] : undefined,
    default_lng: user ? user.map_center[1] : undefined,
    use_random_map_location: user ? user.use_random_map_location : undefined,
    platform: platform,
    en_target_inputs: user ? user.en_target_inputs : undefined,
  });

  useEffect(() => {
    if (!user) return;
    set_local_user({
      invoice_name: user.invoice_name,
      invoice_company: user.company,
      invoice_address: user.invoice_address,
      default_lat: user.map_center[0],
      default_lng: user.map_center[1],
      use_random_map_location: user.use_random_map_location,
      platform: platform,
      en_target_inputs: user.en_target_inputs,
    });
  }, [user]);

  const visible = useSelector((state) => state.sift.ioManager.uiState.account_visible);

  const handleToggleOff = () => {
    dispatch(siftActions.toggleAccountModal(false));
  };

  const toggleTab = (key) => {
    // console.log("what now?", key);
    set_tab(key);
  };

  const handleInputUpdate = (key, value) => {
    if (key == "default_lat" || key == "default_lng") {
      let changed_value = value.trimEnd().replace("°", "");
      set_local_user({ ...local_user, [key]: changed_value });
    } else {
      set_local_user({ ...local_user, [key]: value });
    }
  };

  const handleLogOut = () => {
    dispatch(authActions.logout());
  };

  const handleSaveChanges = () => {
    dispatch(UserActions.updateUserData(local_user));
    set_saving_user(true);
    setTimeout(() => {
      let bulk_inputs = local_user.en_target_inputs == 1 ? { ...JSON.parse(JSON.stringify(simple_inverter)) } : { ...JSON.parse(JSON.stringify(default_inverter_inputs)) };
      dispatch(inputsActions.update_bulk_inputs(bulk_inputs));
      set_saving_user(false);
    }, 2000);
  };

  const PlanTab = () => {
    return (
      <section style={{ display: "flex" }}>
        <SolutionOutlined style={{ marginRight: 5 }} />
        <p>Plan</p>
      </section>
    );
  };

  const BillingTab = () => {
    return (
      <section style={{ display: "flex" }}>
        <BookOutlined style={{ marginRight: 5 }} />
        <p>billing</p>
      </section>
    );
  };

  const UserTab = () => {
    return (
      <section style={{ display: "flex" }}>
        <UserOutlined style={{ marginRight: 5 }} />
        <p>User</p>
      </section>
    );
  };

  const onToggleFunnel = () => {
    console.log("funnel_visible", funnel_visible);
    dispatch(accountActions.toggleFunnelVis(!funnel_visible));
  };

  const getStripePortalUrl = (plan_id) => {
    // POST request to our backend to requet a url and then redirect to it
    // console.log("getStripePortalUrl");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ plan: plan_id }),
    };

    fetchUtil(`${config.apiUrl}/billing/portal/`, requestOptions, true, (response) => {
      response.text().then((text) => {
        let resp_d = text && JSON.parse(text);
        if (!response.ok) {
          message.error("Error requesting reset.", 2.5);
          return Promise.reject(error);
        } else {
          window.open(resp_d.data.data.url);
        }
        return resp_d;
      });
    });
  };

  function handleResponse(response) {
    return;
  }

  return (
    <Modal
      title="Account"
      showFooter={false}
      // afterOpenChange={(e) => {
      //   manage_modal_zIndex(e);
      // }}
      open={visible && !funnel_visible}
      onCancel={handleToggleOff}
      footer={null}
      width="80%"
      height="700px"
      bodyStyle={{ padding: "45px" }}
      className="sift-modal"
    >
      <Spin spinning={getting_user_data} tip="Account Loading..." className="sift-loader">
        {!getting_user_data && (
          <>
            {user && user.billing && user.products ? (
              <section style={{ background: "#FFFFFF" }}>
                <div
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    gridTemplateColumns: "50% 50%",
                  }}
                >
                  <div style={{ gridColumn: 1, padding: "10px" }}>
                    <Card title={`Plan: ${user.products[user.product_plan_paid - 1]?.name}`} style={{ height: 650 }} className="account">
                      {/* {user.free_trial_status == 0 && (
                        <FreeTrialColumn>
                          <InfoCircleFilled style={{ fontSize: "13px", color: "#2890FF", marginTop: "5px", marginRight: "8px" }} />
                          <span>
                            You have a 7 day Free Trial for a STANDARD or PRO plan, click <a onClick={() => dispatch(accountActions.toggleFunnelVis(true))}>here</a> to upgrade. Valid payment method is
                            required.
                          </span>
                        </FreeTrialColumn>
                      )} */}
                      {/* Account alerts */}
                      {(user.free_trial_status == 1 || user.free_trial_status == 3) && (
                        <p>
                          SIFT Free Trial expires: <b>{user.free_trial_end_dt}.</b>
                        </p>
                      )}
                      {user.free_trial_status == 2 && user.product_plan > 1 && (
                        <p>
                          Next billing cycle: <b>{user.plan_charge_dt}</b>.
                        </p>
                      )}
                      {user.product_plan_paid > user.product_plan && (
                        <p>
                          You have paid for the <b>{user.products[user.product_plan_paid - 1]?.name}</b> plan this billing cycle. You will be downgraded to the{" "}
                          <b>{user.products[user.product_plan - 1]?.name}</b> plan on {user.plan_charge_dt}. {user.product_plan == 1 && <span>You will no longer be charged for SIFT.</span>}
                        </p>
                      )}

                      {/* Overview of plan */}
                      <section>
                        <Alert
                          className="sift-alert"
                          message="Plan Overview"
                          type="success"
                          showIcon
                          description={
                            <span>
                              <ul
                                style={{
                                  listStyleType: "none",
                                  paddingLeft: "0px",
                                  marginBottom: "0px",
                                  width: "260px",
                                }}
                              >
                                {plan_specs[user.products[user.product_plan_paid - 1]?.name?.toLowerCase()]?.plan_details.map((detail, index) => (
                                  <li key={index}>
                                    {detail.text == "plus_divider" ? null : (
                                      <>
                                        -{detail.text}
                                        <span style={{ float: "right" }}>{detail.component}</span>
                                      </>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </span>
                          }
                        />
                      </section>
                      <br />
                      <br />
                      <br />
                      <section>
                        {/* {user.product_plan > 1 && (
                          <ManagePlanColumn>
                            <Button type="primary" style={{ width: "80%", margin: "5px auto" }} onClick={() => getStripePortalUrl(0)}>
                              CANCEL PLAN
                            </Button>
                          </ManagePlanColumn>
                        )} */}
                        {user.product_plan_paid == 1 && (
                          <ManagePlanColumn>
                            <Button type="primary" style={{ width: "80%", margin: "5px auto" }} onClick={() => onToggleFunnel()}>
                              EXPLORE PLANS
                            </Button>
                          </ManagePlanColumn>
                        )}
                        <ManagePlanColumn>
                          <Button type="primary" style={{ width: "80%", margin: "5px auto" }} onClick={() => getStripePortalUrl(0)}>
                            BILLING PORTAL
                          </Button>
                        </ManagePlanColumn>
                      </section>

                      <br />
                    </Card>
                  </div>

                  <div style={{ gridColumn: 2, padding: "10px" }}>
                    <Card title="User Settings" style={{ height: 650 }} className="account">
                      <div style={styles.root}>
                        <div style={styles.save}>
                          <Button
                            type="primary"
                            ghost
                            icon={<DiffOutlined />}
                            size="small"
                            onClick={() => {
                              handleSaveChanges();
                            }}
                            loading={saving_user}
                          >
                            Save Changes
                          </Button>
                          <Button
                            type="primary"
                            ghost
                            icon={<BugOutlined />}
                            size="small"
                            style={{ marginLeft: 5 }}
                            onClick={() => {
                              dispatch(siftActions.toggleHelpToolbarVisibility("bug_visible", true));
                            }}
                            loading={saving_user}
                          >
                            Submit a bugged project
                          </Button>
                        </div>

                        <Divider className="account" orientation="left">
                          General
                        </Divider>
                        <div style={styles.content}>
                          <div>
                            <label>
                              You're logged in as: <span>{user.email}</span>
                            </label>
                          </div>
                          <div>
                            <span>
                              <a
                                onClick={() => {
                                  show_agreement();
                                }}
                              >
                                Terms and Conditions
                              </a>
                              {" - "}
                              <Popconfirm title="Reset Password?" onConfirm={() => password_reset(user.email)} okText="Yes" cancelText="No">
                                <a>Reset Password</a>
                              </Popconfirm>
                              {" - "}
                              <Popconfirm title="Log Out?" onConfirm={() => handleLogOut()} okText="Yes" cancelText="No">
                                <a>Log Out</a>
                              </Popconfirm>
                            </span>
                          </div>
                        </div>
                        <Divider className="account" orientation="left">
                          Map Defaults and Settings
                        </Divider>
                        <div style={styles.content}>
                          <span style={{ ...styles.label, paddingRight: "4px" }}>
                            <label>Map Center</label>
                          </span>
                          <Radio.Group
                            style={{ marginLeft: "auto" }}
                            value={local_user.use_random_map_location}
                            // disabled={rackType == 2}
                            buttonStyle="solid"
                            size="small"
                            onChange={(event) => {
                              handleInputUpdate(event.target.id, event.target.value);
                            }}
                          >
                            <Radio.Button id="use_random_map_location" value={true}>
                              Random
                            </Radio.Button>
                            <Radio.Button id="use_random_map_location" value={false}>
                              User Defined
                            </Radio.Button>
                          </Radio.Group>
                        </div>
                        <div style={styles.content}>
                          <span style={styles.label}>
                            <label>Latitude</label>
                          </span>
                          <Input
                            id="default_lat"
                            onChange={(event) => {
                              handleInputUpdate(event.target.id, event.target.value);
                            }}
                            defaultValue={local_user.default_lat}
                            addonAfter={"°"}
                            size="small"
                            style={styles.input}
                            disabled={local_user.use_random_map_location}
                          />
                        </div>

                        <div style={styles.content}>
                          <span>
                            <label style={styles.label}>Longitude</label>
                            <Input
                              id="default_lng"
                              onChange={(event) => {
                                handleInputUpdate(event.target.id, event.target.value);
                              }}
                              defaultValue={local_user.default_lng}
                              addonAfter={"°"}
                              size="small"
                              style={styles.input}
                              disabled={local_user.use_random_map_location}
                            />
                          </span>
                        </div>

                        {/* <div style={styles.content}>
                        <label>Enable Config Target Inputs</label>
                        <Switch
                          style={{ marginLeft: 5 }}
                          size="small"
                          id="en_target_inputs"
                          checked={local_user.en_target_inputs == 1}
                          onChange={(checked) => {
                        handleInputUpdate("en_target_inputs", checked ? true : false);
                          }}
                        />
                      </div> */}

                        <Divider className="account" orientation="left">
                          Invoice
                        </Divider>
                        <div style={styles.content}>
                          <span style={styles.label}>
                            <label>Name</label>
                          </span>
                          <Input
                            id="invoice_name"
                            onChange={(event) => {
                              handleInputUpdate(event.target.id, event.target.value);
                            }}
                            value={local_user.invoice_name}
                            size="small"
                            style={styles.input.invoice}
                          />
                        </div>
                        <div style={styles.content}>
                          <span>
                            <label style={styles.label}>Company</label>
                            <Input
                              id="invoice_company"
                              onChange={(event) => {
                                handleInputUpdate(event.target.id, event.target.value);
                              }}
                              value={local_user.invoice_company}
                              size="small"
                              style={styles.input.invoice}
                            />
                          </span>
                        </div>
                        <div style={styles.content}>
                          <span>
                            <label style={styles.label}>Address</label>
                            <TextArea
                              id="invoice_address"
                              rows={2}
                              onChange={(event) => {
                                handleInputUpdate(event.target.id, event.target.value);
                              }}
                              value={local_user.invoice_address}
                              size="small"
                              style={styles.input.invoice}
                            />
                          </span>
                        </div>
                      </div>

                      {user.user_type == 3 && (
                        <section style={{ paddingBottom: "10px" }}>
                          <Alert
                            className="sift-alert"
                            description="Your account is paid for and managed by your organization, no payment method is required. Your plan features are shown to the left, upgrading or downgrading your plan is disabled. For inquiries about upgrading SIFT to a higher plan, speak with your organization's SIFT accounts manager."
                            type="info"
                            showIcon
                          />
                        </section>
                      )}
                    </Card>
                  </div>
                </div>
              </section>
            ) : (
              <section style={{ padding: "7%" }}>
                <Skeleton active />
              </section>
            )}
          </>
        )}
      </Spin>
    </Modal>
  );
};

export { Account };
