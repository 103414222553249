import React, { useMemo, useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";
import { useSelector, useDispatch } from "react-redux";
import { getCornersOfPolygon } from "../ShadeHelpers";

import { canopyActions, getCenterPointofFeature } from "../../../Redux";

const BuildingEventLayer = ({ building, interactive = true }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  // const building = useSelector((state) => state.canopy.shade_objects[id]);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const selected_shade_object_id_ref = useRef(selected_shade_object_id);

  useEffect(() => {
    selected_shade_object_id_ref.current = selected_shade_object_id;
  }, [selected_shade_object_id]);

  const memoizedBuilding = useMemo(() => building, [building]);

  useEffect(() => {
    if (!map || !draw || !memoizedBuilding) return;

    const memoizedBuildingWithId = {
      ...memoizedBuilding.geoJson,
      id: memoizedBuilding.id,
      properties: {
        ...memoizedBuilding.geoJson.properties,
        shape: "building",
      },
    };

    draw.add(memoizedBuildingWithId);

    map.once("idle", () => {
      selectFeatureOnMap(selected_shade_object_id_ref.current);
    });

    map.on("draw.update", handleUpdate);
    map.on("draw.delete", handleDelete);
    return () => {
      draw.delete(memoizedBuilding.id);
      map.off("draw.update", handleUpdate);
      map.off("draw.delete", handleDelete);
    };
  }, [map, building]);

  const selectFeatureOnMap = (id) => {
    if (!id) return;
    draw.changeMode("direct_select", { featureId: id });
  };

  const handleDelete = (e) => {
    if (e.features[0].id === building.id) {
      // console.log("Deleted feature:", e.features[0].id);
      dispatch(canopyActions.updateShadeObject("delete_shade_object", building.id));
      draw.delete(e.features[0].id);
    }
  };

  const handleUpdate = (e) => {
    if (selected_shade_object_id_ref.current !== building.id) return;
    console.log("updating building", e.features[0]);
    let edited_building = { ...memoizedBuilding };
    edited_building.geoJson = e.features[0];
    edited_building.center = getCenterPointofFeature(e.features[0]);
    edited_building.corners = getCornersOfPolygon(e.features[0]);
    edited_building.rotation = 0;
    dispatch(canopyActions.updateShadeObject("edit_shade_object", edited_building));

    draw.delete(e.features[0].id);
  };

  return null;
};

export { BuildingEventLayer };
