import styled, { css } from "styled-components";

const DropdownWrapper = styled.section`
  position: relative;

  > section > button {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;

    &:hover {
      background: ${(props) => (props.disabled ? "#fff" : null)} !important;
      img {
        filter: ${(props) => (props.disabled ? "none" : null)} !important;
      }
    }
  }
`;

const DropdownStyles = css`
  #root section.dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 13px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    z-index: 1050;
    display: block;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);

    & > ul {
      margin: 0;
      padding: 4px;
      text-align: left;
      list-style-type: none;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 4px;
      outline: none;
      -webkit-transform: translate3d(0, 0, 0);

      .submenu-wrapper {
      }

      li {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 5px;
        /* padding: 5px 12px; */
        color: #333;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        white-space: nowrap;
        cursor: pointer;
        transition: all 0.3s;

        span,
        img {
          margin-right: 5px;
        }

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }
`;

const SubmenuWrapper = styled.li`
  position: relative;
`;

const SubmenuTitle = styled.div`
  padding: 8px 20px;
  cursor: pointer;
`;

const SubmenuContainer = styled.ul`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: var(--subtle-box-shadow);
  z-index: 1000;
  margin: 0;
  padding: 5px;
  list-style: none;
  border-radius: 4px;
  white-space: nowrap;
`;

const SubmenuItem = styled.li`
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export { DropdownWrapper, DropdownStyles, SubmenuWrapper, SubmenuTitle, SubmenuContainer, SubmenuItem };
