import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";

import { canopyActions, usePrevious, getCenterOfLineString } from "../../../Redux";
import { build_shade_object, getCornersOfPolygon } from "../ShadeHelpers";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";

const DrawTreeLine = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  const drawing_tree_line = useSelector((state) => state.canopy.drawing_tree_line);
  const prev_drawing_tree_line = usePrevious(drawing_tree_line);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const drawing_tree_line_ref = useRef(drawing_tree_line);
  const cancel_key = useKey("escape");

  useEffect(() => {
    drawing_tree_line_ref.curent = drawing_tree_line;
    if (!prev_drawing_tree_line && drawing_tree_line) {
      drawTreeLine();
    } else if (prev_drawing_tree_line && !drawing_tree_line) {
      cancelDraw();
    }
  }, [drawing_tree_line]);

  useEffect(() => {
    if (cancel_key && drawing_tree_line) {
      cancelDraw();
    }
  }, [cancel_key, drawing_tree_line]);

  useEffect(() => {
    if (!map || !draw) return;

    map.on("draw.create", handleCreated);

    return () => {
      map.off("draw.create", handleCreated);
    };
  }, [map, draw]);

  const handleCreated = (e) => {
    if (!draw) return;
    if (!drawing_tree_line_ref.curent) return;
    console.log("creating a treeline");
    const geoJson = e.features[0];
    const center = getCenterOfLineString(geoJson);
    const corners = geoJson.geometry.coordinates[0];
    let shade_object = build_shade_object(geoJson, corners, center, shade_height_unit, "treeLine");
    dispatch(canopyActions.updateShadeObject("add_shade_object", shade_object));
    setTimeout(() => {
      draw.delete(e.features[0].id);
    }, 1500);
  };

  const drawTreeLine = () => {
    draw.changeMode("draw_line_string");
  };

  const cancelDraw = () => {
    draw.changeMode("simple_select");
    dispatch(canopyActions.update_canopy_toolbar("drawing_tree_line", false));
  };

  return null;
};

export { DrawTreeLine };
