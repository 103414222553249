import { useEffect, useMemo } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../DrawToolProvider";
import { useKey } from "../../Map/components/MapEventHandler/useKey";
import { useDispatch, useSelector } from "react-redux";
import { inputsActions } from "../..";

const HandleFeatureDelete = ({ feature }) => {
  const dispatch = useDispatch();
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const draw = useMapboxDraw();

  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);
  const topo_id = useSelector((state) => state.inputs.topo_id);

  const memoizedFeature = useMemo(() => feature, [feature]);

  const delete_feature_hotkey_mac = useKey("backspace");
  const delete_feature_hotkey = useKey("delete");

  // const mapHasFocus = () => {
  //   return document.querySelector("canvas.mapboxgl-canvas") == document.activeElement;
  // };

  useEffect(() => {
    if (!selectedFeatureId) return;
    if (delete_feature_hotkey || delete_feature_hotkey_mac) handleDeleteFromHotkey(selectedFeatureId);
  }, [delete_feature_hotkey, delete_feature_hotkey_mac]);

  useEffect(() => {
    if (!map) return;
    map.on("deleteAllFeatures", () => deleteAllFeatures());
  }, [map]);

  const deleteAllFeatures = () => {
    draw.deleteAll();

    dispatch(inputsActions.update_input("site_features", []));

    if (topo_id) {
      dispatch(inputsActions.clear_topo_data());
    }
  };

  useEffect(() => {
    if (!map || !draw || !memoizedFeature) return;
    // console.log("the memorized feature:", memoizedFeature);

    map.on("draw.delete", handleDelete);

    return () => {
      map.off("draw.delete", handleDelete);
    };
  }, [map, memoizedFeature, memoizedFeature.properties.identity]);

  const handleDelete = (e) => {
    if (e.features[0].properties.index === feature.properties.index) {
      // console.log("Deleted feature:", e.features[0].id);
      dispatch(inputsActions.update_site_input("remove_site_feature", e.features[0].properties.index));
      draw.delete(e.features[0].properties.index);
    }
  };

  const handleDeleteFromHotkey = (id) => {
    dispatch(inputsActions.update_site_input("remove_site_feature", id));
    draw.delete(id);
  };
  return null;
};

export { HandleFeatureDelete };
