import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";

import { canopyActions, usePrevious, getCenterPointofFeature } from "../../../Redux";
import { build_shade_object, getCornersOfPolygon } from "../ShadeHelpers";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";

const DrawBuilding = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  const drawing_building = useSelector((state) => state.canopy.drawing_building);
  const prev_drawing_building = usePrevious(drawing_building);
  const shade_height_unit = useSelector((state) => state.canopy.shade_height_unit);

  const drawing_building_ref = useRef(drawing_building);
  const cancel_key = useKey("escape");

  useEffect(() => {
    drawing_building_ref.current = drawing_building;
    if (!prev_drawing_building && drawing_building) {
      drawBuilding();
      // document.getElementById("map").style.cursor = "crosshair";
    } else if (prev_drawing_building && !drawing_building) {
      cancelDraw();
      // document.getElementById("map").style.cursor = "grab";
    }
  }, [drawing_building]);

  useEffect(() => {
    if (cancel_key && drawing_building) {
      cancelDraw();
    }
  }, [cancel_key, drawing_building]);

  useEffect(() => {
    if (!map || !draw) return;

    map.on("draw.create", handleCreated);

    return () => {
      map.off("draw.create", handleCreated);
    };
  }, [map, draw]);

  const handleCreated = (e) => {
    if (!draw) return;
    if (!drawing_building_ref.current) return;
    console.log("creaing a building");
    const geoJson = e.features[0];
    const center = getCenterPointofFeature(geoJson);
    const corners = getCornersOfPolygon(geoJson);
    let shade_object = build_shade_object(geoJson, corners, center, shade_height_unit, "building");
    dispatch(canopyActions.updateShadeObject("add_shade_object", shade_object));
    setTimeout(() => {
      draw.delete(e.features[0].id);
    }, 1500);
  };

  const drawBuilding = () => {
    draw.changeMode("draw_rectangle");
  };

  const cancelDraw = () => {
    draw.changeMode("simple_select");
    dispatch(canopyActions.update_canopy_toolbar("drawing_building", false));
  };

  return null;
};

export { DrawBuilding };
