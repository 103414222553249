import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMap, useMapEvents, TileLayer } from "react-leaflet";
import { TopoScaleLayer } from "./TopoScaleLayer/TopoScaleLayer";

import { BingLayer, EsriLayer, Esri_FeatureLayer, HotkeyTipBox, MapMessageBox } from "../";

import { inputsActions } from "../../../Redux";

const mapToken = "pk.eyJ1IjoibnNhbmRlcnNvbngiLCJhIjoiY2x1c2I0aW43MDEyNDJqbnVoazNzZnhnMSJ9.gkvHjLK-5PZAJp8x5t52CQ";

import "./BingLayer/leaflet.bing";
import { accessToken } from "../../../Redux/_reducers/authentication.reducer";

const TileSets = {
  Mapbox: { type: "LD", data: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${mapToken}` },
  Satellite: { type: "LD", data: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" },
  StreetMap: { type: "LD", data: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" },
  // Test: { type: "LD", data: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}" },
  // SatelliteHD: { type: "HD", data: "Aerial" },
  // StreetMapHD: { type: "HD", data: "Road" },
  // Satellite_With_Labels: { type: "HD", data: "AerialWithLabels" },
};

const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

const TopoModeMapper = {
  ele: "vis_ele_ungraded_avail",
  NS: "vis_ns_ungraded_avail",
  EW: "vis_ew_ungraded_avail",
  U: "vis_max_ungraded_avail",
  // "ele/G": "vis_ele_graded_avail",
  "ele/G": "graded_avail",
  "NS/G": "vis_ns_graded_avail",
  "EW/G": "vis_ew_graded_avail",
  "U/G": "vis_max_graded_avail",
  // CF: "vis_g_ungraded_avail",
  CF: "graded_avail",
  Off: "Off",
};

export const MapTileLayer = ({ position, liveReportMap = false, tileLayer = undefined }) => {
  // get the map from parent
  const map = useMap();
  const dispatch = useDispatch();

  const inputs = useSelector((state) => state.inputs);
  const bounding_box = useSelector((state) => state.inputs.boundary_bbox);
  const topo_mode = useSelector((state) => state.inputs.topo_mode);
  const topo_id = useSelector((state) => state.inputs.topo_id);
  const topo_url = useSelector((state) => state.inputs.topo_url);
  const topo_scale_url = useSelector((state) => state.inputs.topo_scale_url);
  const show_live_report = useSelector((state) => state.sift.ioManager.uiState.show_live_report);
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);

  const tile_set = useSelector((state) => (tileLayer ? tileLayer : state.inputs.uiState.tile_set || "Mapbox"));
  const wetlands_on = useSelector((state) => state.inputs.uiState.additional_layers.wetlands_on);
  const riparian_on = useSelector((state) => state.inputs.uiState.additional_layers.riparian_on);
  const fema_on = useSelector((state) => state.inputs.uiState.additional_layers.fema_on);
  // const transmission_lines_on = useSelector((state) => state.inputs.uiState.additional_layers);
  const additonalMapLayers = useSelector((state) => state.inputs.uiState.additional_layers);
  const user = useSelector((state) => state.user);

  const [loading_tiles_err, set_loading_tiles_err] = useState(false);
  // const [map_bounds, set_map_bounds] = useState(undefined);
  // const [trans_line, set_trans_line] = useState(`https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/US_Electric_Power_Transmission_Lines/FeatureServer/0`);

  // const bing_key = "Apg1Wo2sThhv7onPyHncSIy7J3Nn98FFIzAhWYNFDrNLDpCRkZaSic1uuBHRoppM";

  // useEffect(() => {
  //   if (!map_bounds) return;
  //   let transmission_line_url = `https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/US_Electric_Power_Transmission_Lines/FeatureServer/0/query?geometry=${map_bounds._southWest.lng}, ${map_bounds._southWest.lat}, ${map_bounds._northEast.lng}, ${map_bounds._northEast.lat}`;
  //   set_trans_line(transmission_line_url);
  // }, [map_bounds]);

  // useEffect(() => {
  //   if (!map) return;
  //   set_map_bounds(map.getBounds());
  // }, [map]);

  // useEffect(() => {
  //   map.on("zoomend", () => set_map_bounds(map.getBounds()));
  //   map.on("dragend", () => set_map_bounds(map.getBounds()));

  //   return () => {
  //     map.off("zoomend");
  //     map.off("dragend");
  //   };
  // }, []);
  // useEffect(() => {
  //   if (loading_tiles_err) {
  //     alert("It looks like there might have been an error loading map tiles. If the issue is affecting your project, try switching to a different map layer via the Map Toolbar.");
  //   }
  // }, [loading_tiles_err]);

  useEffect(() => {
    L.TileLayer.include({
      _tileOnError: function (done, tile, e) {
        //we only want this to run one time on each page refresh, but this never gets toggled back and forth from true to false or vice versa
        // console.log("map tile load error");
        // set_loading_tiles_err(true);
        var errorUrl = this.options.errorTileUrl;
        if (errorUrl) {
          tile.src = errorUrl;
        }
        done(e, tile);
      },
    });
  }, []);

  const [zoom, setZoom] = useState(map.getZoom());
  const [showHotKeyPopup, setShowHotKeyPopup] = useState(true);

  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";

  useMapEvents({
    checkTileLayer: () => checkTileLayer(),
  });

  useEffect(() => {
    if (!user.getting_user_data) checkTileLayer();
  }, [user.getting_user_data]);

  useEffect(() => {
    if (map) map.invalidateSize();
  }, [map]);

  useEffect(() => {
    setZoom(map.getZoom());
  }, [map.getZoom()]);

  const hideHotKeyTipsSection = () => {
    setShowHotKeyPopup(false);
  };
  const checkTileLayer = () => {
    dispatch(inputsActions.update_ui_state("tile_set", "Mapbox"));

    // if (!user.getting_user_data) {
    //   dispatch(inputsActions.update_ui_state("tile_set", "SatelliteHD"));
    // } else {
    //   dispatch(inputsActions.update_ui_state("tile_set", "Satellite"));
    // }
  };

  const [layerLoading, setLayerLoading] = useState({
    wetlands_on: false,
    riparian_on: false,
    fema_on: false,
    transmission_lines_on: false,
    gas_lines_on: false,
    railway_lines_on: false,
  });

  const onChangeLayerLoading = (key, value) => {
    setLayerLoading((pre) => ({
      ...pre,
      [key]: value,
    }));
  };

  return (
    <>
      {/* {user && TileSets[tile_set].type == "HD" ? ( */}
      {false ? (
        <BingLayer key={tile_set} bingkey={bing_key} type={TileSets[tile_set].data} maxZoom={25} maxNativeZoom={18} tileerror={(e) => console.log("Error loading tile, probably nothing exists", e)} />
      ) : (
        <TileLayer
          key={tile_set}
          url={TileSets[tile_set].data}
          errorTileUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          tileerror={(e) => console.log("Error loading tile, probably nothing exists", e)}
          zIndex={9}
          maxZoom={20}
          maxNativeZoom={19}
        />
      )}
      {/* Topo Layer */}
      {topo_id && topo_mode != "Off" && inputs[TopoModeMapper[topo_mode]] && !using_canopy && (
        <>
          <TileLayer
            key={`${topo_url}_${inputs[TopoModeMapper[topo_mode]]}`}
            url={topo_url}
            opacity={0.7}
            tms
            zIndex={10}
            options={{ errorTileUrl: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" }}
            crossOrgin
            tileerror={(e) => console.log("Error loading tile, probably nothing exists", e)}
          />
          {topo_scale_url && !show_live_report && !using_canopy && <TopoScaleLayer topo_scale_url={topo_scale_url} />}
        </>
      )}
      {/* Riparian Layer */}
      {/* Riparian Layer */}
      {riparian_on && (
        <EsriLayer
          key="rip_layer"
          url="https://fwsprimary.wim.usgs.gov/server/rest/services/Riparian/MapServer"
          opacity={0.5}
          zIndex={9}
          eventHandlers={{
            loading: () => onChangeLayerLoading("riparian_on", true),
            load: () => onChangeLayerLoading("riparian_on", false),
          }}
        />
      )}
      {/* {riparian_on && (
        <Esri_FeatureLayer
          key="rip_layer"
          // url={riparian0}
          layer_type={"riparian"}
          url="https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer/0"
          // url="https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Riparian/MapServer/0"
          // url="https://fwsprimary.wim.usgs.gov/server/rest/services/Riparian/MapServer"
          opacity={0.5}
          zIndex={9}
          eventHandlers={{
            loading: () => onChangeLayerLoading("riparian_on", true),
            load: () => onChangeLayerLoading("riparian_on", false),
          }}
        />
      )} */}
      {/* Wetlands layer */}
      {wetlands_on && (
        <>
          <EsriLayer
            key="wet_layer"
            url="https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Wetlands/MapServer"
            zIndex={9}
            transparent={true}
            opacity={0.5}
            eventHandlers={{
              loading: () => onChangeLayerLoading("wetlands_on", true),
              load: () => onChangeLayerLoading("wetlands_on", false),
            }}
          />
        </>
      )}
      {fema_on && (
        <Esri_FeatureLayer
          key="fema_layer"
          // url="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer"
          url="https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0"
          // layers={['Flood Hazard Zones', 'Flood Hazard Boundaries']}
          eventHandlers={{
            loading: () => onChangeLayerLoading("fema_on", true),
            load: () => onChangeLayerLoading("fema_on", false),
          }}
        />
      )}
      {/* {wetlands_on && (
        <>
          <EsriLayer
            key="wet_layer"
            url="https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Wetlands/MapServer"
            // url="https://fwsprimary.wim.usgs.gov/server/rest/services/Wetlands/MapServer"
            zIndex={9}
            transparent={true}
            opacity={0.5}
            eventHandlers={{
              loading: () => onChangeLayerLoading("wetlands_on", true),
              load: () => onChangeLayerLoading("wetlands_on", false),
            }}
          />
        </>
      )} */}
      {/* {wetlands_on && (
        <>
          <Esri_FeatureLayer
            key="wet_layer"
            layer_type={"wetland"}
            url="https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Wetlands/FeatureServer/0" //json layer
            // url="https://fwsprimary.wim.usgs.gov/server/rest/services/Wetlands/MapServer"
            // url="https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Wetlands/FeatureServer/0/query?where=&objectIds=&time=&geometry=-82.356572,29.716114,-82.345116,29.72427&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=10&units=esriSRUnit_Meter&supportsQueryWithDistance=true&relationParam=&returnGeodetic=false&outFields=&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token="
            // url="https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Wetlands/FeatureServer/0/query?where=&objectIds=&time=&geometry=-180,-66.51326044311186,-90,0&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=10&units=esriSRUnit_Meter&supportsQueryWithDistance=true&relationParam=&returnGeodetic=false&outFields=&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=3857&defaultSR=3857&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token="

            zIndex={9}
            transparent={true}
            opacity={0.5}
            eventHandlers={{
              loading: () => onChangeLayerLoading("wetlands_on", true),
              load: () => onChangeLayerLoading("wetlands_on", false),
            }}
          />
        </>
      )} */}
      {/* FEMA Layers */}
      {/* {fema_on && (
        <EsriLayer
          key="fema_layer"
          url="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer"
          // layers={['Flood Hazard Zones', 'Flood Hazard Boundaries']}
          eventHandlers={{
            loading: () => onChangeLayerLoading("fema_on", true),
            load: () => onChangeLayerLoading("fema_on", false),
          }}
        />
      )}			
      {/* {fema_on && (
        <EsriLayer
          key="fema_layer"
					layer_type={"fema"}
          url="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/28"
          // url="https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0"
          // layers={['Flood Hazard Zones', 'Flood Hazard Boundaries']}
          eventHandlers={{
            loading: () => onChangeLayerLoading("fema_on", true),
            load: () => onChangeLayerLoading("fema_on", false),
          }}
        />
      )} */}
      {/* {fema_on && (
        <Esri_FeatureLayer
          key="fema_layer"
          layer_type={"fema"}
          // url="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/28"
          // url="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/28?f=pjson"
          url="https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0"
          // url="https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0/query?f=pbf&geometry=-19018759.0796,-1617292.3218,16233965.0371,10040669.8803&maxRecordCountFactor=4&resultOffset=&resultRecordCount=8000&where=&quantizationParameters=&resultType=tile&returnCentroid=true&spatialRel=esriSpatialRelIntersects&geometryType=esriGeometryEnvelope&defaultSR=102100&f=pgeojson&token=" //fema working
          // url="https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0/query?f=pbf&geometry=-92.356572,30.716114,-93.345116,30.77427&maxRecordCountFactor=4&resultOffset=&resultRecordCount=8000&where=&quantizationParameters=&resultType=tile&returnCentroid=true&spatialRel=esriSpatialRelIntersects&geometryType=esriGeometryEnvelope&defaultSR=102100&f=pgeojson&token=" //fema
          // layers={['Flood Hazard Zones', 'Flood Hazard Boundaries' -82.356572,15.716114,-60.345116,30.72427]}
          eventHandlers={{
            loading: () => onChangeLayerLoading("fema_on", true),
            load: () => onChangeLayerLoading("fema_on", false),
          }}
        />
      )}
      {/* {fema_on && (
        <Esri_FeatureLayer
          key="fema_layer"
          layer_type={"fema"}
          // url="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/28"
          // url="https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer/28?f=pjson"
          // url="https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0"
          // url="https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0/query?f=pbf&geometry=-19018759.0796,-1617292.3218,16233965.0371,10040669.8803&maxRecordCountFactor=4&resultOffset=&resultRecordCount=8000&where=&quantizationParameters=&resultType=tile&returnCentroid=true&spatialRel=esriSpatialRelIntersects&geometryType=esriGeometryEnvelope&defaultSR=102100&f=pgeojson&token=" //fema working
          url="https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Flood_Hazard_Reduced_Set_gdb/FeatureServer/0/query?f=pbf&geometry=-92.356572,30.716114,-93.345116,30.77427&maxRecordCountFactor=4&resultOffset=&resultRecordCount=8000&where=&quantizationParameters=&resultType=tile&returnCentroid=true&spatialRel=esriSpatialRelIntersects&geometryType=esriGeometryEnvelope&defaultSR=102100&f=pgeojson&token=" //fema

          // layers={['Flood Hazard Zones', 'Flood Hazard Boundaries' -82.356572,15.716114,-60.345116,30.72427]}
          eventHandlers={{
            loading: () => onChangeLayerLoading("fema_on", true),
            load: () => onChangeLayerLoading("fema_on", false),
          }}
        />
      )} */}
      {additonalMapLayers.transmission_lines_on && (
        <Esri_FeatureLayer
          layer_type={"transmission"}
          key={`transmission_lines_layer`}
          // url="https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Transmission_Lines/FeatureServer/0" // electric transmission lines
          url="https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/US_Electric_Power_Transmission_Lines/FeatureServer/0" // electric transmission lines
          // url="https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/US_Electric_Power_Transmission_Lines/FeatureServer/0/query?geometry=-102.356572,30.716114,-133.345116,40.77427&resultRecordCount=500" // electric transmission lines
          // url={trans_line} // electric transmission lines
          eventHandlers={{
            loading: () => onChangeLayerLoading("transmission_lines_on", true),
            load: () => onChangeLayerLoading("transmission_lines_on", false),
          }}
        />
      )}
      {additonalMapLayers.gas_lines_on && (
        <Esri_FeatureLayer
          layer_type={"gasline"}
          key="gas_lines_layer"
          url="https://services2.arcgis.com/FiaPA4ga0iQKduv3/arcgis/rest/services/Natural_Gas_Interstate_and_Intrastate_Pipelines_1/FeatureServer/0"
          // url="https://services1.arcgis.com/Hp6G80Pky0om7QvQ/arcgis/rest/services/Natural_Gas_Liquid_Pipelines/FeatureServer/0" //gas pipelines
          eventHandlers={{
            loading: () => onChangeLayerLoading("gas_lines_on", true),
            load: () => onChangeLayerLoading("gas_lines_on", false),
          }}
        />
      )}
      {additonalMapLayers.railway_lines_on && (
        <Esri_FeatureLayer
          layer_type={"railway"}
          key="railway_lines_layer"
          // url="https://tigerweb.geo.census.gov/arcgis/rest/services/TIGERweb/Transportation/MapServer/9" // railway lines
          url="https://services3.arcgis.com/6rJKAjBRDRSfjCzV/arcgis/rest/services/Norfolk_Southern_Railway/FeatureServer/0" // Norfolk_Southern_Railway railway lines
          eventHandlers={{
            // loading: () => set_railway_lines_loading(true),
            // load: () => set_railway_lines_loading(false),
            loading: () => onChangeLayerLoading("railway_lines_on", true),
            load: () => onChangeLayerLoading("railway_lines_on", false),
          }}
        />
      )}
      {!liveReportMap && <MapMessageBox position="bottomleft" layerLoading={layerLoading} />}
      {/* {selectedFeatureId && (<HotkeyTipBox  onRemove={hideHotKeyTipsSection} showHotKeyPopup={showHotKeyPopup}/>)} */}
    </>
  );
};

// I found this one for differnt kind of data
// https://nrt3.modaps.eosdis.nasa.gov/archive/

// // https://nrt3.modaps.eosdis.nasa.gov/archive/allData/61/AM1EPHNE/
// https://lance.modaps.eosdis.nasa.gov/modis/

// sift parsers repo nasa's api

// https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Wetlands/FeatureServer/0/query?where=&objectIds=&time=&geometry=-82.356572%2C29.716114%2C-82.345116%2C29.72427&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=standard&distance=10&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields=&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=

// API docs
